import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, Navigate } from 'react-router-dom';
import { SkeletonLine, Spinner } from '@crazyegginc/hatch';

import { DashboardPage } from '/src/components/Page';
import { DashHeader } from '/src/components/headers/DashHeader';
import { SitePickerBar } from '/src/components/site-picker/index';
import { SurveyResults } from '/src/features/addons/surveys/results/SurveyResults';
import { CTAResults } from '/src/features/addons/ctas/results/CTAResults';

import { usePermissions } from '/src/hooks';
import { addonDetailQuery } from '/src/features/addons/queries';

import { FEATURES } from '/src/features/_global/constants';
import { ADDON_TYPES } from '/src/features/addons/constants';

// eslint-disable-next-line no-restricted-syntax
export default function AddonResults() {
  const { id } = useParams();
  const permissions = usePermissions();

  const canViewSurveys = permissions.can('view', FEATURES.SURVEYS).allowed;
  const canViewCTAs = permissions.can('view', FEATURES.CTAS).allowed;

  const {
    data,
    isFetching,
    refetch: refetchDetails,
  } = useQuery({
    ...addonDetailQuery({ id }),
  });

  const addon = data?.addonDetail ?? null;

  useEffect(() => {
    if (window.Metrics && addon) {
      if (addon.type === ADDON_TYPES.CTA) {
        window.Metrics.used('CTA: Results');
      } else if (addon.type === ADDON_TYPES.SURVEY) {
        window.Metrics.used('Survey: Results');
      }
    }
  }, [addon]);

  if (isFetching && !addon) {
    return (
      <DashboardPage>
        <DashHeader titleComponent={<SkeletonLine className="w-52" />} />

        <SitePickerBar sites={[]} selectedSite={null} loading={true} hidePageFeedback={true} />

        <div className="flex flex-col p-10">
          <div className="mt-6 flex w-full items-center justify-center">
            <Spinner />
            <div className="ml-2.5">Loading...</div>
          </div>
        </div>
      </DashboardPage>
    );
  }

  if (!isFetching && !addon) {
    return <Navigate replace to="/addons" />;
  }
  if (!isFetching && !addon.publishedAt) {
    if (addon.type === ADDON_TYPES.CTA) {
      return <Navigate replace to={{ pathname: '/addons', search: `type=${FEATURES.CTAS}` }} />;
    } else if (addon.type === ADDON_TYPES.SURVEY) {
      return <Navigate replace to={{ pathname: '/addons', search: `type=${FEATURES.SURVEYS}` }} />;
    }
  }

  if (addon.type === ADDON_TYPES.CTA && canViewCTAs) {
    return <CTAResults cta={addon} />;
  } else if (addon.type === ADDON_TYPES.SURVEY && canViewSurveys) {
    return <SurveyResults survey={addon} refetchDetails={refetchDetails} />;
  } else {
    <Navigate replace to="/addons" />;
  }
}
