import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, useSelectedSite, usePermissions } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as RecordingIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import ErrorTrackingPNG from '@crazyegginc/hatch/dist/images/illustration-error-tracking.png';
import ErrorTrackingWebP from '@crazyegginc/hatch/dist/images/illustration-error-tracking.webp';

export function StartTrackingErrorsWall() {
  const { currentAccount } = useAuthContext();

  const permissions = usePermissions();
  const { selectedSite } = useSelectedSite();
  const canCustomizeRecordings = permissions.can('settings', FEATURES.RECORDINGS).allowed;

  return (
    <Paywall>
      <Paywall.Image webp={ErrorTrackingWebP} fallback={ErrorTrackingPNG} width="204px" height="162px" />

      <Paywall.Title>Start tracking your errors!</Paywall.Title>
      <div className="text-body-2 mt-6 max-w-xl text-center">
        Automatically track Javascript errors on your website that could frustrate or lose visitors. Watch recordings of
        the error happen and get all the important debugging info you need to fix the issue quickly.
      </div>
      <div className="mt-10 flex max-w-xl flex-col items-center rounded border-2 border-mystic-500 p-5">
        <div className="flex items-center justify-between">
          <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-solitude-500">
            <RecordingIcon className="h-5 w-5 fill-current text-dodger-blue-500" />
          </div>
          <div className="text-body-2 text-center">
            We can only track errors for recorded visitor sessions, so to get started, you’ll need to enable the
            recordings feature on your website.
          </div>
        </div>
        <div className="mt-5">
          {canCustomizeRecordings ? (
            <Button component={Link} to={`/recordings/settings/sites/${selectedSite?.id ?? ''}`}>
              Enable recordings
            </Button>
          ) : (
            <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
              Contact account owner
            </Button>
          )}
        </div>
      </div>
    </Paywall>
  );
}
