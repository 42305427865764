import { Panel, Button } from '@crazyegginc/hatch';

import { OptionsHeader } from '../components/OptionsHeader';
import { useHasFeatureFlag } from '/src/hooks';
import { isStaging } from '/src/utils';
import { AUTH_TOKEN_KEY } from '/src/features/_global/constants';

// eslint-disable-next-line no-restricted-syntax
export default function IntegrationsOptions() {
  const hasChannelsFlag = useHasFeatureFlag('channels');
  const token = window.localStorage.getItem(AUTH_TOKEN_KEY);
  const url = isStaging()
    ? `https://channels.staging2.crazyegg.com/slack/oauth?token=${token}`
    : `https://channels.crazyegg.com/slack/oauth?token=${token}`;

  return (
    <>
      <OptionsHeader>Third-Party Integrations</OptionsHeader>
      {hasChannelsFlag ? (
        <Panel className="mb-5">
          <h2 className="text-header-2 mb-5">Slack</h2>
          <Button component="a" href={url} target="_blank" rel="noopener noreferrer">
            Add CrazyEgg to Slack
          </Button>
        </Panel>
      ) : null}
      <Panel>
        <zapier-app-directory
          app="crazy-egg"
          link-target="new-tab"
          theme="light"
          create-without-template="show"
          use-this-zap="show"
        />
      </Panel>
    </>
  );
}
