import { useContext, useReducer, createContext, useState, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { startOfDay, subDays, endOfDay, getUnixTime } from 'date-fns';

import { isObject } from '/src/utils/object';
import { useQueryParams, useHasFeatureFlag } from '/src/hooks';
import { getParam, deleteParam } from '/src/utils/location';
import { getInitialDate } from '/src/utils/date';
import { STEP_TYPES, ANY_VALUE } from './constants';

const SET_FILTER = 'SET_FILTER';
const SET_STEPS = 'SET_STEPS';
const RESET_FILTERS = 'RESET_FILTERS';

const FilterContext = createContext();

const getInitialSteps = (name) => {
  const param = getParam(name);
  let parsed;
  try {
    parsed = JSON.parse(param);
  } catch {
    deleteParam(name);
    return null;
  }
  if (
    Array.isArray(parsed) &&
    parsed.every((x) => isObject(x) && x.type && Object.values(STEP_TYPES).includes(x.type))
  ) {
    return parsed;
  } else {
    deleteParam(name);
    return null;
  }
};

const initialState = (hasPageViewFlag) => ({
  filters: {
    date: getInitialDate(getParam('date')) ?? {
      start_date: startOfDay(subDays(new Date(), 28)),
      end_date: endOfDay(new Date()),
    },
    landingPage: getParam('landingPage') || ANY_VALUE,
    referrerUrl: getParam('referrerUrl') || ANY_VALUE,
    utmSource: getParam('utmSource') || ANY_VALUE,
    utmCampaign: getParam('utmCampaign') || ANY_VALUE,
    utmMedium: getParam('utmMedium') || ANY_VALUE,
    utmTerm: getParam('utmTerm') || ANY_VALUE,
    utmContent: getParam('utmContent') || ANY_VALUE,
    abTestName: getParam('abTestName') || ANY_VALUE,
    abTestVariationName: getParam('abTestVariationName') || ANY_VALUE,
  },
  steps:
    getInitialSteps('steps') ||
    (hasPageViewFlag ? [{ id: null, type: STEP_TYPES.VISIT }] : [{ id: null, type: STEP_TYPES.GOAL }]),
});

export function FunnelProvider({ children }) {
  const hasPageViewFlag = useHasFeatureFlag('page-view');

  function funnelReducer(state, action) {
    switch (action.type) {
      case SET_FILTER:
        return {
          ...state,
          filters: {
            ...state.filters,
            ...action.payload,
          },
        };
      case SET_STEPS:
        return {
          ...state,
          steps: [...action.payload],
        };
      case RESET_FILTERS:
        return {
          ...state,
          filters: {
            ...initialState().filters,
          },
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(funnelReducer, hasPageViewFlag, initialState);
  const [funnelState, setFunnelState] = useState({ state, dispatch });
  if (!isEqual(state, funnelState.state)) {
    setFunnelState({ state, dispatch });
  }
  return <FilterContext.Provider value={funnelState}>{children}</FilterContext.Provider>;
}

export function useFunnelContext() {
  const { set: queryParamsSet, removeAll } = useQueryParams();
  const {
    state: { filters, steps },
    dispatch,
  } = useContext(FilterContext);

  const setDateRange = useCallback(
    (value) => {
      if (value.start_date) {
        queryParamsSet(
          'date',
          JSON.stringify({
            start_date: getUnixTime(value.start_date),
            end_date: getUnixTime(value.end_date),
          }),
        );
      } else {
        queryParamsSet('date', JSON.stringify(value));
      }
      dispatch({ type: SET_FILTER, payload: { date: value } });
    },
    [queryParamsSet, dispatch],
  );

  const setFilterParamValue = useCallback(
    (value) => {
      const [param, paramValue] = Object.entries(value)[0];
      if (paramValue === ANY_VALUE) {
        queryParamsSet(param, '');
      } else {
        queryParamsSet(param, paramValue);
      }
      dispatch({
        type: SET_FILTER,
        payload: value,
      });
    },
    [queryParamsSet, dispatch],
  );

  const setSteps = useCallback(
    (value) => {
      queryParamsSet('steps', JSON.stringify(value));
      dispatch({
        type: SET_STEPS,
        payload: value,
      });
    },
    [queryParamsSet, dispatch],
  );

  const resetFilters = useCallback(() => {
    removeAll(Object.keys(initialState().filters));
    dispatch({
      type: RESET_FILTERS,
    });
    window.dispatchEvent(new Event('filters:reset'));
  }, [removeAll, dispatch]);

  return {
    filters,
    steps,
    setSteps,
    setDateRange,
    setFilterParamValue,
    resetFilters,
  };
}
