import { gql } from '@urql/core';
import { produce } from 'immer';

import { snapshotFeatureKeys } from './queries';
import { globalFeatureKeys } from '/src/features/_global/queries';
import { teamAndSharingFeatureKeys } from '/src/features/team-and-sharing/queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';
import { gettingStartedFeatureKeys } from '../getting-started/queries';

export const createFolderMutation = ({ client, queryClient }) => ({
  mutationFn: ({ siteId, title }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation CreateFolder($folder: FolderCreateParams!) {
          createFolder(folder: $folder) {
            id
            siteId
            title
            type
          }
        }
      `,
      {
        folder: {
          siteId,
          title,
          type: 'snapshots',
        },
      },
    ),
  onSuccess: (_, variables) => {
    return queryClient.invalidateQueries({ ...snapshotFeatureKeys.folders({ siteId: variables.siteId }) });
  },
});

export const deleteFolderMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation DeleteFolder($id: Int!) {
          deleteFolder(id: $id) {
            id
            siteId
            title
            type
          }
        }
      `,
      {
        id,
      },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshots._def,
    });
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshot._def,
    });
    queryClient.invalidateQueries({ ...globalFeatureKeys.capabilities });
    return queryClient.invalidateQueries({
      ...snapshotFeatureKeys.folders({ siteId: data.deleteFolder.siteId }),
    });
  },
});

export const updateFolderMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, title }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdateFolder($folder: FolderUpdateParams!) {
          updateFolder(folder: $folder) {
            id
            siteId
            title
            type
          }
        }
      `,
      {
        folder: {
          id,
          title,
        },
      },
    ),
  onSuccess: (data) => {
    return queryClient.invalidateQueries({
      ...snapshotFeatureKeys.folders({ siteId: data.updateFolder.siteId }),
    });
  },
});

export const renameSnapshotMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, name }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation RenameSnapshot($snapshot: SnapshotRenameParams!) {
          renameSnapshot(snapshot: $snapshot) {
            id
            name
          }
        }
      `,
      {
        snapshot: {
          id,
          name,
        },
      },
    ),
  onSuccess: (data) => {
    queryClient.setQueriesData({ queryKey: snapshotFeatureKeys.snapshots._def }, (oldData) =>
      oldData
        ? produce(oldData, (draft) => {
            const index = draft.snapshots.list.findIndex((s) => s.id === data.renameSnapshot.id);
            if (index > -1) {
              draft.snapshots.list[index].name = data.renameSnapshot.name;
            }
          })
        : oldData,
    );
    queryClient.invalidateQueries({
      ...snapshotFeatureKeys.snapshot({ id: data.renameSnapshot.id }),
    });
  },
});

export const duplicateSnapshotsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation DuplicateSnapshot($snapshotIds: [Int]!) {
          duplicateSnapshots(snapshotIds: $snapshotIds) {
            affected
          }
        }
      `,
      {
        snapshotIds: [...ids],
      },
    ),
  onSuccess: () => {
    window.Metrics?.used('Snapshot: Duplicated');
    queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.folders._def,
    });
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshots._def,
    });
    queryClient.invalidateQueries({ ...globalFeatureKeys.capabilities });
  },
});

export const deleteSnapshotMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation DeleteSnapshots($snapshotIds: [Int]!) {
          deleteSnapshots(snapshotIds: $snapshotIds) {
            affected
          }
        }
      `,
      {
        snapshotIds: [...ids],
      },
    ),
  onSuccess: (data) => {
    queryClient.setQueriesData({ queryKey: snapshotFeatureKeys.snapshots._def }, (oldData) =>
      oldData
        ? produce(oldData, (draft) => {
            draft.snapshots.list = draft.snapshots.list.filter((s) => !data.deleteSnapshots.affected.includes(s.id));
          })
        : oldData,
    );

    queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshots._def,
    });
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.folders._def,
    });
    data.deleteSnapshots.affected.forEach((id) => {
      queryClient.invalidateQueries({
        ...snapshotFeatureKeys.snapshot({ id }),
      });
    });
    queryClient.invalidateQueries({ ...globalFeatureKeys.capabilities });
  },
});

export const refreshSnapshotsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation RefreshSnapshots($snapshotIds: [Int]!) {
          refreshSnapshots(snapshotIds: $snapshotIds) {
            affected
          }
        }
      `,
      {
        snapshotIds: [...ids],
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshots._def,
    });
  },
});

export const pauseSnapshotsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation PauseSnapshots($snapshotIds: [Int]!) {
          pauseSnapshots(snapshotIds: $snapshotIds) {
            affected
            alreadyStopped
          }
        }
      `,
      {
        snapshotIds: [...ids],
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshots._def,
    });
  },
});

export const resumeSnapshotsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation ResumeSnapshots($snapshotIds: [Int]!) {
          resumeSnapshotsNew(snapshotIds: $snapshotIds) {
            affected
          }
        }
      `,
      {
        snapshotIds: [...ids],
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshots._def,
    });
  },
});

export const exportSnapshotsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ date, destinationEmail, snapshotIds, views }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation ExportSnapshot(
          $views: [SnapshotExportView]!
          $date: String!
          $snapshotIds: [Int]!
          $destinationEmail: String
        ) {
          exportSnapshots(views: $views, date: $date, snapshotIds: $snapshotIds, destinationEmail: $destinationEmail) {
            affected
          }
        }
      `,
      {
        date,
        destinationEmail,
        snapshotIds,
        views,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({
      ...snapshotFeatureKeys.snapshotExports,
    });
  },
});

export const moveSnapshotsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids, toFolderId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation MoveSnapshot($toFolderId: Int!, $snapshotIds: [Int]!) {
          moveSnapshots(toFolderId: $toFolderId, snapshotIds: $snapshotIds) {
            affected
          }
        }
      `,
      {
        snapshotIds: [...ids],
        toFolderId,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshots._def,
    });
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.folders._def,
    });
  },
});

export const unfileSnapshotsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UnfileSnapshots($snapshotIds: [Int]!) {
          unfileSnapshots(snapshotIds: $snapshotIds) {
            affected
          }
        }
      `,
      {
        snapshotIds: [...ids],
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshots._def,
    });
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.folders._def,
    });
  },
});

export const createSnapshotsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ params, settings }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation CreateSnapshots($params: [CreateSnapshotsParams!]!, $settings: CreateSnapshotsSettings!) {
          createSnapshots(params: $params, settings: $settings) {
            errors {
              snapshots {
                field
                index
                message
                args
              }
              settings {
                field
                message
                args
              }
            }
            snapshots {
              id
              name
            }
          }
        }
      `,
      {
        params,
        settings,
      },
    ),
  onSuccess: () => {
    window.Metrics?.used('Snapshot: Created');
    queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.folders._def,
    });
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshots._def,
    });
    queryClient.invalidateQueries({ ...globalFeatureKeys.capabilities });
    queryClient.invalidateQueries({ queryKey: teamAndSharingFeatureKeys.accountUsers._def });
    queryClient.invalidateQueries({ ...gettingStartedFeatureKeys.gettingStarted });
  },
});

export const updateSnapshotMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, params }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdateSnapshot($id: Int!, $params: UpdateSnapshotParams!) {
          updateSnapshot(id: $id, params: $params) {
            errors {
              settings {
                args
                field
                index
                message
              }
              snapshots {
                args
                field
                index
                message
              }
            }
            snapshot {
              id
              name
            }
          }
        }
      `,
      {
        id,
        params,
      },
    ),
  onSuccess: (_, variables) => {
    queryClient.invalidateQueries({
      queryKey: snapshotFeatureKeys.snapshots._def,
    });
    queryClient.invalidateQueries({
      ...snapshotFeatureKeys.snapshot({ id: variables.id }),
    });
  },
});

export const pageCameraSetScreenshotMutation = ({ client }) => ({
  mutationFn: ({ snapshotId, recapture }) =>
    defaultMutationFuction(client, PAGE_CAMERA_SET_SCREENSHOT_STATUS, {
      snapshotId,
      recapture,
    }),
});

export const PAGE_CAMERA_SET_SCREENSHOT_STATUS = gql`
  mutation PageCameraSetScreenshotStatus($snapshotId: Int!, $recapture: Boolean!) {
    pageCameraSetScreenshotStatus(snapshotId: $snapshotId, recapture: $recapture)
  }
`;
