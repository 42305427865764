import { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';

import { RECORDINGS_SITES_QUERY } from '/src/features/recordings/queries';
import { useSite, useSelectedSite, usePermissions } from '/src/hooks';
import { useErrorsFilter } from '../../errors-filter-context';

import { SitePickerBar } from '/src/components/site-picker/index';
import { InstallationBanner } from '/src/components/banners/InstallationBanner';
import { StartTrackingErrorsWall } from '../paywalls/StartTrackingErrorsWall';
import { ErrorsFilterBar } from './ErrorsFilterBar';
import { ErrorsStats } from './ErrorsStats';
import { ErrorsList } from './ErrorsList';

import { isProduction } from '/src/utils';
import { getFeatureName } from '/src/features/_global/utils';
import { FEATURES } from '/src/features/_global/constants';
import { ERROR_STATUSES } from '../../constants';

import { RecordingAlerts } from '/src/features/_global/components/RecordingAlerts';

export function ErrorsDashboardContent() {
  const {
    sites: recordingsSites,
    selectedSite,
    selectSite,
    loadingSites,
  } = useSite({ sitesQuery: RECORDINGS_SITES_QUERY });
  const { sites } = useSite({ dontSelectSite: true });
  const permissions = usePermissions();
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  const [perfMetricStarted, setPerfMetricStarted] = useState(false);
  const [partsLoaded, setPartsLoaded] = useState({ stats: false, list: false });
  const isProd = isProduction();

  useEffect(() => {
    if (window.CE2?.timing && isProd) {
      window.CE2.timing.start('errors_dashboard');
      setPerfMetricStarted(true);
    }
  }, [isProd]);

  useEffect(() => {
    if (perfMetricStarted && window.CE2?.timing && isProd && partsLoaded.stats === true && partsLoaded.list === true) {
      try {
        window.CE2.timing.stop('errors_dashboard');
        setPerfMetricStarted(false);
      } catch {
        // prevent metrics error thrown from crashing the app
        setPerfMetricStarted(false);
      }
    }
  }, [perfMetricStarted, partsLoaded, isProd]);

  if (recordingsSites.length === 0 && !loadingSites) {
    return (
      <div className="mt-5 flex w-full items-center justify-center">
        <StartTrackingErrorsWall />
      </div>
    );
  }

  return (
    <>
      <SitePickerBar
        loading={loadingSites}
        sites={recordingsSites}
        selectedSite={selectedSite}
        selectSite={selectSite}
        showEnableOtherSites={sites.length > recordingsSites.length}
        addSiteOption={canEditSites}
        pageDisplayName={`${getFeatureName(FEATURES.ERRORS_TRACKING)} dashboard`}
      />
      <RecordingAlerts />
      <InstallationBanner />
      <Errors setPartsLoaded={setPartsLoaded} />
    </>
  );
}

function Errors({ setPartsLoaded }) {
  const { selectedSite } = useSelectedSite();
  const { queryParams, setStatus } = useErrorsFilter();

  let initialTab = 0;
  switch (queryParams.status) {
    case ERROR_STATUSES.UNRESOLVED:
      initialTab = 0;
      break;
    case ERROR_STATUSES.RESOLVED:
      initialTab = 1;
      break;
    case ERROR_STATUSES.MUTED:
      initialTab = 2;
      break;
  }

  if (!selectedSite || !selectedSite.id) return null;

  return (
    <>
      <div className="flex flex-col p-10">
        <Tab.Group
          defaultIndex={initialTab}
          onChange={(index) => {
            let status;
            switch (index) {
              case 0:
                status = ERROR_STATUSES.UNRESOLVED;
                break;
              case 1:
                status = ERROR_STATUSES.RESOLVED;
                break;
              case 2:
                status = ERROR_STATUSES.MUTED;
                break;
            }
            setStatus(status);
          }}
        >
          <>
            <ErrorsFilterBar />
            <ErrorsStats site={selectedSite} setPartsLoaded={setPartsLoaded} />
            <ErrorsList site={selectedSite} setPartsLoaded={setPartsLoaded} />
          </>
        </Tab.Group>
      </div>
    </>
  );
}
