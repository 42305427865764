import { useState } from 'react';
import { Link } from 'react-router-dom';
import { fromUnixTime, format } from 'date-fns';

import { usePermissions, useMetric, useAuthContext } from '/src/hooks/';
import { inflect } from '/src/utils/string';
import { featureUsage } from '/src/utils/features';
import { getDaysRemainingInMonthServerTime } from '/src/utils/date';
import { DashboardBanner } from '/src/components/DashboardBanner';
import { MANAGE_SUBSCRIPTION_PATH, FEATURES, SUBSCRIPTION_STATES } from '../constants';

export function RecordingAlerts() {
  const { currentAccount, currentUser, capabilities, subscription } = useAuthContext();
  const trackMetric = useMetric();

  const [hasDismissedAlerts, setHasDismissedAlerts] = useState(
    Boolean(window.sessionStorage.getItem('ce.dashboards.recordings.dismissedAlerts') ?? false),
  );

  const permissions = usePermissions();
  const canManageSubscription = permissions.can('edit', FEATURES.BILLING).allowed;
  const canEditRecordings = permissions.can('edit', FEATURES.RECORDINGS).allowed;

  const dontShowPaymentAlert = [181, 197].includes(currentUser.subscriptions.recordings.paymentPlan.id); // ch18817,sc24366
  const isExpiring = subscription.state === SUBSCRIPTION_STATES.EXPIRING;

  function dismissAlerts() {
    window.sessionStorage.setItem('ce.dashboards.recordings.dismissedAlerts', 'true');
    setHasDismissedAlerts(true);
  }

  if (!canEditRecordings) {
    return null;
  }

  const daysRemaining = getDaysRemainingInMonthServerTime();

  if (isExpiring && !dontShowPaymentAlert && canManageSubscription) {
    if (hasDismissedAlerts) return null;

    return (
      <DashboardBanner title="There’s a payment issue!" data-testid="alert-banner" onClose={dismissAlerts}>
        Your Account will be deactivated on {format(fromUnixTime(subscription.endDate), 'MMM dd, yyyy')} due to a
        problem with payment.{' '}
        <Link to="/account/billing?billingModal=true" className="text-link !text-white !underline">
          Please update your payment info
        </Link>
        .
      </DashboardBanner>
    );
  }

  const { monthlyRecordings } = capabilities.recordings.quotas;
  const canStoreMore = monthlyRecordings.current < monthlyRecordings.limit;
  const recordingsUsage = featureUsage(capabilities.recordings.quotas.monthlyRecordings);

  if (!canStoreMore) {
    return (
      <DashboardBanner title="Recordings Limit Reached!" data-testid="alert-banner">
        You’ve used up 100% of the recordings quota this month.{' '}
        {canManageSubscription ? (
          <Link
            to={MANAGE_SUBSCRIPTION_PATH}
            className="text-link !text-white !underline"
            onMouseDown={() => trackMetric('upgrade_cta_click', 'recordings_pageview_limit_reached_banner')}
          >
            Upgrade now
          </Link>
        ) : (
          <>
            Please contact the account owner{' '}
            <a href={`mailto:${currentAccount.ownerEmail}`} className="text-link">
              {currentAccount.ownerEmail}
            </a>
          </>
        )}{' '}
        or wait {daysRemaining} {inflect('day', daysRemaining)} to continue recording more sessions.
      </DashboardBanner>
    );
  }

  if (recordingsUsage > 90 && canStoreMore) {
    if (hasDismissedAlerts) return null;
    return (
      <DashboardBanner data-testid="alert-banner" onClose={dismissAlerts}>
        You’ve reached {recordingsUsage}% of your recordings quota this month. When you reach 100%, sessions will stop
        tracking.
        {canManageSubscription ? (
          <Link
            to={MANAGE_SUBSCRIPTION_PATH}
            className="text-link !text-white !underline"
            onMouseDown={() => trackMetric('upgrade_cta_click', 'recordings_pageview_limit_reached_banner')}
          >
            Upgrade now
          </Link>
        ) : (
          <>
            Please contact the account owner{' '}
            <a href={`mailto:${currentAccount.ownerEmail}`} className="text-link">
              {currentAccount.ownerEmail}
            </a>
          </>
        )}
        .
      </DashboardBanner>
    );
  }

  return null;
}
