import { useState, useEffect } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { Button, Tooltip, Panel } from '@crazyegginc/hatch';

import { useSelectedSite } from '/src/hooks';

import { SelectedValues } from './SelectedValues';
import { UTMSelect } from './UTMSelect';
import { URLParser } from './URLParser';
import { db } from '../db';
import { initialSelection, getEmptyText } from '../utils';
import { MAX_COMPARISONS, QUERY_LIMIT } from '../constants';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

const initialLoadingState = {
  utmSource: false,
  utmMedium: false,
  utmCampaign: false,
  utmTerm: false,
  utmContent: false,
  referrerUrl: false,
};

export function BuildYourOwnTab({
  comparisons,
  fetching,
  initDone,
  addToComparison,
  criteriaFilterOrder,
  totalSessionsForParams,
}) {
  const [selection, setSelection] = useState(() => initialSelection());
  const [loading, setLoading] = useState(initialLoadingState);
  const { selectedSite } = useSelectedSite();

  useEffect(() => {
    setSelection(() => initialSelection());
  }, [selectedSite]);

  const filterDataRow = (dataRow, utmParam, criteria) =>
    selection[criteria].length > 0 && utmParam !== criteria
      ? selection[criteria].some((y) => y.value.toLowerCase() === dataRow[criteria].toLowerCase())
      : true;

  const getOptions = async (utmParam) => {
    if (!initDone) return [];

    setLoading((x) => ({ ...x, [utmParam]: true }));

    let options = [];
    const mainFilterCriteria = criteriaFilterOrder.find(
      (criteria) => selection[criteria].length > 0 && criteria !== utmParam,
    );

    if (mainFilterCriteria === undefined) {
      // no criterias selected yet, so just get each unique value

      // sc22016
      if ((await db.traffic.count()) !== 0) {
        await db.traffic
          .orderBy(utmParam)
          .eachUniqueKey((x) => options.push({ value: x, label: x === '' ? getEmptyText(utmParam) : null }));
      }

      setLoading((x) => ({ ...x, [utmParam]: false }));

      // sort options based on total sessions they are in
      options = options.sort((a, b) => {
        if (a.value === '') return -1;
        if (b.value === '') return 1;
        if (totalSessionsForParams[utmParam]) {
          return totalSessionsForParams[utmParam][a.value] > totalSessionsForParams[utmParam][b.value]
            ? -1
            : totalSessionsForParams[utmParam][a.value] < totalSessionsForParams[utmParam][b.value]
            ? 1
            : 0;
        }
        return 0;
      });
      return options;
    } else {
      // filter
      let total = {};

      // sc22016
      if ((await db.traffic.count()) !== 0) {
        await db.traffic
          .where(mainFilterCriteria)
          .anyOfIgnoreCase(selection[mainFilterCriteria].map((x) => x.value))
          .and((x) => {
            return (
              filterDataRow(x, utmParam, 'referrerUrl') &&
              filterDataRow(x, utmParam, 'utmSource') &&
              filterDataRow(x, utmParam, 'utmMedium') &&
              filterDataRow(x, utmParam, 'utmCampaign') &&
              filterDataRow(x, utmParam, 'utmTerm') &&
              filterDataRow(x, utmParam, 'utmContent')
            );
          })
          .each((x) => {
            options.push(x[utmParam]);
            total[x[utmParam]] ? (total[x[utmParam]] += x.total) : (total[x[utmParam]] = x.total);
          });
      }

      setLoading((x) => ({ ...x, [utmParam]: false }));

      return [...new Set(options)]
        .sort((a, b) => {
          if (a === '') return -1;
          if (b === '') return 1;
          return total[a] > total[b] ? -1 : total[a] < total[b] ? 1 : 0;
        })
        .map((x) => ({ value: x, label: x === '' ? getEmptyText(utmParam) : null }));
    }
  };

  const options = {};

  options.utmSource = useLiveQuery(
    () => getOptions('utmSource'),
    [
      selection.utmMedium,
      selection.utmCampaign,
      selection.utmTerm,
      selection.utmContent,
      selection.referrerUrl,
      initDone,
    ],
  );
  options.utmMedium = useLiveQuery(
    () => getOptions('utmMedium'),
    [
      selection.utmSource,
      selection.utmCampaign,
      selection.utmTerm,
      selection.utmContent,
      selection.referrerUrl,
      initDone,
    ],
  );
  options.utmCampaign = useLiveQuery(
    () => getOptions('utmCampaign'),
    [
      selection.utmSource,
      selection.utmMedium,
      selection.utmTerm,
      selection.utmContent,
      selection.referrerUrl,
      initDone,
    ],
  );
  options.utmTerm = useLiveQuery(
    () => getOptions('utmTerm'),
    [
      selection.utmSource,
      selection.utmMedium,
      selection.utmCampaign,
      selection.utmContent,
      selection.referrerUrl,
      initDone,
    ],
  );
  options.utmContent = useLiveQuery(
    () => getOptions('utmContent'),
    [
      selection.utmSource,
      selection.utmMedium,
      selection.utmCampaign,
      selection.utmTerm,
      selection.referrerUrl,
      initDone,
    ],
  );
  options.referrerUrl = useLiveQuery(
    () => getOptions('referrerUrl'),
    [
      selection.utmSource,
      selection.utmMedium,
      selection.utmCampaign,
      selection.utmTerm,
      selection.utmContent,
      initDone,
    ],
  );

  // don't allow selecting while any is still loading
  const disabled = Object.keys(loading).some((x) => loading[x] === true);

  const selectionCount = Object.keys(selection).reduce((acc, x) => acc + selection[x].length, 0);

  return (
    <>
      <div className="text-header-3 mb-4">Select parameters to filter by:</div>

      <Panel className="min-w-min !p-[30px]">
        <div className="flex w-full justify-between space-x-2">
          <UTMSelect
            name="Campaign Source"
            values={selection.utmSource}
            onChange={(value) => setSelection((x) => ({ ...x, utmSource: value }))}
            options={options.utmSource ?? []}
            loading={loading.utmSource || fetching || !initDone}
            disabled={disabled}
          />
          <UTMSelect
            name="Campaign Medium"
            values={selection.utmMedium}
            onChange={(value) => setSelection((x) => ({ ...x, utmMedium: value }))}
            options={options.utmMedium ?? []}
            loading={loading.utmMedium || fetching || !initDone}
            disabled={disabled}
          />
          <UTMSelect
            name="Campaign Name"
            values={selection.utmCampaign}
            onChange={(value) => setSelection((x) => ({ ...x, utmCampaign: value }))}
            options={options.utmCampaign ?? []}
            loading={loading.utmCampaign || fetching || !initDone}
            disabled={disabled}
          />
          <UTMSelect
            name="Campaign Term"
            values={selection.utmTerm}
            onChange={(value) => setSelection((x) => ({ ...x, utmTerm: value }))}
            options={options.utmTerm ?? []}
            loading={loading.utmTerm || fetching || !initDone}
            disabled={disabled}
          />
          <UTMSelect
            name="Campaign Content"
            values={selection.utmContent}
            onChange={(value) => setSelection((x) => ({ ...x, utmContent: value }))}
            options={options.utmContent ?? []}
            loading={loading.utmContent || fetching || !initDone}
            disabled={disabled}
          />
          <UTMSelect
            name="Referrer"
            values={selection.referrerUrl}
            onChange={(value) => setSelection((x) => ({ ...x, referrerUrl: value }))}
            options={options.referrerUrl ?? []}
            loading={loading.referrerUrl || fetching || !initDone}
            disabled={disabled}
          />
        </div>
        <div className="text-body-4 mt-2.5">
          <span className="font-bold">TIP:</span> Try adjusting the date range. We show your top{' '}
          {QUERY_LIMIT.toLocaleString()} sources for the selected date range.
        </div>
        <URLParser
          selectedSite={selectedSite}
          onClick={(values) =>
            setSelection({
              utmSource: values.utm_source ? [{ value: values.utm_source }] : [],
              utmMedium: values.utm_medium ? [{ value: values.utm_medium }] : [],
              utmCampaign: values.utm_campaign ? [{ value: values.utm_campaign }] : [],
              utmTerm: values.utm_term ? [{ value: values.utm_term }] : [],
              utmContent: values.utm_content ? [{ value: values.utm_content }] : [],
              referrerUrl: [],
            })
          }
        />
        {selectionCount > 0 ? (
          <div className="text-body-4 mt-5 w-full border border-dashed border-cadet-blue-500 px-[30px] pb-5 pt-6">
            <SelectedValues options={options} selection={selection} />
          </div>
        ) : null}
        <div className="mt-6 flex w-full">
          <Tooltip
            show={comparisons.length >= MAX_COMPARISONS}
            tooltipContent={
              <div>
                You can only add a maximum of {MAX_COMPARISONS} comparisons to the table. <br />
                Please remove one before adding more.
              </div>
            }
          >
            <Button
              size="lg"
              onClick={() => {
                addToComparison(selection);
                setSelection(() => initialSelection());
              }}
              disabled={selectionCount === 0 || comparisons.length >= MAX_COMPARISONS}
            >
              <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
              Analyze and compare traffic
            </Button>
          </Tooltip>
          {selectionCount > 0 && (
            <Button
              size="lg"
              variant="cancel"
              onClick={() => setSelection(() => initialSelection())}
              className="!ml-2.5"
            >
              Clear selection
            </Button>
          )}
        </div>
      </Panel>
    </>
  );
}
