import { useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconButton, Toggle, ReactTable, Tooltip, capitalizeFirstLetter } from '@crazyegginc/hatch';

import { DashboardPage } from '/src/components/Page';
import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { SitePickerBar } from '/src/components/site-picker/index';
import { InstallationBanner } from '/src/components/banners/InstallationBanner';
import { RowActions } from '/src/features/addons/common/actions';
import { NoCTAsWall } from '/src/features/addons/paywalls/NoCTAsWall';
import { NoSurveysWall } from '/src/features/addons/paywalls/NoSurveysWall';
import { NoAddonsWall } from '../paywalls/NoAddonsWall';
import { NoMatchesWall } from '../../_global/paywalls/NoMatchesWall';
import { AddSiteModal } from '/src/components/modals/AddSiteModal';
import { NewAddonButton } from '/src/features/addons/common/NewAddonButton';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { ExternalShareModal } from '/src/features/addons/surveys/modals/ExternalShareModal';
import { AddonActiveToggle } from '/src/features/addons/common/AddonActiveToggle';
import { AddonsFilterProvider, useFilter } from '/src/features/addons/filter-context';

import { addonListQuery, SITES_FOR_ADDONS } from '/src/features/addons/queries';

import { FEATURES } from '/src/features/_global/constants';
import { ADDON_TYPES } from '/src/features/addons/constants';

import { useAuthContext, useSearchParams, useSite, useNotifications, usePermissions, useModal } from '/src/hooks';
import { isProduction } from '/src/utils';
import { SearchInput } from '/src/components/search-input';
import { getSurveyQueryParams } from '/src/utils/url';
import { formatDate, formatDateTime } from '/src/utils/date';
import { getFeatureName } from '/src/features/_global/utils';

import { ReactComponent as PencilIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';

// eslint-disable-next-line
export default function AddonsDashboardWrapper() {
  return (
    <AddonsFilterProvider>
      <AddonsDashboard />
    </AddonsFilterProvider>
  );
}

function AddonsDashboard() {
  const location = useLocation();
  const notifications = useNotifications();
  const permissions = usePermissions();
  const modal = useModal();
  const { currentAccount } = useAuthContext();
  const [searchParams] = useSearchParams();
  const { setTypeFilter, type: addonType } = useFilter();

  const canViewAddons = permissions.can('view', FEATURES.ADDONS);
  const canViewSurveys = permissions.can('view', FEATURES.SURVEYS);
  const canViewCTAs = permissions.can('view', FEATURES.CTAS);
  const canEditSites =
    permissions.can('navigate', FEATURES.SITE_SETTINGS).allowed &&
    permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  const {
    sites,
    selectedSite,
    selectSite,
    loadingSites: loadingAddonSites,
  } = useSite({
    sitesQuery: SITES_FOR_ADDONS,
    sitesQueryProps: { enabled: canViewAddons.allowed },
    enableAllSites: true,
  });

  const { type, publishSuccess, externalPublishSuccess, draftPublishSuccess, publishedId } = location.state ?? {};

  useEffect(() => {
    if (publishSuccess || draftPublishSuccess) {
      let content;
      if (draftPublishSuccess) {
        content = `It may take a minute for the changes to appear in your ${type}.`;
      } else if (type === 'survey') {
        content = 'It may take a minute for it to appear on your website and start collecting responses.';
      } else {
        content = 'It may take a minute for it to appear on your website.';
      }
      notifications.success({
        title: `${capitalizeFirstLetter(type)} Published!`,
        content,
        timeout: 5000,
      });
      if (window.Metrics) {
        if (publishSuccess) {
          window.Metrics.used(`${capitalizeFirstLetter(type)}: Published`);
        } else if (draftPublishSuccess) {
          window.Metrics.used(`${capitalizeFirstLetter(type)}: Re-published`);
        }
      }
    }
  }, [publishSuccess, draftPublishSuccess, notifications, type]);

  useEffect(() => {
    if (externalPublishSuccess) {
      modal.show(<ExternalShareModal surveyId={publishedId} userId={currentAccount?.id} />);
    }
  }, [externalPublishSuccess, publishedId, currentAccount?.id, modal]);

  const canVisitSurveys = permissions.can('navigate', FEATURES.SURVEYS).allowed;
  const canVisitCTAs = permissions.can('navigate', FEATURES.CTAS).allowed;
  const allowedTypes = useMemo(() => {
    const result = [];
    if (canVisitSurveys) {
      result.push(FEATURES.SURVEYS);
    }
    if (canVisitCTAs) {
      result.push(FEATURES.CTAS);
    }
    return result;
  }, [canVisitSurveys, canVisitCTAs]);

  useEffect(() => {
    const typeInURL = searchParams.get('type');
    if (!addonType && !typeInURL) {
      setTypeFilter(allowedTypes.includes(FEATURES.SURVEYS) ? FEATURES.SURVEYS : FEATURES.CTAS);
    } else {
      if (typeInURL !== addonType && allowedTypes.includes(typeInURL)) {
        setTypeFilter(typeInURL);
      }
    }
  }, [setTypeFilter, allowedTypes, addonType, searchParams]);

  if (addonType === FEATURES.SURVEYS && !canViewSurveys.allowed) {
    return <DashboardPaywall feature={FEATURES.SURVEYS} reason={canViewSurveys.reason} />;
  } else if (addonType === FEATURES.CTAS && !canViewCTAs.allowed) {
    return <DashboardPaywall feature={FEATURES.CTAS} reason={canViewCTAs.reason} />;
  }

  if (!addonType) return null;

  return (
    <DashboardPage>
      <SEO title={getFeatureName(addonType)} />
      <DashHeader title={getFeatureName(addonType)} actionButton={<NewAddonButton type={addonType} />} />
      <SitePickerBar
        loading={loadingAddonSites}
        sites={sites}
        selectedSite={selectedSite}
        selectSite={selectSite}
        enableAllSites={true}
        addSiteOption={canEditSites}
        pageDisplayName={`${getFeatureName(addonType)} dashboard`}
      />
      <AddonsDashboardContent />
    </DashboardPage>
  );
}

function AddonsDashboardContent() {
  const { search, setSearch, type } = useFilter();
  const permissions = usePermissions();
  const { sites, reloadSites, loadingSites, selectedSite } = useSite({
    sitesQuery: SITES_FOR_ADDONS,
    dontSelectSite: true,
  });
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const isProd = isProduction();
  const [perfMetricStarted, setPerfMetricStarted] = useState(false);

  useEffect(() => {
    if (window.CE2?.timing && isProd) {
      window.CE2.timing.start('addons_dashboard');
      setPerfMetricStarted(true);
    }
  }, [isProd]);

  useEffect(() => {
    if (window.Metrics) {
      if (type === FEATURES.CTAS) {
        window.Metrics.used('CTA: Dashboard');
      } else if (type === FEATURES.SURVEYS) {
        window.Metrics.used('Survey: Dashboard');
      } else {
        window.Metrics.used('Add-on: Dashboard');
      }
    }
  }, [type]);

  const {
    data: addonsData,
    isFetching: loadingAddons,
    hasNextPage,
    fetchNextPage,
    isInitialLoading,
  } = useInfiniteQuery({
    ...addonListQuery({
      siteId: selectedSite?.id ?? null,
      filter: {
        limit: 25,
        query: search.trim() === '' ? undefined : search,
        type: type?.replace(FEATURES.CTAS, ADDON_TYPES.CTA).replace(FEATURES.SURVEYS, ADDON_TYPES.SURVEY),
      },
    }),
    enabled: Boolean(selectedSite),
  });

  const addons = useMemo(
    () => addonsData?.pages.reduce((acc, page) => [...acc, ...(page.addonList?.list ?? [])], []) ?? [],
    [addonsData],
  );

  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  useEffect(() => {
    if (perfMetricStarted && window.CE2?.timing && isProd && addons && !loadingAddons) {
      try {
        window.CE2.timing.stop('addons_dashboard');
        setPerfMetricStarted(false);
      } catch {
        // prevent metrics error thrown from crashing the app
        setPerfMetricStarted(false);
      }
    }
  }, [perfMetricStarted, addons, isProd, loadingAddons]);

  const columns = useMemo(
    () => [
      {
        header: 'Status',
        size: 40,
        cell: ({ row }) => (
          <>
            {row.original.publishedAt ? (
              <AddonActiveToggle addon={row.original} />
            ) : (
              <AddonDraftButton addon={row.original} />
            )}
          </>
        ),
        meta: {
          align: 'center',
          justify: 'left',
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ row }) => <NameCell addon={row.original} />,
        meta: {
          align: 'center',
          justify: 'left',
        },
      },
      {
        header: 'Activity',
        accessorKey: 'responseCount',
        size: 60,
        cell: ({ row }) =>
          row.original.publishedAt ? (
            <span>{`${row.original.responseCount} ${
              row.original.type === ADDON_TYPES.CTA ? 'Clicks' : 'Responses'
            }`}</span>
          ) : (
            <span className="text-body-5">N/A</span>
          ),
        meta: {
          align: 'center',
          justify: 'left',
        },
      },
      {
        header: 'Created',
        size: 50,
        cell: ({ row }) => (
          <Tooltip
            show={!!row.original.publishedAt}
            tooltipContent={row.original.publishedAt ? `Published on ${formatDateTime(row.original.publishedAt)}` : ''}
          >
            <div className="flex flex-col">
              <span>{formatDate(row.original.createdAt)}</span>
              <span className="text-caption">by {row.original.createdBy}</span>
            </div>
          </Tooltip>
        ),
        meta: {
          align: 'center',
          justify: 'left',
        },
      },
      {
        header: 'Actions',
        size: 30,
        cell: ({ row }) => (
          <div>
            <RowActions addon={row.original} />
          </div>
        ),
        meta: {
          align: 'center',
          justify: 'center',
        },
      },
    ],
    [],
  );

  if (!loadingSites && !sites.length && canEditSites) {
    return (
      <AddSiteModal
        customTitle="Add a site before creating Add-Ons"
        onExtraSteps={() => {
          reloadSites();
        }}
        onCancel={() => navigate('/snapshots', { replace: true })}
      />
    );
  }

  if (addonsData && !loadingAddons && !addons.length && !search && !type) {
    return <NoAddonsWall />;
  }

  const noAddonsForType = !loadingAddons && !addons.length && selectedSite && type && !search;

  return (
    <>
      <InstallationBanner />
      <div className="flex flex-col p-10">
        {noAddonsForType ? null : (
          <div className="flex w-full items-center justify-end pb-[15px]">
            <SearchInput
              initialValue={search}
              search={setSearch}
              placeholder="Search by Name"
              className=" !border-solitude-500 !bg-solitude-500 !font-semibold !placeholder-dodger-blue-500/50"
            />
          </div>
        )}
        {!loadingAddons && selectedSite && !addons.length && !!search ? (
          <NoMatchesWall
            reset={() => window.dispatchEvent(new Event('filters:reset'))}
            text="matches, try clearing the search"
            actionText="search"
          />
        ) : (
          <>
            {noAddonsForType ? (
              <>{type === FEATURES.SURVEYS ? <NoSurveysWall /> : <NoCTAsWall />}</>
            ) : (
              <ReactTable
                fetching={isInitialLoading || !selectedSite}
                ref={{ tableRef }}
                enableSorting={false}
                columns={columns}
                data={addons}
                rowPadding={true}
                useVirtualization={true}
                hasNextPage={hasNextPage}
                loadNextPage={fetchNextPage}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

function AddonDraftButton({ addon }) {
  const permissions = usePermissions();
  const canEdit = permissions.can('edit', addon).allowed;

  if (canEdit) {
    return (
      <div className="flex items-center space-x-4">
        <IconButton
          component={Link}
          to={`/addons/${addon.id}/edit`}
          className="w-[35px]"
          icon={<PencilIcon className="h-3.5 w-3.5 fill-current text-dodger-blue-300 hover:text-dodger-blue-500" />}
          label="Edit"
        />
        <span className="mt-px">Draft</span>
      </div>
    );
  }

  return <Toggle labelClasses="!mt-px" disabled={true} label="Draft" displayLabel={true} />;
}

function NameCell({ addon }) {
  const permissions = usePermissions();
  const canViewResults = permissions.can('viewResults', addon).allowed;
  const canEdit = permissions.can('edit', addon).allowed;
  const isDraft = !addon.publishedAt;
  const hasDraftEdits = addon.draftContent || addon.drafName;

  if (canEdit && isDraft) {
    return (
      <Link to={`/addons/${addon.id}/edit`} className="text-link underline">
        {addon.name}
      </Link>
    );
  }

  if (!isDraft && canViewResults) {
    return (
      <div>
        <Link to={`/addons/${addon.id}?${getSurveyQueryParams()}`} className="text-link underline">
          {addon.name}
        </Link>
        {hasDraftEdits && <div className="text-body-4 italic">Unpublished Edits</div>}
      </div>
    );
  }

  return <span>{addon.name}</span>;
}
