import { DAYS_IN_MONTH } from '/src/features/options/constants';

/*
number of days per month = always 30.4 (average)
const DAYS_IN_MONTH = 30.4

CALCULATION:
  Automatic: 
    1/[siteRatio] * 100 
    ie. 1/11 * 100 = 9.09%

  Manual:
    number: 
    ([user_input] /100) * [estimated_visits_day] * DAYS_IN_MONTH
    Ie. (100/100) * 2567 * 31 = 79,577 recordings

    percentage:
    ([user_input] / DAYS_IN_MONTH / [estimated_visits_day] ) * 100
    Ie. (39788 / 2567 / 31) * 100 = 49.99% sampling rate
*/

export function calculateRecordingRatio(ratio) {
  const calculateRatio = ((1 / ratio) * 100).toFixed(2);
  return parseFloat(calculateRatio);
}

export function numberToPercentage(value, visitsDay) {
  const calculateNumber = ((value / DAYS_IN_MONTH / visitsDay) * 100).toFixed(2);
  return parseFloat(calculateNumber);
}

export function percentageToNumber(value, visitsDay) {
  return Math.round((value / 100) * visitsDay * DAYS_IN_MONTH);
}
