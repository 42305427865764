import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions, useMetric } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';
import { FEATURES } from '/src/features/_global/constants';

import GoalsPNG from '@crazyegginc/hatch/dist/images/illustration-goal-target.png';
import GoalsWebP from '@crazyegginc/hatch/dist/images/illustration-goal-target.webp';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function GoalsUpgradePaywall({ mini }) {
  const { currentAccount } = useAuthContext();
  const trackMetric = useMetric();

  const permissions = usePermissions();
  const canManageBilling = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Paywall>
      <Paywall.Image
        webp={GoalsWebP}
        png={GoalsPNG}
        width={mini ? '80px' : '175px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />
      <Paywall.Title mini={mini}>Maximise your website’s potential with Goals</Paywall.Title>

      <Paywall.Body>
        It can be easy to lose sight of your main website objectives. Let us help you not only keep them in focus, but
        continue to improve them.
        {mini ? null : (
          <div className="mt-6 flex justify-center">
            <div className="space-y-2">
              <div className="flex items-center">
                <TickIcon className="mr-2.5 h-4 w-4 fill-current text-lima-500" />
                Measure the effectiveness of your website.
              </div>
              <div className="flex items-center">
                <TickIcon className="mr-2.5 h-4 w-4 fill-current text-lima-500" />
                Optimize user experience.
              </div>
              <div className="flex items-center">
                <TickIcon className="mr-2.5 h-4 w-4 fill-current text-lima-500" />
                Set up your Goal in less than 60 seconds.
              </div>
            </div>
          </div>
        )}
        {!canManageBilling ? (
          <p className="mt-5">
            To get access to this feature, contact your Account Owner &#40;{currentAccount.ownerEmail}&#41; to upgrade
            the plan.
          </p>
        ) : null}
      </Paywall.Body>

      {canManageBilling ? (
        <Button
          component={Link}
          to={MANAGE_SUBSCRIPTION_PATH}
          onMouseDown={() => trackMetric('upgrade_cta_click', 'goals_upsell_paywall')}
          className="mb-5"
        >
          Get Goals
          <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />
        </Button>
      ) : (
        <Button component="a" href={`mailto:${currentAccount.ownerEmail}`} className="mb-5">
          Contact account owner
        </Button>
      )}
    </Paywall>
  );
}
