import { useEffect, useRef } from 'react';

import { isE2E } from '/src/utils';
import { AppConfig } from '../../AppConfig';
import { isStaging, timeoutPromise } from '/src/utils';
import { fetchNewToken, validToken } from '/src/utils/auth';
import { AUTH_TOKEN_KEY } from '/src/features/_global/constants';

const mockyBasicConf = {
  jwtGenerator: async () => {
    if (window.MOCKY_JWT) {
      return window.MOCKY_JWT;
    } else {
      if (!validToken(window.localStorage.getItem(AUTH_TOKEN_KEY))) {
        await fetchNewToken();
      }
      return new Promise((resolve, reject) => {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.withCredentials = true;

        xmlHttp.onreadystatechange = function callback() {
          if (xmlHttp.readyState === 4) {
            const json = JSON.parse(xmlHttp.responseText);
            resolve(json.token);
          }
        };

        try {
          xmlHttp.open('GET', `${AppConfig.legacyCoreBaseURL()}/integration/mocky`, true);
          xmlHttp.send();
        } catch (e) {
          reject(e);
        }
      });
    }
  },
  providerId: isStaging() ? 2 : 1,
};

export const mockyNotificationsConfig = {
  ...mockyBasicConf,
  scopes: () => [],
  notifications: {
    slideDirection: 'left',
    slideStartOffset: () =>
      document.querySelector('nav') ? document.querySelector('nav').getBoundingClientRect().width + 'px' : '0px',
    currentUrlOnly: false,
    disabled: false,
    openInNewTab: true,
  },
  theme: {
    'sidepanel-bg': '#fff',
    'sidepanel-text': '#0A1C2E',
    'sidepanel-shadow': '4px 0 12px 0 rgba(7,31,49,0.1)',
    'sidepanel-header-bg': '#f4f9fd',
    'sidepanel-header-shadow': 'none',
    'sidepanel-closeButton': '#bcc1c6',
    'sidepanel-dropdown-hover': 'rgba(218,237,249,0.52)',
    'sidepanel-dropdown-shadow': '0 4px 6px 0 rgba(7,31,49,0.17)',
    'sidepanel-scrollbar-track': '#f6f7fA',
    'sidepanel-scrollbar-thumb': '#bcc1c6',
    'notification-bg': '#f6f7fA',
    'notification-title': '#0086e6',
    'notification-date': 'rgba(10,28,46,0.5)',
    'notification-shadow': 'none',
  },
  disableCommenting: true,
};

export const mockyPlayerConfig = (teamMembersData) => ({
  ...mockyBasicConf,
  scopes: () => {
    return [
      {
        match: 'passthrough',
        elements: [document.querySelector('#mocky-player-overlay')],
      },
    ];
  },
  addressBook: (callback) => {
    callback(
      teamMembersData?.teamMembers.map((x) => ({
        provider_uid: x.id,
        name: x.name || x.email,
        email: x.email,
      })),
    );
  },
  theme: {
    'mocky-switch-bg': '#3c424d',
    'mocky-switch-hover-border': '#fa3c66',
  },
  position: {
    top: '-100px',
    left: '-100px',
  },
  borderPosition: {
    top: '-100px',
    left: '-100px',
  },
  countType: 'skipHash',
  tooltipPlacement: 'bottom',
  persistOnState: false,
});

export function useMocky(mockyConfig, readyCallback, errorCallback, targetReady = true) {
  const prevConfig = useRef();

  useEffect(() => {
    async function startMocky() {
      const alreadyLoaded = document.querySelector(`script[src="${window.MOCKY_SCRIPT_URL}"]`);
      const configChanged = prevConfig?.current !== mockyConfig;

      if (alreadyLoaded && configChanged) {
        let time = 0;
        while (!document.querySelector('mocky-comment') && time < 2000) {
          await timeoutPromise(100);
          time += 100;
        }
        window.Mocky?.stop();
        alreadyLoaded.parentNode.removeChild(alreadyLoaded);
      }
      if ((!alreadyLoaded || configChanged) && targetReady) {
        prevConfig.current = mockyConfig;
        if (isE2E()) {
          readyCallback?.();
        } else {
          if (window.ENVIRONMENT === 'development' && window.MOCKY_BACKEND_URL) {
            mockyConfig.host = window.MOCKY_BACKEND_URL;
          }

          const scriptTag = document.createElement('script');
          scriptTag.onload = () => {
            window.Mocky.start(mockyConfig);
            readyCallback?.();
          };
          scriptTag.onerror = () => {
            errorCallback?.();
          };
          scriptTag.src = window.MOCKY_SCRIPT_URL;
          document.body.appendChild(scriptTag);
        }
      }
    }
    startMocky();
  }, [mockyConfig, readyCallback, errorCallback, targetReady]);
}
