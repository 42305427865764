import Honeybadger from '@honeybadger-io/js';

export async function defaultMutationFuction(client, query, variables) {
  const { data, error, operation } = await client.mutation(query, variables);
  const queryName = operation.query.definitions[0].name.value;
  const stringVars = JSON.stringify(operation.variables);
  if (error) {
    Honeybadger.addBreadcrumb(`[GQL mutation]: ${queryName}`, {
      category: 'error',
      metadata: { variables: stringVars, error: JSON.stringify(error) },
    });
    throw error;
  } else {
    Honeybadger.addBreadcrumb(`[GQL mutation]: ${queryName}`, {
      category: 'query',
      metadata: { variables: stringVars },
    });
  }
  return data;
}
