import { gql } from '@urql/core';

import { recordingsFeatureKeys } from './queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';
import { gettingStartedFeatureKeys } from '../getting-started/queries';

export const deleteRecordingsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ recordingIds, site }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation DeleteRecordings($recordingIds: [Int!]!, $site: Int!) {
          deleteRecordings(recordingIds: $recordingIds, site: $site) {
            affected
          }
        }
      `,
      {
        recordingIds,
        site,
      },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: ['sites'] });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsList._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsGroups._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsInGroup._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsCount._def });
    data.deleteRecordings.affected.forEach((hashedId) => {
      queryClient.invalidateQueries({
        ...recordingsFeatureKeys.recording({ hashedId }),
      });
    });
  },
});

export const watchRecordingMutation = ({ client, queryClient }) => ({
  mutationFn: ({ recordingId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation WatchRecording($recordingId: Int!) {
          watchRecording(recordingId: $recordingId)
        }
      `,
      {
        recordingId,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsList._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsGroups._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsInGroup._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recording._def });
    queryClient.invalidateQueries({ ...gettingStartedFeatureKeys.gettingStarted });
  },
});

export const watchRecordingsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ recordingIds }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation WatchRecordings($recordingIds: [Int!]!) {
          watchRecordings(recordingIds: $recordingIds)
        }
      `,
      {
        recordingIds,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsList._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsGroups._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsInGroup._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recording._def });
  },
});

export const unwatchRecordingsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ recordingIds }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UnwatchRecordings($recordingIds: [Int!]!) {
          unwatchRecordings(recordingIds: $recordingIds)
        }
      `,
      {
        recordingIds,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsList._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsGroups._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsInGroup._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recording._def });
  },
});

export const addTagsToRecordingsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ recordingIds, tags }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AddTagsToRecordings($recordingIds: [Int!]!, $tags: [String!]!) {
          addTagsToRecordings(recordingIds: $recordingIds, tags: $tags) {
            count
            tag
          }
        }
      `,
      {
        recordingIds,
        tags,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsList._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsInGroup._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recording._def });
  },
});

export const removeTagsFromRecordingsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ recordingIds, tags }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation RemoveTagsFromRecordings($recordingIds: [Int!]!, $tags: [String!]!) {
          removeTagsFromRecordings(recordingIds: $recordingIds, tags: $tags) {
            count
            tag
          }
        }
      `,
      {
        recordingIds,
        tags,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsList._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recordingsInGroup._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.recording._def });
  },
});

export const updatePlaylistNameMutation = ({ client, queryClient }) => ({
  mutationFn: ({ site, playlist, name }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdatePlaylistName($site: Int!, $playlist: Int!, $name: String!) {
          updatePlaylistName(site: $site, playlist: $playlist, name: $name)
        }
      `,
      {
        site,
        playlist,
        name,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.playlistsMostRecent._def });
    return queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.playlistsList._def });
  },
});

export const savePlaylistMutation = ({ client, queryClient }) => ({
  mutationFn: ({ site, playlist }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SavePlaylist($site: Int!, $playlist: PlaylistParams!) {
          savePlaylist(site: $site, playlist: $playlist) {
            id
            name
            filter
            type
          }
        }
      `,
      {
        site,
        playlist,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.playlistsList._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.playlistsMostRecent._def });
  },
});

export const deletePlaylistMutation = ({ client, queryClient }) => ({
  mutationFn: ({ site, playlist }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation DeletePlaylist($site: Int!, $playlist: Int!) {
          deletePlaylist(site: $site, playlist: $playlist)
        }
      `,
      {
        site,
        playlist,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.playlistsList._def });
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.playlistsMostRecent._def });
  },
});

export const markPlaylistAsUsedMutation = ({ client, queryClient }) => ({
  mutationFn: ({ site, playlist }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation MarkPlaylistAsUsed($site: Int!, $playlist: Int!) {
          markPlaylistAsUsed(site: $site, playlist: $playlist)
        }
      `,
      {
        site,
        playlist,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: recordingsFeatureKeys.playlistsMostRecent._def });
  },
});
