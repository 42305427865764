import { NewAddonButton } from '/src/features/addons/common/NewAddonButton';

import AddonPNG from '@crazyegginc/hatch/dist/images/illustration-addons.png';
import AddonWebP from '@crazyegginc/hatch/dist/images/illustration-addons.webp';

import { Paywall } from '/src/components/Paywall';
import { FEATURES } from '/src/features/_global/constants';

export function NoCTAsWall({ mini }) {
  return (
    <Paywall>
      <Paywall.Image
        webp={AddonWebP}
        fallback={AddonPNG}
        width={mini ? '80px' : '175px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />
      <Paywall.Title mini={mini}>Create your first CTA!</Paywall.Title>

      <Paywall.Body>
        <p style={{ textWrap: 'balance' }}>
          Increase engagement with CTAs to highlight promotions, direct traffic to key pages, or even grow your mailing
          list.
        </p>
      </Paywall.Body>
      <NewAddonButton size="base" disableIfNoPermission={true} type={FEATURES.CTAS} />
    </Paywall>
  );
}
