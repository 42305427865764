import { useRef } from 'react';
import { formatDistanceToNow, fromUnixTime, startOfDay, endOfDay, subYears } from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { SkeletonLine, Button, DateRangePicker } from '@crazyegginc/hatch';

import { useAuthContext, useSite, useQueryParams, usePermissions, useHasFeatureFlag } from '/src/hooks';
import { VisitorIdProvider } from '/src/features/visitor-panel/visitor-id-context';
import { VisitorPanel } from '/src/features/visitor-panel/components/VisitorPanel';
import { useErrorsFilter } from '../../errors-filter-context';
import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { getErrorsQueryParams } from '/src/utils/url';

import { CrossOriginError } from './CrossOriginError';
import { ErrorHelper } from './ErrorHelper';
import { ErrorHistory } from './ErrorHistory';
import { StatusSelector } from './StatusSelector';
import { SummaryCards } from './SummaryCards';
import { ErrorList } from './ErrorList';
import { ErrorDetails } from './ErrorDetails';

import { uniqueErrorQuery } from '/src/features/errors/queries';
import { SINGLE_SITE_QUERY } from '/src/features/_global/queries';
import { RECORDINGS_SITES_QUERY } from '/src/features/recordings/queries';
import { FEATURES } from '/src/features/_global/constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';

import { ErrorNotFoundWall } from '../paywalls/ErrorNotFoundWall';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';
import { ReactComponent as ShareIcon } from '@crazyegginc/hatch/dist/images/icon-share-outline.svg';

export function ErrorReport() {
  const { isSharing, sharedResource } = useAuthContext();

  const { uniqueErrorId } = useParams();
  const permissions = usePermissions();
  const canVisitDashboard = permissions.can('navigate', FEATURES.ERRORS_TRACKING).allowed;
  const canEdit = permissions.can('edit', FEATURES.ERRORS_TRACKING).allowed;

  const { selectedSite, loadingSites } = useSite({
    sitesQuery: isSharing ? SINGLE_SITE_QUERY : RECORDINGS_SITES_QUERY,
    onlySelectFromQueryParam: true,
    sitesQueryProps: isSharing ? { variables: { id: sharedResource.resource.siteId } } : undefined,
  });

  return (
    <>
      <SEO title="Error report" />
      <DashHeader
        title="Error report"
        actionButton={
          <>
            {canVisitDashboard && (
              <Button
                component={Link}
                variant="secondary"
                to={{
                  pathname: `/errors`,
                  search: `${getErrorsQueryParams()}`,
                }}
                size="lg"
              >
                <ArrowIcon className="mr-2 h-4 w-4 rotate-180 fill-current" />
                Back to all Errors
              </Button>
            )}
            {canEdit && selectedSite?.id && (
              <Button
                className="ml-2.5"
                variant="secondary"
                leftIcon={ShareIcon}
                size="lg"
                onClick={() => {
                  window.SharingModal.show({
                    entity: { id: uniqueErrorId, siteId: selectedSite.id, type: SHARABLE_RESOURCE_TYPES.ERROR },
                  });
                }}
              >
                Share
              </Button>
            )}
          </>
        }
      />
      <VisitorIdProvider>
        <VisitorPanel />
        <ErrorReportContentWrapper selectedSite={selectedSite} loadingSites={loadingSites} />
      </VisitorIdProvider>
    </>
  );
}

function ErrorReportContentWrapper({ selectedSite, loadingSites }) {
  if (loadingSites) return null;

  if (!selectedSite || !selectedSite.id) return <ErrorNotFoundWall />;

  return <ErrorReportContent site={selectedSite} />;
}

function ErrorReportContent({ site }) {
  const isHighVolume = useHasFeatureFlag('high-volume-errors-dashboard');
  const { uniqueErrorId } = useParams();
  const { get: queryParamsGet } = useQueryParams();
  const eid = useRef(queryParamsGet('eid'));
  const { queryParams, setDateRange } = useErrorsFilter();

  const { data, isFetching, isLoading, error } = useQuery({
    ...uniqueErrorQuery({
      fingerprintMd5: uniqueErrorId,
      siteId: site.id,
    }),
    enabled: Boolean(uniqueErrorId),
  });
  const uniqueErrorData = data?.uniqueError;

  if ((!isFetching && !uniqueErrorData) || error) {
    return <ErrorNotFoundWall />;
  }

  if (uniqueErrorData?.fingerprint && uniqueErrorData.fingerprint.match(/^CrossOrigin Error/)) {
    return <CrossOriginError data={uniqueErrorData} />;
  }

  return (
    <div className="text-body-2 flex flex-col items-center p-10">
      <ErrorHeader uniqueErrorData={uniqueErrorData} fetching={isLoading} />
      <ErrorHistory uniqueErrorId={uniqueErrorId} siteId={site.id} uniqueErrorData={uniqueErrorData} />

      {!isHighVolume ? (
        <>
          <div className="mb-3.75 flex w-full justify-end">
            <DateRangePicker
              lowerBoundary={startOfDay(subYears(new Date(), 2))}
              upperBoundary={endOfDay(new Date())}
              startDate={queryParams.dateRange?.start_date}
              endDate={queryParams.dateRange?.end_date}
              special={queryParams.dateRange?.special}
              setDateRange={setDateRange}
              setCommonDateRange={setDateRange}
              showCommonRanges={true}
              maxRange={31}
              size="lg"
            />
          </div>
          <SummaryCards uniqueErrorId={uniqueErrorId} site={site} />
        </>
      ) : null}
      {eid.current ? (
        // When linking to a specific occurrence, show only the details
        <ErrorDetails errorId={eid.current} site={site} standalone={true} />
      ) : (
        <>
          <ErrorList uniqueErrorId={uniqueErrorId} site={site} />
        </>
      )}
    </div>
  );
}

function ErrorHeader({ uniqueErrorData, fetching }) {
  return (
    <div className="mb-10 flex w-full flex-col items-center justify-between bg-mystic-500/40 pb-2 pt-4">
      <div className="flex w-full px-5 pb-2">
        <div className="min-w-0 grow">
          <div className="text-header-3 mb-1 mr-4 truncate">
            {fetching ? <SkeletonLine width="55%" /> : uniqueErrorData.fingerprint}
          </div>
          <div className="text-body-3">
            {fetching ? (
              <div className="mt-2">
                <SkeletonLine width="25%" />
              </div>
            ) : (
              <>Last seen {formatDistanceToNow(fromUnixTime(uniqueErrorData.lastSeen), { addSuffix: true })} </>
            )}
          </div>
        </div>
        <div className="flex flex-shrink-0 items-center">
          {fetching ? (
            <SkeletonLine className="!w-40" />
          ) : (
            <StatusSelector status={uniqueErrorData.status} fingerprintMd5={uniqueErrorData.fingerprintMd5} />
          )}
        </div>
      </div>
      {!fetching && (
        <ErrorHelper
          status={uniqueErrorData.status}
          summary={uniqueErrorData.fingerprint}
          fingerprintMd5={uniqueErrorData.fingerprintMd5}
        />
      )}
    </div>
  );
}
