import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions, useMetric } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';
import { FEATURES } from '/src/features/_global/constants';

import ErrorsPNG from '@crazyegginc/hatch/dist/images/illustration-error-tracking.png';
import ErrorsWebP from '@crazyegginc/hatch/dist/images/illustration-error-tracking.webp';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function ErrorsUpgradePaywall({ mini }) {
  const { currentAccount } = useAuthContext();
  const trackMetric = useMetric();

  const permissions = usePermissions();
  const canManageBilling = permissions.can('edit', FEATURES.BILLING).allowed;

  return (
    <Paywall>
      <Paywall.Image
        webp={ErrorsWebP}
        png={ErrorsPNG}
        width={mini ? '80px' : '224px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />
      <Paywall.Title mini={mini}>Are Javascript Errors frustrating your visitors?</Paywall.Title>

      <Paywall.Body>
        Our automatic error detection can tell you which visitors are getting errors, and which errors are most
        important to fix.
        {mini ? null : (
          <div className="mt-6 flex justify-center">
            <div className="space-y-2">
              <div className="flex items-center">
                <TickIcon className="mr-2.5 h-4 w-4 fill-current text-lima-500" />
                Watch video recordings of your errors to see how and why they occur.
              </div>
              <div className="flex items-center">
                <TickIcon className="mr-2.5 h-4 w-4 fill-current text-lima-500" />
                Get all the debugging details you need to fix each error.
              </div>
              <div className="flex items-center">
                <TickIcon className="mr-2.5 h-4 w-4 fill-current text-lima-500" />
                Easily share details and recordings of errors with your team.
              </div>
            </div>
          </div>
        )}
        {!canManageBilling ? (
          <p className="mt-5">
            To get access to this feature, contact your Account Owner &#40;{currentAccount.ownerEmail}&#41; to upgrade
            the plan.
          </p>
        ) : null}
      </Paywall.Body>

      {canManageBilling ? (
        <Button
          component={Link}
          to={MANAGE_SUBSCRIPTION_PATH}
          onMouseDown={() => trackMetric('upgrade_cta_click', 'errors_tracking_upsell_paywall')}
          className="mb-5"
        >
          Get Errors Tracking
          <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />
        </Button>
      ) : (
        <Button component="a" href={`mailto:${currentAccount.ownerEmail}`} className="mb-5">
          Contact account owner
        </Button>
      )}
    </Paywall>
  );
}
