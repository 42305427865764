import { useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { CloseButton, Button, Input, Popover, StyledPopoverPanel, PopoverItem, Divider } from '@crazyegginc/hatch';

import {
  useAuthContext,
  useSelectedSite,
  useModal,
  usePermissions,
  useOutsideClickNotify,
  useMutation,
} from '/src/hooks';
import { inflect } from '/src/utils/string';
import { ActionIcon } from '/src/components/bulk-action/ActionIcon';
import { DeleteSnapshotsModal } from '/src/features/snapshots/components/dashboard/modals/DeleteSnapshotsModal';
import { SnapshotsExportModal } from '/src/features/snapshots/components/dashboard/modals/SnapshotsExportModal';

import { foldersQuery } from '/src/features/snapshots/queries';
import {
  duplicateSnapshotsMutation,
  deleteSnapshotMutation,
  refreshSnapshotsMutation,
  pauseSnapshotsMutation,
  resumeSnapshotsMutation,
  exportSnapshotsMutation,
  moveSnapshotsMutation,
  unfileSnapshotsMutation,
  createFolderMutation,
} from '/src/features/snapshots/mutations';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as RemoveIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as PauseIcon } from '@crazyegginc/hatch/dist/images/icon-pause-filled.svg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as RefreshIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';
import { ReactComponent as DuplicateIcon } from '@crazyegginc/hatch/dist/images/icon-web-pages-outline.svg';
import { ReactComponent as MoveIcon } from '@crazyegginc/hatch/dist/images/icon-folder-outline.svg';
import { ReactComponent as ExportIcon } from '@crazyegginc/hatch/dist/images/icon-download-filled.svg';

import { ReactComponent as FolderIcon } from '@crazyegginc/hatch/dist/images/icon-folder-outline.svg';

export function SnapshotsBulkActions({
  onActionStart,
  onSuccess,
  onError,
  currentSelection,
  selectedIds,
  filter,
  sites,
  setPollExports,
}) {
  const { capabilities } = useAuthContext();
  const { selectedSite } = useSelectedSite();
  const modal = useModal();
  const permissions = usePermissions();
  const duplicateSnapshots = useMutation(duplicateSnapshotsMutation);
  const deleteSnapshots = useMutation(deleteSnapshotMutation);
  const pauseSnapshots = useMutation(pauseSnapshotsMutation);
  const resumeSnapshots = useMutation(resumeSnapshotsMutation);
  const refreshSnapshots = useMutation(refreshSnapshotsMutation);
  const exportSnapshots = useMutation(exportSnapshotsMutation);
  const moveSnapshots = useMutation(moveSnapshotsMutation);
  const unfileSnapshots = useMutation(unfileSnapshotsMutation);
  const createFolder = useMutation(createFolderMutation);

  const canEdit = permissions.can('edit', FEATURES.SNAPSHOTS).allowed;
  const canPause = permissions.can('pause', currentSelection, 'some').allowed;
  const canResume = permissions.can('resume', currentSelection, 'some').allowed;
  const canRefresh = permissions.can('refresh', currentSelection, 'some').allowed;
  const canDuplicate = permissions.can('duplicate', currentSelection, 'some').allowed;

  const exportableIds = currentSelection
    .filter((snapshot) => permissions.can('export', snapshot).allowed)
    .map((snapshot) => parseInt(snapshot.id));

  const ids = selectedIds.map((id) => parseInt(id));
  const selectedNum = selectedIds.length;

  const firstSelectionSiteId = selectedNum > 0 && parseInt(currentSelection[0].siteId);
  const allFromSameSiteId =
    selectedNum > 0 && currentSelection.every((snapshot) => snapshot.siteId === firstSelectionSiteId);

  const { data: foldersData } = useQuery({
    ...foldersQuery({ siteId: allFromSameSiteId ? firstSelectionSiteId : filter.site?.id ?? null }),
    enabled: Boolean(allFromSameSiteId || filter.site),
  });

  const folders = foldersData?.folders?.list ?? [];
  const allFromSameFolder =
    selectedNum > 0 && currentSelection.every((snapshot) => snapshot.folderId === currentSelection[0].folderId);
  const snapshotsInFolders = folders.reduce((acc, folder) => acc + folder.snapshotsCount, 0);

  const [showNewFolderDialog, setShowNewFolderDialog] = useState(false);

  const getUnfiledNumber = () => {
    let totalSnapshotNumberForSite;
    if (selectedSite?.id) {
      totalSnapshotNumberForSite = sites.find((site) => site.id === selectedSite.id).snapshotsCount;
    } else {
      totalSnapshotNumberForSite = capabilities.snapshots.quotas.totalSnapshots.current;
    }
    return totalSnapshotNumberForSite - snapshotsInFolders;
  };

  return (
    <>
      {canEdit && (
        <ActionIcon
          img={RemoveIcon}
          alt="Delete selected"
          tooltip="Delete"
          onClick={() => {
            modal.show(
              <DeleteSnapshotsModal
                onConfirm={() => {
                  onActionStart();
                  modal.close();
                  deleteSnapshots.mutate(
                    { ids },
                    {
                      onError: (error) => onError({ error, name: 'Delete snapshots' }),
                      onSuccess: (data) => {
                        const affected = data.deleteSnapshots.affected;
                        onSuccess({
                          deselectAll: true,
                          message: `${affected.length} ${inflect('Snapshot', affected.length)} deleted successfully.`,
                        });
                      },
                    },
                  );
                }}
                numToDelete={selectedNum}
              />,
            );
          }}
        />
      )}
      {canEdit && canPause && (
        <ActionIcon
          img={PauseIcon}
          alt="Pause selected"
          tooltip="Pause"
          iconClassName="px-0.5"
          onClick={() => {
            onActionStart();
            pauseSnapshots.mutate(
              { ids },
              {
                onError: (error) => onError({ error, name: 'Pause snapshots' }),
                onSuccess: (data) => {
                  const affected = data.pauseSnapshots.affected;
                  onSuccess({
                    deselectAll: true,
                    message: `${affected.length} ${inflect('Snapshot', affected.length)} ${
                      affected.length > 1 ? 'have' : 'has'
                    } been paused and you can resume tracking again at any time.`,
                  });
                },
              },
            );
          }}
        />
      )}
      {canEdit && canResume && (
        <ActionIcon
          img={PlayIcon}
          alt="Resume selected"
          tooltip="Resume"
          iconClassName="pl-1"
          onClick={() => {
            onActionStart();
            resumeSnapshots.mutate(
              { ids },
              {
                onError: (error) => onError({ error, name: 'Resume snapshots' }),
                onSuccess: (data) => {
                  const affected = data.resumeSnapshotsNew.affected;
                  if (affected.length === 0 && ids.length > 0) {
                    return onError({
                      deselectAll: true,
                      message: 'We could not resume your selected Snapshots.',
                      name: 'Resume snapshots',
                    });
                  }
                  if (affected.length !== ids.length) {
                    return onSuccess({
                      deselectAll: true,
                      message: `We resumed ${affected.length} of the selected ${ids.length} Snapshots.`,
                    });
                  }
                  onSuccess({
                    deselectAll: true,
                    message: `We will start tracking data for ${affected.length > 1 ? 'these' : 'this'} ${
                      affected.length
                    } ${inflect('Snapshot', affected.length)} ASAP.`,
                  });
                },
              },
            );
          }}
        />
      )}
      {canEdit && canRefresh && (
        <ActionIcon
          img={RefreshIcon}
          alt="Refresh selected"
          tooltip="Refresh"
          onClick={() => {
            onActionStart();
            refreshSnapshots.mutate(
              { ids },
              {
                onError: (error) => onError({ error, name: 'Refresh snapshots' }),
                onSuccess: (data) => {
                  const affected = data.refreshSnapshots.affected;
                  onSuccess({
                    deselectAll: true,
                    message: `We're in the process of updating ${affected.length} snapshot ${inflect(
                      'image',
                      affected.length,
                    )}.`,
                  });
                },
              },
            );
          }}
        />
      )}
      {canEdit && canDuplicate && (
        <ActionIcon
          img={DuplicateIcon}
          alt="Duplicate selected"
          tooltip="Duplicate"
          onClick={() => {
            onActionStart();
            duplicateSnapshots.mutate(
              { ids },
              {
                onError: (error) => onError({ error, name: 'Duplicate snapshots' }),
                onSuccess: (data) => {
                  const affected = data.duplicateSnapshots.affected;
                  onSuccess({
                    deselectAll: true,
                    message: `${affected.length} ${inflect('Snapshot', affected.length)} ${
                      affected.length > 1 ? 'have' : 'has'
                    } been duplicated.`,
                  });
                },
              },
            );
          }}
        />
      )}
      {canEdit && (allFromSameSiteId || filter.site) && (
        <Popover className="relative ml-[25px] first:ml-0">
          {({ open }) => (
            <>
              <Popover.Button
                as={ActionIcon}
                img={MoveIcon}
                alt="Move to folder"
                tooltip={open || showNewFolderDialog ? null : 'Move to folder'}
              />
              {open && (
                <StyledPopoverPanel
                  align="center"
                  static
                  className="!bottom-[60px] !min-w-[155px] !max-w-[255px] !pr-1"
                >
                  {({ close }) => (
                    <>
                      <div
                        className={classNames(
                          'flex max-h-60 flex-col pr-3',
                          '!overflow-y-auto',
                          'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded',
                        )}
                      >
                        {folders.map((folder) => {
                          const disabled = allFromSameFolder && folder.id === currentSelection[0].folderId;
                          return (
                            <PopoverItem
                              className={classNames('!flex !items-start', {
                                'hover:!text-body-5 !cursor-not-allowed !bg-white-lilac-500 !italic': disabled,
                                'group ': !disabled,
                              })}
                              key={folder.id}
                              disabled={disabled}
                              onClick={() => {
                                setShowNewFolderDialog(false);
                                close();
                                onActionStart();
                                moveSnapshots.mutate(
                                  {
                                    ids,
                                    toFolderId: folder.id,
                                  },
                                  {
                                    onError: (error) => onError({ error, name: 'Move snapshots' }),
                                    onSuccess: (data) => {
                                      const affected = data.moveSnapshots.affected;
                                      onSuccess({
                                        deselectAll: true,
                                        message: `${affected.length} ${inflect('Snapshot', affected.length)} ${
                                          affected.length > 1 ? 'have' : 'has'
                                        } been moved.`,
                                      });
                                    },
                                  },
                                );
                              }}
                            >
                              <FolderIcon
                                className={classNames(
                                  'mr-2.5 h-4 w-4 flex-shrink-0',
                                  'fill-current text-cadet-blue-500 group-hover:text-dodger-blue-500',
                                )}
                              />
                              <div
                                before={`${folder.title} (${folder.snapshotsCount})`}
                                className={classNames(
                                  'w-max',
                                  'before:invisible before:block before:h-0 before:overflow-hidden before:font-semibold before:content-[attr(before)]',
                                )}
                                style={{ overflowWrap: 'anywhere' }}
                              >
                                {folder.title} ({folder.snapshotsCount})
                              </div>
                            </PopoverItem>
                          );
                        })}
                        <PopoverItem
                          disabled={allFromSameFolder && !currentSelection[0].folderId}
                          className={classNames('!pl-[36px]', {
                            'hover:!text-body-5 !cursor-not-allowed !bg-white-lilac-500 !italic':
                              allFromSameFolder && !currentSelection[0].folderId,
                          })}
                          onClick={() => {
                            setShowNewFolderDialog(false);
                            close();
                            onActionStart();
                            unfileSnapshots.mutate(
                              { ids },
                              {
                                onError: (error) => onError({ error, name: 'Unfile snapshots' }),
                                onSuccess: (data) => {
                                  const affected = data.unfileSnapshots.affected;
                                  onSuccess({
                                    deselectAll: true,
                                    message: `${affected.length} ${inflect('Snapshot', affected.length)} ${
                                      affected.length > 1 ? 'have' : 'has'
                                    } been unfiled.`,
                                  });
                                },
                              },
                            );
                          }}
                        >
                          <div
                            before={`Unfiled (${getUnfiledNumber()})`}
                            className={classNames(
                              'w-max',
                              'before:invisible before:block before:h-0 before:overflow-hidden before:font-semibold before:content-[attr(before)]',
                            )}
                          >
                            Unfiled ({getUnfiledNumber()})
                          </div>
                        </PopoverItem>
                      </div>
                      {selectedSite.id && (
                        <div className="pr-3">
                          <Divider className="-mx-4 my-2 !w-auto" />
                          <PopoverItem onClick={() => setShowNewFolderDialog(true)}>
                            <div>Move to new folder</div>
                          </PopoverItem>
                        </div>
                      )}
                    </>
                  )}
                </StyledPopoverPanel>
              )}
              {showNewFolderDialog && (
                <MoveToNewFolderDialog
                  folders={folders}
                  onClose={() => setShowNewFolderDialog(false)}
                  onConfirm={(newFolder) => {
                    setShowNewFolderDialog(false);
                    onActionStart();
                    createFolder.mutate(
                      {
                        siteId: selectedSite.id,
                        title: newFolder,
                      },
                      {
                        onError: (error) => onError({ error, name: 'Snapshot new folder create error' }),
                        onSuccess: (data) => {
                          moveSnapshots.mutate(
                            {
                              ids,
                              toFolderId: data.createFolder.id,
                            },
                            {
                              onError: (error) => onError({ error, name: 'Move snapshots' }),
                              onSuccess: (data) => {
                                const affected = data.moveSnapshots.affected;
                                onSuccess({
                                  deselectAll: true,
                                  message: `${affected.length} ${inflect('Snapshot', affected.length)} ${
                                    affected.length > 1 ? 'have' : 'has'
                                  } been moved to a new folder.`,
                                });
                              },
                            },
                          );
                        },
                      },
                    );
                  }}
                />
              )}
            </>
          )}
        </Popover>
      )}
      {exportableIds.length > 0 && (
        <ActionIcon
          img={ExportIcon}
          alt="Export selected"
          tooltip="Export"
          onClick={() => {
            modal.show(
              <SnapshotsExportModal
                ids={exportableIds}
                onConfirm={(params) => {
                  onActionStart();
                  modal.close();
                  exportSnapshots.mutate(params, {
                    onError: (error) => onError({ error, name: 'Export snapshots' }),
                    onSuccess: (data) => {
                      setPollExports(true);
                      const affected = data.exportSnapshots.affected;
                      onSuccess({
                        deselectAll: true,
                        message: `Exporting ${affected.length} ${inflect('Snapshot', affected.length)}...`,
                      });
                    },
                  });
                }}
              />,
            );
          }}
        />
      )}
    </>
  );
}

function getNewFolderValidationSchema(folders) {
  return yup.object().shape({
    folderName: yup
      .string()
      .required('Please provide a name.')
      .matches(/\S/, 'Please provide a valid name')
      .test('duplicate-test', 'Please provide a unique name.', (value) => {
        return !folders.find((folder) => folder.title === value);
      }),
  });
}

function MoveToNewFolderDialog({ onClose, onConfirm, folders }) {
  const newfolderDialogRef = useRef();

  useOutsideClickNotify([newfolderDialogRef], () => onClose());

  return (
    <>
      <div
        ref={newfolderDialogRef}
        className={classNames(
          'flex w-[350px] animate-scalein flex-col rounded-md border border-mystic-500 bg-white p-6 shadow-md',
          'absolute bottom-[60px] left-[-175px] z-[9999]',
        )}
      >
        <CloseButton onClick={onClose} label="close save playlist dialog" />
        <div className="text-header-3 mb-8">Move to new folder</div>
        <Formik
          initialValues={{ folderName: 'New folder' }}
          validationSchema={getNewFolderValidationSchema(folders)}
          onSubmit={(values) => {
            onConfirm(values.folderName.trim());
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <>
                <Input
                  type="text"
                  id="new-folder"
                  name="folderName"
                  label="Folder name:"
                  value={values.folderName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.folderName ? errors.folderName : null}
                  // eslint-disable-next-line
                  autoFocus
                  onFocus={(e) => {
                    if (!touched.folderName) {
                      e.target.select();
                    }
                  }}
                />

                <div className="mt-4 flex space-x-2.5">
                  <Button type="submit">Create</Button>
                  <Button variant="cancel" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
