import { createContext, useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { WizardLoading } from '/src/features/ab-testing/components/wizard/WizardLoading';
import { useMutation, useNotifications } from '/src/hooks';
import { abTestWizardMachine } from '/src/features/ab-testing/machines/wizard';
import { contextToArgs, convertDraftToPayload, syncVariants } from '/src/features/ab-testing/utils';
import { AB_TEST_STATUSES } from '/src/features/ab-testing/constants';

import { AbTestWizard } from '../components/wizard';
import { abTestDetailQuery } from '../queries';
import {
  abTestUpdateMutation,
  abTestAddVariantsMutation,
  abTestUpdateVariantsMutation,
  abTestDeleteVariantMutation,
  abTestUpdateDraftMutation,
  abTestPublishMutation,
} from '/src/features/ab-testing/mutations';

export const AbTestWizardContext = createContext({});

const machine = abTestWizardMachine({ initial: 'waitingForDraft' });

// eslint-disable-next-line
export default function EditAbTestPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { refetch } = useQuery({
    ...abTestDetailQuery({
      id: Number(id),
    }),
    cacheTime: 0,
    // enabled: !!id,
    enabled: false,
  });

  const [state, , service] = useMachine(machine, {
    devTools: true,
  });

  useEffect(() => {
    const subscription = service.subscribe(async (state) => {
      if (state.matches('waitingForDraft')) {
        const { data } = await refetch();
        if (data.abTestDetail.status !== AB_TEST_STATUSES.DRAFT) {
          navigate('/ab-tests');
        }
        const payload = convertDraftToPayload(
          data.abTestDetail.id,
          data.abTestDetail.draft,
          data.abTestDetail.variants,
        );
        service.send({
          type: 'SET_PAYLOAD',
          payload,
          original: data.abTestDetail,
        });
      }
      if (state.matches('cancelled')) {
        navigate('/ab-tests');
      }
    });

    return subscription.unsubscribe;
  }, [navigate, refetch, service]);

  const isLoading = state.matches('waitingForDraft');
  const isSaving = state.matches('syncDraft') || state.matches('savingDraft');

  return (
    <>
      {isLoading || isSaving ? (
        <WizardLoading
          nextText={state.context.nextText}
          balloonText={isLoading ? 'Loading...' : 'Saving your draft...'}
          title={state.context.title}
          step={state.context.step}
          totalSteps={4}
        />
      ) : (
        <AbTestWizardContext.Provider value={{ abTestService: service }}>
          <AbTestWizard context={AbTestWizardContext} />
        </AbTestWizardContext.Provider>
      )}
      <StateHander service={service} />
    </>
  );
}

function StateHander({ service }) {
  const notifications = useNotifications();
  const navigate = useNavigate();
  const { mutateAsync: mutateUpdateAbTest } = useMutation(abTestUpdateMutation);
  const { mutateAsync: mutateAddVariants } = useMutation(abTestAddVariantsMutation);
  const { mutateAsync: mutateUpdateVariants } = useMutation(abTestUpdateVariantsMutation);
  const { mutateAsync: mutateDeleteVariant } = useMutation(abTestDeleteVariantMutation);
  const { mutateAsync: mutateUpdateDraft } = useMutation(abTestUpdateDraftMutation);
  const { mutateAsync: mutatePublishTest } = useMutation(abTestPublishMutation);

  const { success: notifySuccess, error: notifyError } = notifications;

  useEffect(() => {
    const subscription = service.subscribe(async (state) => {
      if (state.matches('syncVariants')) {
        await syncVariants(state.context.payload.id, state.context, {
          mutateAddVariants,
          mutateUpdateDraft,
          mutateDeleteVariant,
          mutateUpdateVariants,
        });
        service.send({ type: 'DONE' });
      }

      if (state.matches('savingDraft') || state.matches('syncDraft')) {
        await syncVariants(state.context.payload.id, state.context, {
          mutateAddVariants,
          mutateUpdateDraft,
          mutateDeleteVariant,
          mutateUpdateVariants,
        });
        await mutateUpdateAbTest(
          {
            id: state.context.payload.id,
            args: contextToArgs(state.context),
          },
          {
            onSuccess: (data) => {
              notifySuccess({ content: `Draft saved successfully.`, timeout: 3000 });
              service.send({ type: 'SAVE_SUCCESS', value: data.abTestUpdate });
            },
            onError: (error) => {
              notifyError({ content: `Draft save failed.`, timeout: 3000, context: { error } });
              service.send({ type: 'SAVE_FAILED', value: error });
            },
            onSettled: () => {
              service.send({ type: 'DONE' });
            },
          },
        );
      }

      if (state.matches('publishTest')) {
        await syncVariants(state.context.payload.id, state.context, {
          mutateAddVariants,
          mutateUpdateDraft,
          mutateDeleteVariant,
          mutateUpdateVariants,
        });
        await mutateUpdateAbTest({
          id: state.context.payload.id,
          args: contextToArgs(state.context),
        });
        await mutatePublishTest(
          {
            id: state.context.id,
          },
          {
            onSuccess: (data) => {
              notifySuccess({ content: `A/B test published successfully.`, timeout: 3000 });
              service.send({ type: 'SUCCESS', value: data.abTestPublish });
            },
            onError: (error) => {
              notifyError({ content: `Failed to publish your A/B test.`, timeout: 3000 });
              service.send({ type: 'ERROR', error });
            },
          },
        );
      }

      if (state.matches('cancelled')) {
        navigate(state.context.previousPath, { replace: true });
      }
    });

    return subscription.unsubscribe;
  }, [
    service,
    mutateUpdateDraft,
    mutateAddVariants,
    mutateUpdateVariants,
    mutateDeleteVariant,
    mutateUpdateAbTest,
    mutatePublishTest,
    notifyError,
    notifySuccess,
    navigate,
  ]);

  return null;
}
