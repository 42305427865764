export function getExternalPreviewLink(userId, siteId, token) {
  return `https://surveys.crazyegg.com/preview/${userId}/${siteId}#ce-survey-${token}`;
}

export function getExternalSurveyLink(id, userId) {
  if (id && userId) {
    return `https://surveys.crazyegg.com/s/${userId}/${id}`;
  }
  return undefined;
}

export function getPreviewShareLink(url, token, type) {
  return `${url}#ce-${type.toLowerCase()}-${token}`;
}
