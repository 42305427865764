import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Popover,
  StyledPopoverPanel,
  PopoverItem,
  Input,
  Divider,
  Tooltip,
  Indicator,
} from '@crazyegginc/hatch';

import { usePrevious, useMutation, useModal, usePermissions, useSelectedSite } from '/src/hooks';
import { DeleteConfirmationModal } from '/src/components/modals/DeleteConfirmationModal';
import { inflect } from '/src/utils/string';
import { foldersQuery } from '/src/features/snapshots/queries';
import { createFolderMutation, deleteFolderMutation, updateFolderMutation } from '/src/features/snapshots/mutations';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as FolderIcon } from '@crazyegginc/hatch/dist/images/icon-folder-outline.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';
import { ReactComponent as RemoveIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';

export function FoldersDropdown({ selected = undefined, folderId, onSelect, totalCount }) {
  const { selectedSite } = useSelectedSite();
  const prevSelectedSite = usePrevious(selectedSite);

  const { data: foldersData, isLoading: fetchingFolders } = useQuery({
    ...foldersQuery({ siteId: selectedSite?.id }),
    enabled: Boolean(selectedSite?.id),
  });

  const folders = selectedSite ? foldersData?.folders?.list ?? null : null;
  const unfiledCount = totalCount - (folders?.reduce((acc, folder) => acc + folder.snapshotsCount, 0) ?? 0);
  const disabled = !selectedSite?.id || !folders || fetchingFolders;
  const selectedCount = folders?.find((folder) => folder.id === selected?.id)?.snapshotsCount;

  useEffect(() => {
    // load folder from queryParam (folderId)
    if (folderId === 'unfiled' && selected !== null) {
      onSelect(null);
    } else if (folders && folderId && folderId !== 'unfiled' && !selected) {
      onSelect(folders.find((f) => f.id === +folderId));
    }
  }, [selected, folders, folderId, onSelect]);

  useEffect(() => {
    // clear selected folder when selected site changes
    if (prevSelectedSite && prevSelectedSite !== selectedSite) {
      onSelect(undefined);
    }
  }, [onSelect, prevSelectedSite, selectedSite]);

  const displayTitle = () => {
    if (!selectedSite?.id) {
      return `All folders ${totalCount ? `(${totalCount})` : ''}`;
    }
    if (fetchingFolders) {
      return 'Loading...';
    }
    if (selected === null) {
      return `Unfiled (${unfiledCount})`;
    }
    if (selected?.title) {
      return `${selected.title} (${selectedCount})`;
    }
    return `All folders ${totalCount ? `(${totalCount})` : ''}`;
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Tooltip
            show={!selectedSite?.id}
            tooltipContent={
              <div className="max-w-[140px]">
                Switch from &quot;All Sites&quot; to a specific site to access folders.
              </div>
            }
          >
            <Popover.Button
              as={Button}
              disabled={disabled}
              variant="secondary"
              size="lg"
              className={open ? '!border-dodger-blue-500' : ''}
            >
              <FolderIcon className="mr-2.5 h-4 w-4 fill-current" />
              {displayTitle()}
              <Indicator type="dropdown" className="ml-2.5" />
            </Popover.Button>
          </Tooltip>
          <FoldersPanel
            open={open}
            onSelect={onSelect}
            selected={selected}
            folders={folders}
            totalCount={totalCount}
            unfiledCount={unfiledCount}
            selectedSite={selectedSite}
          />
        </>
      )}
    </Popover>
  );
}

function getNewFolderValidationSchema(folders) {
  return yup.object().shape({
    folderName: yup
      .string()
      .required('Please provide a name.')
      .matches(/\S/, 'Please provide a valid name')
      .test('duplicate-test', 'Please provide a unique name.', (value) => {
        return !folders.find((folder) => folder.title === value);
      }),
  });
}

function getUpdateFolderValidationSchema(folders, folder) {
  return yup.object().shape({
    folderName: yup
      .string()
      .required('Please provide a name.')
      .matches(/\S/, 'Please provide a valid name')
      .test('duplicate-test', 'Please provide a unique name.', (value) => {
        return !folders.find((x) => x.title === value && x.title !== folder.title);
      }),
  });
}

function FoldersPanel({ open, selectedSite, onSelect, selected, folders, totalCount, unfiledCount }) {
  const createFolder = useMutation(createFolderMutation);
  const deleteFolder = useMutation(deleteFolderMutation);
  const updateFolder = useMutation(updateFolderMutation);
  const permissions = usePermissions();
  const modal = useModal();
  const canEdit = permissions.can('edit', FEATURES.SNAPSHOTS).allowed;
  const [editing, setEditing] = useState(false);
  const [creatingFolder, setCreatingFolder] = useState(false);

  useEffect(() => {
    if (!open) {
      setCreatingFolder(false);
      setEditing(false);
    }
  }, [open]);

  function clearEditStates() {
    setCreatingFolder(false);
    setEditing(false);
  }

  function unselectFolder() {
    clearEditStates();
    onSelect(undefined);
  }

  function doCreateFolder(folderName) {
    createFolder.mutate(
      {
        siteId: selectedSite.id,
        title: folderName,
      },
      { onSettled: () => clearEditStates() },
    );
  }

  function doDeleteFolder(folder, close) {
    const folderHasSnapshots = folder.snapshotsCount > 0;

    const deleteFolderFunc = () => {
      return deleteFolder.mutate(
        {
          id: parseInt(folder.id),
        },
        {
          onSettled: () => {
            if (selected && editing === selected.id) {
              // unselect this deleted folder
              unselectFolder();
            }
            setEditing(false);
            modal.close();
            close();
          },
        },
      );
    };

    if (folderHasSnapshots) {
      modal.show(
        <DeleteConfirmationModal
          text={
            <>
              Are you sure you want to delete the folder <span className="text-body-1">{folder.title}</span>? Deleting
              this folder will also delete{' '}
              <span className="text-body-1">
                {folder.snapshotsCount} {inflect('Snapshot', folder.snapshotsCount)}
              </span>{' '}
              inside of it.
            </>
          }
          entity="folder"
          onDelete={deleteFolderFunc}
          onCancel={clearEditStates}
        />,
      );
    } else {
      deleteFolderFunc();
    }
  }

  function doUpdateFolder(newName) {
    // if the folder already exists, clear edit states
    const folderAlreadyExists = folders.find((folder) => folder.title === newName);
    if (folderAlreadyExists) {
      return clearEditStates();
    }

    updateFolder.mutate(
      {
        id: Number(editing),
        title: newName,
      },
      { onSettled: () => clearEditStates() },
    );
  }

  return (
    open && (
      <StyledPopoverPanel align="center" static className="w-[260px] !pr-1" data-testid="folders_dropdown">
        {({ close }) => (
          <>
            <div
              className={classNames(
                'flex max-h-60 flex-col pr-3',
                '!overflow-y-auto',
                'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded',
              )}
            >
              <PopoverItem
                className="!font-semibold"
                onClick={() => {
                  onSelect(undefined);
                  close();
                }}
              >
                All folders {totalCount ? `(${totalCount})` : ''}
              </PopoverItem>

              {folders &&
                folders.map((folder) =>
                  typeof editing === 'number' && editing === folder.id ? (
                    /* Edit folder form  */
                    <PopoverItem key={`${folder.title}-${folder.id}-editing`} component="div" className="group">
                      <Formik
                        initialValues={{ folderName: folder.title }}
                        validationSchema={getUpdateFolderValidationSchema(folders, folder)}
                        onSubmit={(values) => {
                          doUpdateFolder(values.folderName.trim());
                        }}
                      >
                        {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
                          <Form>
                            <div className="flex items-start">
                              <FolderIcon
                                className={classNames(
                                  'mr-2 mt-1 h-4 w-4 flex-shrink-0',
                                  'fill-current text-malibu-500 group-hover:text-dodger-blue-500',
                                )}
                              />
                              <div className="min-w-[140px]">
                                <Input
                                  disabled={isSubmitting || deleteFolder.isLoading}
                                  className="!px-2"
                                  name="folderName"
                                  value={values.folderName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={touched.folderName ? errors.folderName : null}
                                  // eslint-disable-next-line
                                  autoFocus
                                  size="xs"
                                />
                              </div>
                              <button
                                disabled={isSubmitting}
                                type="submit"
                                className={classNames(
                                  'ml-1.5 mt-1.5 flex-shrink-0 px-0.5 text-cadet-blue-500 focus-visible:outline-black',
                                  {
                                    'hover:text-dodger-blue-500': !isSubmitting,
                                  },
                                )}
                              >
                                <TickIcon className="h-3 w-3 fill-current" aria-label="apply" />
                              </button>
                              <button
                                disabled={deleteFolder.isLoading}
                                type="button"
                                onClick={() => doDeleteFolder(folder, close)}
                                className={classNames(
                                  'ml-0.5 mt-1.5 flex-shrink-0 px-0.5 text-cadet-blue-500 focus-visible:outline-black',
                                  {
                                    'hover:text-carnation-500': !deleteFolder.isLoading,
                                  },
                                )}
                              >
                                <RemoveIcon className="h-3 w-3 fill-current" aria-label="delete folder" />
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </PopoverItem>
                  ) : (
                    /* Folder list item  */
                    <PopoverItem
                      key={`${folder.title}-${folder.id}`}
                      className="group !flex !items-start"
                      component="div"
                    >
                      <button
                        onClick={() => {
                          onSelect(folder);
                          close();
                        }}
                        type="button"
                        className="flex min-w-0 flex-1 flex-shrink-0 focus-visible:outline-black"
                      >
                        <FolderIcon
                          className={classNames(
                            'mr-2 h-4 w-4 flex-shrink-0',
                            'fill-current text-malibu-500 group-hover:text-dodger-blue-500',
                          )}
                        />
                        <div className="text-left group-hover:font-semibold" style={{ overflowWrap: 'anywhere' }}>
                          {folder.title} ({folder.snapshotsCount})
                        </div>
                      </button>
                      <button
                        type="button"
                        className={classNames(
                          'ml-2.5 flex-shrink-0 px-0.5 py-0.5 text-cornflower-500 hover:text-dodger-blue-500 focus-visible:outline-black',
                          'invisible group-focus-within:visible group-hover:visible',
                        )}
                        onClick={() => setEditing(parseInt(folder.id))}
                      >
                        <EditIcon className="h-3 w-3 fill-current" aria-label="edit" />
                      </button>
                    </PopoverItem>
                  ),
                )}

              {/* Create new folder form */}
              {canEdit && creatingFolder && (
                <PopoverItem component="div" className="group">
                  <Formik
                    initialValues={{ folderName: 'New folder' }}
                    validationSchema={getNewFolderValidationSchema(folders)}
                    onSubmit={(values) => {
                      doCreateFolder(values.folderName.trim());
                    }}
                  >
                    {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
                      <Form>
                        <div className="flex items-start">
                          <FolderIcon className="mr-2 mt-1 h-4 w-4 flex-shrink-0 fill-current text-malibu-500 group-hover:text-dodger-blue-500" />
                          <div className="min-w-[140px]">
                            <Input
                              disabled={isSubmitting}
                              className="!px-2"
                              name="folderName"
                              value={values.folderName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.folderName ? errors.folderName : null}
                              // eslint-disable-next-line
                              autoFocus
                              onFocus={(e) => {
                                if (!touched.folderName) {
                                  e.target.select();
                                }
                              }}
                              size="xs"
                            />
                          </div>
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className={classNames(
                              'ml-1.5 mt-1.5 flex-shrink-0 px-1 text-cadet-blue-500 focus-visible:outline-black',
                              {
                                'hover:text-dodger-blue-500': !isSubmitting,
                              },
                            )}
                          >
                            <TickIcon className="h-3 w-3 fill-current" aria-label="create folder" />
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </PopoverItem>
              )}
              {/* Create new folder form end */}

              <PopoverItem
                className="!pl-[34px]"
                onClick={() => {
                  onSelect(null);
                  close();
                }}
              >
                Unfiled ({unfiledCount})
              </PopoverItem>
            </div>

            <div className="pr-3">
              {canEdit && (
                <>
                  <Divider className="-mx-4 my-2 !w-auto" />
                  <PopoverItem
                    className="!font-semibold !text-dodger-blue-500"
                    onClick={() => {
                      setCreatingFolder(true);
                      if (editing) setEditing(false);
                    }}
                  >
                    Create new folder
                  </PopoverItem>
                </>
              )}
            </div>
          </>
        )}
      </StyledPopoverPanel>
    )
  );
}
