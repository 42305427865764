import { useNavigate } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { DashboardPage } from '/src/components/Page';
import { usePermissions, useSelectedSite } from '/src/hooks';

import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { SelectionProvider } from '/src/contexts/selection';
import { VisitorIdProvider } from '../../visitor-panel/visitor-id-context';
import { FilterProvider } from '../recordings-filter-context';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { DashboardFeatureUsage } from '/src/components/usage';
import { RecordingsDashboardContent } from '../components/dashboard/RecordingsDashboardContent';

import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';

// eslint-disable-next-line no-restricted-syntax
export default function RecordingsDashboard() {
  const permissions = usePermissions();

  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.RECORDINGS);

  if (!canViewDashboard) {
    return <DashboardPaywall feature={FEATURES.RECORDINGS} reason={reason} />;
  }

  return (
    <DashboardPage>
      <SEO title={getFeatureName(FEATURES.RECORDINGS)} />
      <FilterProvider>
        <VisitorIdProvider>
          <RecordingDashHeader />
          <SelectionProvider>
            <RecordingsDashboardContent />
          </SelectionProvider>
        </VisitorIdProvider>
      </FilterProvider>
    </DashboardPage>
  );
}

function RecordingDashHeader() {
  const permissions = usePermissions();
  const canManageSettings = permissions.can('settings', FEATURES.RECORDINGS).allowed;

  return (
    <DashHeader
      title={getFeatureName(FEATURES.RECORDINGS)}
      actionButton={
        <>
          <DashboardFeatureUsage feature={FEATURES.RECORDINGS} />
          {canManageSettings && <CustomizeRecordingsButton />}
        </>
      }
    />
  );
}

function CustomizeRecordingsButton() {
  const navigate = useNavigate();
  const { selectedSite } = useSelectedSite();

  return (
    <Button
      variant="secondary"
      className="!w-[175px]"
      size="xl"
      onClick={() => {
        navigate({
          pathname: `/recordings/settings/sites/${selectedSite?.id ?? ''}`,
        });
      }}
    >
      Customize Recordings
    </Button>
  );
}
