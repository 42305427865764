import Honeybadger from '@honeybadger-io/js';
import jwtDecode from 'jwt-decode';

import { hydrateToken } from '/src/utils/auth';

const ignorePatterns = [
  /unauthorized/i, // ignore unauthorized errors. TODO: remove when deprecating v1
  /already exists/i, // these errors are handled by the components
  /no active subscription: snapshots/i,
  /no active subscription: recordings/i,
  /trouble accessing the page/i,
  /ResizeObserver/i, // ignore ResizeObserver errors, they are safe to ignore and thrown by Chrome
  /loading chunk \d+ failed/i, // ignore chunk errors, they only happen after a deploy when a user has a cached bundle
  /invalid admin credentials/i,
  /InvalidAdminCredentialsError/i,
  /UnhandledPromiseRejectionWarning/i,
  /Invalid time value/,
  /Object\.fromEntries is not a function/, // old browsers, we show a banner to update their browser
  /Shared resource doesn't exists/, // https://app.shortcut.com/crazyegg/story/34163/honeybadger-investigation-for-shell#activity-34171
  /Share URL is not valid/,
  /(?<!Site\s)not\sfound/i,
];

export function configure() {
  const config = {
    apiKey: window.HONEYBADGER_KEY,
    environment: window.ENVIRONMENT,
    revision: window.version,
    reportData: import.meta.env.PROD,
    enableUncaught: false,
  };

  const instance = Honeybadger.configure(config);
  Honeybadger.beforeNotify(function (notice) {
    if (ignorePatterns.some((p) => p.test(notice.message))) {
      return false;
    }
  });
  const userToken = hydrateToken();
  if (userToken) {
    identifyUserByToken(userToken);
  }
  return instance;
}

export function identifyUser({ userId, guestId, email, currentAccount = null }) {
  Honeybadger.setContext({
    session_id: window.ceSessionId,
    user_id: userId,
    guest_id: guestId,
    user_email: email,
    current_account: currentAccount,
  });
}

function identifyUserByToken(token) {
  try {
    const payload = jwtDecode(token);
    identifyUser({ userId: payload.sub, guestId: payload.guest_id, email: payload.email, currentAccount: null });
  } catch (err) {
    console.error('Failed to parse auth token');
  }
}

export function clearContext() {
  Honeybadger.resetContext();
}
