import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import classNames from 'classnames';
import Honeybadger from '@honeybadger-io/js';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { getUnixTime, addDays, differenceInDays, fromUnixTime } from 'date-fns';
import { capitalize, DeviceIcon } from '@crazyegginc/hatch';

import { PageCameraApi } from '/src/features/snapshots/components/creation-flow/PageCameraApi';
import { WizardHeader } from '/src/components/wizard/legacy/wizard-ui';
import { useMutation, useSite, useNotifications, useWizard } from '/src/hooks';
import { inflect } from '/src/utils/string';

import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';
import { ReactComponent as TrafficIcon } from '@crazyegginc/hatch/dist/images/icon-traffic-outline.svg';
import { ReactComponent as StartIcon } from '@crazyegginc/hatch/dist/images/icon-start-outline.svg';
import { ReactComponent as StopIcon } from '@crazyegginc/hatch/dist/images/icon-stop-outline.svg';
import { ReactComponent as RecurringIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';

import PlaceholderImg from '/src/assets/images/thumb_placer.jpg';

import { thumbnailsQuery, checkSnapshotsQuotaQuery } from '/src/features/snapshots/queries';
import { createSnapshotsMutation, updateSnapshotMutation } from '/src/features/snapshots/mutations';
import {
  SNAPSHOT_START_OPTIONS,
  SNAPSHOT_END_OPTIONS,
  SNAPSHOT_SAMPLING_RATIO_OPTIONS,
  SNAPSHOT_CAPTURE_TIMER_OPTIONS,
  SNAPSHOT_TRACKING_OPTIONS,
  SNAPSHOT_STATUSES,
} from '/src/features/snapshots/constants';
import { DEVICE_TYPES } from '/src/features/_global/constants';
const { DESKTOP, TABLET, PHONE, MOBILE } = DEVICE_TYPES;

import { formatDateTime } from '/src/utils/date';
import { setCookie } from '/src/utils/cookies';

function getViewportForDevice(device) {
  switch (device) {
    case DESKTOP:
      return '1366x768';
    case TABLET:
      return '768x1024';
    case PHONE:
      return '375x812';
    default:
      return '800x600';
  }
}

function getThumbnailWidth(device) {
  switch (device) {
    case DESKTOP:
      return 140;
    case TABLET:
      return 90;
    case PHONE:
      return 60;
    default:
      return 140;
  }
}

export function ReviewSnapshots() {
  const formik = useFormikContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { sites } = useSite({ dontSelectSite: true });
  const notifications = useNotifications();
  const oldSitesIds = useRef();

  useEffect(() => {
    if (sites) {
      if (!oldSitesIds.current) {
        oldSitesIds.current = sites.map((s) => s.id);
      }
    }
  }, [sites]);

  const {
    set: wizardSet,
    data: wizardData,
    setCancelConfirm: wizardSetCancelConfirm,
    state: wizardState,
  } = useWizard();

  const { returnLocation } = wizardState;

  const isPageCameraSnapshot = wizardData.pageCameraSnapshot;
  const isLegacyPageCameraSnapshot = wizardData.pageCameraSnapshot && !wizardData.preUpload;

  const [mountPageCameraApi, setMountPageCameraApi] = useState(null);
  const existingId = wizardData?.existingSnapshot?.id ?? null;
  const isEditing = !!existingId;
  const isBulk = formik.values.snapshots.length > 1;

  const snapshotsCount = formik.values.deviceTracking.custom
    ? formik.values.snapshots.length
    : formik.values.snapshots.length * formik.values.deviceTracking.devices.length;

  const { mutate: mutateCreateSnapshots } = useMutation(createSnapshotsMutation);
  const { mutate: mutateSaveSnapshot } = useMutation(updateSnapshotMutation);

  const expires = useMemo(
    () => ({
      // always use CUSTOM, since we covert the 'default' values here, unless editing and unchanged (to avoid backend validation error)
      option: isEditing && !formik.touched?.ends ? formik.values.ends.option : SNAPSHOT_END_OPTIONS.CUSTOM,
      at:
        formik.values.ends.option === SNAPSHOT_END_OPTIONS.CUSTOM || (isEditing && !formik.touched?.ends)
          ? getUnixTime(formik.values.ends.at)
          : getUnixTime(addDays(formik.values.starts.at, formik.values.ends.days)),
    }),
    [
      formik.touched?.ends,
      formik.values.ends.at,
      formik.values.ends.days,
      formik.values.ends.option,
      formik.values.starts.at,
      isEditing,
    ],
  );

  const restartFlow = useCallback(() => {
    wizardSet({
      title: 'Create new Snapshots',
      back: null,
      backEnabled: false,
      nextEnabled: false,
      nextText: `Next`,
      currentStep: 1,
      totalSteps: 2,
      celebrate: false,
      loading: false,
    });
    navigate(isEditing ? `/snapshots/edit/${wizardData.existingSnapshot.id}` : '/snapshots/new', { replace: true });
  }, [navigate, isEditing, wizardData?.existingSnapshot?.id, wizardSet]);

  function renderStartsAt() {
    if (isEditing) {
      if (wizardData.existingSnapshot.snapshotStatus === SNAPSHOT_STATUSES.SCHEDULED) {
        return `Scheduled to start on ${formatDateTime(wizardData.existingSnapshot.startsAt)}`;
      } else {
        return `Started on ${formatDateTime(wizardData.existingSnapshot.createdAt)}`;
      }
    } else if (formik.values.starts.option === SNAPSHOT_START_OPTIONS.IMMEDIATELY) {
      return capitalize(formik.values.starts.option);
    } else {
      return formatDateTime(getUnixTime(formik.values.starts.at));
    }
  }

  const snapshotsToSave = useMemo(() => {
    const { custom, devices, customTracking } = formik.values.deviceTracking;

    const result = [
      ...formik.values.snapshots.flatMap((snapshot) => {
        return custom
          ? [
              {
                device: customTracking.screenshot.replace(MOBILE, PHONE),
                name: snapshot.name,
                siteUrl: snapshot.siteUrl,
                trackingDevices: customTracking.traffic.map((trafficSource) => trafficSource.replace(MOBILE, PHONE)),
              },
            ]
          : devices.map((device) => ({
              device: device.replace(MOBILE, PHONE),
              name:
                formik.values.trackingOptions.option === SNAPSHOT_TRACKING_OPTIONS.NAMED
                  ? snapshot.name
                  : `${snapshot.name} - ${capitalize(device)}`,
              siteUrl: snapshot.siteUrl,
              trackingDevices: [device.replace(MOBILE, PHONE)],
            }));
      }),
    ];
    if (isEditing) {
      result[0].id = existingId;
    }
    return result;
  }, [
    formik.values.deviceTracking,
    formik.values.snapshots,
    formik.values.trackingOptions.option,
    existingId,
    isEditing,
  ]);

  const { data: thumbnailData } = useQuery({
    ...thumbnailsQuery({
      requests: snapshotsToSave.map((snap) => ({
        url: snap.siteUrl,
        viewport: getViewportForDevice(snap.device),
        thumbnailMaxWidth: getThumbnailWidth(snap.device),
      })),
    }),
  });

  const thumbnails = thumbnailData?.thumbnails ?? [];

  function getThumbnail(url, device) {
    const deviceMapping = {
      DESKTOP: 'width=140',
      TABLET: 'width=90',
      PHONE: 'width=60',
    };

    const resolvedThumbnail = thumbnails.find(
      (thumb) => thumb.url === url && thumb.thumbnailUrl.indexOf(deviceMapping[device]) > -1,
    );

    if (resolvedThumbnail) {
      return resolvedThumbnail;
    } else {
      return null;
    }
  }

  const getMatchingRule = useCallback(() => {
    const { trackingOptions } = formik.values;
    switch (trackingOptions.option) {
      case SNAPSHOT_TRACKING_OPTIONS.URL:
        return { ...trackingOptions.urlTracking };
      case SNAPSHOT_TRACKING_OPTIONS.WILDCARD:
        return { wildcard: trackingOptions.wildcard.wildcard };
      case SNAPSHOT_TRACKING_OPTIONS.REGEX:
        return { regex: trackingOptions.regex.regex };
      case SNAPSHOT_TRACKING_OPTIONS.NAMED:
        return { named: trackingOptions.named.named };
      case SNAPSHOT_TRACKING_OPTIONS.GOOGLE_OPTIMIZE: {
        const { trackingId, experimentId, variantId } = trackingOptions.googleOptimize;
        return { googleOptimize: `${trackingId}.${experimentId}.${variantId}` };
      }
    }
  }, [formik.values]);

  const trackedSourcesString = (snapshot) => {
    return formik.values.deviceTracking.custom
      ? `We will track ${snapshot.trackingDevices.length === 1 ? 'only ' : ''}` +
          snapshot.trackingDevices.reduce(
            (acc, device, i, arr) =>
              acc +
              device.replace(PHONE, MOBILE).toLowerCase() +
              (i >= arr.length - 2 ? (i === arr.length - 2 ? ' and ' : '') : ', '),
            '',
          ) +
          ' traffic.'
      : `We will track only ${snapshot.device.replace(PHONE, MOBILE).toLowerCase()} traffic.`;
  };

  const { data: quotaData } = useQuery({
    ...checkSnapshotsQuotaQuery({ newSnapshotsCount: snapshotsToSave.length }),
  });

  const handleCreateSuccess = useCallback(
    (snapshotIds) => {
      // notifications.success({ content: 'Snapshot(s) created successfully.', timeout: 3000 });
      setTimeout(() => {
        navigate('/snapshots/new/finish', {
          state: {
            snapshotIds,
            oldSitesIds: oldSitesIds.current,
          },
          replace: true,
        });
      }, 0);
    },
    [navigate],
  );

  const createSnapshots = useCallback(() => {
    wizardSet({
      title: `Creating your ${inflect('Snapshot', snapshotsToSave.length)}`,
      back: location?.state?.previousRoute,
      backEnabled: true,
      next: createSnapshots,
      nextEnabled: false,
      nextText: `Creating...`,
      currentStep: 2,
      totalSteps: 2,
      celebrate: false,
      loading: true,
    });

    const {
      ends,
      pageCameraSnapshot,
      blocking,
      samplingRatio,
      starts,
      trackingOptions,
      captureTimer,
      recurringSnapshot,
    } = formik.values;

    const variables = {
      params: snapshotsToSave,
      settings: {
        expiresAt: expires.at,
        expiresAtOption: expires.option,
        matchingRule: getMatchingRule(),
        maxVisits: +ends.visits,
        pageCameraSnapshot: pageCameraSnapshot,
        preUpload: pageCameraSnapshot ? !isLegacyPageCameraSnapshot : undefined,
        removeElements: blocking.elements,
        removeElementsOption: blocking.option,
        samplingRatio: +samplingRatio.ratio,
        startsAt: starts.option === SNAPSHOT_START_OPTIONS.IMMEDIATELY ? undefined : getUnixTime(starts.at),
        startsAtOption: starts.option,
        trackingOption: trackingOptions.option,
        useSamplingRatio: samplingRatio.option === SNAPSHOT_SAMPLING_RATIO_OPTIONS.DEFAULT ? false : true,
        useWaitAfterLoad: captureTimer.option === SNAPSHOT_CAPTURE_TIMER_OPTIONS.DEFAULT ? false : true,
        waitAfterLoad: +captureTimer.seconds,
        reoccurring: recurringSnapshot,
      },
    };
    mutateCreateSnapshots(variables, {
      onError: (error) => {
        notifications.error({
          content: 'Snapshot(s) creation failed.',
          timeout: 3000,
          context: { error, variables, formikValues: formik.values, formikErrors: formik.errors },
        });
        error.graphQLErrors.forEach((error) => {
          notifications.error({ content: error.message, timeout: 5000 });
        });
        restartFlow();
      },
      onSuccess: (data) => {
        if (!data?.createSnapshots?.errors && data?.createSnapshots?.snapshots.length) {
          if (isLegacyPageCameraSnapshot) {
            const pageCameraSnapshotId = data.createSnapshots.snapshots[0].id;
            setCookie('page_camera_snapshot_id', pageCameraSnapshotId);
            setMountPageCameraApi({ snapshotId: pageCameraSnapshotId });
          } else {
            const snapshotIds = data.createSnapshots.snapshots.map((snap) => snap.id);
            handleCreateSuccess(snapshotIds);
          }
        } else if (data.createSnapshots?.errors && data.createSnapshots?.errors.snapshots.length) {
          let reason;
          if (data.createSnapshots?.errors.snapshots.every((s) => s.message === 'invalid_url')) {
            reason = 'invalid_url';
          } else if (data.createSnapshots?.errors.snapshots.every((s) => s.message === 'denied')) {
            reason = 'denied';
          }
          Honeybadger.notify(`Snapshot(s) creation failed. - ${reason}`, {
            context: {
              error: data.createSnapshots?.errors,
              variables,
              formikValues: formik.values,
              formikErrors: formik.errors,
            },
            name: `Snapshot(s) creation failed. - ${reason}`,
          });
          notifications.error({
            title: 'Snapshot(s) creation failed.',
            content: 'Please fix the errors below.',
            timeout: 5000,
            skipHoneybadger: true,
          });
          // we have errors, go back to the flow with validation message
          if (isBulk) {
            navigate('/snapshots/new/bulk', {
              state: { validationErrors: [...data.createSnapshots.errors.snapshots] },
              replace: true,
            });
          } else {
            navigate('/snapshots/new/single', {
              state: { validationErrors: [...data.createSnapshots.errors.snapshots] },
              replace: true,
            });
          }
        } else if (data.createSnapshots?.errors && data.createSnapshots?.errors.settings.length) {
          notifications.error({
            title: 'Snapshot(s) creation failed.',
            content: 'Please fix the errors below.',
            timeout: 5000,
            context: {
              error: data.createSnapshots?.errors,
              variables,
              formikValues: formik.values,
              formikErrors: formik.errors,
            },
          });
          if (isBulk) {
            navigate('/snapshots/new/settings', {
              state: { validationErrors: [...data.createSnapshots.errors.settings] },
              replace: true,
            });
          } else {
            navigate('/snapshots/new/single', {
              state: { validationErrors: [...data.createSnapshots.errors.settings] },
              replace: true,
            });
          }
        }
      },
    });
  }, [
    formik.values,
    formik.errors,
    getMatchingRule,
    navigate,
    isBulk,
    location?.state?.previousRoute,
    mutateCreateSnapshots,
    notifications,
    restartFlow,
    snapshotsToSave,
    wizardSet,
    expires,
    handleCreateSuccess,
    isLegacyPageCameraSnapshot,
  ]);

  const saveSnapshot = useCallback(() => {
    wizardSet({
      title: `Saving your ${inflect('Snapshot', snapshotsCount)}`,
      back: location?.state?.previousRoute,
      backEnabled: true,
      next: saveSnapshot,
      nextEnabled: false,
      nextText: `Saving...`,
      currentStep: 2,
      totalSteps: 2,
      celebrate: false,
      loading: true,
    });

    const { ends, blocking, samplingRatio, starts, trackingOptions, captureTimer, snapshots, recurringSnapshot } =
      formik.values;

    const variables = {
      id: wizardData.existingSnapshot.id,
      params: {
        expiresAt: expires.at,
        expiresAtOption: expires.option,
        matchingRule: getMatchingRule(),
        maxVisits: +ends.visits,
        name: snapshots[0].name,
        removeElements: blocking.elements,
        removeElementsOption: blocking.option,
        samplingRatio: +samplingRatio.ratio,
        startsAt: starts.option === SNAPSHOT_START_OPTIONS.IMMEDIATELY ? undefined : getUnixTime(starts.at),
        startsAtOption:
          starts.option === SNAPSHOT_START_OPTIONS.SCHEDULED ? SNAPSHOT_START_OPTIONS.SCHEDULED : undefined,
        trackingOption: trackingOptions.option,
        trackingDevices: snapshotsToSave[0].trackingDevices,
        useSamplingRatio: samplingRatio.option === SNAPSHOT_SAMPLING_RATIO_OPTIONS.DEFAULT ? false : true,
        useWaitAfterLoad: captureTimer.option === SNAPSHOT_CAPTURE_TIMER_OPTIONS.DEFAULT ? false : true,
        waitAfterLoad: +captureTimer.seconds,
        reoccurring: recurringSnapshot,
      },
    };
    mutateSaveSnapshot(variables, {
      onError: (error) => {
        const errorMsg = error?.graphQLErrors?.[0]?.message;
        notifications.error({
          title: 'Snapshot update failed.',
          content: errorMsg,
          timeout: 4000,
          context: {
            error,
            variables,
            formikValues: formik.values,
            formikErrors: formik.errors,
          },
        });
      },
      onSuccess: (data) => {
        if (!data.updateSnapshot?.errors && data.updateSnapshot?.snapshot.id) {
          notifications.success({ content: 'Snapshot updated successfully.', timeout: 3000 });
          navigate(returnLocation ?? '/snapshots', { replace: true });
        } else if (data.updateSnapshot?.errors) {
          notifications.error({
            title: 'Snapshot update failed.',
            content: 'Please fix the errors below.',
            timeout: 5000,
            context: {
              error: data.updateSnapshot?.errors,
              variables,
              formikValues: formik.values,
              formikErrors: formik.errors,
            },
          });
          // we have errors, go back to the flow with validation message
          if (data.updateSnapshot.errors.snapshots?.length) {
            navigate(`/snapshots/edit/${existingId}`, {
              state: { validationErrors: [...data.updateSnapshot.errors.snapshots] },
              replace: true,
            });
          } else if (data.updateSnapshot.errors.settings?.length) {
            navigate(`/snapshots/edit/${existingId}`, {
              state: { validationErrors: [...data.updateSnapshot.errors.settings] },
              replace: true,
            });
          }
        }
      },
      onSettled: () => {
        wizardSet({
          title: 'Review your work',
          back: location?.state?.previousRoute,
          backEnabled: true,
          next: saveSnapshot,
          nextEnabled: !isPageCameraSnapshot,
          nextText: isPageCameraSnapshot ? 'Saving screenshot...' : 'Save Snapshot',
          currentStep: 2,
          totalSteps: 2,
          celebrate: true,
          loading: false,
        });
        wizardSetCancelConfirm(false);
      },
    });
  }, [
    formik.values,
    formik.errors,
    getMatchingRule,
    navigate,
    location?.state?.previousRoute,
    mutateSaveSnapshot,
    snapshotsCount,
    snapshotsToSave,
    notifications,
    wizardSet,
    wizardSetCancelConfirm,
    wizardData?.existingSnapshot?.id,
    expires,
    existingId,
    returnLocation,
    isPageCameraSnapshot,
  ]);

  useEffect(() => {
    const uniqueCount = snapshotsToSave.length;
    const isStopped = isEditing && wizardData?.existingSnapshot?.status === 'stopped';
    const getNextText = () => {
      if (isEditing) return 'Save Snapshot';
      if (uniqueCount === 0) return 'Continue';
      return `Create ${uniqueCount} ${inflect('Snapshot', uniqueCount)}`;
    };
    setTimeout(() => {
      if (formik.values.snapshots.length === 1 && formik.values.snapshots[0].siteUrl === '') {
        return restartFlow();
      }
      wizardSet({
        title: 'Review your work',
        back: location?.state?.previousRoute,
        backEnabled: true,
        next: (isEditing || snapshotsToSave.length) && ((isEditing && saveSnapshot) || (!isEditing && createSnapshots)),
        nextEnabled: !mountPageCameraApi && (uniqueCount > 0 || (isEditing && isStopped)),
        nextText: mountPageCameraApi ? 'Saving screenshot...' : getNextText(),
        currentStep: isBulk ? 3 : 2,
        totalSteps: isBulk ? 3 : 2,
        celebrate: uniqueCount > 0,
      });
      wizardSetCancelConfirm(true);
    }, 0);
  }, [
    mountPageCameraApi,
    formik.values.snapshots.length,
    formik.values.snapshots,
    isEditing,
    location?.state?.previousRoute,
    restartFlow,
    wizardSet,
    wizardSetCancelConfirm,
    createSnapshots,
    saveSnapshot,
    wizardData?.existingSnapshot?.status,
    isBulk,
    snapshotsToSave.length,
  ]);

  function overTheQuotaFeedback() {
    if (quotaData?.checkSnapshotsQuota && quotaData.checkSnapshotsQuota > 0) {
      return (
        <div className="mb-2.5 flex items-center">
          <WarningIcon className="mr-2.5 h-4 w-4 flex-shrink-0 fill-current text-dandelion-500" />
          <div className="text-body-1">
            You have reached the maximum number of Snapshots allowed for your plan. Please go back and delete{' '}
            {quotaData.checkSnapshotsQuota} snapshots to ensure tracking. Otherwise, if you choose to continue, please
            note that some of your snapshots will not track.
          </div>
        </div>
      );
    }
  }

  return (
    <>
      {mountPageCameraApi?.snapshotId ? (
        <PageCameraApi
          snapshotId={mountPageCameraApi.snapshotId}
          onSuccess={handleCreateSuccess}
          skipSnapshotCount={true}
        />
      ) : null}
      <WizardHeader>Let’s review your work</WizardHeader>
      <div className="mb-5 font-normal text-black-pearl-500">
        Nearly finished! Review your work below to be sure everything is correct before we{' '}
        {isEditing ? 'update' : 'create'} your {inflect('Snapshot', snapshotsCount)}.{' '}
      </div>
      <div className="text-header-2 mb-5">
        {`${isEditing ? 'Editing' : 'Creating'} ${snapshotsCount} ${inflect('Snapshot', snapshotsCount)}`}
      </div>

      {overTheQuotaFeedback()}

      {snapshotsToSave.map((snapshot, idx) => {
        return (
          <div
            className="relative mt-5 flex min-h-[160px] w-full max-w-[720px] flex-col rounded border border-mystic-500 first:mt-2.5"
            key={`Snapshot-${idx}`}
          >
            <div className="flex h-[75px] border-b border-mystic-500 bg-white-lilac-500">
              <div className="flex flex-1 flex-col justify-center overflow-hidden px-5 py-6">
                <a target="_blank" rel="noopener noreferrer" href={snapshot.siteUrl}>
                  <h2 title={snapshot.siteUrl} className="text-header-4 truncate leading-tight">
                    {snapshot.siteUrl}
                  </h2>
                </a>
                <span className="text-body-2 text-lynch-500">{snapshot.name}</span>
              </div>
            </div>
            <div className="flex min-h-[150px] w-full items-center rounded-b bg-white">
              <div className="flex min-h-full min-w-[200px] items-center justify-center">
                <a target="_blank" rel="noopener noreferrer" href={snapshot.siteUrl}>
                  <div
                    className={classNames('rounded-sm border border-mystic-500 bg-cover', {
                      'h-[98px] w-[140px]': snapshot.device === DESKTOP,
                      'h-[115px] w-[60px]': snapshot.device === PHONE,
                      'h-[115px] w-[90px]': snapshot.device === TABLET,
                    })}
                    style={{
                      background: `url(${
                        getThumbnail(snapshot.siteUrl, snapshot.device)?.thumbnailUrl ?? PlaceholderImg
                      }) center center`,
                    }}
                  />
                </a>
              </div>

              <div className="flex w-full">
                <div className="text-body-5 flex w-[250px] flex-col space-y-1 font-semibold">
                  <div className="flex items-center">
                    <DeviceIcon device={snapshot.device} tooltip={false} className={'mr-2.5'} />
                    <span>Screenshot Device</span>
                  </div>
                  <div className="flex items-center">
                    <TrafficIcon className="mr-2.5 h-4 w-4 fill-current text-cadet-blue-500" />
                    <span>Track Traffic From</span>
                  </div>
                  <div className="flex items-center">
                    <StartIcon className="ml-0.5 mr-3 h-3 w-3 fill-current text-cadet-blue-500" />
                    <span>Start</span>
                  </div>
                  <div className="flex items-center">
                    <StopIcon className="ml-0.5 mr-3.5 h-2.5 w-2.5 fill-current text-cadet-blue-500" />
                    <span>End</span>
                  </div>
                  {formik.values.recurringSnapshot && (
                    <div className="flex items-center">
                      <RecurringIcon className="ml-0.5 mr-3.5 h-2.5 w-2.5 fill-current text-cadet-blue-500" />
                      <span>Recurring</span>
                    </div>
                  )}
                </div>

                <div className="text-body-2 flex w-full flex-col space-y-1 pl-2.5">
                  <div className="flex items-center">
                    <span>{capitalize(snapshot.device.replace(PHONE, MOBILE))}</span>
                  </div>
                  <div className="flex items-center">
                    <span>{trackedSourcesString(snapshot)}</span>
                  </div>
                  <div className="flex items-center">
                    <span>{renderStartsAt()}</span>
                  </div>
                  <div className="flex items-center">
                    <span>
                      {formatDateTime(expires.at)} or {parseInt(formik.values.ends.visits).toLocaleString()} visits
                    </span>
                  </div>
                  {formik.values.recurringSnapshot && (
                    <div className="flex items-center">
                      <span>
                        Auto-recurs after {differenceInDays(fromUnixTime(expires.at), formik.values.starts.at)} days or{' '}
                        {parseInt(formik.values.ends.visits).toLocaleString()} visits
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
