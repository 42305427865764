export const PER_PAGE_OPTIONS = [25, 50, 100, 200];

export const SNAPSHOT_REPORT_TYPES = {
  HEATMAP: 'HEATMAP',
  SCROLLMAP: 'SCROLLMAP',
  CONFETTI: 'CONFETTI',
  OVERLAY: 'OVERLAY',
  LIST: 'LIST',
};

export const SNAPSHOT_EXPORT_STATUSES = {
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  EXPORTING: 'EXPORTING',
};

export const SNAPSHOT_ACTIONS = {
  DUPLICATED: 'DUPLICATED',
  DELETED: 'DELETED',
  PAUSED: 'PAUSED',
  RESUMED: 'RESUMED',
  REFRESHED: 'REFRESHED',
  EXPORTED: 'EXPORTED',
  MOVED: 'MOVED',
  UNFILED: 'UNFILED',
  SHARED: 'SHARED',
  SHOW_ERROR: 'SHOW_ERROR',
  CLEAR: 'CLEAR',
};

export const SNAPSHOT_STATUSES = {
  COMPLETED: 'COMPLETED',
  DELETED: 'DELETED',
  ERROR: 'ERROR',
  RUNNING: 'RUNNING',
  SCHEDULED: 'SCHEDULED',
  UNKNOWN: 'UNKNOWN',
};

export const SNAPSHOT_BASIC_STATUSES = {
  STOPPED: 'STOPPED',
  ACTIVE: 'ACTIVE',
};

export const SNAPSHOT_FILTER_STATUSES = {
  ALL: 'ALL', // frontend only
  COMPLETED: 'COMPLETED',
  NOT_COMPLETED: 'NOT_COMPLETED',
};

export const SNAPSHOT_TRACKING_OPTIONS = {
  DEFAULT: 'DEFAULT',
  WILDCARD: 'WILDCARD',
  REGEX: 'REGEX',
  URL: 'URL',
  NAMED: 'NAMED',
  GOOGLE_OPTIMIZE: 'GOOGLE_OPTIMIZE',
};

export const SNAPSHOT_BLOCKING_POPUP_OPTIONS = {
  DEFAULT: 'DEFAULT',
  REMOVE_ELEMENTS: 'REMOVE_ELEMENTS',
  REMOVE_POPUPS: 'REMOVE_POPUPS',
};

export const SNAPSHOT_START_OPTIONS = {
  IMMEDIATELY: 'IMMEDIATELY',
  SCHEDULED: 'SCHEDULED',
};

export const SNAPSHOT_END_OPTIONS = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
};

export const SNAPSHOT_SAMPLING_RATIO_OPTIONS = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
};

export const SNAPSHOT_CAPTURE_TIMER_OPTIONS = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
};

export const SNAPSHOTS_TABLE_ORDER = {
  NAME: 'NAME',
  CREATED_AT: 'CREATED_AT',
  STARTS_AT: 'STARTS_AT',
  TOTAL_VISITS: 'TOTAL_VISITS',
  STATUS: 'STATUS',
  DEVICE: 'DEVICE',
};

export const MAX_SNAPSHOT_YEARS = 3;

export const DEFAULT_DAYS_STRING = 'snapshot_creation_default_end_days';
export const DEFAULT_DAYS_VALUE = 60;

export const DEFAULT_VISITS_STRING = 'snapshot_creation_default_visits';
export const DEFAULT_VISITS_VALUE = 25000;

export const PAGE_CAMERA_STATUSES = {
  PENDING: 'PENDING',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
};
