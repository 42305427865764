import { useFormikContext } from 'formik';
import { Select } from '@crazyegginc/hatch';

import { Input } from '../../../common/basic-ui';
import { RATING_SUBTYPES } from '/src/features/addons/constants';
import { DEFAULT_MIN_LABEL, DEFAULT_MAX_LABEL, DEFAULT_NPS_MIN_LABEL, DEFAULT_NPS_MAX_LABEL } from '../SurveyEditor';
import { useEditorContext } from '../../../editor-context';

const minOptions = [{ value: 0 }, { value: 1 }];
const maxOptions = Array.apply(null, new Array(10 - 2 + 1)).map((_, i) => ({ value: i + 2 }));

export function RatingValuesLabels({ index }) {
  const { readonly, publishedEdit, publishedQuestionIds } = useEditorContext();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = useFormikContext();

  const q = values.questions[index];

  const changingRangeDisabled = readonly || (publishedEdit && publishedQuestionIds.includes(q.id));

  return (
    <>
      <div className="flex items-start">
        <div className="mr-2.5 w-1 flex-grow">
          <Input
            label="Minimum rating label"
            id={`questions[${index}].min.label`}
            name={`questions[${index}].min.label`}
            value={q.min.label}
            error={
              errors.questions?.[index]?.min?.label && touched.questions?.[index]?.min?.label
                ? errors.questions[index].min.label
                : null
            }
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={(e) => {
              if (e.target.value === DEFAULT_MIN_LABEL || e.target.value === DEFAULT_NPS_MIN_LABEL) {
                e.target.select();
              }
            }}
            disabled={readonly}
          />
        </div>
        <div className="ml-2.5 w-1 flex-grow">
          <Input
            label="Maximum rating label"
            id={`questions[${index}].max.label`}
            name={`questions[${index}].max.label`}
            value={q.max.label}
            error={
              errors.questions?.[index]?.max?.label && touched.questions?.[index]?.max?.label
                ? errors.questions[index].max.label
                : null
            }
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={(e) => {
              if (e.target.value === DEFAULT_MAX_LABEL || e.target.value === DEFAULT_NPS_MAX_LABEL) {
                e.target.select();
              }
            }}
            disabled={readonly}
          />
        </div>
      </div>
      {q.subtype === RATING_SUBTYPES.NUMERICAL ? (
        <div className="flex items-start">
          <div className="mr-2.5 w-1 flex-grow">
            <Select
              label="Minimum value"
              labelClassName="font-semibold"
              options={minOptions}
              value={q.min.value}
              onChange={(value) => setFieldValue(`questions[${index}].min.value`, value)}
              disabled={changingRangeDisabled}
            />
          </div>
          <div className="ml-2.5 w-1 flex-grow">
            <Select
              label="Maximum value"
              labelClassName="font-semibold"
              options={maxOptions}
              value={q.max.value}
              onChange={(value) => setFieldValue(`questions[${index}].max.value`, value)}
              disabled={changingRangeDisabled}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
