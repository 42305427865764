import { useEffect, useState, useRef } from 'react';

export function useThrottle(value, delay) {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastExec = useRef(Date.now());

  useEffect(() => {
    const handler = setTimeout(() => {
      if (Date.now() - lastExec.current >= delay) {
        setThrottledValue(value);
        lastExec.current = Date.now();
      }
    }, delay - (Date.now() - lastExec.current));

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return throttledValue;
}
