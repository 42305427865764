import { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';

import { useMutation, useNotifications, useDebounce, usePrevious } from '/src/hooks';
import { setRecordingSettings } from '/src/features/options/mutations';

import { Select, Input, Tooltip } from '@crazyegginc/hatch';

import { calculateRecordingRatio, numberToPercentage, percentageToNumber } from '/src/features/options/utils';
import { SiteSettingsColumnSection } from '/src/features/options/components/SiteSettingsColumnSection';

import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';

import { SAMPLING_TYPE, NUMBER_TYPE } from '/src/features/options/constants';

function AutoSave({ func }) {
  const formik = useFormikContext();
  const { values, isValid, dirty, isSubmitting } = formik;

  const debounceValue = useDebounce(values.value, 500);
  const prevDebouncedValue = usePrevious(debounceValue);

  useEffect(() => {
    if (isValid && dirty && !isSubmitting && prevDebouncedValue && prevDebouncedValue !== debounceValue) {
      func();
    }
  }, [func, formik, debounceValue, dirty, isSubmitting, isValid, prevDebouncedValue]);

  return null;
}

export function SiteSettingsSamplingInputs({
  siteId,
  samplingType,
  samplingValue,
  samplingRecordingRatio,
  samplingEstimatedVisitsDay,
  name,
}) {
  /*
    NOTE:
      For initial data
     - AUTO: check the recordingRatio
     - RATE or STATIC: check the samplingValue
  */

  const initialValue = {
    type: samplingType,
    value: samplingValue,
  };

  const validationSchema = yup.object().shape({
    type: yup.string().oneOf([...Object.values(SAMPLING_TYPE), ...Object.values(NUMBER_TYPE)]),
    value: yup
      .number()
      .when('type', {
        is: (value) => value === SAMPLING_TYPE.AUTO || value === NUMBER_TYPE.RATE,
        then: yup
          .number()
          .required('Please provide a valid number.')
          .typeError('Please provide a valid number.')
          .moreThan(0, 'Should not be 0 or less than 0.')
          .max(100, 'Should not exceed 100.')
          .test('is-decimal', 'The maximum decimal is two digits after comma.', (value) => {
            if (value != undefined) {
              return /^\d+(\.\d{0,2})?$/.test(value);
            }
            return true;
          }),
      })
      .when('type', {
        is: (value) => value === NUMBER_TYPE.STATIC,
        then: yup
          .number()
          .required('Please provide a valid number.')
          .typeError('Please provide a valid number.')
          .integer('Please provide a valid number.')
          .positive('Please provide a valid number.')
          .test(
            'not-more-than-allocated',
            "That's more than your monthly visitors. Please use 100% instead.",
            (value) => {
              const isBigger = value > percentageToNumber(100, samplingEstimatedVisitsDay);
              return !isBigger;
            },
          ),
      }),
  });

  const updateRecordingSettings = useMutation(setRecordingSettings);
  const notifications = useNotifications();
  const isSamplingAutomatic = samplingType === SAMPLING_TYPE.AUTO;
  const isNumberTypePercentage = samplingType === NUMBER_TYPE.RATE;

  const { mutate } = updateRecordingSettings;

  // isWarning is Temporary
  const isWarning = false;

  const options = [
    {
      value: SAMPLING_TYPE.AUTO,
      label: 'Automatic',
    },
    {
      value: SAMPLING_TYPE.MANUAL,
      label: 'Manual',
    },
  ];

  const numTypeOption = [
    {
      value: NUMBER_TYPE.RATE,
      label: '%',
    },
    {
      value: NUMBER_TYPE.STATIC,
      label: '#',
    },
  ];

  const handleSamplingUpdate = useCallback(
    (type, value) => {
      mutate(
        {
          siteId,
          samplingType: type,
          samplingValue: parseFloat(value),
        },
        {
          onError: (error) => {
            notifications.error({
              content: `Sampling update failed for ${name}.`,
              timeout: 3000,
              context: { error },
            });
          },
          onSuccess: () => {
            notifications.success({ content: `Sampling updated successfully for ${name}.`, timeout: 3000 });
          },
        },
      );
    },
    [notifications, siteId, mutate, name],
  );

  return (
    <Formik initialValues={initialValue} validationSchema={validationSchema} validateOnMount={true}>
      {({ values, errors, setErrors, setFieldValue, handleChange }) => {
        const samplingNumber = values?.value?.toLocaleString() ?? 0;

        return (
          <Form className="relative flex w-full">
            <SiteSettingsColumnSection placement="left">
              <div className="flex flex-col">
                <Select
                  name="samplingType"
                  value={
                    values.type === NUMBER_TYPE.RATE || values.type === NUMBER_TYPE.STATIC
                      ? SAMPLING_TYPE.MANUAL
                      : SAMPLING_TYPE.AUTO
                  }
                  options={options}
                  disabled={errors.value}
                  onChange={(type) => {
                    if (type === SAMPLING_TYPE.MANUAL) {
                      setFieldValue('type', NUMBER_TYPE.RATE);
                      handleSamplingUpdate(NUMBER_TYPE.RATE, values.value);
                    } else {
                      if (values.value <= 100) {
                        setFieldValue('type', type);
                        handleSamplingUpdate(SAMPLING_TYPE.AUTO, samplingRecordingRatio);
                      } else {
                        setErrors({ value: 'Change number to be less or equal to 100' });
                      }
                    }
                  }}
                />
              </div>
            </SiteSettingsColumnSection>
            <SiteSettingsColumnSection placement="right">
              <div className="relative flex">
                <div className="flex max-w-[200px] flex-1">
                  <div className="flex-1">
                    <Input
                      name="number"
                      id="number"
                      value={isSamplingAutomatic ? calculateRecordingRatio(samplingRecordingRatio) : samplingNumber}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('value', e.target.value);
                      }}
                      inputMode="numeric"
                      step="any"
                      disabled={isSamplingAutomatic}
                      className={classNames(
                        'rounded-br-none rounded-tr-none !pr-1 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
                        {
                          '!border-carnation-500': errors.value,
                        },
                      )}
                    />
                  </div>
                  <Select
                    name="numberType"
                    value={
                      isSamplingAutomatic || values.type === NUMBER_TYPE.RATE ? NUMBER_TYPE.RATE : NUMBER_TYPE.STATIC
                    }
                    options={numTypeOption}
                    onChange={(type) => {
                      handleSamplingUpdate(type, values.value);
                      setFieldValue('type', type);
                    }}
                    style={{
                      background: `${isSamplingAutomatic || errors.value ? '#e1e6ef' : '#e5f2fc'}`,
                      borderColor: '#e1e6ef',
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px',
                      borderLeft: '0px',
                      cursor: `${isSamplingAutomatic || errors.value ? 'default' : 'pointer'}`,
                      color: `${isSamplingAutomatic || errors.value ? '#69768d' : '#0086e6'}`,
                      fontWeight: 'bold',
                      width: '60px',
                    }}
                    disabled={isSamplingAutomatic || errors.value}
                  />
                </div>

                <div className="text-caption ml-2.5 flex w-[60px] items-center leading-none">
                  {(isNumberTypePercentage || isSamplingAutomatic) && 'of visitors to this site'}
                </div>

                {isWarning && (
                  <div className="ml-1 flex items-center">
                    <Tooltip
                      tooltipContent={
                        <div className="w-[300px] p-0.5 leading-tight">
                          <p>
                            There are other active Recordings settings that could restrict what gets recorded. View
                            settings
                          </p>
                        </div>
                      }
                      placement="bottom"
                    >
                      <WarningIcon className="-mt-[2px] h-4 w-4 fill-current text-dandelion-500" />
                    </Tooltip>
                  </div>
                )}
              </div>
              <div className="absolute -bottom-[3px] w-full">
                <span
                  className={classNames('text-caption', {
                    'absolute -bottom-1 left-0 text-carnation-500': errors.value,
                  })}
                >
                  {!errors.value
                    ? isSamplingAutomatic
                      ? `${percentageToNumber(
                          calculateRecordingRatio(samplingRecordingRatio),
                          samplingEstimatedVisitsDay,
                        ).toLocaleString()} recordings`
                      : isNumberTypePercentage
                      ? `${percentageToNumber(values.value, samplingEstimatedVisitsDay).toLocaleString()} recordings`
                      : `${numberToPercentage(
                          values.value,
                          samplingEstimatedVisitsDay,
                        ).toLocaleString()}% sampling rate`
                    : errors.value}
                </span>
              </div>
            </SiteSettingsColumnSection>
            <AutoSave func={() => handleSamplingUpdate(values.type, values.value)} />
          </Form>
        );
      }}
    </Formik>
  );
}
