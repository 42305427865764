import { useFormikContext, FieldArray } from 'formik';
import classNames from 'classnames';
import {
  Checkbox,
  capitalize,
  DeviceIcon,
  RadioGroup,
  Button,
  IconButton,
  Indicator,
  Popover,
  StyledPopoverPanel,
  PopoverItem,
  Divider,
  Spinner,
  Tooltip,
  Toggle,
} from '@crazyegginc/hatch';

import { CodeBox } from '/src/components/CodeBox';
import { AddSiteModal } from '/src/components/modals/AddSiteModal';
import { SectionHeader, SectionPanel, SectionContent, Input } from '../basic-ui';
import { Sampling } from '../../surveys/editor/Sampling';
import { useSite, useModal } from '/src/hooks';
import { useEditorContext } from '../../editor-context';
import { normalizeUrl } from '/src/utils/url';
import { advancedSettingsJSScript } from '/src/features/addons/utils';
import { SITES_FOR_ADDONS } from '/src/features/addons/queries';

import { SupportLinks } from '/src/support';
import { DEVICE_TYPES } from '/src/features/_global/constants';
import { REFERRER_VALUES, SURVEY_DISPLAY_TYPES, AUDIENCE_MODES, ADDON_TYPES } from '/src/features/addons/constants';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as FilledInfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-filled.svg';
import { ReactComponent as AudienceIcon } from '@crazyegginc/hatch/dist/images/icon-people-outline.svg';
import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

export function Audience() {
  const { id, type } = useEditorContext();
  const { values } = useFormikContext();
  const { mode, displayType } = values;

  if (displayType === SURVEY_DISPLAY_TYPES.EXTERNAL) {
    return (
      <div className="mx-10">
        <SiteSelector />
      </div>
    );
  }

  return (
    <SectionPanel>
      <SectionHeader>
        <div className="flex items-center">
          <AudienceIcon className="mr-1.25 h-[18px] w-[18px] fill-current text-cadet-blue-500" />
          Audience
        </div>
      </SectionHeader>
      <SectionContent>
        {mode === AUDIENCE_MODES.SIMPLE ? (
          <>
            <ModeSelector />
            <DeviceSelector />
            <SiteSelector />
            <PagesSelector />
            <ReferrerSelector />
            {type === ADDON_TYPES.SURVEY && <Sampling />}
          </>
        ) : (
          <div className="flex flex-col space-y-5">
            {id ? (
              <>
                <ModeSelector />
                <SiteSelector />
                <ScriptTrigger />
              </>
            ) : (
              <>
                <ModeSelector />
                <div className="max-w-md">
                  <AlertBox
                    header={
                      <>
                        {type === ADDON_TYPES.SURVEY
                          ? 'Please add at least one question and save your Survey to see the advanced script trigger.'
                          : 'Please save your CTA to see the advanced script trigger.'}
                      </>
                    }
                  />
                </div>
              </>
            )}
          </div>
        )}
      </SectionContent>
    </SectionPanel>
  );
}

function SiteSelector() {
  const { readonly, publishedEdit, spelledType } = useEditorContext();
  const { sites, selectSite, loadingSites } = useSite({ dontSelectSite: true, sitesQuery: SITES_FOR_ADDONS });
  const { setFieldValue, errors, values } = useFormikContext();
  const modal = useModal();

  const disabled = loadingSites || readonly || publishedEdit;

  return (
    <div>
      <div className="text-body-1 mb-0.5 block">
        {values.displayType === SURVEY_DISPLAY_TYPES.EXTERNAL
          ? 'What site does this survey belong to?'
          : `What site do you want to show your ${spelledType} on?`}
      </div>
      <div className="w-80">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  'relative h-[35px] w-full cursor-default rounded border border-mystic-500 pl-3 pr-9 text-left',
                  'text-body-4 leading-none',
                  'focus:border-dodger-blue-500 focus:outline-none',
                  {
                    '!border-dodger-blue-500': open,
                    'cursor-not-allowed bg-white-lilac-500 text-lynch-500': disabled,
                    'bg-white text-black-pearl-500': !disabled,
                  },
                )}
                disabled={disabled}
                aria-label="site selector"
              >
                {loadingSites ? (
                  <div className="flex items-center space-x-2.5">
                    <Spinner />
                    <span className="text-lynch-500">loading sites...</span>
                  </div>
                ) : (
                  <span>{values.audience.site?.name ?? 'Select your site'}</span>
                )}
                <Indicator type="dropdown" className="absolute right-4 top-1/2 -translate-y-1/2" />
              </Popover.Button>
              <StyledPopoverPanel align="right" className="!h-max-[280px] !w-full !overflow-y-auto">
                {({ close }) => (
                  <>
                    {sites.length ? (
                      sites.map((site) => (
                        <PopoverItem
                          key={site.id}
                          onClick={() => {
                            selectSite(site);
                            setFieldValue('audience.site', site);
                            close();
                          }}
                        >
                          {site.name}
                        </PopoverItem>
                      ))
                    ) : (
                      <div className="text-body-5 relative inline-block w-full max-w-full rounded px-2.5 py-1.25 text-left leading-[1.15]">
                        You don’t currently have any sites.
                      </div>
                    )}
                    <Divider className="!-mx-4 !my-2 !w-auto" />
                    <PopoverItem
                      className="!font-semibold !text-dodger-blue-500"
                      onClick={() => {
                        modal.show(
                          <AddSiteModal
                            onExtraSteps={(newSite) => {
                              selectSite(newSite);
                              setFieldValue('audience.site', newSite);
                            }}
                          />,
                        );
                      }}
                    >
                      Add a new site
                    </PopoverItem>
                  </>
                )}
              </StyledPopoverPanel>
            </>
          )}
        </Popover>
      </div>
      {errors.audience?.site ? <div className="text-error">{errors.audience.site}</div> : null}
    </div>
  );
}

const referrerOptions = [
  { value: REFERRER_VALUES.EVERYONE, label: 'Everyone' },
  { value: REFERRER_VALUES.DIRECT_TRAFFIC, label: 'Visitors who come to my site directly' },
  { value: REFERRER_VALUES.REFERRER, label: 'Visitors who visit from a link on another site' },
];

function ReferrerSelector() {
  const { readonly, spelledType } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <div className="self-start">
        <RadioGroup
          label={`Show this ${spelledType} to these visitors`}
          groupLabelClass="!mb-2"
          size="sm"
          options={referrerOptions}
          value={values.audience.referrerOption}
          onChange={async (value) => {
            if (value === REFERRER_VALUES.REFERRER) {
              if (!values.audience.referrers || values.audience.referrers?.length === 0) {
                await setFieldValue('audience.referrers', ['']);
              }
            }
            setFieldValue('audience.referrerOption', value);
          }}
          disabled={readonly}
        />
      </div>
      {values.audience.referrerOption === REFERRER_VALUES.REFERRER && <ReferrerList />}
    </>
  );
}

function ReferrerList() {
  const { readonly } = useEditorContext();
  const { values, errors, touched, handleBlur, handleChange } = useFormikContext();

  return (
    <div role="group" aria-labelledby="referrer-list-label" className="!mt-2.5 ml-[34px] space-y-2.5">
      <div id="referrer-list-label" className="text-body-1 mb-2">
        Referrers
      </div>
      <FieldArray name={'audience.referrers'}>
        {(actions) => (
          <>
            {values.audience.referrers.map((page, i) => {
              const error =
                touched.audience?.referrers?.[i] && errors.audience?.referrers?.[i]
                  ? errors.audience?.referrers?.[i]
                  : null;

              return (
                <div className="relative flex items-start" key={`referrer-${i}`}>
                  <Input
                    aria-label={`referrer ${i}`}
                    id={`audience.referrers[${i}]`}
                    name={`audience.referrers[${i}]`}
                    value={values.audience.referrers[i]}
                    error={error}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="othersite.com"
                    disabled={readonly}
                  />
                  <div
                    className={classNames('absolute left-0 top-0 h-10 w-1.25 rounded-l', {
                      'bg-mystic-500/60': !error,
                      'bg-carnation-500': error,
                    })}
                  />
                  <div className="ml-4 flex h-10 w-5 flex-shrink-0 items-center">
                    {!readonly && values.audience.referrers?.length > 1 && (
                      <IconButton
                        icon={<CrossIcon className="h-3 w-3 fill-current" />}
                        className="flex-shrink-0 text-cadet-blue-500 hover:text-carnation-500"
                        onClick={() => {
                          actions.remove(i);
                        }}
                        label={`delete referrer ${i}`}
                      />
                    )}
                  </div>
                </div>
              );
            })}

            {typeof errors.audience?.referrers === 'string' ? (
              <div className="text-error">{errors.audience.referrers}</div>
            ) : null}

            {!readonly && (
              <div className="mr-9 flex items-start justify-between space-x-5">
                <Button variant="secondary" onClick={() => actions.push('')} className="!flex-shrink-0">
                  <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
                  Add Referrer
                </Button>

                <Tooltip
                  interactive={true}
                  tooltipContent={
                    <div className="text-left">
                      <ul className="ml-5 list-outside list-disc font-mono">
                        <li>othersite.com</li>
                        <li>othersite.com/specific/page</li>
                        <li>othersite.com/specific/page?q=1</li>
                        <li>othersite.com/products/*</li>
                        <li>?utm_param=foo</li>
                        <li>?param&param2=bar</li>
                      </ul>
                    </div>
                  }
                >
                  <span className="text-caption flex h-3.5 cursor-pointer items-center text-dodger-blue-500">
                    accepted formats
                  </span>
                </Tooltip>
              </div>
            )}
          </>
        )}
      </FieldArray>
    </div>
  );
}

function PagesSelector() {
  const { readonly, spelledType } = useEditorContext();
  const { values, setFieldValue } = useFormikContext();
  const options = [
    {
      value: true,
      label: values.audience.site?.name ? (
        <>
          All pages on <span className="font-semibold">{values.audience.site.name}</span> where Crazy Egg is installed
        </>
      ) : (
        'On all pages where the Crazy Egg code is installed'
      ),
    },
    { value: false, label: 'On specific pages' },
  ];
  return (
    <>
      <div className="self-start">
        <RadioGroup
          label={`Show this ${spelledType} on these pages`}
          groupLabelClass="!mb-2"
          size="sm"
          options={options}
          value={values.audience.allPages}
          onChange={async (value) => {
            if (value === false) {
              if (!values.audience.pages || values.audience.pages?.length === 0) {
                await setFieldValue('audience.pages', ['']);
              }
            }
            setFieldValue('audience.allPages', value);
          }}
          disabled={readonly}
        />
      </div>
      {!values.audience.allPages && <PagesList />}
    </>
  );
}

function PagesList() {
  const { readonly } = useEditorContext();
  const { values, errors, touched, handleBlur, setFieldValue } = useFormikContext();
  return (
    <div role="group" aria-labelledby="pages-list-label" className="!mt-2.5 ml-[34px] space-y-2.5">
      <div id="pages-list-label" className="text-body-1 mb-2">
        Pages
      </div>
      <FieldArray name={'audience.pages'}>
        {(actions) => (
          <>
            {values.audience.pages.map((page, i) => {
              const error =
                touched.audience?.pages?.[i] && errors.audience?.pages?.[i] ? errors.audience?.pages?.[i] : null;

              return (
                <div className="relative flex items-start" key={`page-${i}`}>
                  <Input
                    aria-label={`page ${i}`}
                    id={`audience.pages[${i}]`}
                    name={`audience.pages[${i}]`}
                    value={values.audience.pages[i]}
                    error={error}
                    onChange={(e) =>
                      setFieldValue(`audience.pages[${i}]`, normalizeUrl(e.target.value, { appendSlash: false }))
                    }
                    onBlur={handleBlur}
                    placeholder="https://www.yoursite.com/page or https://www.yoursite.com/page/*"
                    disabled={readonly}
                  />
                  <div
                    className={classNames('absolute left-0 top-0 h-10 w-1.25 rounded-l', {
                      'bg-mystic-500/60': !error,
                      'bg-carnation-500': error,
                    })}
                  />
                  <div className="ml-4 flex h-10 w-5 flex-shrink-0 items-center">
                    {!readonly && values.audience.pages?.length > 1 && (
                      <IconButton
                        icon={<CrossIcon className="h-3 w-3 fill-current" />}
                        className="flex-shrink-0 text-cadet-blue-500 hover:text-carnation-500"
                        onClick={() => {
                          actions.remove(i);
                        }}
                        label={`delete page ${i}`}
                      />
                    )}
                  </div>
                </div>
              );
            })}

            {typeof errors.audience?.pages === 'string' ? (
              <div className="text-error">{errors.audience.pages}</div>
            ) : null}

            {!readonly && (
              <div className="mr-9 flex items-start justify-between space-x-5">
                <Button variant="secondary" onClick={() => actions.push('')} className="!flex-shrink-0">
                  <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
                  Add Page
                </Button>
              </div>
            )}
          </>
        )}
      </FieldArray>
    </div>
  );
}

function ScriptTrigger() {
  const { id, spelledType, type } = useEditorContext();
  const code = advancedSettingsJSScript(type, id);

  return (
    <div role="group" aria-labelledby="device-selector-label" className="self-start">
      <div id="device-selector-label" className="text-body-1 mb-2">
        Use JavaScript to trigger the {spelledType}
      </div>

      <CodeBox fullWidth={false} code={code} canCopy={true} />

      <div id="device-selector-label" className="text-body-2 mb-2">
        Place this script inside the &lt;head&gt; tag of any page you want the {spelledType} to appear on.
        <br />
        Alternatively it can be triggered from any JavaScript event handler.
        <br />
        <a href={SupportLinks.survey.advancedTrigger} className="text-link" rel="noopener noreferrer" target="_blank">
          Read more about triggering add-ons with JavaScript
        </a>
        .
      </div>
    </div>
  );
}

function DeviceSelector() {
  const { readonly, spelledType } = useEditorContext();
  const { errors } = useFormikContext();
  return (
    <div role="group" aria-labelledby="device-selector-label" className="self-start">
      <div id="device-selector-label" className="text-body-1 mb-2">
        Display this {spelledType} on these devices
      </div>
      <div className="flex items-center space-x-2.5">
        <DeviceCheckbox value={DEVICE_TYPES.DESKTOP} readonly={readonly} />
        <DeviceCheckbox value={DEVICE_TYPES.TABLET} readonly={readonly} />
        <DeviceCheckbox value={DEVICE_TYPES.MOBILE} readonly={readonly} />
      </div>
      {errors.audience?.device && <div className="text-error !mt-0.5">{errors.audience.device}</div>}
    </div>
  );
}

function DeviceCheckbox({ value, readonly }) {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const checked = values.audience.device.includes(value);

  return (
    // eslint-disable-next-line
    <div
      className={classNames('flex h-9 items-center justify-center rounded border px-3.5', {
        'border-transparent bg-white-lilac-500': !checked,
        'border-dodger-blue-500 bg-solitude-500': checked,
        'cursor-pointer': !readonly,
        'cursor-not-allowed': readonly,
      })}
      onClick={(e) => {
        e.preventDefault();
        if (!readonly) {
          setFieldValue(
            'audience.device',
            checked ? values.audience.device.filter((y) => y !== value) : [...values.audience.device, value],
          );
        }
      }}
    >
      <Checkbox
        name="audience.device"
        value={value}
        onChange={handleChange}
        checked={checked}
        id={value}
        label={
          <div
            className={classNames('flex items-center text-xs', {
              'text-dodger-blue-500': checked,
              'text-black-pearl-500': !checked,
              'cursor-pointer': !readonly,
              'cursor-not-allowed': readonly,
            })}
          >
            <DeviceIcon
              device={value}
              tooltip={false}
              className={classNames('mr-2', {
                '!text-dodger-blue-500': checked,
                '!text-lynch-500': !checked,
              })}
            />

            {capitalize(value)}
          </div>
        }
        disabled={readonly}
      />
    </div>
  );
}

function ModeSelector() {
  const { readonly } = useEditorContext();
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <div>
      <div className="flex items-center space-x-2.5">
        <Toggle
          setEnabled={(newValue) => {
            setFieldValue('mode', newValue ? AUDIENCE_MODES.ADVANCED : AUDIENCE_MODES.SIMPLE);
          }}
          enabled={values.mode === AUDIENCE_MODES.ADVANCED}
          disabled={readonly}
          label="Trigger survey with JavaScript (Advanced Setting)"
          displayLabel={true}
        />
        <Tooltip tooltipContent="Display a survey with custom code">
          <InfoIcon
            aria-label="information on advanced settings"
            className="h-3 w-3 fill-current text-dodger-blue-300"
          />
        </Tooltip>
      </div>

      {errors?.mode ? <div className="text-error">{errors.mode}</div> : null}
    </div>
  );
}

function AlertBox({ header = null, body }) {
  return (
    <div className="flex items-start rounded bg-carnation-500 py-2 pl-2.5 pr-3.5">
      <div className="flex-shrink-0  pr-1.25 pt-0.5">
        <FilledInfoIcon className="h-3.5 w-3.5 fill-current text-white" />
      </div>
      <div>
        {header && <div className="text-info-header mb-0.5 text-white">{header}</div>}
        <div className="text-info-body leading-none text-white">{body}</div>
      </div>
    </div>
  );
}
