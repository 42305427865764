export const MAX_STEPS = 6;

export const STEP_TYPES = {
  VISIT: 'VISIT',
  GOAL: 'GOAL',
};

export const STEP_COLORS = [
  { tw: 'bg-pink-salmon-500', hex: '#ff8fa7' },
  { tw: 'bg-dodger-blue-300', hex: '#99cff5' },
  { tw: 'bg-riptide-500', hex: '#82ede4' },
  { tw: 'bg-lavender-500', hex: '#ba89f5' },
  { tw: 'bg-dandelion-500', hex: '#ffd155' },
  { tw: 'bg-lima-500', hex: '#8abc00' },
];

export const ANY_VALUE = '*';
