import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useActor, useSelector } from '@xstate/react';
import { Checkbox, Input, LoaderBalloon, RadioGroup, Select, SkeletonLine } from '@crazyegginc/hatch';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { normalizeUrl } from '/src/utils/url';

import { WebP } from '/src/components/WebP';
import {
  GOAL_TRACKING_OPTIONS,
  ELEMENT_SELECTOR_TYPES,
  GOAL_TRIGGERS_METADATA,
  GOAL_VALUE_TYPES,
} from '/src/features/goals/constants';
import { CodeBox } from '/src/components/CodeBox';
import Wizard from '/src/components/wizard/Wizard';
import { CurrencySelector } from '/src/components/CurrencySelector';
import { useAuthContext, useSite, useMutation, useNotifications } from '/src/hooks';
import { goalEditMutation } from '/src/features/goals/mutations';
import { ValueElementSelector } from './value/ValueElementSelector';
import { goalDetailQuery } from '/src/features/goals/queries';
// import { deserializeTrigger } from '/src/features/goals/machines/wizard';
import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

const selectTriggers = (state) => state.context?.payload?.triggers;
const selectGoal = (state) => state.context.payload;

function goalTriggerValue(trigger) {
  if (trigger.valueType === 'CODE') {
    return {
      valueType: 'CODE',
      fixedValue: null,
      elementValue: null,
    };
  }

  if (trigger.valueType === 'FIXED') {
    return {
      valueType: 'FIXED',
      elementValue: null,
      fixedValue: {
        currency: trigger.fixedValue.currency,
        worth: trigger.fixedValue.worth,
      },
    };
  }

  if (trigger.valueType === 'ELEMENT') {
    return {
      valueType: 'ELEMENT',
      elementValue: {
        currency: trigger.elementValue.currency,
        condition: {
          trackingOption: trigger.elementValue.condition.trackingOption,
          elementSelectorType: trigger.elementValue.condition.elementSelectorType,
          pageUrl: normalizeUrl(trigger.elementValue.condition.pageUrl),
          onPage:
            !!trigger.elementValue.condition.pageUrl &&
            trigger.elementValue.condition.trackingOption === GOAL_TRACKING_OPTIONS.SPECIFIC_PAGE
              ? normalizeUrl(trigger.elementValue.condition.pageUrl)
              : null,
          onAnyPage: trigger.elementValue.condition.trackingOption === GOAL_TRACKING_OPTIONS.ANY_PAGE ? true : null,
          wildcardUrl:
            !!trigger.elementValue.condition.wildcardUrl &&
            trigger.elementValue.condition.trackingOption === GOAL_TRACKING_OPTIONS.WILDCARD
              ? trigger.elementValue.condition.wildcardUrl
              : null,
          selectorForDesktop: trigger.elementValue.condition.selectorForDesktop,
          selectorForTablet: trigger.elementValue.condition.selectorForTablet,
          selectorForPhone: trigger.elementValue.condition.selectorForPhone,
        },
      },
      fixedValue: null,
    };
  }
}

export function GoalValueWizard({ service }) {
  const notifications = useNotifications();
  const [state, send] = useActor(service);
  const { goalId } = useParams();
  const { currentUser } = useAuthContext();
  const { defaultCurrency } = currentUser.settings;

  const { mutate: mutateGoalEdit } = useMutation(goalEditMutation);

  const { refetch } = useQuery({
    ...goalDetailQuery({
      id: parseInt(goalId),
    }),
    enabled: false,
    keepPreviousData: false,
    cacheTime: 0,
    networkMode: 'always',
  });

  const { isEditing } = state.context;

  useEffect(() => {
    const subscription = service.subscribe(async (state) => {
      if (state.matches('initializeValue')) {
        const result = await refetch();
        const currency = defaultCurrency || 'USD';

        if (result.isSuccess) {
          send({ type: 'GOAL_LOADED', value: result.data.goalDetail, currency });
        }
      } else if (state.matches('savingValue')) {
        const { autoName, name, triggers, purpose = 'MORE_PAGE_VIEWS', syncValues = true } = state.context.payload;
        const { goalId } = state.context;
        mutateGoalEdit(
          {
            params: {
              id: parseInt(goalId),
              name,
              autoName: autoName || name,
              purpose,
              syncValues,
              triggers: triggers.map((trigger) => {
                return {
                  autoName: trigger.autoName,
                  name: trigger.name,
                  filter: trigger.filter,
                  uiMode: trigger.uiMode,
                  valueType: trigger.valueType,
                  fixedValue: trigger.fixedValue,
                  elementValue: trigger.elementValue,
                  ...goalTriggerValue(trigger),
                };
              }),
            },
          },
          {
            onSuccess: (data) => {
              notifications.success({ content: 'Goal updated successfully', timeout: 3000 });
              service.send({ type: 'VALUE_SUCCESS', goalId: data.goalEdit.id });
            },
            onError: (error) => {
              notifications.error({ content: 'Goal update failed.', timeout: 3000, context: { error } });
              service.send('VALUE_ERROR');
            },
          },
        );
      }
    });

    return subscription.unsubscribe;
  }, [refetch, service, send, defaultCurrency, mutateGoalEdit, notifications]);

  const isLoading = state.matches('initializeValue');

  const isNextEnabled = service.initialized ? service.nextState({ type: 'NEXT' }).changed : false;

  return (
    <Wizard>
      <Wizard.Header>
        <Wizard.CloseButton onClick={() => send('CANCEL')} />
      </Wizard.Header>
      <Wizard.Content dontMask={true}>
        <div className="mx-auto max-w-[740px]">
          <Wizard.Title>Set the value of your Goal (optional)</Wizard.Title>

          <p className="mb-10 text-center">
            Set a value attributed to completing this Goal to track the total goal value over time.
            <br />
            You can also set this or adjust it later.
          </p>

          <div className="mx-auto max-w-[740px]">
            {isLoading ? (
              <div className="mt-10">
                <LoaderBalloon />
              </div>
            ) : (
              <GoalValueForm service={service} />
            )}
          </div>
        </div>
      </Wizard.Content>
      <Wizard.SimpleFooter
        currentStep={isEditing ? 1 : 2}
        totalSteps={isEditing ? 1 : 2}
        showMeta={!isEditing}
        title="Goal value"
        cancelText="Cancel"
        nextText="Save goal"
        nextEnabled={isNextEnabled}
        onBack={() => send('BACK')}
        onNext={() => send('NEXT')}
        onCancel={() => send('CANCEL')}
        confirmCancel={false}
        onSkip={() => send('SKIP')}
      />
    </Wizard>
  );
}

const valueTypeOptions = [
  { value: GOAL_VALUE_TYPES.FIXED, label: 'Set a fixed value' },
  { value: GOAL_VALUE_TYPES.ELEMENT, label: 'Set by page element' },
  { value: GOAL_VALUE_TYPES.CODE, label: 'Set by code' },
];

function GoalValueForm({ service }) {
  const [, send] = useActor(service);
  const goal = useSelector(service, selectGoal);
  const triggers = useSelector(service, selectTriggers);
  const { currentUser } = useAuthContext();
  const { defaultCurrency } = currentUser.settings;
  const userCurrency = defaultCurrency || 'USD';

  const hasMoreThanOneTrigger = triggers.length > 1;

  return (
    <div>
      {triggers && triggers.length > 1 ? (
        <Checkbox
          checked={!goal.syncValues}
          value={!goal.syncValues}
          onChange={(e) => {
            send({
              type: 'SET_SYNC_VALUES',
              value: !e.target.checked,
            });
          }}
          id="syncValues"
          label="Set different value for each trigger"
        />
      ) : null}

      {goal.syncValues || !hasMoreThanOneTrigger ? (
        <h2 className="text-header-2 mt-[30px]">How do you want to set the value?</h2>
      ) : null}

      {!goal.syncValues && hasMoreThanOneTrigger ? (
        <ul className="mt-[30px] flex flex-col gap-5">
          {goal.triggers.map((trigger, index) => {
            return (
              <li key={`GoalValue:${index}`}>
                <TriggerCard service={service} index={index}>
                  {renderForm(trigger.valueType, { index, trigger, userCurrency, service })}
                </TriggerCard>
              </li>
            );
          })}
        </ul>
      ) : (
        renderForm(goal.triggers?.[0]?.valueType || GOAL_VALUE_TYPES.FIXED, {
          userCurrency,
          trigger: { ...(goal.triggers?.[0] ?? { valueType: GOAL_VALUE_TYPES.FIXED }) },
          service,
          index: null,
        })
      )}
    </div>
  );
}

function renderForm(type, props) {
  switch (type) {
    case GOAL_VALUE_TYPES.FIXED:
      return <FixedValueForm {...props} />;
    case GOAL_VALUE_TYPES.ELEMENT:
      return <ElementValueForm {...props} />;
    case GOAL_VALUE_TYPES.CODE:
      return <CodeValueForm {...props} />;
    default:
      return <FixedValueForm {...props} />;
  }
}

function getTriggerValue(trigger) {
  return trigger?.[`${trigger?.valueType?.toLowerCase?.()}Value`] || {};
}

function GoalValueCurrencySelector({ trigger, service, index, ...rest }) {
  const [, send] = useActor(service);
  const triggerValue = useMemo(() => getTriggerValue(trigger), [trigger]);

  return (
    <CurrencySelector
      value={triggerValue.currency}
      onChange={(value) => {
        send({ type: 'SET_TRIGGER_VALUE', index, trigger, key: 'currency', value });
      }}
      unitOnly={true}
      {...rest}
    />
  );
}

function GoalValueTypeSelector({ service, trigger, index }) {
  const [, send] = useActor(service);

  useEffect(() => {
    if (!trigger.valueType) {
      send({ type: 'SET_TRIGGER', index, key: 'valueType', value: GOAL_VALUE_TYPES.FIXED });
    }
  }, [trigger.valueType, send, index]);

  return (
    <div className="w-1/2">
      <Select
        options={valueTypeOptions}
        value={trigger.valueType}
        onChange={(value) => {
          send({ type: 'SET_TRIGGER', index, key: 'valueType', value });
        }}
        label={index !== null ? 'Set value by' : null}
      />
    </div>
  );
}

function FixedValueForm({ service, trigger, index }) {
  const [, send] = useActor(service);

  return (
    <div className="flex items-end gap-5">
      <GoalValueTypeSelector trigger={trigger} service={service} index={index} />
      <div className="w-1/4">
        <Input
          value={trigger.fixedValue?.worth || ''}
          onChange={(e) => {
            const { value } = e.target;
            send({ type: 'SET_TRIGGER_VALUE', index, trigger, key: 'worth', value });
          }}
          id="valueMetric"
          label="Value"
          placeholder="eg. 10"
        />
      </div>
      <div className="w-1/4">
        <GoalValueCurrencySelector
          trigger={trigger}
          service={service}
          index={index}
          id="valueCurrency"
          label="Currency"
        />
      </div>
    </div>
  );
}

function ElementValueForm({ service, trigger, index }) {
  const [, send] = useActor(service);
  const triggerValue = useMemo(() => getTriggerValue(trigger), [trigger]);

  const elementSelectorReady =
    !!triggerValue?.condition?.pageUrl &&
    !!triggerValue?.condition?.trackingOption &&
    !!triggerValue?.condition?.elementSelectorType;

  return (
    <div className="mt-6 flex flex-col gap-5">
      <GoalValueTypeSelector trigger={trigger} service={service} index={index} />

      <h2 className="text-header-2 mt-5">What page is the element on?</h2>
      <Input
        value={triggerValue?.condition?.pageUrl ?? ''}
        onChange={(e) => {
          const { value } = e.target;
          send({
            type: 'SET_TRIGGER_VALUE',
            index,
            trigger,
            key: 'condition',
            value: { ...(triggerValue.condition || {}), pageUrl: value },
          });
        }}
      />

      <RadioGroup
        options={[
          { value: GOAL_TRACKING_OPTIONS.SPECIFIC_PAGE, label: 'Track this element only on this page.' },
          {
            value: GOAL_TRACKING_OPTIONS.ANY_PAGE,
            label: 'Include this element from any page across my website.',
          },
          {
            value: GOAL_TRACKING_OPTIONS.WILDCARD,
            label: 'Include this element for any pages that match a wildcard.',
          },
        ]}
        value={triggerValue?.condition?.trackingOption}
        onChange={(value) => {
          send({
            type: 'SET_TRIGGER_VALUE',
            index,
            trigger,
            key: 'condition',
            value: {
              ...(triggerValue.condition || {}),
              trackingOption: value,
              onPage: value !== GOAL_TRACKING_OPTIONS.SPECIFIC_PAGE ? undefined : triggerValue.condition.pageUrl,
              onAnyPage: value !== GOAL_TRACKING_OPTIONS.ANY_PAGE ? undefined : true,
              wildcardUrl: value !== GOAL_TRACKING_OPTIONS.WILDCARD ? undefined : triggerValue.condition.wildcardUrl,
            },
          });
        }}
      />

      {triggerValue?.condition?.trackingOption === GOAL_TRACKING_OPTIONS.WILDCARD ? (
        <Input
          placeholder={`Enter a wildcard pattern (e.g. ${triggerValue?.condition?.pageUrl}/*)`}
          value={triggerValue?.condition?.wildcardUrl ?? ''}
          onChange={(e) => {
            send({
              type: 'SET_TRIGGER_VALUE',
              index,
              trigger,
              key: 'condition',
              value: {
                ...(triggerValue.condition || {}),
                wildcardUrl: e.target.value,
              },
            });
          }}
        />
      ) : null}

      <h2 className="text-header-2 mt-5">What is the currency of the element?</h2>
      <GoalValueCurrencySelector trigger={trigger} service={service} index={index} />

      <h2 className="text-header-2">Select the element</h2>

      <RadioGroup
        options={[
          { value: ELEMENT_SELECTOR_TYPES.SPECIFIC, label: 'Track only the element I click.' },
          // https://app.shortcut.com/crazyegg/story/27554/broad-element-matching-for-goal-triggers
          // { value: 'BROAD', label: 'Track all similar elements' },
          { value: ELEMENT_SELECTOR_TYPES.MANUAL, label: 'Advanced: Track via CSS selector.' },
        ]}
        value={triggerValue?.condition?.elementSelectorType}
        onChange={(value) => {
          send({
            type: 'SET_TRIGGER_VALUE',
            index,
            trigger,
            key: 'condition',
            value: { ...(triggerValue.condition || {}), elementSelectorType: value },
          });
        }}
      />

      <div className="text-body-4 mb-2.5 mt-2.5">
        The element you select will be outlined in red. To cancel a selection, click on the element again.
      </div>

      {elementSelectorReady ? (
        <ValueElementSelector
          isManual={triggerValue?.condition?.elementSelectorType === ELEMENT_SELECTOR_TYPES.MANUAL}
          trigger={trigger}
          service={service}
          index={index}
          errors={[]}
        />
      ) : null}
    </div>
  );
}

const escapeHtml = (unsafe) => {
  return unsafe
    .replaceAll('&', '&amp;')
    .replaceAll('<', '&lt;')
    .replaceAll('>', '&gt;')
    .replaceAll('"', '&quot;')
    .replaceAll("'", '&#039;');
};

const scriptTriggerCode = (goalId, goalCode) => {
  return escapeHtml(`<script type="text/javascript">
  (window.CE_API || (window.CE_API=[])).push(function() {
    // Set the "worth" and "currency" to the values you need.
    CE2.nextGoalConversionValue(
      '${goalCode || goalId}',
      { worth: '99.95', currency: 'USD' }
    );
  });
</script>`);
};

function CodeValueForm({ service, trigger, index }) {
  const [state] = useActor(service);

  const code = useMemo(() => {
    return scriptTriggerCode(state?.context?.goalId, trigger.code);
  }, [state?.context?.goalId, trigger.code]);

  return (
    <div className="mt-6 flex flex-col gap-5">
      <GoalValueTypeSelector trigger={trigger} service={service} index={index} />
      <h2 className="text-header-2 mt-5">Insert this code snippet onto your site</h2>
      <p className="text-body-2">Paste this code snippet inside the &lt;head&gt; tag of your site.</p>
      <CodeBox loading={false} canCopy={true} code={code} height="200px" />
    </div>
  );
}

function TriggerCard({ service, index, children }) {
  const [state, send] = useActor(service);
  const { selectedSite } = useSite();

  // const trigger = useMemo(
  //   () => deserializeTrigger(state.context.goal.triggers[index].filter),
  //   [state.context.goal.triggers, index],
  // );

  const trigger = useMemo(() => state.context.payload.triggers[index], [state.context.payload.triggers, index]);

  const metadata = useMemo(() => GOAL_TRIGGERS_METADATA[trigger.trigger], [trigger?.trigger]);

  const target = useMemo(() => {
    return selectedSite?.name;
  }, [selectedSite?.name]);

  return (
    <div className="rounded border border-mystic-500 shadow">
      <div className="flex justify-between border-b border-dashed border-mystic-500 p-6 !pb-5">
        <div className="flex">
          <WebP
            className="-ml-2 w-[35px]"
            webp={metadata.webp}
            fallback={metadata.png}
            width="35"
            height="auto"
            alt="trigger"
          />
          <div className="ml-3 flex flex-col">
            <h3 className="text-header-2">{metadata.selectLabel}</h3>
            <span className="text-body-2 flex items-center gap-1">
              on{' '}
              {target ? (
                <a href={`http://${target}`} target="_blank" rel="noreferrer" className="text-link">
                  {target}
                </a>
              ) : (
                <SkeletonLine />
              )}
            </span>
          </div>
        </div>
        <button className="mr-4 text-dodger-blue-500" onClick={() => send({ type: 'TOGGLE_TRIGGER', index })}>
          <ExpandIcon
            aria-label={trigger.expanded ? 'collapse' : 'expand'}
            className={classNames('h-3 w-3 fill-current', { 'rotate-180': !trigger.expanded })}
          />
        </button>
      </div>
      <div className="flex flex-col p-6">{children}</div>
    </div>
  );
}
