import { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { SkeletonLine } from '@crazyegginc/hatch';
import { getUnixTime } from 'date-fns';

import { FeatureIcon } from '/src/features/whats-new/components/Event';
import { changeLogQuery } from '/src/features/whats-new/queries';
import { getFeatureName } from '/src/features/_global/utils';
import { formatDate } from '/src/utils/date';
import { externalizeLinks } from '/src/features/whats-new/utils';
import { Section } from '../Section';

import { ReactComponent as WhatsNewIcon } from '@crazyegginc/hatch/dist/images/icon-megaphone-filled.svg';
import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function WhatsNewSection() {
  const { data, fetching } = useQuery(changeLogQuery({ newOnly: true }));

  return (
    <Section className="relative col-end-4 row-span-2 min-h-[300px] overflow-hidden pl-10 [contain:size]">
      <Section.Header icon={WhatsNewIcon} color="bg-regal-blue-500">
        <span>What&apos;s New</span>
      </Section.Header>
      <div className="relative overflow-hidden">
        <div className="absolute left-9 top-0 h-full w-1 bg-cadet-blue-500" />
        {fetching ? (
          <div className="relative">
            <SkeletonEntry />
            <SkeletonEntry />
            <SkeletonEntry />
            <SkeletonEntry />
          </div>
        ) : (
          <>
            <div className="relative">
              {data?.changelog?.map((event) => (
                <Event key={`event${event.id}`} event={event} />
              ))}
            </div>
          </>
        )}
      </div>
      <div
        className={classNames(
          'absolute bottom-0 left-0 flex h-28 w-full items-end justify-end rounded-b px-4 pb-2',
          'bg-gradient-to-b from-white/0 via-white-lilac-500/80 to-white-lilac-500/100',
        )}
      >
        <Link to="/new" className="text-link">
          More...
        </Link>
      </div>
    </Section>
  );
}

function Event({ event }) {
  const contentRef = useRef(null);
  useEffect(() => {
    if (contentRef.current) {
      externalizeLinks(contentRef.current);
    }
  }, [contentRef]);

  return (
    <Section.Body className="relative mb-5 !pb-4 !pr-5">
      <div className="mb-1 flex">
        <div className="shrink-0 grow-0">
          <FeatureIcon feature={event.category} />
        </div>
        <div className="ml-3 grow space-y-px">
          <div className="text-body-2 line-clamp-1">
            <strong className="text-body-1">{getFeatureName(event.category)}:</strong> {event.title}
          </div>
          <div className="text-caption">{formatDate(getUnixTime(new Date(event.publishedAt)))}</div>
        </div>
      </div>
      <div
        className="textWithLinks text-body-2 line-clamp-3 leading-snug"
        dangerouslySetInnerHTML={{
          __html: event.contentHtml
            .replace('<strong>', '')
            .replace('</strong>', '')
            .replace(/<br\s*\/?>/, ''),
        }}
        ref={contentRef}
      />
      <Link to="/new" className="text-link absolute bottom-4 right-1 flex h-5 w-5 items-center justify-center">
        <ArrowIcon className="ml-1 h-2.5 w-2.5 rotate-90 fill-current" aria-label="read more" />
      </Link>
    </Section.Body>
  );
}

function SkeletonEntry() {
  return (
    <Section.Body className="mb-5">
      <div className="mb-2 flex">
        <div>
          <SkeletonLine className="!h-10 !w-10 rounded-full" />
        </div>
        <div className="ml-3 grow space-y-1">
          <SkeletonLine className="!w-2/3" />
          <SkeletonLine className="!w-1/3" />
        </div>
      </div>
      <SkeletonLine className="mb-1 !w-full" />
      <SkeletonLine className="!w-4/5" />
    </Section.Body>
  );
}
