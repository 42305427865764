import { useState } from 'react';
import classNames from 'classnames';
import { Select, capitalize, Button } from '@crazyegginc/hatch';

import { processUnsafeText } from '../../../common/common-functions';
import { inflect } from '/src/utils/string';
import { LineChart } from './LineChart';
import { Dot } from '../Dot';
import { htmlDecode } from '/src/utils/string';

import { ReactComponent as SortIcon } from '@crazyegginc/hatch/dist/images/icon-sort.svg';

const SORT_OPTIONS = {
  ORIGINAL: 'ORIGINAL',
  ALPHABETICAL: 'ALPHABETICAL',
  POPULARITY: 'POPULARITY',
};

export function MultiChoice({ question }) {
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.ORIGINAL);
  const [asc, setAsc] = useState(true);
  const totalCount = question.results?.reduce((acc, curr) => acc + curr.count, 0);

  // deleted options might still show up in results
  const allOptions = [...new Set([...question.options, ...question.results.map((o) => htmlDecode(o.value))])].map(
    (option) => ({
      count: question.results.find((r) => htmlDecode(r.value) === option)?.count ?? 0,
      isDeleted: question.options.findIndex((o) => o === option) === -1,
      safeOptionHtml: processUnsafeText(option),
    }),
  );

  let sortedOptions = allOptions;
  if (sortBy !== SORT_OPTIONS.ORIGINAL) {
    sortedOptions = sortedOptions.sort((a, b) => {
      if (sortBy === SORT_OPTIONS.POPULARITY) {
        if (asc) {
          return a.count > b.count ? 1 : a.count < b.count ? -1 : 0;
        } else {
          return a.count > b.count ? -1 : a.count < b.count ? 1 : 0;
        }
      } else if (sortBy === SORT_OPTIONS.ALPHABETICAL) {
        if (asc) {
          return a.safeOptionHtml > b.safeOptionHtml ? 1 : a.safeOptionHtml < b.safeOptionHtml ? -1 : 0;
        } else {
          return a.safeOptionHtml > b.safeOptionHtml ? -1 : a.safeOptionHtml < b.safeOptionHtml ? 1 : 0;
        }
      }
    });
  }

  return (
    <>
      <div className="absolute right-0 top-0 flex items-center">
        <span className="text-body-2 mr-2">Sort by:</span>
        <Select
          options={Object.values(SORT_OPTIONS).map((o) => ({ value: o, label: `${capitalize(o)}` }))}
          value={sortBy}
          onChange={(v) => {
            setSortBy(v);
            if (v === SORT_OPTIONS.ALPHABETICAL) {
              setAsc(true);
            } else if (v === SORT_OPTIONS.POPULARITY) {
              setAsc(false);
            }
          }}
          style={{
            background: '#e5f2fc',
            borderColor: '#e5f2fc',
            cursor: 'pointer',
            color: '#0086e6',
            fontWeight: 'bold',
            width: '145px',
          }}
        />
        <Button
          onClick={() => setAsc((x) => !x)}
          className="ml-1 !w-12"
          variant="secondary"
          disabled={sortBy === SORT_OPTIONS.ORIGINAL}
        >
          <SortIcon className={classNames('h-4 w-4 fill-current', { 'scale-y-[-1]': asc })} />
        </Button>
      </div>
      <div className="flex flex-col space-y-5 px-3.75">
        {sortedOptions.map(({ count, isDeleted, safeOptionHtml }, index) => (
          <div className="flex flex-col" key={`${question.id}_${index}`}>
            <div className="text-body-4 mb-2.5 flex w-full items-center justify-between">
              <div
                className={classNames('flex items-center space-x-1 whitespace-pre', {
                  'text-black-pearl-500': !isDeleted,
                  'text-lynch-500': isDeleted,
                })}
              >
                <span className="flex whitespace-pre" dangerouslySetInnerHTML={{ __html: safeOptionHtml }} />
                <Dot />
                <span>{Math.ceil((count / totalCount || 0) * 100)}%</span>
                {isDeleted && <span className="whitespace-pre italic"> — deleted</span>}
              </div>
              <span className="text-lynch-500">
                {count} {inflect('response', count)}
              </span>
            </div>
            <LineChart index={index} value={count} maxValue={totalCount} />
          </div>
        ))}
      </div>
    </>
  );
}
