import { FEATURES, PERMISSION_NOT_ALLOWED } from '/src/features/_global/constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';

export const RECORDINGS_PERMISSIONS = {
  [FEATURES.RECORDINGS]: ({ capabilities, isSharing, sharedResource, currentUser }) => {
    const sharedResourceIsRecording =
      sharedResource?.resource?.resourceType === SHARABLE_RESOURCE_TYPES.RECORDING ?? false;
    return isSharing
      ? {
          navigate: PERMISSION_NOT_ALLOWED,
          view: sharedResourceIsRecording ? { allowed: true } : PERMISSION_NOT_ALLOWED,
          edit: PERMISSION_NOT_ALLOWED,
          settings: PERMISSION_NOT_ALLOWED,
          viewGroups: PERMISSION_NOT_ALLOWED,
          viewHUDSnapshot: PERMISSION_NOT_ALLOWED,
        }
      : {
          navigate: capabilities.recordings.permissions.nav,
          view: capabilities.recordings.permissions.view,
          edit: capabilities.recordings.permissions.edit,
          settings: capabilities.recordings.permissions.settings,
          viewGroups: !currentUser.features.includes('recordings-list-view-only')
            ? { allowed: true }
            : PERMISSION_NOT_ALLOWED,
          viewHUDSnapshot: currentUser.features.includes('hud-filter-snapshot')
            ? { allowed: true }
            : PERMISSION_NOT_ALLOWED,
        };
  },
  Recording: ({ capabilities, isSharing, entity: recording = null }) => {
    return isSharing
      ? {
          edit: PERMISSION_NOT_ALLOWED,
          delete: PERMISSION_NOT_ALLOWED,
          share: PERMISSION_NOT_ALLOWED,
          tag: PERMISSION_NOT_ALLOWED,
          canWatchUnwatch: PERMISSION_NOT_ALLOWED,
          canUpgradeToWatch: PERMISSION_NOT_ALLOWED,
        }
      : {
          edit: capabilities.recordings.permissions.edit,
          delete: capabilities.recordings.permissions.edit,
          share: capabilities.recordings.permissions.edit,
          tag: capabilities.recordings.permissions.edit,
          canWatchUnwatch: capabilities.recordings.permissions.edit,
          canUpgradeToView: { allowed: recording && recording.permissions.canUpgradeToView },
        };
  },
};
