import { useRef, useMemo, useCallback, useEffect } from 'react';
import { create as createJsonDiff, formatters } from 'jsondiffpatch/dist/jsondiffpatch.umd';
import 'jsondiffpatch/dist/formatters-styles/html.css';
import 'jsondiffpatch/dist/formatters-styles/annotated.css';
import { Button, Tooltip, ReactTable, Indicator } from '@crazyegginc/hatch';

import '../audit-log.scss';

import { inflect, toTitleCase, toConstant, camelToSnake } from '/src/utils/string';
import { formatShortDateTime } from '/src/utils/date';
import { SORT_ORDER_TYPES } from '/src/features/_global/constants';
import {
  AB_TEST_RATIO_CHANGE,
  BILLING_CHANGE,
  DELETED_AB_TEST,
  DELETED_RECORDINGS,
  DELETED_SITE,
  DELETED_SNAPSHOTS,
  DUPLICATED_AB_TEST,
  INTERNAL_URL_VISITED,
  RECORDING_SETTINGS_CHANGE,
  SNAPSHOT_SETTINGS_CHANGE,
  SNAPSHOTS_VIEWED,
  SNAPSHOTS_CREATED,
  INSTALL,
  DELETED_SURVEY,
  SURVEY_CREATED,
  SURVEY_EDITED,
  SURVEY_TOGGLED,
  SURVEY_VIEW_RESPONSES,
} from '../constants';

export function AuditLogTable({ setSort, sort, listOfAuditTrail, hasNextPage, loadNextPage, fetching }) {
  const tableRef = useRef(null);

  const columns = useMemo(
    () => [
      {
        header: 'Timestamp',
        accessorKey: 'timestamp',
        size: 80,
        meta: {
          align: 'center',
          justify: 'left',
        },
        cell: function TimestampCell({ row }) {
          return <>{formatShortDateTime(row.original.timestamp)}</>;
        },
      },
      {
        header: 'User',
        size: 120,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function UserCell({ row }) {
          return (
            <div className="truncate">
              {row.original.guestEmail || 'Owner'} {row.original.admin ? '(admin)' : null}
            </div>
          );
        },
      },
      {
        header: 'Action',
        size: 100,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function ActionCell({ row }) {
          return <>{userActions(row.original)[0]}</>;
        },
      },
      {
        header: 'Detail',
        size: 125,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function DetailCell({ row }) {
          return (
            !userActions(row.original)[2] && (
              <div className="flex w-full pr-3">
                <Tooltip
                  tooltipContent={<div className="max-w-xs break-words text-left">{userActions(row.original)[1]}</div>}
                >
                  <div className="cursor-pointer">
                    <div className="truncate">{userActions(row.original)[1]}</div>
                  </div>
                </Tooltip>
              </div>
            )
          );
        },
      },
      {
        accessorKey: 'detailActions',
        size: 50,
        meta: {
          align: 'center',
          justify: 'left',
        },
        enableSorting: false,
        cell: function DetailActionsCell({ row, table }) {
          return (
            userActions(row.original)[2] && (
              <>
                <Button
                  rightIcon={<Indicator type="expand" up={row.getIsExpanded()} className="ml-2.5" />}
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    table.toggleAllRowsExpanded(false);
                    row.toggleExpanded(!row.getIsExpanded());
                  }}
                >
                  Details
                </Button>
              </>
            )
          );
        },
      },
    ],
    [],
  );

  const renderExpandedComponent = useCallback(
    ({ row }) => (
      <AuditTrailDetailContent
        detailType={row.original.__typename}
        detailAction={userActions(row.original)[1]}
        isCompare={userActions(row.original)[3]}
      />
    ),
    [],
  );

  const tableSortData = useMemo(
    () => ({
      id: 'timestamp',
      desc: sort === SORT_ORDER_TYPES.DESC,
    }),
    [sort],
  );

  const onFetchData = useCallback(
    ({ sorting }) => {
      if (sorting) {
        const nextSort = sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC;
        setSort(nextSort);
      }
    },
    [setSort],
  );

  return (
    <ReactTable
      columns={columns}
      data={listOfAuditTrail}
      ref={{ tableRef }}
      onFetchData={onFetchData}
      enableSorting={true}
      sorting={tableSortData}
      rowPadding={true}
      rowHeight={50}
      fetching={fetching}
      useVirtualization={true}
      hasNextPage={hasNextPage}
      isNextPageLoading={fetching}
      loadNextPage={loadNextPage}
      renderExpandedComponent={renderExpandedComponent}
      expandedComponentHeight={250}
    />
  );
}

function userActions(action) {
  let withDetails = true;
  let isCompare = true;

  switch (toConstant(action.__typename)) {
    case INTERNAL_URL_VISITED:
      return ['Visited Page', action.url, !withDetails, !isCompare];
    case AB_TEST_RATIO_CHANGE: {
      const newRatios = JSON.parse(action.newRatios);
      const oldRatios = JSON.parse(action.oldRatios);

      const combinedText = newRatios
        .map((value, index) => {
          return { old: oldRatios[index], new: value };
        })
        .map((value, index) => {
          const variantName = index == 0 ? 'Control' : `Variant #${index}`;
          return `${variantName} was changed from ${Math.round(value.old.weight)}% -> ${Math.round(value.new.weight)}%`;
        })
        .join(', ');

      return ['Change AB Test Ratio', [combinedText], withDetails, !isCompare];
    }
    case BILLING_CHANGE:
      return ['Billing Change', [action.oldBillingDetails, action.newBillingDetails], withDetails, isCompare];
    case DELETED_AB_TEST:
      return ['Deleted A/B Testing', `ID: ${action.flowId}`, !withDetails, !isCompare];
    case DELETED_RECORDINGS:
      return ['Deleted Recordings', action.recordingIds, withDetails, !isCompare];
    case DELETED_SITE:
      return ['Deleted Site', action.siteName, !withDetails, !isCompare];
    case DELETED_SNAPSHOTS:
      return ['Deleted Snapshots', action.snapshotIds, withDetails, !isCompare];
    case DUPLICATED_AB_TEST:
      return ['Duplicated A/B Testing', `ID: ${action.flowId}`, !withDetails, !isCompare];
    case SNAPSHOTS_CREATED:
      return ['Created Snapshots', action.snapshotNames, withDetails, !isCompare];
    case RECORDING_SETTINGS_CHANGE:
      return [
        'Change Recording Settings',
        [action.oldRecordingSettings, action.newRecordingSettings],
        withDetails,
        isCompare,
      ];
    case SNAPSHOT_SETTINGS_CHANGE:
      return ['Change Snapshot Settings', action.changes, withDetails, !isCompare];
    case SNAPSHOTS_VIEWED:
      return ['Snapshots Viewed', action.url, !withDetails, !isCompare];
    case INSTALL:
      return ['Install', action.url, !withDetails, !isCompare];
    case DELETED_SURVEY:
      return ['Survey Deleted', action?.surveyName ?? 'N/A', !withDetails, !isCompare];
    case SURVEY_CREATED:
      return ['Survey Created', action.surveyId, !withDetails, !isCompare];
    case SURVEY_EDITED:
      return ['Survey Edited', action.changes, withDetails, !isCompare];
    case SURVEY_TOGGLED:
      return [
        `Survey ${action.newStatus === 'true' ? 'ON' : 'OFF'}`,
        action?.survey?.name ?? 'N/A',
        !withDetails,
        !isCompare,
      ];
    case SURVEY_VIEW_RESPONSES:
      return ['Survey Responses Viewed', action?.survey?.name ?? 'N/A', !withDetails, !isCompare];
    default:
      return ['N/A', 'N/A', !withDetails, !isCompare];
  }
}

const AuditTrailDetailContent = ({ detailType, detailAction, isCompare }) => {
  const isSnapshotSettingsChange = camelToSnake(detailType).toUpperCase() === SNAPSHOT_SETTINGS_CHANGE;
  const isSurveyEdited = camelToSnake(detailType).toUpperCase() === SURVEY_EDITED;

  if (isCompare) {
    return (
      <div className="flex h-full w-full items-start justify-between p-4">
        <span className="flex h-full w-full flex-col">
          <JsonDiff left={detailAction[0]} right={detailAction[1]} show={true} annotated={false} />
        </span>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full items-start justify-between p-4">
      <div className="flex h-full w-full overflow-y-auto rounded bg-white-lilac-500 p-2">
        {isSnapshotSettingsChange || isSurveyEdited ? (
          <TrailDetailChangeData data={detailAction} />
        ) : (
          <>
            <h5 className="text-body-1 mr-4 min-w-[80px]">
              {`${detailAction.length} ${inflect('ID', detailAction.length)}`}:
            </h5>
            <ul className="flex flex-1 flex-wrap content-start">
              {detailAction.map((id, i) => (
                <li key={`${id}-${i}`} className="text-body-2 mb-3 mr-3">
                  {id}
                  {i !== detailAction.length - 1 ? ',' : null}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

const TrailDetailChangeData = ({ data }) => {
  const titleData = JSON.parse(data);
  if (Object.keys(titleData).length < 1) return 'No details available';

  if (Object.keys(titleData).length > 1) {
    return (
      <ul className="w-full">
        {Object.keys(titleData).map((title, i) => {
          const titleValue = titleData[title];
          return (
            <li key={`${title}-${i}`} className="text-body-2 mb-3 flex border-b border-b-mystic-500 pb-2 last:pb-0">
              <span className="w-36 truncate font-bold">{toTitleCase(title.replaceAll('_', ' '))}:</span>
              <span className="ml-4 flex-1">
                {titleValue
                  ? singleSnapshotSettingChangeMessage(title, Object.values(titleValue))
                  : 'No details available'}
              </span>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <span className="text-body-2">
      <span className="w-36 truncate font-bold">{toTitleCase(Object.keys(titleData)[0].replaceAll('_', ' '))}</span>
      <span className="ml-4 flex-1">
        {singleSnapshotSettingChangeMessage(Object.keys(titleData)[0], Object.values(titleData)[0])}
      </span>
    </span>
  );
};

function JsonDiff({ left, right, show = true, annotated = false, objectHash, tips }) {
  const leftParsed = useMemo(() => JSON.parse(left), [left]);
  const rightParsed = useMemo(() => JSON.parse(right), [right]);

  const delta = createJsonDiff({
    objectHash,
  }).diff(leftParsed, rightParsed);

  const html = annotated ? formatters.annotated.format(delta) : formatters.html.format(delta, left);

  useEffect(() => {
    if (show) {
      formatters.html.showUnchanged();
    } else {
      formatters.html.hideUnchanged();
    }
  }, [show]);

  return (
    <div className="h-full overflow-y-auto bg-white-lilac-500 py-3 pl-1 pr-3">
      <div className="jsoncustomclass">
        {html ? (
          <div dangerouslySetInnerHTML={{ __html: html }} />
        ) : (
          <p style={{ fontSize: 12, color: '#999' }}>{tips || 'Both objects are identical.'}</p>
        )}
      </div>
    </div>
  );
}

function singleSnapshotSettingChangeMessage(title, message) {
  switch (title) {
    case 'expires_at':
      return formatShortDateTime(message);
    case 'reoccurring':
      return `${message}`;
    default:
      return message;
  }
}
