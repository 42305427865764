import { AppConfig } from '../AppConfig';
import { refetchAuthToken } from '/src/machines/auth';
import { AUTH_TOKEN_KEY } from '/src/features/_global/constants';

const MESSAGE_ID = 'shell:test-editor';

function generateRpcMessage(options) {
  return JSON.stringify({
    id: options?.id ?? MESSAGE_ID,
    type: 'ember-window-messenger-client',
    name: 'exec',
    query: {
      cmd: options?.cmd,
      params: options?.params ?? undefined,
    },
  });
}

const variantColors = {
  control: '#3B4246',
  red: '#ea5e5c',
  green: '#78ab00',
  orange: '#f4ad3b',
  'sky-blue': '#2A9CC0',
  blue: '#0086E6',
  purple: '#814ec5',
  fuchsia: '#EC58BD',
  0: '#ea5e5c', // red
  1: '#78ab00', // green
  2: '#f4ad3b', // orange
  3: '#2A9CC0', // sky-blue
  4: '#0086E6', // blue
  5: '#814ec5', // purple
  6: '#EC58BD', // fuchsia
  retired: '#A5ACBB',
};

const colorNames = {
  '#ea5e5c': 'red',
  '#78ab00': 'green',
  '#f4ad3b': 'orange',
  '#2A9CC0': 'sky-blue',
  '#0086E6': 'blue',
  '#814ec5': 'purple',
  '#EC58BD': 'fuchsia',
};

export function getVariantColor(variant) {
  if (!variant) {
    return '#e1e6ef';
  }
  if (variant.type === 'control') {
    return variantColors['control'];
  }
  if (variant.type === 'retired') {
    return variantColors['retired'];
  }
  return variantColors[variant.position % 7]; // select one of the total 7 colors
}

export function getVariantColorName(variant) {
  return colorNames[getVariantColor(variant)];
}

function rpc(options, target = 'test-editor-frame') {
  const editorFrame = document.getElementById(target);
  editorFrame?.contentWindow?.postMessage?.(generateRpcMessage(options), '*');
}

async function asyncRpc(options) {
  // issue request and wait for response
  return new Promise((resolve, reject) => {
    window.addEventListener('message', function (msg) {
      try {
        const parsed = JSON.parse(msg.data);
        if (parsed.id === MESSAGE_ID && parsed.response) {
          return resolve(parsed.response);
        }
        // TODO: handle errors possibly
      } catch (err) {
        return reject(err);
      }
    });

    rpc(options);
  });
}

export function changeMoveSetting(value) {
  return rpc({
    cmd: 'change-move-setting',
    params: {
      value,
    },
  });
}

export function changePopupsState(hide, target = 'test-editor-frame') {
  return rpc(
    {
      cmd: 'change-popups-state',
      params: {
        hide,
      },
    },
    target,
  );
}

export function showPage() {
  return rpc({ cmd: 'show' });
}

export function refreshPage() {
  return rpc({ cmd: 'refresh' });
}

export function editPage(editionId, mutations = null, editorColor = 'green', moveSetting = 'move', screenWidth) {
  return rpc({
    cmd: 'edit',
    params: {
      editionId,
      mutations,
      editorColor,
      moveSetting,
      screenWidth,
    },
  });
}

export function changeScreenWidth(width) {
  return rpc({
    cmd: 'screen-width',
    params: {
      width,
    },
  });
}

export function changeInteractiveMode(enabled, target = 'test-editor-frame') {
  return rpc(
    {
      cmd: 'mode',
      params: {
        interactiveMode: enabled,
      },
    },
    target,
  );
}

export async function getMutations(id) {
  try {
    const result = await asyncRpc({
      cmd: 'get-mutations',
      params: {
        editionId: id,
      },
    });
    if (!result) return null;
    return result?.editorPatch ?? null;
  } catch {
    return null;
  }
}

export function refreshEditor() {
  return rpc({
    cmd: 'refresh',
  });
}

export async function undo() {
  return await asyncRpc({
    cmd: 'undo',
  });
}

export async function redo() {
  return await asyncRpc({
    cmd: 'redo',
  });
}

export function openPreview(url, deviceType, editorPatch, meta) {
  const previewData = {
    url,
    deviceType,
    editorPatch: { ...editorPatch },
    ...meta,
  };

  const previewWindow = window.open('/ab-tests/preview', '_blank');

  // pass the preview data to another tab
  // we are opening preview in another tab as these changes are not saved to server yet we are passing like this
  previewWindow.EDITOR_PREVIEW_DATA = { ...previewData };
}

export function showPreview({ editorPatch, editionId, editorColor, screenWidth }) {
  return rpc({
    cmd: 'edit-preview',
    params: {
      mutations: editorPatch,
      editionId: editionId,
      editorColor: editorColor,
      screenWidth: screenWidth,
    },
  });
}

export function updateSelectors(selectors, target = 'element-selector-frame') {
  return rpc(
    {
      cmd: 'update-selectors',
      params: {
        selectors,
      },
    },
    target,
  );
}

async function fetchInteragentJWT() {
  const request = await fetch(`${AppConfig.legacyCoreBaseURL()}/core/interagent/jwt`, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    body: JSON.stringify({
      access_token: window.localStorage.getItem(AUTH_TOKEN_KEY),
    }),
  });

  return await request.json();
}

export async function provideInteragentJWT(frameId, payload) {
  let response = await fetchInteragentJWT();
  if (response.msg === 'ERROR' && !response.interagent) {
    // handle interagent error
    await refetchAuthToken();
    response = await fetchInteragentJWT();
  }
  return respond(frameId, payload.id, payload.type, response.interagent, false);
}

export async function provideAuthJWT(frameId, payload, token) {
  return respond(frameId, payload.id, payload.type, token, false);
}

function respond(frameId, id, type, payload, hasError) {
  const query = {
    id,
    type: `ember-window-messenger-server`,
    response: payload,
    error: hasError,
  };

  const editorFrame = document.getElementById(frameId);
  editorFrame.contentWindow.postMessage(JSON.stringify(query), '*');
}
