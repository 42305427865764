import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const abTestsFeatureKeys = createQueryKeys('abTestsFeature', {
  abTestList: ({ site, search, status }) => [{ site, search, status }],
  abTestDetail: ({ id }) => [{ id }],
});

export const abTestListQuery = ({ site, search, status }) => ({
  ...abTestsFeatureKeys.abTestList({ site, search, status }),
  useErrorBoundary: false,
  getNextPageParam: (lastPage) => (lastPage.abTestList.hasNextPage ? lastPage.abTestList.nextPageCursor : undefined),
  meta: {
    query: gql`
      query AbTestList($cursor: String, $limit: Int, $search: String, $site: Int, $status: AbTestStatus) {
        abTestList(cursor: $cursor, limit: $limit, search: $search, site: $site, status: $status) {
          list {
            autoReweight
            bestVariantId
            createdAt
            createdByUserId
            createdByUserName
            devices
            duration
            goal {
              id
              name
              displayData
              icon
              historical
            }
            hasErrors
            hasResults
            id
            matchingUrl
            name
            pageUrl
            publishedAt
            recentlyCreated
            scriptInstalled
            siteId
            status
            stoppedAt
            thumbnailUrl
            totalConversions
            totalVisitors
            type
            variantsCount
            permissions {
              canAdjustWeight
              canViewResults
              canStop
              canShare
              canChangeGoal
              canRename
              canCheckInstallation
              canDelete
              canAddVariants
              canDuplicate
            }
            variants {
              id
              variantName
              viewVariantUrl
              position
              type
              redirectUrl
              weight
              results {
                conversions
                visitors
              }
            }
          }
          nextPageCursor
          hasNextPage
        }
      }
    `,
  },
});

export const abTestDetailQuery = ({ id }) => ({
  ...abTestsFeatureKeys.abTestDetail({ id }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query AbTestDetail($id: Int!) {
        abTestDetail(id: $id) {
          autoReweight
          bestVariantId
          createdAt
          createdByUserId
          createdByUserName
          devices
          draft
          duration
          goal {
            id
            name
            displayData
            icon
            historical
          }
          hasErrors
          hasResults
          id
          matchingUrl
          name
          pageUrl
          permissions {
            canAddVariants
            canDuplicate
            canAdjustWeight
            canChangeGoal
            canRename
            canCheckInstallation
            canDelete
            canShare
            canStop
            canViewResults
          }
          publishedAt
          recentlyCreated
          scriptInstalled
          siteId
          status
          stoppedAt
          thumbnailUrl
          totalConversions
          totalVisitors
          type
          variants {
            createdAt
            updatedAt
            editorPatch
            redirectUrl
            hasErrors
            id
            isRetired
            permissions {
              canDuplicate
              canEdit
              canRetire
              canViewRecordings
              canViewSnapshot
            }
            position
            results {
              conversionRate
              conversions
              hasBestImprovement
              improvementFromControl
              trafficSplit
              visitors
            }
            resultsId
            retiredAt
            snapshotId
            thumbnailUrl
            screenshotUrl
            type
            variantName
            viewVariantUrl
            weight
          }
          variantsCount
          originalId
        }
      }
    `,
  },
});
