import { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { LoaderBalloonScreen } from '@crazyegginc/hatch';

import { useMutation, useNotifications } from '/src/hooks';
import { abTestDetailQuery } from '/src/features/ab-testing/queries';
import { abTestAddVariantsMutation } from '/src/features/ab-testing/mutations';
import { VariantEditorWizard } from '/src/features/ab-testing/components/VariantEditorWizard';
import { variantMachine, parseExistingVariant } from '/src/features/ab-testing/machines/variant-editor';
import { generateVariantEditorPatches } from '/src/features/ab-testing/utils';
import { DEVICE_WIDTHS, VARIANT_STATUSES } from '/src/features/ab-testing/constants';

// eslint-disable-next-line
export default function AbTestAddVariantPage() {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const notifications = useNotifications();

  const { data, isFetching } = useQuery({
    ...abTestDetailQuery({ id: Number(id) }),
  });

  const variants = data?.abTestDetail?.variants;
  const pageUrl = data?.abTestDetail?.pageUrl;
  const device = data?.abTestDetail?.devices.sort((a, b) => parseInt(DEVICE_WIDTHS[b]) - parseInt(DEVICE_WIDTHS[a]))[0];

  const { mutate: mutateCreateVariants } = useMutation(abTestAddVariantsMutation);

  const [state, send, service] = useMachine(variantMachine, {
    devTools: true,
  });

  useEffect(() => {
    let running = false;

    const doMutate = async (e) => {
      if (running) return;
      const variants = generateVariantEditorPatches({
        variants: e.data.variants.filter(
          (variant) => variant.type === 'variant' && variant.status === VARIANT_STATUSES.DIRTY,
        ),
      });
      mutateCreateVariants(
        {
          id: Number(id),
          variants,
        },
        {
          onMutate: () => {
            running = true;
          },
          onSuccess: () => {
            running = false;
            notifications.success({ content: `Variant created successfully.`, timeout: 3000 });
            navigate(`/ab-tests/${id}`, { replace: true });
          },
          onError: (error) => {
            running = false;
            notifications.error({ content: `Variant creation failed.`, timeout: 3000, context: { error } });
          },
        },
      );
    };
    service.onDone(doMutate);

    return () => {
      service.off(doMutate);
    };
  }, [mutateCreateVariants, id, notifications, navigate, service]);

  useEffect(() => {
    if (pageUrl && pageUrl !== state.context.url) {
      send({
        type: 'SET_VARIANTS',
        payload: variants.map(parseExistingVariant),
        createEmptyVariant: true,
        initialEditorPatch: location?.state?.editorPatch,
      });
      send({
        type: 'SET',
        value: {
          url: pageUrl,
          device,
        },
      });
    }
  }, [pageUrl, state.context.url, send, device, variants, location?.state?.editorPatch]);

  if (!state.context.url || !state.context.device || isFetching) {
    return <LoaderBalloonScreen text={true} />;
  }

  return <VariantEditorWizard service={service} backEnabled={false} nextText="Add variant" />;
}
