import { gql } from '@urql/core';

import { globalFeatureKeys } from '/src/features/_global/queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';

export const updateUserProfileMutation = ({ client, queryClient }) => ({
  mutationFn: ({ login, name, language }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdateUserProfile($login: String!, $name: String!, $language: String!) {
          updateUserProfile(login: $login, name: $name, language: $language) {
            name
            email
            language
          }
        }
      `,
      {
        login,
        name,
        language,
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({ ...globalFeatureKeys.me });
  },
});

export const deleteAccountMutation = ({ client }) => ({
  mutationFn: ({ delete: deleteString, userId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation DeleteAccount($delete: String!, $userId: Int!) {
          deleteAccount(delete: $delete, userId: $userId)
        }
      `,
      {
        delete: deleteString,
        userId,
      },
    ),
});

export const setUserNotificationsMutation = ({ client, queryClient }) => ({
  mutationFn: ({
    accountLimits,
    dailyUpdate,
    endOfTest,
    monthlyExport,
    newsletter,
    receipt,
    snapshotRecommendation,
    trialEnded,
    weeklyExport,
    weeklyUpdate,
  }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SetUserNotifications(
          $accountLimits: Boolean
          $dailyUpdate: Boolean
          $endOfTest: Boolean
          $monthlyExport: Boolean
          $newsletter: Boolean
          $receipt: Boolean
          $snapshotRecommendation: Boolean
          $trialEnded: Boolean
          $weeklyExport: Boolean
          $weeklyUpdate: Boolean
        ) {
          setUserNotifications(
            accountLimits: $accountLimits
            dailyUpdate: $dailyUpdate
            endOfTest: $endOfTest
            monthlyExport: $monthlyExport
            newsletter: $newsletter
            receipt: $receipt
            snapshotRecommendation: $snapshotRecommendation
            trialEnded: $trialEnded
            weeklyExport: $weeklyExport
            weeklyUpdate: $weeklyUpdate
          )
        }
      `,
      {
        accountLimits,
        dailyUpdate,
        endOfTest,
        monthlyExport,
        newsletter,
        receipt,
        snapshotRecommendation,
        trialEnded,
        weeklyExport,
        weeklyUpdate,
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({ ...globalFeatureKeys.me });
  },
});

export const sendAddonDetailEmailMutation = ({ client }) => ({
  mutationFn: ({ email }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SendAddonDetailEmail($email: SimpleEmailParams!) {
          sendAddonDetailEmail(email: $email) {
            success
            email {
              subject
              from
              to
              message
              userId
            }
          }
        }
      `,
      {
        email,
      },
    ),
});
