/* eslint-disable no-restricted-syntax, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import PlayerAPI from '@crazyegginc/video-api';
import useResizeObserver from 'use-resize-observer';
import { useMachine, useSelector } from '@xstate5/react';
// import { createBrowserInspector } from '@statelyai/inspect';
import { fromPromise, fromCallback } from 'xstate5';

import { getParam as getParamSimple } from '/src/utils/location';

import { SINGLE_SITE_QUERY } from '/src/features/_global/queries';
import { RECORDINGS_SITES_QUERY } from '/src/features/recordings/queries';

import {
  usePermissions,
  useMutation,
  useUrqlClient,
  useAuthContext,
  useQueryParams,
  useSite,
  useModal,
  useThrottle,
} from '/src/hooks';
import { recordingsPlayerCheck } from '/src/utils/compatibility-check';
import { deleteParam, setParam } from '/src/utils/location';
import { useFilter } from '../player-filter-context';
import { useMocky, mockyPlayerConfig } from '/src/features/commenting/mocky';

import { getPlayerPlaylistQueryParams } from '/src/utils/url';
import { isE2E, isPreview } from '/src/utils';

import { FilterProvider } from '../recordings-filter-context';
import { PlayerSessionError } from '/src/features/recordings/components/player/modals/PlayerSessionError';
import { PrivacyExtensionModal } from '/src/features/recordings/components/player/modals/PrivacyExtensionModal';
import { SelectNextAction } from '/src/features/recordings/components/player/SelectNextAction';
import { VisitorIdProvider } from '/src/features/visitor-panel/visitor-id-context';

import { teamMembersQuery } from '/src/features/_global/queries';
import { recordingQuery } from '/src/features/recordings/queries';
import { watchRecordingMutation } from '/src/features/recordings/mutations';

import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as PauseIcon } from '@crazyegginc/hatch/dist/images/icon-pause-filled.svg';
import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-expand.svg';
import { ReactComponent as ReduceIcon } from '@crazyegginc/hatch/dist/images/icon-reduce.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';
import LoaderGif from '@crazyegginc/hatch/dist/animations/loader-circle.gif';

import { FEATURES } from '/src/features/_global/constants';
import {
  PLAYER_EVENTS,
  PLAYER_FORM_SUBEVENTS,
  PLAYER_ECOMMERCE_SUBEVENTS,
  PLAYER_END_EVENT_REASONS,
} from '/src/features/recordings/constants';

import { SpeedButton } from '../components/player/SpeedButton';
import { PlayerTimeline } from '../components/player/PlayerTimeline';
import { Sidebar } from '../components/player/Sidebar';
import { Tabs } from '../components/player/Tabs';
import { PlayerHeader } from '../components/player/PlayerHeader';
import { PlayerControls } from '../components/player/PlayerControls';
import { decomposeFilter } from '../components/recordings-filter/filter-functions';
import { DevTools } from '../components/player/DevTools';
import { isProduction, formatCurrency } from '/src/utils';
import { PlayerPaywalls, RenderPlayerWall } from '../components/player/paywalls/RenderPlayerWall';

import { playerMachine } from '../machines/player';

// const { inspect } = createBrowserInspector();

const SCROLL_GAP = 10000;

const {
  PAGE,
  MOUSEDOWN,
  SCROLL,
  BACKGROUNDED,
  PAGE_LOAD,
  RESIZE,
  VISIT,
  END,
  ERROR_EVENT,
  TAB_SWITCH,
  FORM,
  ECOMMERCE,
  SURVEY_RESPONSE,
  GOAL_CONVERSION,
  QUICK_BACK,
  NAVIGATED_BACK,
} = PLAYER_EVENTS;

const { FORM_SUBMIT, FORM_RESUBMIT, FORM_ABANDON, FORM_SIGNUP, FORM_LOGIN, FORM_EMAIL, FORM_SEARCH } =
  PLAYER_FORM_SUBEVENTS;

const { ECOMMERCE_ADD_TO_CART, ECOMMERCE_CHECKOUT_START, ECOMMERCE_CHECKOUT_COMPLETE } = PLAYER_ECOMMERCE_SUBEVENTS;

const supportedEvents = [
  PAGE,
  MOUSEDOWN,
  SCROLL,
  BACKGROUNDED,
  PAGE_LOAD,
  RESIZE,
  VISIT,
  END,
  ERROR_EVENT,
  TAB_SWITCH,
  FORM,
  ECOMMERCE,
  SURVEY_RESPONSE,
  GOAL_CONVERSION,
  QUICK_BACK,
  NAVIGATED_BACK,
];

function debounceThrottle(func, wait) {
  let timeout;
  let lastCall = 0;

  return function (...args) {
    const now = Date.now();

    const later = () => {
      lastCall = now;
      timeout = null;
      func.apply(this, args);
    };

    const remaining = wait - (now - lastCall);

    clearTimeout(timeout);

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      lastCall = now;
      func.apply(this, args);
    } else {
      timeout = setTimeout(later, remaining);
    }
  };
}

function generateEventSearchMeta(event) {
  return getTags().filter(Boolean);

  function getTags() {
    switch (event.type) {
      case PAGE:
        return [PAGE, 'visit', 'visit page', 'page visit', event.title, event.url];
      case MOUSEDOWN:
        return [MOUSEDOWN, 'click', event.rage && 'rage click', event.dead && 'dead click', event.selector];
      case SCROLL:
        return [SCROLL, 'scrolled'];
      case BACKGROUNDED:
        return [BACKGROUNDED];
      case PAGE_LOAD:
        return [PAGE, 'pageload', 'page load', 'Time to interactive', 'DOM content loaded', 'Content page loaded'];
      case RESIZE:
        return [RESIZE, event.size];
      case VISIT:
        return [VISIT];
      case END:
        return [
          END,
          'recording ended',
          event.reason === PLAYER_END_EVENT_REASONS.INACTIVITY && 'Inactivity',
          event.reason === PLAYER_END_EVENT_REASONS.CLOSED && 'Abandoned page',
        ];
      case ERROR_EVENT:
        return [ERROR_EVENT, 'bug', event.fingerprint];
      case TAB_SWITCH:
        return [TAB_SWITCH, 'tabswitch', 'tab switch', 'switch tab', event.title, event.url];
      case FORM:
        switch (event.sub_event_type) {
          case FORM_SUBMIT:
            return [FORM_SUBMIT, 'submit form', 'form submit'];
          case FORM_RESUBMIT:
            return [FORM_RESUBMIT, 'resubmit form', 'form resubmit'];
          case FORM_ABANDON:
            return [FORM_ABANDON, 'abandon form', 'form abandon'];
          case FORM_SIGNUP:
            return [FORM_SIGNUP, 'signup form', 'form signup', 'sign up', 'register'];
          case FORM_LOGIN:
            return [FORM_LOGIN, 'login form', 'form login', 'log in', 'sign in'];
          case FORM_EMAIL:
            return [FORM_EMAIL, 'email form', 'form email', 'e-mail', 'enter email'];
          case FORM_SEARCH:
            return [FORM_SEARCH, 'search form', 'form search', 'searching'];
        }
        break;
      case ECOMMERCE:
        switch (event.sub_event_type) {
          case ECOMMERCE_ADD_TO_CART:
            return [
              ECOMMERCE_ADD_TO_CART,
              'add to cart',
              event.product_name && event.quantity && `${event.quantity} x ${event.product_name}`,
              `${formatCurrency(event.price, event.currency)} ${event.currency}`,
            ];
          case ECOMMERCE_CHECKOUT_START:
            return [ECOMMERCE_CHECKOUT_START, 'checkout start', 'start checkout'];
          case ECOMMERCE_CHECKOUT_COMPLETE:
            return [ECOMMERCE_CHECKOUT_COMPLETE, 'checkout complete', 'complete checkout'];
        }
        break;
      case SURVEY_RESPONSE:
        return [SURVEY_RESPONSE, 'survey response'];
      case GOAL_CONVERSION:
        return [GOAL_CONVERSION, 'goal conversion'];
      case QUICK_BACK:
        return [QUICK_BACK, 'quick back', 'quickback'];
      case NAVIGATED_BACK:
        return [NAVIGATED_BACK, 'navigated back', 'navigatedback'];
    }
  }
}

function MobilePlayButton({ playing, onClick }) {
  return (
    <button
      className={classNames(
        playing ? 'opacity-0' : 'opacity-100',
        'absolute bottom-0 left-0 right-0 top-0 z-10 m-auto block h-16 w-16 rounded bg-woodsmoke-500 transition-opacity duration-150 ease-in-out md:hidden',
      )}
      onClick={onClick}
    >
      <PlayIcon aria-label="play" className="mx-auto h-6 w-6 fill-current text-white" />
      <span className="sr-only">play video</span>
    </button>
  );
}

function VideoActionButton({ actorRef, onClick = null }) {
  const lastAction = useSelector(actorRef, (state) => state.context.lastAction);
  return (
    <button
      className={classNames(
        'absolute bottom-0 left-0 right-0 top-0 z-10 m-auto h-16 w-16 rounded bg-woodsmoke-500 transition-opacity duration-150 ease-in-out',
        { 'hidden md:block': lastAction, hidden: !lastAction },
      )}
      onClick={onClick}
    >
      {lastAction === 'play' ? (
        <>
          <PlayIcon className="mx-auto hidden h-6 w-6 fill-current text-white md:block" />
          <span className="sr-only">played video</span>
        </>
      ) : null}
      {lastAction === 'pause' ? (
        <>
          <PauseIcon className="mx-auto hidden h-6 w-6 fill-current text-white md:block" />
          <span className="sr-only">paused video</span>
        </>
      ) : null}
    </button>
  );
}

function LoadingSpinner() {
  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 z-10 m-auto flex h-28 w-28 items-center justify-center rounded-xl bg-woodsmoke-500 bg-opacity-95 shadow-lg transition-opacity duration-150 ease-in-out">
      <img src={LoaderGif} alt="" role="presentation" className="h-10 w-10" />
      <span className="sr-only">video is currently loading</span>
    </div>
  );
}

function updateCommentButtonPosition({ top, left }) {
  window.Mocky?.updatePosition({ top: `${top}px`, right: `${document.documentElement.clientWidth - (left - 6)}px` });
}

export default function PlayerWrapper() {
  return (
    <FilterProvider>
      <Player />
    </FilterProvider>
  );
}

function getRecordingHash() {
  return window.location.pathname.split('/')[2];
}

function getPlayerDomain() {
  if (window.ENVIRONMENT === 'staging' || window.location.search.indexOf('staging') > 0) {
    return window.STAGING_PLAYER_URL;
  }

  return window.PLAYER_URL;
}

function processEvents(storedEvents) {
  const unsupportedEvents = [];
  const outOfBoundsEvents = [];

  const events = storedEvents
    .filter((event) => {
      if (event.timestamp < 0) return false;
      if (supportedEvents.includes(event.type)) return true;
      unsupportedEvents.push(event);
      return false;
    })
    .reduce((acc, ev) => {
      // collapse consecutive scroll events
      const lastEvent = acc[acc.length - 1];
      if (
        lastEvent &&
        lastEvent.type === SCROLL &&
        ev.type === SCROLL &&
        ev.timestamp - lastEvent.timestamp < SCROLL_GAP
      ) {
        return acc;
      }
      return [...acc, ev];
    }, []);

  window.unsupportedEvents = [...unsupportedEvents];
  window.outOfBoundsEvents = [...outOfBoundsEvents];

  const parsedEvents = [];

  for (const [idx, event] of events.entries()) {
    if (event.type === VISIT || event.type === PAGE || event.type === TAB_SWITCH) {
      parsedEvents.push({ ...event, tags: generateEventSearchMeta(event), events: [] });
    } else {
      // push into last added page's events
      const endTimestamp = events?.[idx + 1]?.timestamp ?? events[idx].timestamp + 1000;
      // only push if the previous element exists
      parsedEvents[parsedEvents.length - 1]?.events?.push?.({
        ...event,
        endTimestamp,
        tags: generateEventSearchMeta(event),
      });
    }
  }

  return [events, parsedEvents];
}

const debouncedThrottleSetSearchParam = debounceThrottle(setParam, 1000);

const playerLogic = fromCallback(({ sendBack, receive, input }) => {
  const mode = getParamSimple('mode', 'production');
  const startTime = Date.now();

  if (window.CE2?.timing && isProduction()) {
    window.CE2.timing.start('recordings_player');
  }

  const { recording, ticks, speed, skipPauses, explicitAgent } = input;
  const player = new PlayerAPI({
    mode: isE2E() ? 'mock' : mode,
    playerDomain: getPlayerDomain(),
    authToken: recording.sessionToken,
    userId: parseInt(recording.userId),
    sessionId: recording.sessionId,
    fetchJwt: () => {
      return recording.sessionToken;
    },
    target: document.querySelector('#cePlayer'),
    speed,
    skipPauses: Boolean(skipPauses),
    explicitAgent,
  });

  if (ticks) {
    player.seek(ticks);
  }

  receive((event) => {
    switch (event.type) {
      case 'PLAY':
        player.play();
        break;
      case 'PAUSE':
        player.pause();
        break;
      case 'DONE':
        break;
      case 'SEEK':
        player.seek(event.ticks);
        break;
      case 'SET_SPEED':
        player.speed(event.speed);
        break;
      case 'SET_SKIP_PAUSES':
        player.skipPauses(event.skipPauses);
        break;
    }
  });

  function onTick(elapsedTime) {
    if (player) {
      sendBack({ type: 'TICK', ticks: elapsedTime });
    }
    debouncedThrottleSetSearchParam('s', parseInt(elapsedTime / 1000));
  }

  async function onReady() {
    const metadata = await player.metadata();
    const events = await player.events();

    const [validEvents, groupedEvents] = processEvents(events);

    // Check total numbers of events to show in Dev Tools button in the header, for the actual events, will be using same logic as sidebar
    const devToolsEventsCount = groupedEvents.filter((event) => event.type === 'error').length;

    const payload = {
      duration: metadata.duration,
      rawEvents: events,
      events: validEvents,
      groupedEvents,
      devToolsEventsCount,
    };

    sendBack({ type: 'READY', payload });

    /* START: player load time metric */
    const endTime = Date.now();
    const totalTime = endTime - startTime;
    const loadTimeRatio = totalTime / metadata.duration;

    try {
      if (import.meta.env.PROD && !isPreview()) {
        fetch('https://app.crazyegg.com/recordings/stats?ratio=' + loadTimeRatio, { method: 'POST', mode: 'cors' });
      }
    } catch (err) {
      // noop
    }
    /* END: player load time metric */

    if (window.CE2?.timing && isProduction()) {
      try {
        window.CE2.timing.stop('recordings_player');
      } catch {
        // noop - prevent app from crashing when metrics throw error
      }
    }
  }

  player.on('tick', onTick);

  player.on('ready', onReady);

  player.on('loading', (percentage) => {
    sendBack({ type: 'PLAYER_LOADING', percentage });
  });

  player.on('playbackDone', () => sendBack({ type: 'DONE' }));

  player.on('tabs:changed', ({ active_tab, tabs }) => {
    sendBack({ type: 'SET_TABS', active_tab, tabs });
  });

  player.on('error', (playerError) => {
    sendBack({ type: 'PLAYER_ERROR', error: playerError });
  });

  player.load();

  // cleanup function when actor is stopped
  return () => {
    player.destroy();
  };
});

function getErrorWall(type) {
  switch (type) {
    case 'notFound':
      return PlayerPaywalls.RecordingNotFound;
    case 'general':
      return PlayerPaywalls.GeneralError;
    case 'noStates':
      return PlayerPaywalls.GeneralError;
    default:
      return null;
  }
}

function getErrorModal(type, props) {
  switch (type) {
    case 'noStates':
      return <PlayerSessionError {...props} />;
    default:
      return null;
  }
}

function Player() {
  const { isSharing, currentUser, sessionInfo, sharedResource } = useAuthContext();
  const queryClient = useQueryClient();

  const isImpersonated = sessionInfo?.isImpersonated ?? false;

  const { selectSite } = useSite({
    sitesQuery: isSharing ? SINGLE_SITE_QUERY : RECORDINGS_SITES_QUERY,
    onlySelectFromQueryParam: true,
    sitesQueryProps: isSharing ? { variables: { id: sharedResource.resource.siteId } } : undefined,
  });

  const { mutateAsync: watchRecordingMutate } = useMutation(watchRecordingMutation);

  const [playerState, , actorRef] = useMachine(
    playerMachine.provide({
      actions: {
        watchRecording: async ({ context }) => {
          if (isSharing || isImpersonated) return false;

          const res = await watchRecordingMutate({ recordingId: context.recording.id });

          return res.data?.watchRecording ?? false;
        },
      },
      actors: {
        loadRecording: fromPromise(async () => {
          try {
            const res = await queryClient.fetchQuery({ ...recordingQuery({ hashedId: getRecordingHash() }) });
            if (res?.recording) {
              if (!isSharing) {
                selectSite(res.recording.siteId);
              }
              return { ...res.recording.recording };
            }
          } catch (err) {
            throw err.message.replace('[GraphQL]', '').trim();
          }
        }),
        playerLogic,
      },
    }),
    {
      input: {
        explicitAgent: currentUser?.settings?.explicitAgent,
      },
      // inspect,
    },
  );

  const ticks = useSelector(actorRef, (state) => state.context.ticks);
  const skipPauses = useSelector(actorRef, (state) => state.context.skipPauses);
  const recordingHash = useSelector(actorRef, (state) => state.context.recordingHash);
  const recording = useSelector(actorRef, (state) => state.context.recording);
  const duration = useSelector(actorRef, (state) => state.context.duration);
  const active_tab = useSelector(actorRef, (state) => state.context.active_tab);
  const tabs = useSelector(actorRef, (state) => state.context.tabs);
  const autoPlay = useSelector(actorRef, (state) => state.context.autoPlay);
  const isDevToolsOpen = useSelector(actorRef, (state) => state.context.showDevtools);
  const fullScreen = useSelector(actorRef, (state) => state.context.fullScreen);
  const mockyOpen = useSelector(actorRef, (state) => state.context.mockyOpen);
  const playerLoading = useSelector(actorRef, (state) => state.context.loading);
  const playlist = useSelector(actorRef, (state) => state.context.playlist);

  const isPlaying = playerState.matches({ initialized: { active: 'playing' } });
  const isPaused = playerState.matches({ initialized: { active: 'paused' } });
  const isDone = playerState.matches({ initialized: { active: 'done' } });
  const isError = playerState.matches({ initialized: 'error' });

  const errorType = isError && playerState.value?.initialized?.error;

  const playerReady = playerState.matches({ initialized: 'active' });
  const playbackReady = playerState.matches({ initialized: { active: 'ready' } });
  const recordingLoading =
    playerState.matches({ initialized: 'loading' }) || playerState.matches({ initialized: { active: 'loading' } });

  const isPlayable = playbackReady || isPlaying || isPaused || isDone;

  const [paramsRead, setParamsRead] = useState(false);
  const permissions = usePermissions();
  const canUseCommenting = permissions.can('navigate', FEATURES.COMMENTING).allowed;
  const { client } = useUrqlClient();
  const navigate = useNavigate();
  const playerContainerRef = useRef(null);
  const { get: getParam } = useQueryParams();
  const group = getParam('group');
  const { setFilters, recordingsCriteriaDefinitionQuery, playlistsListQuery, filtersVersion, queryParams, filters } =
    useFilter();

  const { data: recordingsCriteriaDefinition } = !isSharing ? recordingsCriteriaDefinitionQuery : { data: null };
  const { data: playlistsListData } = !isSharing ? playlistsListQuery : { data: null };

  const modal = useModal();

  const navigateToAnotherRecording = useCallback(
    (nextRecording) => {
      deleteParam('s');

      // only navigate to another recording now
      navigate(
        {
          pathname: nextRecording ? `/recordings/${nextRecording.hashedId}/player` : `/recordings`,
          search: getPlayerPlaylistQueryParams(),
        },
        { replace: true },
      );
    },
    [navigate],
  );

  const togglePlaying = useCallback(() => {
    actorRef.send({ type: 'TOGGLE_PLAYING' });
  }, [actorRef]);

  const seekVideo = useCallback(
    (ticks) => {
      actorRef.send({ type: 'SEEK', ticks });
    },
    [actorRef],
  );

  useEffect(() => {
    if (!paramsRead) {
      if (recordingsCriteriaDefinition?.definitions && playlistsListData?.playlistsList) {
        if (getParam('filters')) {
          const { conditions, playlist } = decomposeFilter({
            filtersParam: getParam('filters'),
            definitions: recordingsCriteriaDefinition?.definitions,
            playlists: playlistsListData.playlistsList,
            filtersVersion,
            client,
            currentUser,
          });

          if (conditions) {
            if (playlist) {
              setFilters(JSON.parse(playlist.filter), playlist, conditions);
            } else {
              setFilters(JSON.parse(getParam('filters')), null, conditions);
            }
          }
        }
        setParamsRead(true);
      }
    }
  }, [
    paramsRead,
    getParam,
    setFilters,
    filtersVersion,
    client,
    recordingsCriteriaDefinition,
    playlistsListData,
    currentUser,
  ]);

  const replayRecording = useCallback(() => {
    actorRef.send({ type: 'REPLAY' });
  }, [actorRef]);

  const playNextRecording = useCallback(
    (nextRecording) => {
      navigateToAnotherRecording(nextRecording);

      actorRef.send({ type: 'CHANGE_RECORDING', hashedId: nextRecording.hashedId });
    },
    [actorRef, navigateToAnotherRecording],
  );

  const [mockyReady, setMockyReady] = useState(false);

  useEffect(() => {
    window.playerCompatStatus = window.playerCompatStatus || 'idle';
    async function runCompatCheck() {
      window.playerCompatStatus = 'running';
      // eslint-disable-next-line
      console.log('Running player compat check...');

      try {
        const compatCheck = await recordingsPlayerCheck();

        if (compatCheck.privacyExtensions?.result === 'client_error') {
          modal.show(<PrivacyExtensionModal />);
        }

        window.playerCompatReport = compatCheck;
      } catch (error) {
        console.error('Player compat check failed!');
        window.playerCompatReport = null;
      } finally {
        window.playerCompatStatus = 'idle';
      }
    }

    if (window.playerCompatStatus === 'idle') {
      runCompatCheck();
    }
  }, [modal]);

  const { data: teamMembersData } = useQuery({
    ...teamMembersQuery({ siteId: recording?.siteId }),
    enabled: Boolean(!isSharing && recording?.siteId),
  });

  const pause = useCallback(() => {
    actorRef.send({ type: 'PAUSE' });
  }, [actorRef]);

  useEffect(() => {
    window.addEventListener('player:pause', pause);

    return () => window.removeEventListener('player:pause', pause);
  }, [pause]);

  const throttledTicks = useThrottle(ticks, 500);

  const [timestampListWithComments, setTimestampListWithComments] = useState([]);
  const [lastLoadedCommentTimestamp, setLastLoadedCommentTimestamp] = useState(null);
  const [mockyEnabled, setMockyEnabled] = useState(false);
  const [mockyOverlayStyle, setMockyOverlayStyle] = useState({ left: 0, top: 0 });
  const mockyConfig = useMemo(() => mockyPlayerConfig(teamMembersData), [teamMembersData]);
  useMocky(
    mockyConfig,
    useCallback(() => setMockyReady(true), []),
    useCallback(() => setMockyReady(true), []),
    playerReady && teamMembersData && canUseCommenting,
  );
  // re-renders when changes
  useResizeObserver({ ref: playerContainerRef });
  const boundingRect = playerContainerRef?.current?.getBoundingClientRect();

  useEffect(() => {
    if (mockyReady && !isError) {
      // update border, overlay and button position if playerContainerRef size changed, and when mocky is ready
      if (boundingRect?.top) {
        const top = `${boundingRect.top}px`;
        const left = `${boundingRect.left}px`;
        const bottom = `${window.innerHeight - (boundingRect.top + boundingRect.height)}px`;
        const right = `${document.documentElement.clientWidth - (boundingRect.left + boundingRect.width)}px`;
        window.Mocky?.updateBorderPosition({ top, left, bottom, right });
        setMockyOverlayStyle({ top, left, bottom, right });

        const actionButtons = document.querySelector('#actionButtons');
        updateCommentButtonPosition(actionButtons.getBoundingClientRect());
      }
    }
  }, [mockyReady, boundingRect?.top, boundingRect?.left, boundingRect?.width, boundingRect?.height, isError]);

  useEffect(() => {
    async function run() {
      if (mockyEnabled && window.Mocky) {
        const url = new URL(window.location);
        const search = new URLSearchParams(url.search);
        if (search.get('mcid')) {
          // start with comment open
          search.delete('mcid');
          url.search = search;
          window.history.replaceState(undefined, undefined, url);
          setLastLoadedCommentTimestamp(throttledTicks);
        } else if (timestampListWithComments.includes(throttledTicks)) {
          if (lastLoadedCommentTimestamp !== throttledTicks) {
            // current timestamp has comment, so pause and load comment
            pause();
            window.history.replaceState(undefined, undefined, `#${throttledTicks}`);
            setLastLoadedCommentTimestamp(throttledTicks);
            await window.Mocky?.reloadComments();
          }
        } else if (lastLoadedCommentTimestamp) {
          // current timestamp does NOT have comment, but we had one loaded before, so refresh
          if (lastLoadedCommentTimestamp !== throttledTicks) {
            url.hash = '';
            window.history.replaceState(undefined, undefined, url);
            setLastLoadedCommentTimestamp(null);
            await window.Mocky?.reloadComments();
          }
        }
      }
    }
    run();
  }, [throttledTicks, mockyEnabled, timestampListWithComments, pause, lastLoadedCommentTimestamp]);

  useEffect(() => {
    function onNewComment() {
      // when a new comment dialog opens, we pause the player and append necessary params to the url
      pause();
      setTimeout(() => {
        const search = new URLSearchParams(window.location.search);
        search.set('autostart', 0);
        window.history.replaceState(undefined, undefined, `?${search.toString()}#${search.get('s')}`);
        setLastLoadedCommentTimestamp(Number(search.get('s')));
      }, 500);
    }
    async function oncommentUpdate() {
      // on init, or when a comment is added/deleted we need to refetch the comment list
      const url = new URL(window.location);
      url.hash = '';
      const data = (await window.Mocky.getNotifications({ type: 'comment', url: url.toString() })) ?? [];
      const timestamps = data.map((x) => Number(new URL(x.url).hash.slice(1)));
      setTimestampListWithComments(timestamps);
      setLastLoadedCommentTimestamp(Number(new URLSearchParams(url.search).get('s')));
    }
    function onEnabled() {
      setMockyOverlayStyle((s) => ({ ...s, pointerEvents: undefined }));
      setMockyEnabled(true);
    }
    function onDisabled() {
      setMockyOverlayStyle((s) => ({ ...s, pointerEvents: 'none' }));
      setMockyEnabled(false);
    }
    if (mockyReady && window.Mocky) {
      window.Mocky?.on('newComment', onNewComment);
      window.Mocky?.on('enabled', onEnabled);
      window.Mocky?.on('commentUpdate', oncommentUpdate);
      window.Mocky?.on('disabled', onDisabled);

      return () => {
        window.Mocky?.off('newComment', onNewComment);
        window.Mocky?.off('enabled', onEnabled);
        window.Mocky?.off('commentUpdate', oncommentUpdate);
        window.Mocky?.off('disabled', onDisabled);
      };
    }
  }, [mockyReady, pause]);

  useHotkeys('k', () => togglePlaying(), []);
  useHotkeys('space', () => togglePlaying(), []);
  useHotkeys('j', () => seekVideo(ticks - 10000), [ticks]);
  useHotkeys('l', () => seekVideo(ticks + 10000), [ticks]);
  useHotkeys(',', () => !isPlaying && seekVideo(ticks + 60), [ticks]);
  useHotkeys('.', () => !isPlaying && seekVideo(ticks - 60), [ticks]);
  useHotkeys('home', () => seekVideo(0), []);
  useHotkeys('shift+h+a', () => window.open('https://youtu.be/dQw4w9WgXcQ', '_blank'), []);
  useHotkeys('0', () => seekVideo(0), []);
  useHotkeys('1', () => seekVideo(0.1 * duration), [duration]);
  useHotkeys('2', () => seekVideo(0.2 * duration), [duration]);
  useHotkeys('3', () => seekVideo(0.3 * duration), [duration]);
  useHotkeys('4', () => seekVideo(0.4 * duration), [duration]);
  useHotkeys('5', () => seekVideo(0.5 * duration), [duration]);
  useHotkeys('6', () => seekVideo(0.6 * duration), [duration]);
  useHotkeys('7', () => seekVideo(0.7 * duration), [duration]);
  useHotkeys('8', () => seekVideo(0.8 * duration), [duration]);
  useHotkeys('9', () => seekVideo(0.9 * duration), [duration]);
  useHotkeys(
    'f',
    () => {
      actorRef.send({ type: 'TOGGLE_FULL_SCREEN' });
    },
    [actorRef],
  );
  useHotkeys(
    'esc',
    () => {
      if (fullScreen) {
        actorRef.send({ type: 'TOGGLE_FULL_SCREEN' });
      }
    },
    [actorRef, fullScreen],
  );
  useHotkeys('ctrl+d+t', () => toggleDevTools(), []);

  const onSeek = useCallback(
    (relativePosition, { isTimestamp } = { isTimestamp: false }) => {
      actorRef.send({ type: 'SEEK', ticks: isTimestamp ? relativePosition : relativePosition * duration });
    },
    [duration, actorRef],
  );

  const toggleSkipPauses = useCallback(() => {
    actorRef.send({ type: 'TOGGLE_SKIP_PAUSES' });
  }, [actorRef]);

  const toggleAutoPlay = useCallback(() => {
    actorRef.send({ type: 'TOGGLE_AUTO_PLAY' });
  }, [actorRef]);

  const toggleDevTools = useCallback(() => {
    actorRef.send({ type: 'TOGGLE_DEVTOOLS' });
  }, [actorRef]);

  const hasMultipleTabs = useMemo(() => tabs.length > 1, [tabs]);

  return (
    <div className="flex h-screen w-screen flex-col overflow-hidden bg-mako-500 lg:flex-row">
      <main
        className={classNames('relative z-20 flex h-3/5 flex-1 flex-col lg:h-full lg:max-h-screen')}
        aria-label="player interface"
      >
        <PlayerHeader
          actorRef={actorRef}
          currentHash={recordingHash}
          queryParams={queryParams}
          filters={filters}
          navigateToAnotherRecording={navigateToAnotherRecording}
          group={group}
          isError={isError}
          loaded={paramsRead || isSharing}
          mockyReady={mockyReady}
        />

        {!isDone && mockyOpen ? (
          <div id="mocky-player-overlay" className="fixed z-10 opacity-0" style={mockyOverlayStyle} />
        ) : null}

        {isError ? (
          <RenderPlayerError
            actorRef={actorRef}
            type={errorType}
            navigateToAnotherRecording={navigateToAnotherRecording}
            recording={recording}
          />
        ) : null}

        <section className="relative flex flex-1 flex-col gradient-black">
          {recording && hasMultipleTabs && <Tabs tabs={tabs} active_tab={active_tab} />}
          <div className="relative flex w-full flex-1 flex-col" onClick={togglePlaying} id="player-content">
            {isDone ? (
              <section className="absolute inset-0 z-30 flex w-full flex-1 flex-col bg-current">
                <SelectNextAction actorRef={actorRef} onReplay={replayRecording} onNext={playNextRecording} />
              </section>
            ) : null}
            {!isError && (!playerReady || recordingLoading || playerLoading) ? (
              <LoadingSpinner />
            ) : (
              <>
                <MobilePlayButton
                  playing={isPlaying}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlaying();
                  }}
                />
                <VideoActionButton
                  actorRef={actorRef}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlaying();
                  }}
                />
              </>
            )}
            <div
              id="cePlayer"
              className={classNames('relative m-auto flex h-full w-full flex-col gradient-black', {
                'opacity-20': !isPlayable,
              })}
              ref={recording ? playerContainerRef : undefined}
              onClick={(e) => {
                e.stopPropagation();
                togglePlaying();
              }}
            />
          </div>
        </section>
        {recording && isDevToolsOpen && <DevTools actorRef={actorRef} onSeek={onSeek} siteId={recording.siteId} />}

        {!isError && recording ? (
          <div className="relative bottom-0 z-50 h-32 w-full py-3.5 transition duration-150 ease-in-out gradient-black hover:opacity-100 md:h-[83px]">
            <PlayerTimeline
              actorRef={actorRef}
              // seekTime={seeking}
              onSeek={onSeek}
              onDrag={() => {
                actorRef.send({ type: 'DRAG' });
              }}
              onDrop={() => {
                actorRef.send({ type: 'DROP' });
              }}
              // commentTimestamps={timestampListWithComments}
            />

            <div className="relative z-10 h-[30px] md:flex md:items-center md:justify-between">
              <PlayerControls
                togglePlaying={togglePlaying}
                playerReady={isPlayable}
                loading={playerLoading}
                isPlaying={isPlaying}
                // seeking={seeking}
                elapsedTime={ticks}
                playbackTime={duration}
                isDone={isDone}
              />
              <div className="flex h-[30px] justify-center md:justify-end md:pr-3.5 xl:pr-5">
                {playlist.length > 1 ? (
                  <button
                    className="group mx-1.5 flex h-[30px] items-center rounded bg-mako-500 p-2.5 text-xs font-semibold text-cadet-blue-500 transition duration-150 ease-in-out hover:bg-dodger-blue-500 hover:text-white md:ml-0 md:mr-1.5"
                    onClick={toggleAutoPlay}
                  >
                    <TickIcon
                      aria-label="checked"
                      className={classNames(
                        'mx-auto mr-2.5 h-3 w-3 fill-current text-cadet-blue-500 transition duration-150 ease-in-out group-hover:text-white',
                        { 'group-hover:opacity-100': autoPlay },
                        { 'opacity-0 group-hover:opacity-40': !autoPlay },
                      )}
                    />
                    Autoplay
                  </button>
                ) : null}

                <button
                  className="group mr-1.5 flex h-[30px] items-center rounded bg-mako-500 p-2.5 text-xs font-semibold text-mauve-500 transition duration-150 ease-in-out hover:bg-lavender-500 hover:text-white md:pl-4 md:pr-4"
                  onClick={toggleSkipPauses}
                >
                  <TickIcon
                    aria-label="checked"
                    className={classNames(
                      'mx-auto mr-2.5 h-3 w-3 fill-current text-mauve-500 transition duration-150 ease-in-out group-hover:text-white',
                      { 'group-hover:opacity-100': skipPauses },
                      { 'opacity-0 group-hover:opacity-40': !skipPauses },
                    )}
                  />
                  Skip pauses
                </button>

                <SpeedButton
                  onClick={(speed) => {
                    actorRef.send({ type: 'SET_SPEED', speed });
                  }}
                />

                <button
                  className="group invisible hidden h-[30px] w-[30px] items-center justify-center rounded bg-mako-500 p-1 text-xs font-semibold text-white transition duration-150 ease-in-out hover:bg-dodger-blue-500 md:visible md:flex"
                  onClick={() => {
                    actorRef.send({ type: 'TOGGLE_FULL_SCREEN' });
                  }}
                >
                  {fullScreen ? (
                    <>
                      <ReduceIcon className="mx-auto h-5 w-5 fill-current text-cadet-blue-500 transition duration-150 ease-in-out group-hover:text-white" />
                      <span className="sr-only">normal screen</span>
                    </>
                  ) : (
                    <>
                      <ExpandIcon className="mx-auto h-5 w-5 fill-current text-cadet-blue-500 transition duration-150 ease-in-out group-hover:text-white" />
                      <span className="sr-only">full screen</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </main>

      {/* ignore v1 recordings: https://app.shortcut.com/crazyegg/story/24729/ignore-v1-recordings-player */}
      {recording && recording.version !== 1 ? (
        <VisitorIdProvider>
          <Sidebar
            loading={recordingLoading}
            actorRef={actorRef}
            seekTime={null}
            onSeek={onSeek}
            navigateToAnotherRecording={navigateToAnotherRecording}
          />
        </VisitorIdProvider>
      ) : null}
    </div>
  );
}

function RenderPlayerError({ actorRef, type, navigateToAnotherRecording, recording }) {
  const errorWall = getErrorWall(type);
  const ErrorModal = getErrorModal(type, { actorRef, recording, navigateToAnotherRecording });
  const modal = useModal();

  useEffect(() => {
    if (ErrorModal !== null) {
      modal.show(ErrorModal);
    }
  }, [ErrorModal, modal]);

  if (errorWall === null) {
    return null;
  }

  return (
    <RenderPlayerWall
      wall={errorWall}
      actorRef={actorRef}
      recording={recording}
      navigateToAnotherRecording={navigateToAnotherRecording}
    />
  );
}
