import { gql } from '@urql/core';
import { produce } from 'immer';

import { globalFeatureKeys } from '/src/features/_global/queries';
import { optionsFeatureKeys } from './queries';
import { teamAndSharingFeatureKeys } from '/src/features/team-and-sharing/queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';

export const deleteSiteAndAssociatedDataMutation = ({ client, queryClient }) => ({
  mutationFn: ({ siteId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation DeleteSiteAndAssociatedData($siteId: Int!) {
          deleteSiteAndAssociatedData(siteId: $siteId) {
            id
            name
          }
        }
      `,
      {
        siteId,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ ...globalFeatureKeys.capabilities });
    queryClient.invalidateQueries({ queryKey: teamAndSharingFeatureKeys.accountUsers._def });
    return queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
  },
});

export const addSiteMutation = ({ client, queryClient }) => ({
  mutationFn: ({ url }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AddSite($url: String!) {
          addSite(url: $url) {
            id
            name
          }
        }
      `,
      {
        url,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: teamAndSharingFeatureKeys.accountUsers._def });
    return queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
  },
});

export const setRecordingSettings = ({ client, queryClient }) => ({
  mutationFn: ({
    siteId,
    blockedUrlMatchingRules,
    endSessionOnBlockedUrl,
    maskElements,
    pageTargetingEnabled,
    samplingType,
    samplingValue,
    urlMatchingRules,
  }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation SetRecordingSettings(
          $siteId: Int!
          $blockedUrlMatchingRules: String
          $endSessionOnBlockedUrl: Boolean
          $maskElements: String
          $pageTargetingEnabled: Boolean
          $samplingType: SamplingType
          $samplingValue: Float
          $urlMatchingRules: String
        ) {
          setRecordingSettings(
            siteId: $siteId
            blockedUrlMatchingRules: $blockedUrlMatchingRules
            endSessionOnBlockedUrl: $endSessionOnBlockedUrl
            maskElements: $maskElements
            pageTargetingEnabled: $pageTargetingEnabled
            samplingType: $samplingType
            samplingValue: $samplingValue
            urlMatchingRules: $urlMatchingRules
          ) {
            id
            blockedUrlMatchingRules
            endSessionOnBlockedUrl
            maskElements
            pageTargetingEnabled
            samplingType
            samplingValue
            urlMatchingRules
          }
        }
      `,
      {
        siteId,
        blockedUrlMatchingRules,
        endSessionOnBlockedUrl,
        maskElements,
        pageTargetingEnabled,
        samplingType,
        samplingValue,
        urlMatchingRules,
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
  },
});

export const updateBlockedIpsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ipList }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdateBlockedIps($ipList: String!) {
          updateBlockedIps(ipList: $ipList) {
            text
            errors {
              line
              message
            }
          }
        }
      `,
      {
        ipList,
      },
    ),
  onSuccess: () => {
    return queryClient.invalidateQueries({ ...optionsFeatureKeys.options });
  },
});

export const updateUserSettingsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ autoIdentity, dnt, defaultCurrency, explicitAgent, useSecureCookies }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation UpdateUserSettings(
          $autoIdentity: Boolean
          $dnt: Boolean
          $defaultCurrency: String
          $explicitAgent: Boolean
          $useSecureCookies: Boolean
        ) {
          updateUserSettings(
            autoIdentity: $autoIdentity
            dnt: $dnt
            defaultCurrency: $defaultCurrency
            explicitAgent: $explicitAgent
            useSecureCookies: $useSecureCookies
          ) {
            settings {
              autoIdentity
              dnt
              defaultCurrency
              explicitAgent
              useSecureCookies
            }
          }
        }
      `,
      {
        autoIdentity,
        dnt,
        defaultCurrency,
        explicitAgent,
        useSecureCookies,
      },
    ),
  onMutate: async ({ autoIdentity, dnt, defaultCurrency, explicitAgent, useSecureCookies }) => {
    await queryClient.cancelQueries({ ...globalFeatureKeys.me });
    const prevSettings = queryClient.getQueryData(globalFeatureKeys.me.queryKey).me.settings;

    queryClient.setQueryData(globalFeatureKeys.me.queryKey, (old) =>
      old
        ? produce(old, (draft) => {
            if (dnt != null) {
              draft.me.settings.dnt = dnt;
            }
            if (autoIdentity != null) {
              draft.me.settings.autoIdentity = autoIdentity;
            }
            if (defaultCurrency != null) {
              draft.me.settings.defaultCurrency = defaultCurrency;
            }
            if (explicitAgent != null) {
              draft.me.settings.explicitAgent = explicitAgent;
            }
            if (useSecureCookies != null) {
              draft.me.settings.useSecureCookies = useSecureCookies;
            }
          })
        : null,
    );

    return { prevSettings };
  },
  onError: (_, __, context) => {
    queryClient.setQueryData(globalFeatureKeys.me.queryKey, (old) =>
      old && context.prevSettings
        ? produce(old, (draft) => {
            draft.me.settings.dnt = context.prevSettings.dnt;
            draft.me.settings.autoIdentity = context.prevSettings.autoIdentity;
            draft.me.settings.defaultCurrency = context.prevSettings.defaultCurrency;
            draft.me.settings.explicitAgent = context.prevSettings.explicitAgent;
            draft.me.settings.useSecureCookies = context.prevSettings.useSecureCookies;
          })
        : null,
    );
  },
  onSettled: () => {
    queryClient.invalidateQueries({ ...globalFeatureKeys.me });
  },
});
