import classNames from 'classnames';
import { Popover, StyledPopoverPanel, Button, Checkbox, Divider, Indicator } from '@crazyegginc/hatch';

import { toTitleCase } from '/src/utils/string';
import { arraysEqualIgnoreOrder } from '/src/utils';
import { DELETED_AB_TEST, DUPLICATED_AB_TEST, AB_TEST_RATIO_CHANGE, defaultActionsToInclude } from '../constants';

export function ActionSelector({ selectedActions, setSelectedActions }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as={Button}
            variant="secondary"
            size="lg"
            className={classNames('flex w-[170px] items-center justify-between', { '!border-dodger-blue-500': open })}
          >
            <span>Actions</span>
            <Indicator type="dropdown" className="ml-2.5" />
          </Popover.Button>

          <StyledPopoverPanel align="left" className="!w-[240px] space-y-1">
            {() => (
              <>
                <div className="flex items-center justify-between">
                  <button
                    type="button"
                    className="text-sm text-dodger-blue-500 focus-visible:outline-black disabled:cursor-not-allowed disabled:text-cadet-blue-500"
                    disabled={arraysEqualIgnoreOrder(selectedActions, defaultActionsToInclude)}
                    onClick={() => setSelectedActions(defaultActionsToInclude)}
                  >
                    Select all
                  </button>
                  <button
                    type="button"
                    className="text-sm text-dodger-blue-500 focus-visible:outline-black disabled:cursor-not-allowed disabled:text-cadet-blue-500"
                    disabled={selectedActions.length === 0}
                    onClick={() => setSelectedActions([])}
                  >
                    Select none
                  </button>
                </div>

                <Divider className="!-mx-4 !my-2 !w-auto" />

                {defaultActionsToInclude.map((action) => {
                  let actionLabel;
                  switch (action) {
                    case DELETED_AB_TEST:
                      actionLabel = 'Deleted A/B Testing';
                      break;
                    case DUPLICATED_AB_TEST:
                      actionLabel = 'Duplicated A/B Testing';
                      break;
                    case AB_TEST_RATIO_CHANGE:
                      actionLabel = 'A/B Test Ratio Change';
                      break;
                    default:
                      actionLabel = toTitleCase(action.replaceAll('_', ' '));
                      break;
                  }

                  return (
                    <Checkbox
                      key={action}
                      label={actionLabel}
                      id={action}
                      onChange={() =>
                        selectedActions.includes(action)
                          ? setSelectedActions(selectedActions.filter((i) => i !== action))
                          : setSelectedActions([...selectedActions, action])
                      }
                      checked={selectedActions.includes(action)}
                    />
                  );
                })}
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
