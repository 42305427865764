import { gql } from '@urql/core';

import { abTestsFeatureKeys } from './queries';
import { goalsFeatureKeys } from '/src/features/goals/queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';
import { gettingStartedFeatureKeys } from '../getting-started/queries';

export const abTestCreateMutation = ({ client, queryClient }) => ({
  mutationFn: ({ args }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestCreate($args: AbTestCreateInput!) {
          abTestCreate(args: $args) {
            id
          }
        }
      `,
      {
        args,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def, refetchType: 'none' });
    queryClient.invalidateQueries({ ...gettingStartedFeatureKeys.gettingStarted });
    // also clear the goal list queries and detail since they contain ab test counts
    queryClient.invalidateQueries({ queryKey: goalsFeatureKeys.goalList._def });
    // queryClient.invalidateQueries({ ...goalsFeatureKeys.goalDetail({ id: data.abTestCreate.goal.id }) });
    queryClient.invalidateQueries({
      queryKey: ['sites'],
    });
  },
});

export const abTestUpdateMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, args }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestUpdate($id: Int!, $args: AbTestCreateInput!) {
          abTestUpdate(id: $id, args: $args) {
            id
          }
        }
      `,
      { id, args },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestUpdate.id }) });
  },
});

export const abTestDeleteMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestDelete($id: Int!) {
          abTestDelete(id: $id) {
            id
          }
        }
      `,
      { id },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({
      ...abTestsFeatureKeys.abTestDetail({ id: data.abTestDelete.id }),
      refetchType: 'none',
    });
    // also clear the goal list queries and detail since they contain ab test counts
    queryClient.invalidateQueries({ queryKey: goalsFeatureKeys.goalList._def });
    // queryClient.invalidateQueries({ ...goalsFeatureKeys.goalDetail({ id: data.abTestDelete.goalId }) });
  },
});

export const abTestStopMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestStop($id: Int!) {
          abTestStop(id: $id) {
            id
          }
        }
      `,
      { id },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestStop.id }) });
    // also clear the goal list queries and detail since they contain ab test counts
    queryClient.invalidateQueries({ queryKey: goalsFeatureKeys.goalList._def });
    // queryClient.invalidateQueries({ ...goalsFeatureKeys.goalDetail({ id: data.abTestStop.goalId }) });
  },
});

export const abTestPublishMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestPublish($id: Int!) {
          abTestPublish(id: $id) {
            id
          }
        }
      `,
      { id },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def, refetchType: 'none' });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestPublish.id }) });
  },
});

export const abTestChangeGoalMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, goalId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestChangeGoal($goalId: Int!, $id: Int!) {
          abTestChangeGoal(goalId: $goalId, id: $id) {
            id
          }
        }
      `,
      { id, goalId },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestChangeGoal.id }) });
    // also clear the goal list queries since they contain ab test counts
    queryClient.invalidateQueries({ queryKey: goalsFeatureKeys.goalList._def });
    // queryClient.invalidateQueries({ ...goalsFeatureKeys.goalDetail({ id: data.abTestChangeGoal.goalId }) });
  },
});

export const abTestAddVariantsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, variants }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestAddVariants($id: Int!, $variants: [AbTestVariantInput!]!) {
          abTestAddVariants(id: $id, variants: $variants) {
            id
          }
        }
      `,
      { id, variants },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestAddVariants.id }) });
  },
});

export const abTestUpdateVariantsMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, variants }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestUpdateVariants($id: Int!, $variants: [AbTestVariantInput!]!) {
          abTestUpdateVariants(id: $id, variants: $variants) {
            id
          }
        }
      `,
      { id, variants },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestUpdateVariants.id }) });
  },
});

export const abTestRetireVariantMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, variantId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestRetireVariant($id: Int!, $variantId: Int!) {
          abTestRetireVariant(id: $id, variantId: $variantId) {
            id
          }
        }
      `,
      { id, variantId },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestRetireVariant.id }) });
  },
});

export const abTestDeleteVariantMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, variantId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestDeleteVariant($id: Int!, $variantId: Int!) {
          abTestDeleteVariant(id: $id, variantId: $variantId) {
            id
          }
        }
      `,
      { id, variantId },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestDeleteVariant.id }) });
  },
});

export const abTestCheckPageMutation = ({ client }) => ({
  mutationFn: ({ checkType, url }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestCheckPage($checkType: AbTestCheckPageType!, $url: String!) {
          abTestCheckPage(checkType: $checkType, url: $url) {
            domain
            errors
            needToUpdateSiteId
            siteCreationSuccessful
            siteId
          }
        }
      `,
      { checkType, url },
    ),
});

export const abTestSetTrafficSplitMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, autoReweight, variants = [] }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestSetTrafficSplit($id: Int!, $autoReweight: Boolean!, $variants: [AbTestVariantWeightInput!]!) {
          abTestSetTrafficSplit(id: $id, autoReweight: $autoReweight, variants: $variants) {
            id
          }
        }
      `,
      { id, autoReweight, variants },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestSetTrafficSplit.id }) });
  },
});

export const abTestRenameMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, name }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestRename($name: String!, $id: Int!) {
          abTestRename(name: $name, id: $id) {
            id
            name
          }
        }
      `,
      { id, name },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestRename.id }) });
  },
});

export const abTestUpdateDraftMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id, args }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestUpdateDraft($id: Int!, $args: AbTestCreateInput!) {
          abTestUpdateDraft(id: $id, args: $args) {
            id
          }
        }
      `,
      { id, args },
    ),
  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
    queryClient.invalidateQueries({ ...abTestsFeatureKeys.abTestDetail({ id: data.abTestUpdateDraft.id }) });
  },
});

export const abTestDuplicateMutation = ({ client, queryClient }) => ({
  mutationFn: ({ id }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation AbTestDuplicate($id: Int!) {
          abTestDuplicate(id: $id) {
            id
          }
        }
      `,
      { id },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: abTestsFeatureKeys.abTestList._def });
  },
});
