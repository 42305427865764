import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Spinner, Divider } from '@crazyegginc/hatch';

import { snapshotExportsQuery } from '/src/features/snapshots/queries';
import { Modal } from '/src/contexts/modal';
import { formatDateTime } from '/src/utils/date';
import { WebP } from '/src/components/WebP';
import { useSelectedSite, useAuthContext } from '/src/hooks';
import { SNAPSHOT_EXPORT_STATUSES } from '/src/features/snapshots/constants';
import { ALL } from '/src/features/_global/constants';

import { ReactComponent as PageIcon } from '@crazyegginc/hatch/dist/images/icon-page-outline.svg';
import EmptyExportPNG from '@crazyegginc/hatch/dist/images/illustration-snapshot-error.png';
import EmptyExportWebP from '@crazyegginc/hatch/dist/images/illustration-snapshot-error.webp';

export function ExportsListModal({ isExportStatusInURL = false }) {
  const { data: exportsData } = useQuery(snapshotExportsQuery());

  const exportsList = exportsData?.snapshotExports ?? [];
  const exporting = exportsList.filter((item) => item.status === SNAPSHOT_EXPORT_STATUSES.EXPORTING);
  const failed = exportsList.filter((item) => item.status === SNAPSHOT_EXPORT_STATUSES.ERROR);
  const completed = exportsList.filter((item) => item.status === SNAPSHOT_EXPORT_STATUSES.COMPLETED);

  const location = useLocation();
  const navigate = useNavigate();
  const search = new URLSearchParams(location.search);

  return (
    <Modal
      dialogClassName="!px-0 !pb-0 !pt-7 !w-[640px]"
      disableOutsideClick={isExportStatusInURL}
      onDismiss={() => {
        isExportStatusInURL
          ? navigate(
              {
                search: search.toString(),
                hash: '',
              },
              { replace: true },
            )
          : null;
      }}
    >
      {exportsList.length === 0 ? (
        <>
          <div className="flex flex-col items-center px-7 pb-7">
            <div className="mb-10 mt-11 flex w-full justify-center">
              <WebP
                webp={EmptyExportWebP}
                fallback={EmptyExportPNG}
                width="223"
                height="160"
                alt="no exports available"
              />
            </div>
            <div className="text-header-2 mb-6">You don&#39;t have any exported Snapshots</div>
            <div className="text-body-2 mb-10">Exports are deleted after 7 days.</div>
          </div>
          <DownloadLink />
        </>
      ) : (
        <>
          <div className="text-header-3 mb-10 px-7">Your exports</div>
          <div className="overflow-y-auto px-7">
            {exporting.length > 0 && (
              <>
                <div className="text-header-5 mb-1.25">Currently exporting</div>
                <div className="text-body-4 mb-6">
                  Exports usually take 1-2 mins to complete, but Snapshots with high visits may take longer.
                </div>
                {exporting.map((item, idx) => (
                  <div
                    key={`exporting-${item.timestamp}-${idx}`}
                    className="-mx-7 flex h-11 min-w-0 items-center pl-7 pr-16 odd:bg-white-lilac-500"
                  >
                    <div className="mr-3.5 w-4 flex-shrink-0">
                      <Spinner />
                    </div>
                    <div className="text-body-2 flex-1 truncate pr-10">{item.name}</div>
                    <div className="text-body-4 flex-shrink-0">
                      {item.timestamp ? formatDateTime(item.timestamp) : '-'}
                    </div>
                  </div>
                ))}
              </>
            )}

            {failed.length > 0 && (
              <>
                {exporting.length > 0 && <Divider className="-mx-7 my-6 !w-auto" dashed />}
                <div className="text-header-5 mb-6">Failed Exporting</div>
                {failed.map((item, idx) => (
                  <div
                    key={`exporting-${item.timestamp}-${idx}`}
                    className="-mx-7 flex h-11 min-w-0 items-center pl-7 pr-16 odd:bg-white-lilac-500"
                  >
                    <div className="text-body-2 flex-1 truncate pr-10">{item.name}</div>
                    <div className="text-body-4 flex-shrink-0">
                      {item.timestamp ? formatDateTime(item.timestamp) : '-'}
                    </div>
                  </div>
                ))}
              </>
            )}

            {completed.length > 0 && (
              <>
                {(failed.length > 0 || exporting.length > 0) && <Divider className="-mx-7 my-6 !w-auto" dashed />}
                <div className="text-header-5 mb-6">Completed exports</div>
                {completed.map((item, idx) => (
                  <div
                    key={`exporting-${item.timestamp}-${idx}`}
                    className="-mx-7 flex h-11 min-w-0 items-center pl-7 pr-16 odd:bg-white-lilac-500"
                  >
                    <a className="text-link flex-1 truncate pr-10" href={item.url} download>
                      {item.name}
                    </a>
                    <div className="text-body-4 flex-shrink-0">
                      {item.timestamp ? formatDateTime(item.timestamp) : '-'}
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className="min-h-[28px]" />
          </div>
          <DownloadLink />
        </>
      )}
    </Modal>
  );
}

function DownloadLink() {
  const { token } = useAuthContext();
  const { selectedSite } = useSelectedSite();
  const siteIdentifier = selectedSite?.name ?? ALL;

  return (
    <a
      className="text-button flex h-[49px] w-full flex-shrink-0 items-center justify-center rounded-b bg-solitude-500 text-dodger-blue-500 outline-none"
      href={`${window.CORE_API_URL}/export/snapshots.csv?site=${siteIdentifier}&token=${token}`}
      download={true}
    >
      <PageIcon className="mr-2.5 h-4 w-4 fill-current text-dodger-blue-500" />
      Download Snapshot List for {siteIdentifier === ALL ? 'all sites' : siteIdentifier} as CSV
    </a>
  );
}
