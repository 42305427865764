import { useFormikContext, FieldArray } from 'formik';
import { normalizeUrl } from '/src/utils/url';
import classNames from 'classnames';

import { Button, IconButton, Input } from '@crazyegginc/hatch';

import { ReactComponent as CrossIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';
import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

export function SiteSettingsModalListofInputs({
  listData,
  type = 'page',
  placeholder = 'e.g. mysite.com/page',
  buttonName = 'page',
  showSingleDelete = false,
  children,
}) {
  const { values, errors, touched, handleBlur, setFieldValue } = useFormikContext();
  const isTypeCSS = type === 'css';

  const arrOfURLs = (target) => {
    const url = normalizeUrl(target, { appendSlash: false });

    if (target.match(/\*/)) {
      return {
        u: url,
        o: '*',
      };
    } else {
      return {
        u: url,
      };
    }
  };

  const checkLastInputEmpty = () => {
    if (values?.[listData].length > 0) {
      if (isTypeCSS) {
        return values?.[listData][0]?.selector === '';
      } else {
        return values?.[listData].at(-1)?.u === '';
      }
    }
    return false;
  };

  const isDuplicate = typeof errors?.[listData] === 'string';

  return (
    <>
      <FieldArray name={`${listData}`}>
        {(actions) => (
          <>
            {values[listData].map((page, i) => {
              const isInputBoxCSS = isTypeCSS ? errors?.[listData]?.[i]?.selector : errors?.[listData]?.[i]?.u;
              const error = touched?.[listData]?.[i] && isInputBoxCSS ? isInputBoxCSS : null;

              return (
                <div className="relative mb-1.5 flex items-start" key={`${type}-${i}`}>
                  <Input
                    aria-label={`${type} ${i}`}
                    id={`${listData}[${i}]`}
                    name={`${listData}[${i}]`}
                    value={!isTypeCSS ? values[listData][i].u : values[listData][i].selector}
                    onChange={(e) => {
                      if (!isTypeCSS) {
                        setFieldValue(`${listData}[${i}]`, arrOfURLs(e.target.value));
                      } else {
                        setFieldValue(`${listData}[${i}].selector`, e.target.value);
                      }
                    }}
                    error={error}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                  />

                  {(values[listData]?.length > 1 || showSingleDelete) && (
                    <div className="ml-4 flex h-10 w-5 flex-shrink-0 items-center">
                      <IconButton
                        icon={<CrossIcon className="h-3 w-3 fill-current" />}
                        className="flex-shrink-0 text-cadet-blue-500 hover:text-carnation-500"
                        onClick={() => {
                          actions.remove(i);
                        }}
                        label={`delete ${type} ${i}`}
                      />
                    </div>
                  )}
                </div>
              );
            })}
            {isDuplicate && <div className="text-error !mt-0.5">{errors[listData]}</div>}
            <div
              className={classNames('flex items-start justify-between space-x-5 pt-[9px]', {
                'mr-9': !isTypeCSS,
              })}
            >
              <Button
                variant="secondary"
                size={!isTypeCSS ? 'sm' : 'base'}
                onClick={() => {
                  if (!isTypeCSS) {
                    actions.push({ u: '' });
                  } else {
                    actions.push({ selector: '' });
                  }
                }}
                className="!flex-shrink-0"
                disabled={isDuplicate || errors?.[listData] || checkLastInputEmpty()}
              >
                <PlusIcon className="mr-2 h-2.5 w-2.5 fill-current" />
                Add {buttonName}
              </Button>

              {children}
            </div>
          </>
        )}
      </FieldArray>
    </>
  );
}
