import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, endOfDay, subYears } from 'date-fns';
import { DateRangePicker, Spinner } from '@crazyegginc/hatch';

import { usePermissions, useSite, useSelectedSite } from '/src/hooks';
import { getConvertedDateRange, getTimeZoneOffset } from '/src/utils/date';

import { SitePickerBar } from '/src/components/site-picker/index';
import { FunnelProvider, useFunnelContext } from '../funnel-context';
import { InstallationBanner } from '/src/components/banners/InstallationBanner';
import { ManageFilters } from './ManageFilters';
import { ManageSteps } from './ManageSteps';
import { AppliedFilters } from './AppliedFilters';
import { SummaryCards } from './SummaryCards';
import { FunnelBarChart } from './FunnelBarChart';
import { StepCharts } from './StepCharts';

import { STEP_TYPES } from '../constants';
import { goalListMinimalQuery } from '/src/features/goals/queries';
import { FEATURES } from '/src/features/_global/constants';
import { getFeatureName } from '/src/features/_global/utils';
//import { getFakeFunnelData } from '../fake-data';
import { funnelQuery } from '../queries';
import { getTimeUnit } from '../utils';

export function FunnelDashboardContent() {
  const { sites, selectedSite, selectSite, loadingSites } = useSite();
  const permissions = usePermissions();
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  if (loadingSites)
    return (
      <div className="mt-16 flex w-full items-center justify-center">
        <Spinner />
        <div className="ml-2.5">Loading...</div>
      </div>
    );

  return (
    <>
      <SitePickerBar
        sites={sites}
        selectedSite={selectedSite}
        selectSite={selectSite}
        loading={loadingSites}
        addSiteOption={canEditSites}
        pageDisplayName={getFeatureName(FEATURES.LIVE_ACTIVITY)}
      />
      {!loadingSites && selectedSite?.installed ? (
        <FunnelProvider>
          <InstallationBanner />
          <div className="flex flex-col p-10">
            <Funnel />
          </div>
        </FunnelProvider>
      ) : (
        <>
          <InstallationBanner />
          <div className="flex flex-col p-10">Please install Crazy Egg!</div>
        </>
      )}
    </>
  );
}

function Funnel() {
  const { selectedSite } = useSelectedSite();
  const { filters, steps, setDateRange } = useFunnelContext();

  const { data: goalsData, isFetching: fetchingGoals } = useQuery({
    ...goalListMinimalQuery({ siteId: selectedSite?.id, limit: 200 }),
    enabled: Boolean(selectedSite?.id),
  });

  const timeZoneOffset = getTimeZoneOffset();
  const convertedDateRage = getConvertedDateRange(filters.date);
  const timeUnit = getTimeUnit(convertedDateRage);
  const { data, isFetching } = useQuery({
    ...funnelQuery({
      siteId: selectedSite?.id,
      startAt: convertedDateRage.startDate + timeZoneOffset,
      endAt: convertedDateRage.endDate + timeZoneOffset,
      timeUnit,
      steps,
      filters: {
        landingPage: filters.landingPage,
        referrerUrl: filters.referrerUrl,
        utmSource: filters.utmSource,
        utmCampaign: filters.utmCampaign,
        utmMedium: filters.utmMedium,
        utmTerm: filters.utmTerm,
        utmContent: filters.utmContent,
        abTestName: filters.abTestName,
        abTestVariationName: filters.abTestVariationName,
      },
    }),
    enabled: Boolean(selectedSite?.id && steps.length > 1),
  });
  /*const data = getFakeFunnelData({ steps, dateRange: convertedDateRage, timeUnit });
  const isFetching = false;*/

  const summary = useMemo(() => data?.funnel?.summary ?? [], [data]);

  const stepsData = useMemo(
    () =>
      steps.map((step, i) => ({
        ...step,
        name:
          i === 0 && steps[0].type === STEP_TYPES.VISIT
            ? 'Visitors'
            : goalsData?.goalList?.list.find((goal) => goal.id === steps[i]?.id)?.name,
      })),
    [goalsData, steps],
  );

  return (
    <>
      <div className="flex justify-end">
        <DateRangePicker
          lowerBoundary={startOfDay(subYears(new Date(), 2))}
          upperBoundary={endOfDay(new Date())}
          startDate={filters.date?.start_date}
          endDate={filters.date?.end_date}
          special={filters.date?.special}
          setDateRange={setDateRange}
          setCommonDateRange={setDateRange}
          showCommonRanges={true}
          size="lg"
        />
      </div>

      <ManageSteps />
      <ManageFilters />
      <AppliedFilters filters={filters} />

      {isFetching || fetchingGoals ? (
        <div className="mt-16 flex w-full items-center justify-center">
          <Spinner />
          <div className="ml-2.5">Loading...</div>
        </div>
      ) : (
        <>
          {steps.length > 1 && (
            <>
              <SummaryCards values={summary} stepsData={stepsData} />
              <FunnelBarChart values={summary} stepsData={stepsData} />
              <StepCharts stepsData={stepsData} timeUnitResults={data.funnel.timeUnitResults} timeUnit={timeUnit} />
            </>
          )}
        </>
      )}
    </>
  );
}
