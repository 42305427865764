import React from 'react';
import { useCallback, useMemo, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Button, Input, Spinner } from '@crazyegginc/hatch';

import { checkScriptQuery } from '/src/features/options/queries';

import { superLenientUrlRegex } from '/src/utils/regex';
import { devices, DeviceSelect } from './DeviceSelect';
import { DEVICE_TYPES } from '/src/features/_global/constants';
import { getParam } from '/src/utils/location';

import { AutoUpdateUrlInput } from './AutoUpdateUrlInput';
import { RenderResult } from './RenderResult';
import { RenderVisit } from './RenderVisit';

const ScriptCheckSchema = Yup.object().shape({
  url: Yup.string()
    .required('The URL is required.')
    .matches(superLenientUrlRegex, 'This URL is not valid. Please correct it and try again.'),
  device: Yup.string().required().oneOf(devices),
});

export function CheckScript({ onSuccess, onError, origin = null }) {
  const [didRedirect, setDidRedirect] = useState(false);

  const [formData, setFormData] = useState(() => ({
    url: getParam('url'),
    device: getParam('device'),
  }));

  const {
    data,
    error,
    isFetching: fetching,
    refetch: refetchCheckScriptQuery,
  } = useQuery({
    ...checkScriptQuery({ device: formData.device, url: formData.url }),
    enabled: Boolean(formData.device && formData.url),
  });

  const redirectedTo = useMemo(() => data?.checkScript?.finalUrl ?? null, [data?.checkScript]);

  useEffect(() => {
    if (!fetching && data?.checkScript) {
      const result = data.checkScript;
      if (result?.finalUrl && result.finalUrl !== formData.url) {
        setDidRedirect(true);
      }

      if (result.error !== null) {
        return onError?.(data.error);
      }
      return onSuccess?.(data.warnings, data.infos);
    }
  }, [formData.url, fetching, data, onError, onSuccess]);

  const originalUrl = formData?.url;
  const redirected = data?.checkScript?.didRedirect ?? false;
  const finalUrl = data?.checkScript?.finalUrl ?? null;

  const redirectWarning = useCallback(() => {
    if (!originalUrl || !finalUrl || !redirected) return null;

    const parsedOriginal = new URL(originalUrl.startsWith('http') ? originalUrl : `http://${originalUrl}`);
    const parsedFinal = new URL(finalUrl);

    const isSameDomain = parsedOriginal.hostname === parsedFinal.hostname;

    const displayOriginal = isSameDomain
      ? parsedOriginal.pathname !== '/'
        ? parsedOriginal.pathname
        : originalUrl
      : originalUrl;
    const displayFinal = isSameDomain ? (parsedFinal.pathname !== '/' ? parsedFinal.pathname : finalUrl) : finalUrl;

    return (
      <>
        <strong className="text-body-1">{displayOriginal}</strong> redirected to{' '}
        <strong className="text-body-1">{displayFinal}</strong>.{!isSameDomain ? <br /> : ' '}
        Showing results for <strong className="text-body-1">{displayFinal}</strong> instead.
      </>
    );
  }, [originalUrl, finalUrl, redirected]);

  return (
    <>
      <div className="text-body-2 mb-5 leading-snug">
        Enter in the URL of the page you want to check below and click on{' '}
        <strong className="text-body-1">Check URL</strong>. Within a few seconds, we will check the page to make sure
        you have installed Crazy Egg correctly. To check a specific version of your site (eg. mobile version), select
        the appropriate version from the dropdown.
      </div>
      <Formik
        validateOnMount={true}
        initialValues={{ url: getParam('url', ''), device: getParam('device', DEVICE_TYPES.DESKTOP) }}
        validationSchema={ScriptCheckSchema}
        onSubmit={(values, helpers) => {
          setFormData({
            ...values,
          });

          if (data || error) {
            refetchCheckScriptQuery();
          }

          helpers.resetForm({
            values,
          });
        }}
      >
        {({ values, errors, touched, setFieldValue, isValid }) => (
          <Form className="w-full">
            <div className="flex gap-x-2.5">
              <div className="w-full max-w-[600px]">
                <Field name="url">
                  {({ field }) => (
                    <Input
                      size="xl"
                      {...field}
                      error={errors.url && touched.url ? errors.url : null}
                      placeholder="eg. http://example.com"
                    />
                  )}
                </Field>
              </div>

              {didRedirect ? <AutoUpdateUrlInput redirectedTo={redirectedTo} setDidRedirect={setDidRedirect} /> : null}

              <DeviceSelect onChange={(value) => setFieldValue('device', value)} value={values.device} />

              <Button size="xl" type="submit" disabled={!isValid || fetching} className="!flex-shrink-0">
                {fetching ? 'Checking...' : 'Check URL'}
              </Button>
            </div>

            {!fetching && redirected && finalUrl ? <span className="text-error">{redirectWarning()}</span> : null}

            {fetching ? (
              <div className="text-body-2 ml-10 mt-10 flex items-center">
                <Spinner className="mr-3 h-5 w-5 text-cadet-blue-500" />
                Testing installation&hellip;
              </div>
            ) : (
              <div>
                <RenderResult
                  data={data?.checkScript ?? null}
                  error={error}
                  originalUrl={originalUrl}
                  origin={origin}
                />

                <RenderVisit
                  data={data?.checkScript ? data.checkScript : error?.graphQLErrors?.[0]?.originalError ?? error}
                  originalUrl={originalUrl}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
