import { DeviceIcon } from '@crazyegginc/hatch';

import { useMutation, useAuthContext, usePermissions, useVisitorId } from '/src/hooks';
import { getVisitorDisplayName } from '/src/utils/visitor';
import { formatDateTime } from '/src/utils/date';
import { removeProtocol } from '/src/utils/url';
import { Gravatar } from '/src/components/gravatar';
import { DeviceDescription } from '/src/components/DeviceDescription';
import { FilterTagsSelect } from '/src/features/recordings/components/recordings-filter/FilterSelect';

import { addTagsToRecordingsMutation, removeTagsFromRecordingsMutation } from '/src/features/recordings/mutations';

import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as CalendarIcon } from '@crazyegginc/hatch/dist/images/icon-calendar-outline.svg';
import { ReactComponent as PinIcon } from '@crazyegginc/hatch/dist/images/icon-pin-filled.svg';
import { ReactComponent as TagIcon } from '@crazyegginc/hatch/dist/images/icon-tag-outline.svg';
import { ReactComponent as PageIcon } from '@crazyegginc/hatch/dist/images/icon-browser-page-outline.svg';

export function MetadataBox({ response }) {
  const { identifier, visitorId, createdAt, recording, surveyUrl } = response;
  const { selectVisitor } = useVisitorId();
  const { isSharing } = useAuthContext();
  const permissions = usePermissions();

  const canTag = !isSharing && recording && permissions.can('tag', recording).allowed;
  const addTagsToRecordings = useMutation(addTagsToRecordingsMutation);
  const removeTagsFromRecordings = useMutation(removeTagsFromRecordingsMutation);

  const handleTagChange = (tags, { action, removedValue }) => {
    if (canTag) {
      if (action === 'select-option' || action === 'create-option') {
        addTagsToRecordings.mutate({ recordingIds: [recording.id], tags: tags.map((t) => t.value) });
      } else if ((action === 'remove-value' || action === 'pop-value') && removedValue) {
        removeTagsFromRecordings.mutate({ recordingIds: [recording.id], tags: [removedValue] });
      }
    }
  };

  return (
    <div className="w-full rounded border border-mystic-500 bg-white shadow-md">
      <div className="flex h-[75px] items-center space-x-3.75 rounded-t border-b border-mystic-500 bg-white-lilac-500 px-4">
        <div className="flex h-11 w-11 shrink-0 items-center justify-center rounded-full bg-white shadow">
          <Gravatar email={identifier || visitorId} size={32} />
        </div>
        <div className="relative min-w-0">
          <div className="truncate text-base font-semibold leading-tight">
            {getVisitorDisplayName(identifier, visitorId)}
          </div>
          {!isSharing && (
            <button
              type="button"
              className="flex items-center text-xs text-dodger-blue-500 hover:underline focus-visible:outline-black"
              data-testid="visitor_id_link"
              onClick={() => selectVisitor({ identifier: identifier, visitorId: visitorId })}
            >
              View profile <ExpandIcon className="ml-1 h-2 w-2 rotate-90 fill-current" />
            </button>
          )}
        </div>
      </div>
      <div className="space-y-3 p-4">
        <Row Icon={CalendarIcon} text={formatDateTime(createdAt)} />
        {recording && (
          <>
            <div className="text-body-4 flex items-center space-x-2.5">
              {recording.device && <DeviceIcon device={recording?.device} tooltip={false} />}
              {recording.browser && recording.os && recording.screenWidth && recording.screenHeight && (
                <DeviceDescription
                  browser={recording.browser}
                  os={recording.os}
                  screenDimensions={`${recording.screenWidth} x ${recording.screenHeight}`}
                />
              )}
            </div>

            {recording.country && <Row Icon={PinIcon} text={recording.country.name} />}
          </>
        )}

        {surveyUrl && <Row Icon={PageIcon} text={removeProtocol(surveyUrl)} />}

        {recording && (
          <div className="items-top !mt-2.5 flex w-full">
            <TagIcon className="mr-2.5 mt-3 h-4 w-4 fill-current text-cadet-blue-500" />
            <div className="flex-1">
              <FilterTagsSelect
                placeholder="Add tags"
                value={recording.tags}
                onChange={handleTagChange}
                label="tags"
                creatable={true}
                menuPosition="fixed"
                menuPortalTarget={document.body}
                containerStyle={{ width: '100%', minWidth: '0px', maxWidth: '100%' }}
                playerStyle={false}
                inlineStyle={true}
                isDisabled={!canTag}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function Row({ Icon, text }) {
  return (
    <div className="text-body-4 flex items-center space-x-2.5">
      <Icon className="h-4 w-4 shrink-0 fill-current text-cadet-blue-500" />
      <span className="leading-none" style={{ overflowWrap: 'anywhere', wordBreak: 'break-word' }}>
        {text}
      </span>
    </div>
  );
}
