import { useCallback, useEffect, useState } from 'react';
import { atom, useAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { Button, CloseButton as HatchCloseButton, Spinner } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { WizardModal } from './legacy/WizardModal';
import { ConfirmLeaveWizardModal } from './ConfirmLeaveWizardModal';

import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';
import { ReactComponent as CeLogoIcon } from '@crazyegginc/hatch/dist/images/logo-crazy-egg-color.svg';

export const wizardLoadingAtom = atom(false);
export const wizardPreviousRouteAtom = atom(null);
export const wizardStepsAtom = atom(null);
export const wizardProgressAtom = atomWithReset({
  current: 1,
  reached: 1,
});
export const wizardModalAtom = atom(null);

function Wizard({ children }) {
  const [modal, setModal] = useAtom(wizardModalAtom);

  if (modal) {
    return <WizardModal close={() => setModal(null)}>{modal.component}</WizardModal>;
  }

  return <div className="relative mx-auto flex w-screen max-w-[1280px] flex-col pb-[100px]">{children}</div>;
}

function Logo() {
  return <CeLogoIcon className="absolute ml-32 mt-9 h-16 w-[120px]" />;
}

function Header({ children }) {
  return (
    <header className="relative flex w-full">
      <CeLogoIcon className="ml-32 mt-9 h-16 w-[120px]" />
      {children}
    </header>
  );
}

function SimpleFooter({
  showMeta = true,
  title,
  cancelText = 'cancel',
  nextText = 'next',
  onBack = null,
  onNext = null,
  onCancel = null,
  nextEnabled = false,
  totalSteps = 1,
  currentStep = 1,
  loading = false,
  confirmCancel = true,
  nextIcon: NextIcon = null,
  hideNextArrow = false,
  nextDisabledText = null,
  nextLoadingText = null,
  onSkip = null,
}) {
  const modal = useModal();

  const showConfirmModal = useCallback(
    ({ onConfirm = null }) => {
      modal.show(<ConfirmLeaveWizardModal onConfirm={onConfirm} />);
    },
    [modal],
  );

  function renderNextText() {
    if (loading && nextLoadingText) return nextLoadingText;
    if (!nextEnabled && nextDisabledText) return nextDisabledText;
    return nextText;
  }

  return (
    <footer
      className={classNames(
        'fixed inset-x-0 bottom-0 z-[89] grid h-[90px] grid-rows-1 border-t border-mystic-500 bg-white px-8',
        {
          'grid-cols-3': showMeta,
          'grid-cols-2': !showMeta,
        },
      )}
      style={{ boxShadow: '0 -1px 4px rgba(0, 0, 0, 0.1)' }}
      data-testid="footer"
    >
      <div className="flex items-center">
        <div className="mr-2.5 flex">
          {currentStep > 1 && (
            <Button variant="secondary" onClick={onBack} disabled={loading || currentStep <= 1}>
              <ArrowIcon className="mr-2.5 h-2.5 w-2.5 -rotate-90 fill-current" />
              Back
            </Button>
          )}
        </div>
        <Button
          className="underline"
          variant="cancel"
          onClick={() => {
            if (confirmCancel) return showConfirmModal({ onConfirm: onCancel });
            onCancel?.();
          }}
        >
          {cancelText}
        </Button>
      </div>
      {showMeta ? (
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center">
            {[...Array(totalSteps)].map((_, idx, arr) => (
              <div key={`WizardStep-${idx}`} className="relative mr-[45px] last:mr-0">
                {idx <= currentStep - 1 ? (
                  <TickIcon aria-label="check" className="h-[25px] w-[25px] fill-current text-dodger-blue-500" />
                ) : (
                  <div
                    className={classNames(
                      'flex h-[25px] w-[25px] items-center justify-center rounded-full border-[3px] border-cadet-blue-500 bg-white',
                    )}
                  />
                )}
                {idx !== arr.length - 1 && (
                  <div
                    className={classNames('absolute left-[30px] top-1/2 h-[3px] w-[35px] -translate-y-1/2', {
                      'bg-cadet-blue-500': idx >= currentStep - 1,
                      'bg-dodger-blue-500': idx < currentStep - 1,
                    })}
                  />
                )}
              </div>
            ))}
          </div>
          <div aria-label="step title" className="mt-2 whitespace-nowrap text-md text-lynch-500">
            {title}
          </div>
        </div>
      ) : null}
      <div className="relative flex items-center justify-end">
        {onSkip ? (
          <Button
            className="mr-2.5 underline"
            variant="cancel"
            onClick={() => {
              onSkip?.();
            }}
          >
            Skip
          </Button>
        ) : null}
        <Button
          disabled={loading || !nextEnabled}
          onClick={() => {
            onNext?.();
          }}
        >
          {loading ? (
            <span className="mr-2.5">
              <Spinner />
            </span>
          ) : NextIcon ? (
            <NextIcon className="mr-2.5 h-[13px] w-[13px] fill-current" />
          ) : null}
          {renderNextText()}
          {!loading && !hideNextArrow ? <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" /> : null}
        </Button>
      </div>
    </footer>
  );
}

function Footer({
  title,
  nextText = 'next',
  defaultCancelRoute,
  onCancel = null,
  onBack = null,
  onNext = null,
  nextEnabled = false,
  onComplete = null,
}) {
  const modal = useModal();
  const navigate = useNavigate();

  const [loading] = useAtom(wizardLoadingAtom);
  const [progress] = useAtom(wizardProgressAtom);
  const progressReset = useResetAtom(wizardProgressAtom);
  const [previousRoute, setPreviousRoute] = useAtom(wizardPreviousRouteAtom);
  const [steps] = useAtom(wizardStepsAtom);

  // const showConfirmModal = ({ onConfirm = null }) => {
  //   modal.show(<ConfirmLeaveWizardModal onConfirm={() => onConfirm?.()} />);
  // };

  return (
    <footer
      className="fixed inset-x-0 bottom-0 z-[89] grid h-[90px] grid-cols-3 grid-rows-1 border-t border-mystic-500 bg-white px-8"
      style={{ boxShadow: '0 -1px 4px rgba(0, 0, 0, 0.1)' }}
      data-testid="footer"
    >
      <div className="flex items-center">
        <div className="mr-2.5 flex">
          {progress.current > 1 && (
            <Button variant="secondary" onClick={onBack} disabled={loading || progress.current <= 1}>
              <ArrowIcon className="mr-2.5 h-2.5 w-2.5 -rotate-90 fill-current" />
              Back
            </Button>
          )}
        </div>
        <Button
          className="underline"
          variant="cancel"
          onClick={() => {
            if (onCancel) {
              onCancel();
            } else {
              // if (shouldConfirmNavigate()) return showConfirmModal();
              modal.close();
              navigate(previousRoute || defaultCancelRoute, { replace: true });
              progressReset();
              setPreviousRoute('/snapshots');
            }
          }}
        >
          Cancel
        </Button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center">
          {[...Array(steps).keys()].map((_, idx, arr) => (
            <div key={`WizardStep-${idx}`} className="relative mr-[45px] last:mr-0">
              {idx <= progress.current - 1 ? (
                <TickIcon aria-label="check" className="h-[25px] w-[25px] fill-current text-dodger-blue-500" />
              ) : (
                <div
                  className={classNames(
                    'flex h-[25px] w-[25px] items-center justify-center rounded-full border-[3px] border-cadet-blue-500 bg-white',
                  )}
                />
              )}
              {idx !== arr.length - 1 && (
                <div
                  className={classNames('absolute left-[30px] top-1/2 h-[3px] w-[35px] -translate-y-1/2', {
                    'bg-cadet-blue-500': idx >= progress.current - 1,
                    'bg-dodger-blue-500': idx < progress.current - 1,
                  })}
                />
              )}
            </div>
          ))}
        </div>
        <div aria-label="step title" className="mt-2 whitespace-nowrap text-md text-lynch-500">
          {title}
        </div>
      </div>
      <div className="relative flex items-center justify-end">
        <Button
          disabled={loading || !nextEnabled}
          onClick={() => {
            if (steps === progress.current) {
              return onComplete?.();
            }
            onNext?.();
          }}
        >
          {loading && (
            <span className="mr-2.5">
              <Spinner />
            </span>
          )}
          {nextText}
          {!loading && <ArrowIcon className="ml-2.5 h-2.5 w-2.5 rotate-90 fill-current" />}
        </Button>
      </div>
    </footer>
  );
}

function FooterNoSteps({ defaultCancelRoute }) {
  const modal = useModal();
  const navigate = useNavigate();
  const [previousRoute, setPreviousRoute] = useAtom(wizardPreviousRouteAtom);

  return (
    <footer
      className="fixed inset-x-0 bottom-0 z-[89] grid h-[90px] grid-cols-2 grid-rows-1 border-t border-mystic-500 bg-white px-8"
      style={{ boxShadow: '0 -1px 4px rgba(0, 0, 0, 0.1)' }}
      data-testid="footer"
    >
      <div className="flex items-center">
        <Button
          className="underline"
          variant="cancel"
          onClick={() => {
            modal.close();
            navigate(previousRoute || defaultCancelRoute, { replace: true });
            setPreviousRoute('/snapshots');
          }}
        >
          Cancel
        </Button>
      </div>
      <div className="relative flex items-center justify-end">
        <Button disabled={true}>Next</Button>
      </div>
    </footer>
  );
}

function Content({ dontMask = false, children }) {
  return (
    <main className="mb-[115px]" {...(dontMask && { 'ce-dont-mask': '' })}>
      {children}
    </main>
  );
}

function CloseButton({ onClick = null, defaultPath = '/snapshots' }) {
  const navigate = useNavigate();
  const progressReset = useResetAtom(wizardProgressAtom);
  const [previousRoute, setPreviousRoute] = useAtom(wizardPreviousRouteAtom);

  return (
    <HatchCloseButton
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          navigate(previousRoute || defaultPath, { replace: true });
          progressReset();
          setPreviousRoute('/snapshots');
        }
      }}
      iconClass="!w-5 !h-5"
      buttonClass="!w-6 !h-6 !top-16 !right-12"
    />
  );
}

function Title({ children }) {
  return <h1 className="mb-10 mt-14 text-center text-[37px] font-semibold">{children}</h1>;
}

export function useWizardPassesValidation(validationSchema) {
  const { values } = useFormikContext();
  const [passesValidation, setPassesValidation] = useState(null); // `null` means validation didn't run yet

  useEffect(() => {
    async function validePageRequirements() {
      try {
        await validationSchema.validate(values);
        if (passesValidation === null || passesValidation === false) {
          setPassesValidation(true);
        }
      } catch (error) {
        if (passesValidation === null || passesValidation === true) {
          setPassesValidation(false);
        }
      }
    }

    validePageRequirements();
  }, [passesValidation, validationSchema, values]);

  return passesValidation;
}

Wizard.Header = Header;
Wizard.Footer = Footer;
Wizard.FooterNoSteps = FooterNoSteps;
Wizard.Content = Content;
Wizard.CloseButton = CloseButton;
Wizard.Title = Title;
Wizard.SimpleFooter = SimpleFooter;
Wizard.Logo = Logo;

// eslint-disable-next-line
export default Wizard;
