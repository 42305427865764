import { PLAYLIST_TYPES } from '/src/features/recordings/constants';
import { SPECIAL_DATE_RANGES, LOGICALS, CURRENCIES } from '/src/features/_global/constants';
import { REFRESH_FILTER_VERSION_QUERY } from '/src/features/recordings/queries';

const { TODAY, LAST_7_DAYS, LAST_30_DAYS, THIS_MONTH, LAST_MONTH } = SPECIAL_DATE_RANGES;

export function getFieldsWithInitialValues(definition, userSettings) {
  return definition.fields.map((field) => ({
    name: field.name === 'default' ? null : field.name,
    value:
      field.params?.default_option ??
      (field.allowed_options && field.display_type === 'dropdown'
        ? field.name === 'currency'
          ? userSettings.defaultCurrency || CURRENCIES.USD
          : field.allowed_options[0].value
        : field.display_type === 'tags'
        ? []
        : ''),
    error: '',
  }));
}

export function combineFilterWithDateRange(filter, dateRange, filtersVersion) {
  const filterObj = filter
    ? JSON.parse(JSON.stringify(filter))
    : { version: filtersVersion, operator: LOGICALS.AND, conditions: [] };

  const index = filterObj.conditions.findIndex((condition) => condition.criteria === 'date_range');
  if (index === -1) {
    filterObj.conditions.push({
      criteria: 'date_range',
      value: dateRange,
    });
  } else {
    filterObj.conditions[index].value = dateRange;
  }

  return filterObj;
}

export function composeFilter(filters, filtersVersion) {
  const includeField = (condition, fieldIndex) => {
    // don't include fields that were not shown due to specific comparison
    const fieldDef = condition.definition.fields[fieldIndex];
    if (fieldDef.show_only_for_specific_comparisons) {
      if (!fieldDef.show_only_for_specific_comparisons_list.includes(condition.comparison)) return false;
    }
    return true;
  };

  const addCriteria = (returnValue, condition) => {
    returnValue.criteria = condition.criteria;
  };

  const addComperison = (returnValue, condition) => {
    if (condition.comparison) {
      returnValue.comparison = condition.comparison;
    }
  };

  const addMultipleValues = (returnValue, condition) => {
    if (condition.multiple_values) {
      returnValue.multiple_values = condition.multiple_values;
    } else if (condition.definition.multiple_values_options) {
      /* if a multiple_value has not been chosen (because we are sending just one value), 
           we still should include the multiple_values field, because the backend expects it. 
           So just grab the first one. */
      returnValue.multiple_values = condition.definition.multiple_values_options[0];
    }
  };

  const addSingleValue = (condition, groupIndex) => {
    if (Array.isArray(condition.values[groupIndex][0].value)) {
      return condition.values[groupIndex][0].value;
    }
    const integerExpected = condition.definition.fields[0].value_type === 'integer';
    let value = condition.values[groupIndex][0].value;
    if (integerExpected) {
      value = +value;
    }
    return value;
  };

  const addMultiFields = (condition, groupIndex) => {
    let combinedValue = {};
    condition.values[groupIndex].map((field, index) => {
      if (includeField(condition, index)) {
        let value = field.value;
        const integerExpected = condition.definition.fields[index].value_type === 'integer';
        if (integerExpected) {
          value = +value;
        }
        combinedValue = Object.assign({}, combinedValue, { [field.name]: value });
      }
    });
    return combinedValue;
  };

  return {
    version: filtersVersion,
    operator: LOGICALS.AND,
    conditions:
      filters.conditions?.map((condition) => {
        let returnValue = {};
        // add the 3 condition level values if applicable
        addCriteria(returnValue, condition);
        addComperison(returnValue, condition);
        addMultipleValues(returnValue, condition);

        if (condition.definition.use_multiple_values) {
          // multi value case
          returnValue.values = [];
          condition.values.map((group, groupIndex) => {
            if (group.length === 1) {
              // single field
              returnValue.values = returnValue.values.concat(addSingleValue(condition, groupIndex));
            } else {
              // multiple fields
              returnValue.values = returnValue.values.concat(addMultiFields(condition, groupIndex));
            }
          });
        } else {
          // single value case (no chaining)
          if (condition.values[0].length === 1) {
            // single field
            returnValue.value = addSingleValue(condition, 0);
          } else {
            // multiple fields
            returnValue.value = addMultiFields(condition, 0);
          }
        }

        return returnValue;
      }) ?? [],
  };
}

export function getValidationErrors(filters) {
  let errors = [];

  filters.conditions?.map((condition) => {
    condition.values.map((group, groupIndex) => {
      group.map((field, fieldIndex) => {
        // don't include fields that were not shown due to specific comparison
        const fieldDef = condition.definition.fields[fieldIndex];
        if (fieldDef.show_only_for_specific_comparisons) {
          if (!fieldDef.show_only_for_specific_comparisons_list.includes(condition.comparison)) return;
        }

        const target = { criteria: condition.criteria, fieldGroup: groupIndex, fieldIndex: fieldIndex };
        const validations = condition.definition.fields[fieldIndex].validations;
        Object.keys(validations).map((validation) => {
          switch (validation) {
            case 'not_empty':
            case 'not_none':
              // hack for ch13385
              if (fieldDef.display_type === 'tags') {
                if (
                  !field.value ||
                  (Array.isArray(field.value) && !field.value.filter((tag) => !tag.match(/^NOT:/)).length)
                ) {
                  return errors.push({ target, error: validations[validation].message });
                }
              }

              if (!field.value || (Array.isArray(field.value) && !field.value.length)) {
                return errors.push({ target, error: validations[validation].message });
              }
              break;
            case 'integer':
              if (!Number.isInteger(+field.value)) {
                return errors.push({ target, error: validations[validation].message });
              }
              break;
            case 'greater_than_or_equal':
              if (!Number.isInteger(+field.value) || +field.value < validations[validation].value) {
                return errors.push({ target, error: validations[validation].message });
              }
              break;
            case 'less_than_or_equal':
              if (!Number.isInteger(+field.value) || +field.value > validations[validation].value) {
                return errors.push({ target, error: validations[validation].message });
              }
              break;
            case 'not_all':
              if (field.value.length === condition.definition.fields[fieldIndex].allowed_options.length) {
                return errors.push({ target, error: validations[validation].message });
              }
              break;
          }
        });

        if (fieldDef.display_type === 'date_range') {
          if (field.value.special) {
            if (![TODAY, LAST_7_DAYS, LAST_30_DAYS, THIS_MONTH, LAST_MONTH].includes(field.value.special)) {
              errors.push({ target, error: 'Invalid special date range' });
            }
          } else {
            if (Number.isInteger(field.value.start_date) && Number.isInteger(field.value.end_date)) {
              if (field.value.start_date > field.value.end_date) {
                errors.push({ target, error: 'Invalid date range specified' });
              }
            } else {
              errors.push({ target, error: 'Invalid date range specified' });
            }
          }
        }
      });
    });
  });

  if (errors.length) return errors;

  return false;
}

export function hasError(filters) {
  let hasError = false;
  filters.conditions?.map((condition) => {
    condition.values.map((group) => {
      group.map((field) => {
        if (field.error) hasError = true;
      });
    });
  });
  return hasError;
}

export function decomposeFilter({ filtersParam, definitions, playlists, filtersVersion, client, currentUser }) {
  function checkValidOption(field, value) {
    if (field.display_type === 'dropdown' && !field.allowed_options.map((option) => option.value).includes(value)) {
      isInvalid = true;
      return;
    } else if (field.display_type === 'checkboxes' || field.display_type === 'multi_select') {
      value.forEach((v) => {
        if (!field.allowed_options.map((option) => option.value).includes(v)) {
          isInvalid = true;
          return;
        }
      });
    }
  }

  function addValue(stateCondition, definition, groupIndex, valueToAdd) {
    stateCondition.values.push(getFieldsWithInitialValues(definition, currentUser.settings));
    if (definition.fields.length === 1) {
      checkValidOption(definition.fields[0], valueToAdd);
      stateCondition.values[groupIndex][0].value = valueToAdd;
    } else {
      if (typeof valueToAdd !== 'object' || valueToAdd === null) {
        isInvalid = true;
        return;
      }
      Object.entries(valueToAdd).map(([name, value]) => {
        const index = stateCondition.values[groupIndex].findIndex((field) => field.name === name);
        if (index !== -1) {
          checkValidOption(definition.fields[index], value);
          stateCondition.values[groupIndex][index].value = value;
        } else {
          isInvalid = true;
          return;
        }
      });
    }
  }

  let filters;
  let isInvalid = false;
  const conditions = [];
  let playlist = null;
  try {
    filters = JSON.parse(filtersParam);
  } catch {
    return false;
  }

  // playlist id in param
  if (Number.isInteger(filters)) {
    playlist = playlists.find((x) => x.id === filters && x.type === PLAYLIST_TYPES.USER);
    if (playlist) {
      try {
        filters = JSON.parse(playlist.filter);
      } catch {
        return false;
      }
    } else {
      return false;
    }
  }

  // recommended playlist name in param
  else if (typeof filters === 'string') {
    playlist = playlists.find((x) => x.name === filters && x.type === PLAYLIST_TYPES.RECOMMENDED);
    if (playlist) {
      try {
        filters = JSON.parse(playlist.filter);
      } catch {
        return false;
      }
    } else {
      return false;
    }
  }

  // filter in param
  else {
    if (filters.version < filtersVersion) {
      // outdated filter version received, refresh it
      client
        .query(REFRESH_FILTER_VERSION_QUERY, { filters: filtersParam, version: filtersVersion })
        .toPromise()
        .then(({ data }) => {
          try {
            filters = JSON.parse(data.refreshFilterVersion);
          } catch {
            return false;
          }
        });
    }
  }

  if (!filters.conditions || !filters.operator || !filters.version) {
    return false;
  }

  filters.conditions.map((condition) => {
    const stateCondition = {};
    const definition = definitions.find((def) => def.criteria === condition.criteria);
    if (!definition) {
      isInvalid = true;
      return;
    }
    stateCondition.criteria = condition.criteria;
    stateCondition.definition = definition;

    if (definition.use_comparison) {
      if (condition.comparison && definition.allowed_comparisons.includes(condition.comparison)) {
        stateCondition.comparison = condition.comparison;
      } else {
        isInvalid = true;
        return;
      }
    } else {
      if (condition.comparison) {
        isInvalid = true;
        return;
      }
    }

    if (definition.use_multiple_values && definition.multiple_values_type === 'choice') {
      if (definition.multiple_values_options.includes(condition.multiple_values)) {
        if (condition.values?.length > 1) {
          stateCondition.multiple_values = condition.multiple_values;
        }
      } else {
        isInvalid = true;
        return;
      }
    } else {
      if (condition.multiple_values) {
        isInvalid = true;
        return;
      }
    }

    stateCondition.values = [];
    if (definition.use_multiple_values) {
      if (!condition.values) {
        isInvalid = true;
        return;
      }
      if (definition.multiple_values_type === 'native') {
        stateCondition.values.push(getFieldsWithInitialValues(definition, currentUser.settings));
        checkValidOption(definition.fields[0], condition.values);
        stateCondition.values[0][0].value = condition.values;
      } else {
        condition.values.map((group, groupIndex) => {
          addValue(stateCondition, definition, groupIndex, group);
        });
      }
    } else {
      if (!condition.value) {
        isInvalid = true;
        return;
      }
      addValue(stateCondition, definition, 0, condition.value);
    }

    conditions.push(stateCondition);
  });

  if (isInvalid) return false;
  if (getValidationErrors({ conditions })) return false;

  return { conditions, playlist };
}
