import React from 'react';
import { fromUnixTime, isBefore, subDays, format } from 'date-fns';

import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';

export function RenderVisit({ data, originalUrl }) {
  const pageVisitedAt = data?.pageVisitedAt || data?.page_visited_at;
  const siteVisitedAt = data?.siteVisitedAt || data?.site_visited_at;
  const hasVisitInfo = pageVisitedAt || siteVisitedAt;
  const hasPageVisit = !!pageVisitedAt;

  if (hasVisitInfo) {
    const { finalUrl } = data;
    const visitDate = fromUnixTime(hasVisitInfo);
    const isMoreThanWeek = isBefore(visitDate, subDays(new Date(), 7));
    const url = finalUrl || originalUrl;
    return (
      <div>
        <div className="mt-10 h-full w-full items-stretch rounded-md bg-alice-blue-500 px-[30px] pb-10 pt-[30px]">
          <div className="flex h-full w-full flex-col items-start p-[5px]">
            <h3 className="text-header-4">NOTE:</h3>
          </div>
          <div className="flex h-full w-full flex-col items-start">
            <p className="text-body-2 flex p-[5px] leading-snug">
              We last tracked data on&nbsp;
              <strong className="text-body-1">{format(visitDate, 'MMMM dd, yyyy HH:mm:ss')}</strong>&nbsp;for&nbsp;
              {hasPageVisit ? 'page' : 'site'}&nbsp;
              <a className="text-link" target="_blank" href={hasPageVisit ? url : getSite(url)} rel="noreferrer">
                {hasPageVisit ? url : getSite(url)}
              </a>
              &nbsp;
              {isMoreThanWeek && (
                <WarningIcon className="h-4 w-4 fill-current text-dandelion-500" aria-label="warning" />
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

function getSite(url) {
  try {
    return new URL(url).origin;
  } catch (ex) {
    console.log(ex); // eslint-disable-line
    return url;
  }
}
