import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { addDays, format } from 'date-fns';
import { Button, Input, TextArea, Checkbox, Divider } from '@crazyegginc/hatch';

import { useMutation, useWizard } from '/src/hooks';
import { sendInstallInstructionsEmailMutation } from '/src/features/installation/mutations';
import { superLenientUrlRegex } from '/src/utils/regex';
import { WizardHeader, WizardSubHeader } from '/src/components/wizard/legacy/wizard-ui';

import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg';

const validationSchema = yup.object().shape({
  name: yup.string().required('Please provide a name.'),
  email: yup.string().required('Please provide an email address.').email('Please provide a valid email address.'),
  url: yup.string().required('Please provide a URL.').matches(superLenientUrlRegex, 'Please provide a valid URL.'),
  message: yup.string().required('Please provide a message.'),
});

export function EmailDeveloperPage() {
  const navigate = useNavigate();
  const params = useParams();
  const deadline = format(addDays(new Date(), 3), 'MMM do');

  const { set: wizardSet } = useWizard();

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      next: '/',
      nextEnabled: params?.page === 'sent' ?? false,
      title: 'Contact your developer',
      currentStep: 2,
      totalSteps: 2,
    });
  }, [wizardSet, params?.page]);

  const { mutate: sendInstallInstructionsEmail, error, isLoading } = useMutation(sendInstallInstructionsEmailMutation);

  if (params.page === 'sent') {
    return (
      <div className="text-body-2 flex flex-col">
        <WizardHeader className="!mb-5">We sent an email to your developer!</WizardHeader>

        <WizardSubHeader>
          Your developer will receive instructions for installing the Crazy Egg tracking script.
        </WizardSubHeader>

        <div className="relative flex w-full flex-col items-center rounded border border-mystic-500 p-6">
          <TickIcon className="h-16 w-16 fill-current text-lima-500" />
          <div className="text-header-4 mt-2 text-lima-500">Email sent!</div>
          <div className="mt-5">
            Click <strong className="text-body-1">Next</strong> to head to your dashboard.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, let&#39;s contact your web developer!</WizardHeader>

      <WizardSubHeader>
        Tell us about your web developer and we&#39;ll contact them with all the details they need:
      </WizardSubHeader>

      <Formik
        initialValues={{
          name: '',
          email: '',
          url: '',
          message: `Please install the Crazy Egg tracking script on our website by ${deadline} so that we can start tracking data. Thanks!`,
          ccMe: true,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          sendInstallInstructionsEmail(
            {
              email: {
                ccMe: values.ccMe,
                developerEmail: values.email,
                developerName: values.name,
                message: values.message,
                siteUrl: values.url,
              },
            },
            {
              onSuccess: (data) => {
                if (data.sendInstallInstructionsEmail?.success === true) {
                  navigate('/install/developer/sent');
                }
              },
            },
          );
        }}
      >
        {({ touched, values, errors, handleChange, handleBlur }) => (
          <Form>
            <div className="mb-5">
              <Input
                name="name"
                id="dev_name"
                label="Web developer&#39;s name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                validationIcon={touched.name ? !errors.name : null}
                error={touched.name ? errors.name : null}
                placeholder="eg. John Doe"
              />
            </div>

            <div className="mb-5">
              <Input
                name="email"
                id="dev_email"
                label="Web developer&#39;s email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                validationIcon={touched.email ? !errors.email : null}
                error={touched.email ? errors.email : null}
                placeholder="eg. johndoe@gmail.com"
              />
            </div>

            <div className="mb-5">
              <Input
                name="url"
                id="dev_url"
                label="Site to install the tracking script"
                value={values.url}
                onChange={handleChange}
                onBlur={handleBlur}
                validationIcon={touched.url ? !errors.url : null}
                error={touched.url ? errors.url : null}
                placeholder="eg. mywebsite.com/signup"
              />
            </div>

            <div className="mb-2">
              <TextArea
                rows={8}
                name="message"
                id="dev_message"
                label="Send a personalized message to your favorite developer (optional)"
                placeholder="Include a message, encouraging your developer to install the Crazy Egg tracking script."
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.message ? errors.message : null}
              />
            </div>

            <Checkbox
              name="ccMe"
              checked={values.ccMe}
              onChange={handleChange}
              label="CC me on this email"
              id="dev_ccMe"
            />

            <Divider dashed className="my-8" />

            <div className="flex items-center">
              <Button size="lg" className="!mr-2.5 !whitespace-nowrap" type="submit">
                {isLoading ? 'Sending...' : 'Send tracking script to my developer!'}
              </Button>
              <div>
                This person will receive an email with instructions on how to install the tracking script on your site
                so data will start rolling in!
              </div>
            </div>
            {error && <span className="text-error">Oops! Email sending failed. Please try again later.</span>}
          </Form>
        )}
      </Formik>
    </div>
  );
}
