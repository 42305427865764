import { useRef, useMemo, useCallback, useEffect } from 'react';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { Button } from '@crazyegginc/hatch';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';

import { useModal } from '/src/hooks';
import { GoalCard, SkeletonCard } from '/src/features/goals/components/GoalCard';
import { DeleteModal } from './modals/DeleteModal';
import { EditModal } from './modals/EditModal';
import { RemoveDraft } from './modals/RemoveDraft';

function generateCaption(goals, draft) {
  if (goals.length && draft) {
    return 'You can use the stored draft or a saved goal from another test.';
  }
  if (goals.length) {
    return 'You can use a saved goal from another test.';
  }
  if (draft) {
    return 'You can use the stored draft or create a new one.';
  }
  return 'Create your first goal';
}

export function GoalSelector({
  goals,
  draft = null,
  hasNextPage,
  loadNextPage,
  fetching,
  onCreate = null,
  selected = null,
  onSelect = null,
  onEdit = null,
  onDelete = null,
  onClearDraft = null,
}) {
  const modal = useModal();

  return (
    <div className="mx-auto mb-[200px] w-11/12 max-w-[780px] pb-[100px]">
      <h2 className="mb-10 text-[21px] font-normal text-black-pearl-500">{generateCaption(goals, !!draft)}</h2>

      <Button
        onClick={() => {
          if (draft) {
            modal.show(
              <RemoveDraft
                onConfirm={() => {
                  onClearDraft?.();
                  onCreate?.();
                }}
              />,
            );
          } else {
            onCreate?.();
          }
        }}
        disabled={!onCreate}
        variant="secondary"
        className="mb-5"
      >
        <PlusIcon className="mr-2.5 h-[15px] w-[15px] fill-current" />
        Create New Goal
      </Button>

      {draft ? (
        <div className="mb-4">
          <p className="mb-2.5 text-sm text-lynch-500">Draft:</p>

          <GoalCard
            goal={draft}
            isDraft={true}
            selected={selected === 'draft'}
            onSelect={() => {
              if (selected === 'draft') {
                onSelect?.(null);
              } else {
                onSelect?.(draft);
              }
            }}
            onEdit={onCreate}
            onDelete={(e) => {
              e.stopPropagation();

              modal.show(
                <DeleteModal
                  goal={draft}
                  isDraft={true}
                  onConfirm={() => {
                    onClearDraft?.();
                  }}
                />,
              );
            }}
          />
        </div>
      ) : null}

      {goals.length ? (
        <>
          <p className="mb-2.5 text-sm text-lynch-500">Saved goals:</p>

          <ul>
            <VirtualizedItems
              goals={goals}
              selected={selected}
              modal={modal}
              onSelect={onSelect}
              onCreate={onCreate}
              onEdit={onEdit}
              onDelete={onDelete}
              fetching={fetching}
              hasNextPage={hasNextPage}
              loadNextPage={loadNextPage}
            />
          </ul>
        </>
      ) : null}
    </div>
  );
}

function VirtualizedItems({
  goals,
  selected,
  fetching,
  hasNextPage = false,
  loadNextPage,
  modal,
  onSelect,
  onCreate,
  onEdit,
  onDelete,
}) {
  const parentRef = useRef(null);
  const items = useMemo(() => goals ?? [], [goals]);

  const getCount = () => {
    if (hasNextPage) return items.length + 1;
    return items.length;
  };

  const getSegmentLength = (displayData) => {
    if (!displayData) return 0;
    const parsed = JSON.parse(displayData);

    if (typeof parsed.triggers === 'undefined') {
      return 0;
    }

    return parsed.triggers.length;
  };

  const scrollMargin = parentRef.current?.offsetTop;
  const estimateSize = useCallback(
    (index) => {
      const defaultSize = 90;
      const itemLength = getSegmentLength(items[index]?.displayData);

      if (itemLength === 0) {
        return defaultSize;
      }

      if (itemLength === 1 || itemLength === 2) {
        return itemLength * 18 + 103;
      }

      if (itemLength > 2) {
        return 3 * 18 + 103;
      }
    },
    [items],
  );

  const virtualizer = useWindowVirtualizer({
    count: getCount(),
    estimateSize,
    overscan: 5,
    scrollMargin,
  });
  const virtualItems = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();

  useEffect(() => {
    const [lastItem] = [...virtualItems].reverse();
    if (!lastItem) return;
    if (lastItem.index === items.length && hasNextPage && !fetching) {
      loadNextPage();
    }
  }, [hasNextPage, fetching, virtualItems, items.length, loadNextPage]);

  return (
    <ul ref={parentRef} className="relative mb-5 flex w-full flex-col" style={{ height: `${totalSize}px` }}>
      {virtualItems.map((item) => {
        const style = {
          height: `${item.size}px`,
          transform: `translateY(${item.start - virtualizer.options.scrollMargin}px)`,
        };

        if (item.index > items.length - 1) {
          return (
            <li key="skeleton" className="absolute left-0 top-0 w-full" style={style}>
              <SkeletonCard />
            </li>
          );
        }

        const goal = items[item.index];

        return (
          <li key={goal.id} className="absolute left-0 top-0 w-full" style={style} data-testid="goal">
            <GoalCard
              goal={goal}
              selected={selected && goal.id === selected}
              // eslint-disable-next-line
              onSelect={(selectedGoal) => {
                // TODO: add goal to current flow data
                onSelect?.(selectedGoal);
              }}
              onEdit={(e) => {
                e.stopPropagation();

                if (!goal.abTestsCount || goal.abTestsCount === 0) {
                  return onEdit?.(goal);
                }

                modal.show(<EditModal goal={goal} onConfirm={onEdit} onCreate={onCreate} />);
              }}
              onDelete={(e) => {
                e.stopPropagation();

                modal.show(
                  <DeleteModal
                    goal={goal}
                    onConfirm={(id) => {
                      onDelete?.(id);
                    }}
                  />,
                );
              }}
            />
          </li>
        );
      })}
    </ul>
  );
}
