import { useContext, useEffect, useCallback } from 'react';
import { useActor } from '@xstate/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RocketIcon } from '@crazyegginc/hatch/dist/images/icon-rocket.svg';

import { useMutation, useSite } from '/src/hooks';
import { normalizeUrl, splitUrls } from '/src/utils/url';
import Wizard from '/src/components/wizard/Wizard';

import { PageSetup } from './new/index';
import { AbTestGoalSelectPage } from './new/goal';
import { AbTestReviewPage } from './new/review';
import { AbTestSuccessPage } from './new/success';
import { AbTestWeightsPage } from './new/weights';
import AbTestVariantEditorPage from './new/variants';
import { GoalsWizard } from '/src/features/goals/components/GoalsWizard';
import { generateVariantPayload } from '/src/features/ab-testing/utils';

import { abTestCheckPageMutation, abTestCreateMutation } from '../../mutations';

export function AbTestWizard({ context }) {
  const { selectSite } = useSite({ dontSelectSite: true });
  const navigate = useNavigate();
  const { abTestService } = useContext(context);
  const [state] = useActor(abTestService);
  const { send } = abTestService;

  const {
    mutate: mutateCheckPage,
    reset: resetMutateCheckPage,
    isLoading,
    isIdle: mutateCheckPageIdle,
  } = useMutation(abTestCheckPageMutation);
  const {
    mutate: mutateCreateTest,
    isIdle: mutateCreateTestIdle,
    reset: resetMutateCreateTest,
  } = useMutation(abTestCreateMutation);

  useEffect(() => {
    if (state.matches('cancelled')) {
      navigate('/ab-tests', { replace: true });
    }
  }, [navigate, state]);

  const verifyUrl = useCallback(async () => {
    if (state.context.payload.matchingUrl.trim() === '') {
      return send({ type: 'PAGE_DATA_FAILED' });
    }

    if (splitUrls(state.context.payload.matchingUrl.trim()).length > 1) {
      return send({
        type: 'PAGE_DATA_FAILED',
        payload: `COMMA_SEPARATED_LIST`,
      });
    }

    mutateCheckPage(
      {
        checkType: 'INITIAL',
        url: normalizeUrl(state.context.payload.matchingUrl),
      },
      {
        onSuccess: (data) => {
          if (data.abTestCheckPage.errors) {
            send({
              type: 'PAGE_DATA_FAILED',
              payload: data.abTestCheckPage.errors,
            });
          } else {
            if (data.abTestCheckPage.siteId) {
              selectSite(data.abTestCheckPage.siteId);
            }
            send({ type: 'PAGE_DATA_RECEIVED', payload: data.abTestCheckPage });
          }
        },
        onError: (error) => {
          send({
            type: 'PAGE_DATA_FAILED',
            payload: error,
          });
        },
        onSettled() {
          resetMutateCheckPage();
        },
      },
    );
  }, [mutateCheckPage, resetMutateCheckPage, send, state.context.payload.matchingUrl, selectSite]);

  useEffect(() => {
    if (state.matches('setup.verifyPage') && !isLoading) {
      verifyUrl();
    }
  }, [state, verifyUrl, isLoading]);

  const confirmSite = useCallback(async () => {
    mutateCheckPage(
      {
        checkType: 'TRANSITION',
        url: normalizeUrl(state.context.payload.matchingUrl),
        selectedDevices: state.context.payload.devices,
      },
      {
        onSuccess: (data) => {
          if (data?.abTestCheckPage?.errors?.length > 0) {
            send({
              type: 'ERROR',
              payload: data.abTestCheckPage?.errors,
            });
          } else {
            send({ type: 'VALID', payload: data.abTestCheckPage });
          }
        },
        onError: (error) => {
          send({
            type: 'ERROR',
            payload: error,
          });
        },
        onSettled() {
          resetMutateCheckPage();
        },
      },
    );
  }, [mutateCheckPage, resetMutateCheckPage, send, state]);

  const createDraft = useCallback(
    (args) => {
      mutateCreateTest(
        { args },
        {
          onSuccess(data) {
            navigate(`/ab-tests/${data.abTestCreate.id}/edit`);
          },
          onError(error) {
            send({
              type: 'ERROR',
              data: error,
            });
          },
          onSettled() {
            resetMutateCreateTest();
          },
        },
      );
    },
    [mutateCreateTest, send, resetMutateCreateTest, navigate],
  );

  useEffect(() => {
    const subscription = abTestService.subscribe((state) => {
      if (state.matches('setup.confirmSite') && mutateCheckPageIdle) {
        confirmSite();
      }

      if (state.matches('setup.createDraft') && mutateCreateTestIdle) {
        createDraft({
          ...state.context.payload,
          siteId: state.context.siteId,
          variants: generateVariantPayload({
            variants: state.context.payload.variants,
            type: state.context.payload.type,
            autoReweight: state.context.payload.autoReweight,
          }),
        });
      }
    });

    return subscription.unsubscribe;
  }, [confirmSite, createDraft, send, abTestService, mutateCreateTestIdle, mutateCheckPageIdle]);

  const isNextEnabled = abTestService.initialized ? abTestService.nextState({ type: 'NEXT' }).changed : false;
  const isDraftable = abTestService.initialized ? abTestService.nextState({ type: 'DRAFT' }).changed : false;

  if (state.matches('variants')) {
    return (
      <div className="flex h-full w-full flex-col">
        <AbTestVariantEditorPage context={context} />
      </div>
    );
  }

  if (state.matches('goals.createGoal') || state.matches('goals.editGoal') || state.matches('goals.confirmGoal')) {
    const service =
      abTestService?.children?.get?.('newGoal') ||
      abTestService?.children?.get?.('editGoal') ||
      abTestService?.children?.get?.('confirmGoal');
    return (
      <div className="flex h-full w-full flex-col">
        <GoalsWizard service={service} />
      </div>
    );
  }

  if (state.matches('success')) {
    return <AbTestSuccessPage service={abTestService} siteId={state.context.siteId} ids={state.context.result} />;
  }

  return (
    <div className="flex h-full w-full flex-col">
      <Wizard.Header>
        <Wizard.CloseButton
          onClick={() => {
            send('CANCEL');
          }}
          defaultPath={state.context.previousPath || '/ab-tests'}
        />
      </Wizard.Header>

      <Wizard.Content dontMask={true}>{renderWizardContent(context, state)}</Wizard.Content>

      <Wizard.SimpleFooter
        confirmCancel={false}
        currentStep={state.context.step}
        totalSteps={4}
        showMeta={true}
        title={state.context.title}
        onBack={state.context.step > 1 && !state.matches('creatingAbTest') ? () => send({ type: 'BACK' }) : undefined}
        cancelText={isDraftable ? 'Save draft & close' : 'Cancel'}
        nextIcon={state.context.step === 4 ? RocketIcon : null}
        hideNextArrow={state.context.step === 4}
        loading={isLoading}
        nextText={state.context.nextText || 'Next'}
        nextLoadingText={state.context.step === 4 ? 'Publishing...' : null}
        nextEnabled={isNextEnabled}
        onNext={() => send({ type: 'NEXT' })}
        onCancel={() => {
          send('CANCEL');
        }}
      />
    </div>
  );
}

function renderWizardContent(context, state) {
  const stateParts = state.toStrings();
  switch (stateParts[0]) {
    case 'setup':
      return <PageSetup context={context} />;
    case 'variants':
      return null;
    case 'goals':
      return <AbTestGoalSelectPage context={context} />;
    case 'weights':
      return <AbTestWeightsPage context={context} />;
    case 'review':
      return <AbTestReviewPage context={context} />;
    case 'success':
      return null;
  }
}
