import { useState, useEffect, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, Link } from 'react-router-dom';
import { startOfDay, endOfDay, format, eachDayOfInterval, getUnixTime, fromUnixTime } from 'date-fns';
import { Button, DateRangePicker, MetricCards, Panel, Spinner, Tooltip } from '@crazyegginc/hatch';

import { SyncedLines } from '/src/chartjs-plugins/SyncedLines';
import { useAuthContext, useSite, useModal, useNeedsUpgradeToAccessFeature, useQueryParams } from '/src/hooks';
import { ctaResultQuery } from '/src/features/addons/queries';
import { SINGLE_SITE_QUERY } from '/src/features/_global/queries';
import { SITES_FOR_ADDONS } from '/src/features/addons/queries';
import { getSurveyQueryParams } from '/src/utils/url';
import { mergeDeep } from '/src/utils/object';
import { getParam } from '/src/utils/location';
import { formatDate, getConvertedDateRange, getInitialDate } from '/src/utils/date';
import { isProduction } from '/src/utils';
import { round } from '/src/utils/math';

import { DashboardPage } from '/src/components/Page';
import { DashHeader } from '/src/components/headers/DashHeader';
import { SitePickerBar } from '/src/components/site-picker/index';
import { Breadcrumb, BreadcrumbItem } from '/src/components/Breadcrumb';
import { AddonNameTitle } from '../../common/results/AddonNameTitle';
import { AddonActiveToggle } from '../../common/AddonActiveToggle';
import { PopoverActions } from '../../common/actions';
import { BackToDashboardButton } from '../../common/BackToDashboardButton';
import { ActionIconButton } from '../../common/results/ActionIconButton';
import { GoalTriggerSummary } from '/src/features/goals/utils';
import { WebP } from '/src/components/WebP';
import { RecordingsUpgradeModal } from '/src/features/recordings/components/dashboard/modals/RecordingsUpgradeModal';
import { GOAL_TRIGGERS_METADATA } from '/src/features/goals/constants';
import { ScrollingLineChart } from '/src/components/ScrollingLineChart';

import { SupportLinks } from '/src/support';
import { FEATURES, SPECIAL_DATE_RANGES, LOGICALS } from '/src/features/_global/constants';
import { GROUP_BY_TYPES, RECORDINGS_FILTER_VERSION } from '/src/features/recordings/constants';

import { ReactComponent as ViewIcon } from '@crazyegginc/hatch/dist/images/icon-watched-outine.svg';
import { ReactComponent as ClickIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';
import { ReactComponent as PlayIcon } from '@crazyegginc/hatch/dist/images/icon-play-filled.svg';
import { ReactComponent as ReloadIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';
import { ReactComponent as ExternalLinkIcon } from '@crazyegginc/hatch/dist/images/icon-open-url-outline.svg';
import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

const commonChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'nearest',
    axis: 'x',
  },
  scales: {
    x: {
      ticks: {
        padding: 10,
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      suggestedMax: 10,
      ticks: {
        padding: 10,
      },
      border: {
        dash: [5, 5],
        display: false,
      },
      grid: {
        drawTicks: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      padding: { x: 12, y: 6 },
      displayColors: false,
      callbacks: {
        title: () => '',
        label: (context) => `${context.parsed.y.toLocaleString()}`,
      },
    },
    syncedlines: {
      chartIds: ['1', '2', '3', '4'],
    },
  },
};

const plugins = [SyncedLines];

const ClickRateChartOptions = mergeDeep({}, commonChartOptions, {
  scales: {
    y: {
      ticks: {
        padding: 10,
        callback: (value) => {
          return `${value}%`;
        },
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => `${context.parsed.y}%`,
      },
    },
  },
});

export function CTAResults({ cta }) {
  const navigate = useNavigate();
  const { isSharing, sharedResource } = useAuthContext();

  const { sites, selectSite } = useSite({
    sitesQuery: isSharing ? SINGLE_SITE_QUERY : SITES_FOR_ADDONS,
    dontSelectSite: true,
    sitesQueryProps: isSharing ? { variables: { id: sharedResource.resource.siteId } } : undefined,
  });

  const ctaSite = sites.find((site) => site.id === cta?.siteId);

  useEffect(() => {
    selectSite(ctaSite);
  }, [ctaSite, selectSite]);

  return (
    <DashboardPage>
      <DashHeader
        titleComponent={
          <Breadcrumb>
            {!isSharing && (
              <BreadcrumbItem
                active={false}
                to={{
                  pathname: `/addons`,
                  search: `type=${FEATURES.CTAS}${getSurveyQueryParams() ? `&${getSurveyQueryParams()}` : ''}`,
                }}
              >
                CTAs
              </BreadcrumbItem>
            )}
            <BreadcrumbItem active={true}>
              <AddonNameTitle fetching={false} addon={cta} />
            </BreadcrumbItem>
          </Breadcrumb>
        }
        actionButton={
          <div className="flex shrink-0 items-center space-x-2.5">
            {!isSharing && <BackToDashboardButton type={FEATURES.CTAS} />}
            <PopoverActions
              addon={cta}
              onDelete={() => {
                navigate(
                  { pathname: '/addons', search: getSurveyQueryParams({ type: FEATURES.CTAS }) },
                  { replace: true },
                );
              }}
            />
            {!isSharing ? <AddonActiveToggle addon={cta} showLoadingState={false} /> : null}
          </div>
        }
      />

      <SitePickerBar
        sites={ctaSite ? [ctaSite] : []}
        selectedSite={ctaSite}
        loading={false}
        pageDisplayName="CTA Results"
      />

      <div className="flex flex-col p-10 pb-36">
        <CTAResultsContent cta={cta} ctaSite={ctaSite} />
      </div>
    </DashboardPage>
  );
}

function CTAResultsContent({ cta, ctaSite }) {
  const isProd = isProduction();
  const [perfMetricStarted, setPerfMetricStarted] = useState(false);
  const [dateRange, setDateRange] = useState(
    () => getInitialDate(getParam('date')) ?? { special: SPECIAL_DATE_RANGES.LAST_30_DAYS },
  );

  const convertedDateRange = getConvertedDateRange(dateRange, true);

  // sc-37032 temp code
  const dataIssueStart = 1692835200;
  const dataIssueEnd = 1693439999;
  const hasDataIssue = convertedDateRange.startDate <= dataIssueEnd && convertedDateRange.endDate >= dataIssueStart;

  const {
    data,
    isFetching,
    error,
    refetch: refetchResults,
  } = useQuery({
    ...ctaResultQuery({
      id: cta.id,
      startAt: convertedDateRange.startDate,
      endAt: convertedDateRange.endDate,
    }),
  });

  useEffect(() => {
    if (window.CE2?.timing && isProd) {
      window.CE2.timing.start('CTA_results');
      setPerfMetricStarted(true);
    }
  }, [isProd]);

  useEffect(() => {
    if (perfMetricStarted && window.CE2?.timing && isProd && (data || error) && !isFetching) {
      try {
        window.CE2.timing.stop('CTA_results');
        setPerfMetricStarted(false);
      } catch {
        // prevent metrics error thrown from crashing the app
        setPerfMetricStarted(false);
      }
    }
  }, [perfMetricStarted, data, error, isProd, isFetching]);

  if (isFetching) {
    return (
      <div className="mt-16 flex w-full items-center justify-center">
        <Spinner />
        <div className="ml-2.5">Loading...</div>
      </div>
    );
  }

  const result = data?.ctaResult;
  const hasActiveGoal = Boolean(cta.goal?.id && !cta.goal?.deletedAt);

  if (!result) {
    return null;
  }

  return (
    <>
      <CTAResultActionRow
        dateRange={dateRange}
        setDateRange={setDateRange}
        refetch={refetchResults}
        cta={cta}
        ctaSite={ctaSite}
      />
      <CTAResultSummaryTiles
        totalClicks={result.totalClicks}
        totalViews={result.totalViews}
        totalConversions={result.totalConversions}
        hasActiveGoal={hasActiveGoal}
        hasDataIssue={hasDataIssue}
      />
      <LinkedGoal goal={cta.goal} />
      <CTAResultCharts
        result={result}
        hasActiveGoal={hasActiveGoal}
        hasDataIssue={hasDataIssue}
        dateRange={dateRange}
      />
    </>
  );
}

function CTAResultActionRow({ dateRange, setDateRange, refetch, cta, ctaSite }) {
  const { set: queryParamsSet } = useQueryParams();
  const { isSharing } = useAuthContext();
  const convertedDateRange = getConvertedDateRange(dateRange);
  const modal = useModal();

  const chooseDateRange = useCallback(
    (value) => {
      if (value.start_date) {
        queryParamsSet(
          'date',
          JSON.stringify({
            start_date: getUnixTime(value.start_date),
            end_date: getUnixTime(value.end_date),
          }),
        );
      } else {
        queryParamsSet('date', JSON.stringify(value));
      }
      setDateRange(value);
    },
    [queryParamsSet, setDateRange],
  );

  const needsUpgradeToAccess = useNeedsUpgradeToAccessFeature(FEATURES.RECORDINGS);

  const watchRecordingProps = needsUpgradeToAccess
    ? {
        onClick: () => modal.show(<RecordingsUpgradeModal />),
      }
    : {
        component: Link,
        to: {
          pathname: '/recordings',
          search: `filters=${generateCTAFilterUrl(cta.id, convertedDateRange)}&site=${ctaSite?.name}&groupBy=${
            GROUP_BY_TYPES.LIST_VIEW
          }`,
        },
        target: '_blank',
        rel: 'noopener noreferrer',
      };

  return (
    <div className="mb-3.75 flex items-center justify-between">
      <span className="text-header-3">CTA Summary</span>
      <div className="flex items-center space-x-2.5">
        <div className="mr-2.5">
          <ActionIconButton
            tooltip="Refresh results"
            icon={ReloadIcon}
            onClick={() => refetch()}
            label="Refresh results"
          />
        </div>
        {!isSharing && (
          <Button
            variant="secondary"
            size="lg"
            leftIcon={<PlayIcon className="mr-2.5 h-3 w-3 fill-current" />}
            {...watchRecordingProps}
          >
            Watch recordings
          </Button>
        )}
        <DateRangePicker
          lowerBoundary={startOfDay(fromUnixTime(cta.createdAt))}
          upperBoundary={endOfDay(new Date())}
          startDate={dateRange?.start_date}
          endDate={dateRange?.end_date}
          special={dateRange?.special}
          setDateRange={chooseDateRange}
          setCommonDateRange={chooseDateRange}
          showCommonRanges={true}
          size="lg"
        />
      </div>
    </div>
  );
}

function CTAResultSummaryTiles({ totalClicks, totalViews, totalConversions, hasActiveGoal, hasDataIssue }) {
  const cardClass = '!w-1/3 min-w-[240px] max-w-[350px]';

  const clickRate = totalViews ? round((totalClicks / totalViews) * 100, 1) : 0;
  const conversionRate = totalClicks && totalConversions ? round((totalConversions / totalClicks) * 100, 1) : 0;
  const asterisk = hasDataIssue
    ? 'Due to a system error clicks and views were not correctly counted for CTAs from Aug 24 through Aug 30. Your CTA was still showing and visitors could still click on it but we did not track all views and clicks accurately during that time period.'
    : null;

  return (
    <MetricCards className="!mb-0 !justify-start">
      <MetricCards.Card
        className={classNames('!h-32', cardClass)}
        icon={ViewIcon}
        title={
          totalViews === 0 ? (
            <div className="flex items-center">
              <Tooltip
                placement="bottom"
                arrowSkiddingPercent={15}
                interactive={true}
                tooltipContent={
                  <div className="px-2 py-2.5 text-left">
                    <h5 className="mb-2.5 text-xs font-bold text-white">Why are my &apos;total views&apos; 0?</h5>
                    <p className="text-xs font-light leading-tight tracking-wide text-white">
                      Read about possible reasons{' '}
                      <a
                        href={SupportLinks.survey.zeroViews}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-link"
                      >
                        here.
                      </a>
                    </p>
                  </div>
                }
              >
                <InfoIcon className="h-3.5 w-3.5 fill-current text-dodger-blue-300" />
              </Tooltip>
              <span className="ml-2.5">Total views</span>
            </div>
          ) : (
            'Total views'
          )
        }
        value={totalViews}
        color="bg-dodger-blue-300"
        data-testid="total-views"
        asterisk={asterisk}
      />
      <MetricCards.Card
        className={cardClass}
        icon={ClickIcon}
        title="Total clicks"
        value={totalClicks}
        subtext="Click rate:"
        subvalue={`${clickRate}%`}
        color="bg-lavender-500"
        data-testid="total-clicks"
        asterisk={asterisk}
      />
      {hasActiveGoal && !hasDataIssue ? (
        <MetricCards.Card
          className={cardClass}
          icon={TickIcon}
          title="Total conversions"
          value={totalConversions}
          subtext="Conversion rate:"
          subvalue={`${conversionRate}%`}
          color="bg-lima-500"
          data-testid="conversion-rate"
        />
      ) : null}
    </MetricCards>
  );
}

function LinkedGoal({ goal }) {
  if (!goal) {
    return null;
  }

  const deleted = goal.deletedAt;

  return (
    <>
      <span className="text-header-3 mt-10 pb-2.5">Goal linked to this CTA</span>
      <Panel
        className={classNames('!flex-row !items-center justify-between !px-6 !py-5 !pr-20', {
          'text-body-5': deleted,
          'text-body-2': !deleted,
        })}
      >
        <div className="flex items-center gap-x-5">
          <WebP
            className="w-[35px]"
            webp={GOAL_TRIGGERS_METADATA[goal.icon.toUpperCase()]?.webp ?? GOAL_TRIGGERS_METADATA.OTHER.webp}
            fallback={GOAL_TRIGGERS_METADATA[goal.icon.toUpperCase()]?.png ?? GOAL_TRIGGERS_METADATA.OTHER.png}
            width="35"
            alt={goal.purpose.replace('_', ' ')}
          />
          <div>
            {deleted ? (
              <span className="font-semibold">{goal.name}</span>
            ) : (
              <Link
                to={`/goals/${goal.id}`}
                className="text-link group flex items-center gap-x-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                {goal.name}
                <ExternalLinkIcon
                  className="invisible mt-px h-2.5 w-2.5 fill-current text-cadet-blue-500 group-hover:visible"
                  aria-label="link to goal"
                />
              </Link>
            )}
            <GoalTriggerSummary goal={goal} textOnly={deleted} />
          </div>
        </div>
        <span>
          Created: <time dateTime={fromUnixTime(goal.createdAt)}>{formatDate(goal.createdAt)}</time>
        </span>
        <span className={classNames({ italic: deleted })}>{deleted ? 'Deleted' : 'Active'}</span>
      </Panel>
    </>
  );
}

function CTAResultCharts({ result, hasActiveGoal, hasDataIssue, dateRange }) {
  const chartLabels = useMemo(() => {
    const conv = getConvertedDateRange(dateRange);
    if (!result.results) return null;
    return eachDayOfInterval({
      start: fromUnixTime(conv.startDate),
      end: fromUnixTime(conv.endDate),
    }).map((day) => format(day, 'MMM dd'));
  }, [dateRange, result?.results]);

  const viewsData = useMemo(
    () => ({
      labels: chartLabels,
      datasets: [
        {
          label: 'views',
          data: result.results.map((dailyResult) => dailyResult.views),
          fill: true,
          pointBorderWidth: 3,
          pointRadius: 7,
          pointBorderColor: 'rgb(255,255,255)',
          pointHoverBorderColor: 'rgb(255,255,255)',
          borderColor: 'rgb(153,207,245)',
          pointBackgroundColor: 'rgb(153,207,245)',
          pointHoverBackgroundColor: 'rgb(153,207,245)',
          backgroundColor: 'rgba(153,207,245,0.05)',
        },
      ],
    }),
    [chartLabels, result],
  );

  const clicksData = useMemo(
    () => ({
      labels: chartLabels,
      datasets: [
        {
          label: 'clicks',
          data: result.results.map((dailyResult) => dailyResult.clicks),
          fill: true,
          pointBorderWidth: 3,
          pointRadius: 7,
          pointBorderColor: 'rgb(255,255,255)',
          pointHoverBorderColor: 'rgb(255,255,255)',
          borderColor: 'rgb(143,107,186)',
          pointBackgroundColor: 'rgb(143,107,186)',
          pointHoverBackgroundColor: 'rgb(143,107,186)',
          backgroundColor: 'rgba(143,107,186,0.05)',
        },
      ],
    }),
    [chartLabels, result],
  );

  const clickRateData = useMemo(
    () => ({
      labels: chartLabels,
      datasets: [
        {
          label: 'clickrate',
          data: result.results.map(({ views, clicks }) => (views ? round((clicks / views) * 100, 1) : 0)),
          fill: true,
          pointBorderWidth: 3,
          pointRadius: 7,
          pointBorderColor: 'rgb(255,255,255)',
          pointHoverBorderColor: 'rgb(255,255,255)',
          borderColor: 'rgb(165,172,188)',
          pointBackgroundColor: 'rgb(165,172,188)',
          pointHoverBackgroundColor: 'rgb(165,172,188)',
          backgroundColor: 'rgba(165,172,188,0.05)',
        },
      ],
    }),
    [chartLabels, result],
  );

  const conversionsData = useMemo(
    () => ({
      labels: chartLabels,
      datasets: [
        {
          label: 'conversionrate',
          data: result.results.map((dailyResult) => dailyResult.conversions),
          fill: true,
          pointBorderWidth: 3,
          pointRadius: 7,
          pointBorderColor: 'rgb(255,255,255)',
          pointHoverBorderColor: 'rgb(255,255,255)',
          borderColor: 'rgb(165,172,188)',
          pointBackgroundColor: 'rgb(138,188,0)',
          pointHoverBackgroundColor: 'rgb(138,188,0)',
          backgroundColor: 'rgba(138,188,0,0.05)',
        },
      ],
    }),
    [chartLabels, result],
  );

  return (
    <div className="mt-6 min-w-[900px] max-w-[1500px] space-y-5 rounded border border-mystic-500 bg-white p-8 shadow">
      <ScrollingLineChart
        data={viewsData}
        options={commonChartOptions}
        plugins={plugins}
        id="1"
        title="Views"
        height={200}
      />
      <ScrollingLineChart
        data={clicksData}
        options={commonChartOptions}
        plugins={plugins}
        id="2"
        title="Clicks"
        height={200}
      />
      <ScrollingLineChart
        data={clickRateData}
        options={ClickRateChartOptions}
        plugins={plugins}
        id="3"
        title="Click rate"
        height={200}
      />
      {hasActiveGoal && !hasDataIssue && (
        <ScrollingLineChart
          data={conversionsData}
          options={commonChartOptions}
          plugins={plugins}
          id="4"
          title="Conversions"
          height={200}
        />
      )}
    </div>
  );
}

export function generateCTAFilterUrl(id, convertedDateRange) {
  return JSON.stringify({
    version: RECORDINGS_FILTER_VERSION,
    operator: LOGICALS.AND,
    conditions: [
      {
        criteria: 'cta',
        comparison: 'all of',
        values: [id],
      },
      {
        criteria: 'date_range',
        value: {
          start_date: convertedDateRange.startDate,
          end_date: convertedDateRange.endDate,
        },
      },
    ],
  });
}
