import { useMemo, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Spinner, capitalize } from '@crazyegginc/hatch';

import { useSurveyResponseFilter } from '/src/features/addons/survey-response-filter-context';
import { surveyResponsesQuery } from '/src/features/addons/queries';
import { SURVEY_QUESTION_TYPES, TEXT_ENTRY_SUBTYPES, RATING_SUBTYPES } from '/src/features/addons/constants';
import { NoMatchesWall } from '/src/features/_global/paywalls/NoMatchesWall';
import { SectionPanel, SectionHeader, SectionContent } from '../../../common/basic-ui';
import { ActionsRow } from './ActionsRow';
import { Navigator } from './Navigator';
import { MetadataBox } from './MetadataBox';
import { EmojiFaces } from '../EmojiFaces';
import { sentimentMeta } from '../summary/TextEntry';
import { processUnsafeText } from '../../../common/common-functions';
import { isProduction } from '/src/utils';

export function IndividualTab({ survey, convertedDateRange, resetDateRange }) {
  const isProd = isProduction();
  const [perfMetricStarted, setPerfMetricStarted] = useState(false);
  const { index, updateIndex } = useSurveyResponseFilter();

  const {
    data,
    isFetching,
    error,
    refetch: refetchResponse,
  } = useQuery({
    ...surveyResponsesQuery({
      id: survey.id,
      index: index - 1,
      startDate: convertedDateRange.startDate,
      endDate: convertedDateRange.endDate,
    }),
  });

  useEffect(() => {
    if (window.CE2?.timing && isProd) {
      window.CE2.timing.start('survey_results_individual');
      setPerfMetricStarted(true);
    }
  }, [isProd]);

  useEffect(() => {
    if (perfMetricStarted && window.CE2?.timing && isProd && (data || error) && !isFetching) {
      try {
        window.CE2.timing.stop('survey_results_individual');
        setPerfMetricStarted(false);
      } catch {
        // prevent metrics error thrown from crashing the app
        setPerfMetricStarted(false);
      }
    }
  }, [perfMetricStarted, data, error, isProd, isFetching]);

  const parsedSurveyContent = useMemo(() => {
    let parsedContent = {};
    try {
      parsedContent = JSON.parse(survey.content);
    } catch {
      //noop
    }
    return parsedContent;
  }, [survey]);

  const questions = useMemo(
    () =>
      (parsedSurveyContent?.questions ?? [])
        .filter((question) => question.type !== SURVEY_QUESTION_TYPES.STATIC_DISPLAY)
        .sort((a, b) => a.position - b.position),
    [parsedSurveyContent],
  );

  useEffect(() => {
    if ((!isFetching && !data?.surveyResponses?.response) || error) {
      updateIndex(1);
    }
  }, [isFetching, data, error, updateIndex]);

  if (isFetching) {
    return (
      <div className="mt-6 flex w-full items-center justify-center">
        <Spinner />
        <div className="ml-2.5">Loading...</div>
      </div>
    );
  }

  if (!data || error) {
    return null;
  }

  const { total, response } = data.surveyResponses;

  if (survey.responseCount > 0 && !response) {
    return <NoMatchesWall reset={resetDateRange} text="matches for this date range" actionText="date range" />;
  }

  return (
    <div className="flex min-w-[900px] max-w-[1500px] items-start space-x-5">
      <section className="min-w-min grow">
        <ActionsRow survey={survey} response={response} refetchResponse={refetchResponse} total={total} />

        <div className="flex flex-col space-y-10">
          {response.questions.map((r, index) => {
            const question = questions.find((q) => q.id === r.questionId);
            const safeHtmlTitle = processUnsafeText(r.questionText);

            return (
              <SectionPanel key={index} className="!ml-0 !bg-white first-of-type:!mt-0">
                <SectionHeader className="!h-auto !min-h-[44px] !px-3.75">
                  <span className="flex items-center space-x-4">
                    <div
                      className={classNames(
                        'flex h-[30px] w-[30px] shrink-0 items-center justify-center rounded-full border border-mystic-500 bg-white pr-px pt-px',
                        'text-md font-semibold leading-none text-lynch-500',
                      )}
                      data-testid="question-number"
                    >
                      {r.questionPosition + 1}
                    </div>
                    <div
                      className="flex"
                      dangerouslySetInnerHTML={{ __html: safeHtmlTitle }}
                      data-testid="question-title"
                    />
                  </span>
                </SectionHeader>
                <SectionContent className="!mx-3.75 !mb-10">
                  <div className="mx-3.75 !mt-5">
                    <ResponseContent question={question} response={r} />
                  </div>
                </SectionContent>
              </SectionPanel>
            );
          })}
        </div>
      </section>
      <section className="mb-10 w-[310px] grow-0 xl:w-[400px]">
        <Navigator index={index} total={total} updateIndex={updateIndex} />
        <MetadataBox response={response} />
      </section>
    </div>
  );
}

function ResponseContent({ question, response }) {
  const [type, subtype] = response.questionType.split('/');
  switch (type) {
    case SURVEY_QUESTION_TYPES.RATING:
      switch (subtype) {
        case RATING_SUBTYPES.NPS:
          return <NPS response={response} />;
        case RATING_SUBTYPES.STARS:
          return <Stars response={response} />;
        case RATING_SUBTYPES.SMILEY:
          return <SmileyFaces response={response} />;
        case RATING_SUBTYPES.NUMERICAL:
          return <Numerical response={response} question={question} />;
        default:
          return null;
      }
    case SURVEY_QUESTION_TYPES.MULTI_CHOICE:
      return <MultiChoice response={response} />;
    case SURVEY_QUESTION_TYPES.TEXT_ENTRY:
      return <TextEntry response={response} subtype={subtype} />;
    default:
      return null;
  }
}

function TextEntry({ response, subtype }) {
  if (subtype === TEXT_ENTRY_SUBTYPES.EMAIL) {
    if (response.textResponse) {
      return (
        <a className="text-body-2 text-dodger-blue-500 hover:underline" href={`mailto:${response.textResponse}`}>
          {response.textResponse}
        </a>
      );
    }
    return null;
  }

  return (
    <>
      <div className="text-body-2" dangerouslySetInnerHTML={{ __html: processUnsafeText(response.textResponse) }} />
      <div className="mt-1 flex items-center space-x-2.5">
        <div className="h-[7px] w-[7px] rounded-full" style={{ background: sentimentMeta[response.sentiment].color }} />
        <span className="text-body-4">{capitalize(response.sentiment)}</span>
      </div>
    </>
  );
}

function MultiChoice({ response }) {
  return (
    <div className="text-body-2">
      <ul>
        {response.textResponse.split('\n').map((r, i) => (
          <li key={`${response.questionId}-${i}`} dangerouslySetInnerHTML={{ __html: processUnsafeText(r) }} />
        ))}
      </ul>
    </div>
  );
}

function Numerical({ response, question }) {
  return (
    <div className="text-body-2">
      <span className="text-body-1">Rating:</span> {response.integerResponse}
      {question ? `/${question.max.value}` : ''}
    </div>
  );
}

function SmileyFaces({ response }) {
  return (
    <>
      <div className="text-body-2">
        <span className="text-body-1">Rating:</span> {response.integerResponse}/5
      </div>
      <div className="mt-1 flex items-center whitespace-pre">{EmojiFaces(response.integerResponse)}</div>
    </>
  );
}

function Stars({ response }) {
  return (
    <>
      <div className="text-body-2">
        <span className="text-body-1">Rating:</span> {response.integerResponse}/5
      </div>
      <div className="flex items-center whitespace-pre">{`⭐️ `.repeat(response.integerResponse)}</div>
    </>
  );
}

function NPS({ response }) {
  let result;
  if (response.integerResponse >= 9) {
    result = ['Promoter', 'bg-malibu-500'];
  } else if (response.integerResponse < 7) {
    result = ['Detractor', 'bg-pink-salmon-500'];
  } else {
    result = ['Passive', 'bg-dandelion-500'];
  }

  return (
    <>
      <div className="text-body-2">
        <span className="text-body-1">Score:</span> {response.integerResponse}/10
      </div>
      <div className="flex items-center space-x-2.5">
        <div className={classNames('h-[7px] w-[7px] rounded-full', result[1])} />
        <span className="text-body-4">{result[0]}</span>
      </div>
    </>
  );
}
