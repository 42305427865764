import { useModal } from '/src/hooks';
import { ABTEST } from '/src/features/ab-testing/constants';
import { PreviewLoadingModal } from '/src/features/_global/modals/PreviewLoadingModal';

export function PreviewLink({ variant, children }) {
  const modal = useModal();

  if (!variant.id || !variant.viewVariantUrl) {
    // we need the variant to be stored for the link to work
    return null;
  }

  return (
    <button
      onClick={() => {
        modal.show(<PreviewLoadingModal type={ABTEST} target="tab" spelledType="A/B Test" />);
        launchPreview(variant);
      }}
    >
      {children}
    </button>
  );
}

function launchPreview({ id, viewVariantUrl: url }) {
  window.open(url, `_preview_variant_${id}`);
}
