import { Link } from 'react-router-dom';
import { isFuture, fromUnixTime } from 'date-fns';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, useMetric, useModal } from '/src/hooks';
import { formatDate } from '/src/utils/date';
import { abbreviatedIntervalType } from '/src/utils/string';
import { addCETag } from '../utils';

import { EnterprisePlanModal } from '../components/modals/EnterprisePlanModal';
import { Price, SectionHeader } from '../components/accountUI';
import { PlanSubscriptionInfo } from '../components/PlanSubscriptionInfo';

import {
  MANAGE_SUBSCRIPTION_PATH,
  PLAN_TYPES,
  SUBSCRIPTION_STATES,
  PLAN_PLATFORMS,
} from '/src/features/_global/constants';

const { CANCELING, CANCELED, EXPIRING, EXPIRED } = SUBSCRIPTION_STATES;

export function YourPlan({ snapshotAddon, currentPlanTitle }) {
  const { subscription } = useAuthContext();
  const isFreemium = subscription.plan.type === PLAN_TYPES.FREEMIUM;

  return (
    <>
      <SectionHeader>Your plan</SectionHeader>

      <div className="flex w-full space-x-5">
        <PlanInfoPanel snapshotAddon={snapshotAddon} currentPlanTitle={currentPlanTitle} />
        {isFreemium ? <BillingUpgradeCtaPanel /> : <NextBillingPanel />}
      </div>
    </>
  );
}

function PlanInfoPanel({ snapshotAddon, currentPlanTitle }) {
  const modal = useModal();
  const trackMetric = useMetric();
  const { currentAccount, currentUser, subscription } = useAuthContext();
  const {
    paymentPlan: { id: paymentPlanId },
    intervalType,
    id,
  } = currentUser.subscriptions.snapshots;
  const isEnterprise = currentUser?.subscriptions?.snapshots?.paymentPlan?.isEnterprise === true;

  const isTrial = subscription.plan.type === PLAN_TYPES.TRIAL;
  const isShopify = subscription.plan.platform === PLAN_PLATFORMS.SHOPIFY;
  const shopifyLink = currentAccount.shopifyDomain
    ? `https://${currentAccount.shopifyDomain}/admin/apps`
    : 'https://www.shopify.com';

  const expiring15dayTrials = isFuture(fromUnixTime(subscription.endDate)) && [181, 197].includes(paymentPlanId);
  const isFreemium = subscription.plan.type === PLAN_TYPES.FREEMIUM;

  return (
    <section className="flex w-2/3 flex-col justify-between rounded-md bg-white-lilac-500 p-[35px]">
      <div className="flex justify-between">
        <div>
          <h3 className="text-header-2 mb-2.5" id="currentPlan">
            {currentPlanTitle} Plan
          </h3>
          <PlanSubscriptionInfo currentPlanTitle={currentPlanTitle} />
        </div>
        <Price
          amount={Number(subscription.plan.price).toLocaleString()}
          period={abbreviatedIntervalType(intervalType)}
        />
      </div>
      <div className="flex">
        {isEnterprise ? (
          <Button
            onClick={() => {
              addCETag('enterprise_change_plan');
              modal.show(<EnterprisePlanModal planDetail={snapshotAddon} email={currentUser.email} />);
            }}
            className="!mt-[30px] !self-start"
          >
            Change Plan
          </Button>
        ) : (
          <Button
            component={Link}
            to={MANAGE_SUBSCRIPTION_PATH}
            className="!mt-[30px] !self-start"
            onMouseDown={() => {
              addCETag('non_enterprise_change_plan');
              if (isTrial) {
                trackMetric('upgrade_cta_click', 'trial_choose_plan_button');
              }
            }}
          >
            {[CANCELING, CANCELED, EXPIRING, EXPIRED].includes(subscription.state) && !expiring15dayTrials
              ? 'Choose a plan to re-subscribe'
              : isTrial
              ? 'Upgrade Now'
              : 'Change Plan'}
          </Button>
        )}

        {!([CANCELED, CANCELING, EXPIRED].includes(subscription.state) || isFreemium) && (
          <>
            {isShopify ? (
              <Button
                className="!mt-[30px] !self-start"
                variant="cancel"
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={shopifyLink}
              >
                Cancel subscription
              </Button>
            ) : (
              <Button
                component={Link}
                to={`/subscriptions/${id}/cancel`}
                className="!mt-[30px] !self-start"
                variant="cancel"
              >
                Cancel subscription
              </Button>
            )}
          </>
        )}
      </div>
    </section>
  );
}

function NextBillingPanel() {
  const { subscription } = useAuthContext();
  const isShopify = subscription.plan.platform === PLAN_PLATFORMS.SHOPIFY;

  let nextPayment = 'N/A';

  if (subscription.state === EXPIRED) {
    nextPayment = 'N/A';
  } else if ([CANCELED, CANCELING].includes(subscription.state)) {
    nextPayment = `Plan cancelled`;
  } else if (subscription.endDate && subscription.endDate !== -1) {
    nextPayment = formatDate(subscription.endDate);
  }

  return (
    <section className="flex w-1/3 flex-col justify-between rounded-md bg-white-lilac-500 p-[35px]">
      <div>
        <h3 className="text-body-4">Next payment</h3>
        {isShopify ? 'Billing via Shopify' : <div className="text-header-4 mt-2.5">{nextPayment}</div>}
      </div>
      {!isShopify && (
        <Button component={Link} to={`/account/billing`} className="!mt-[30px] !self-start" variant="secondary">
          Manage billing
        </Button>
      )}
    </section>
  );
}

function BillingUpgradeCtaPanel() {
  const trackMetric = useMetric();

  return (
    <div className="flex w-1/3 flex-col justify-between rounded-md bg-riptide-500 p-[35px] pt-[30px] text-black-pearl-500">
      <h3 className="text-header-5">Want Heatmaps, Recordings, Traffic Analysis, A/B Testing & Error Tracking?</h3>

      <p className="text-body-2 mt-2.5">Upgrade your plan to get the full suite of Crazy Egg features.</p>

      <Button
        component={Link}
        to={MANAGE_SUBSCRIPTION_PATH}
        className="text-body-2 !mt-3.75 !h-[30px] min-w-[184px] !self-start !bg-black-pearl-500 !text-white hover:!bg-black-pearl-500"
        onMouseDown={() => trackMetric('upgrade_cta_click', 'plan_view_and_compare_plans')}
      >
        View and compare plans
      </Button>
    </div>
  );
}
