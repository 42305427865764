import React from 'react';
import { createContext, useState, useEffect, useMemo } from 'react';
import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from '@tanstack/react-query';
import Honeybadger from '@honeybadger-io/js';

import { newClient } from './urql';
import { clearCookies } from '/src/utils/cookies';
import { selectivelyClearLocalStorage } from '/src/utils/storage';
import { clearContext as clearHoneybadgerCtx } from '/src/utils/honeybadger';

import { MAINTENANCE_URL } from '/src/features/_global/constants';
import { checkSharingSession } from '/src/utils';

export const ClientContext = createContext();

function isInternalServerError(err) {
  return ['Internal Server Error'].some((msg) => err.message.indexOf(msg) > -1);
}

function isTokenError(err) {
  return ['Expired token', 'Invalid token', 'Malformed token', 'No user session'].some(
    (msg) => err.message.indexOf(msg) > -1,
  );
}

function isAdminError(err) {
  return ['Invalid admin credentials'].some((msg) => err.message.indexOf(msg) > -1);
}

function isNetworkError(err) {
  return [
    'The network connection was lost',
    'Failed to fetch',
    'The Internet connection appears to be offline',
    'Network request failed',
    'NetworkError when attempting to fetch resource',
  ].some((msg) => err.message.indexOf(msg) > -1);
}

function isSessionError(err) {
  return ['User not found'].some((msg) => err.message.indexOf(msg) > -1);
}

function isNormalError(err) {
  return ['recording not found'].some((msg) => err.message.indexOf(msg) > -1);
}

function isMaintenanceRedirect(err) {
  return err.response?.status === 503 || ['Service Unavailable'].some((msg) => err.message.indexOf(msg) > -1);
}

function maybeHandleServerError(error) {
  try {
    const response = JSON.parse(error.graphQLErrors[0].originalError.message);
    const mainReasons = response.error.reason.match(/^\*\* \((\S*)\) ([^\n]*)/);
    const reason2WithoutVariablePart = mainReasons[2].replace(/\d+ms/, '');
    if (mainReasons) {
      Honeybadger.notify(mainReasons[2], {
        context: { ...response },
        name: `Internal Server Error - ${mainReasons[1]}`,
        fingerprint: `${mainReasons[1]} - ${reason2WithoutVariablePart}`,
      });
    } else {
      Honeybadger.notify('Internal Server Error', {
        context: { ...response },
      });
    }
  } catch {
    console.error(error);
  }
}

function shouldIgnoreError(error) {
  const query = error.graphQLErrors?.[0]?.path[0];
  const msg = error.message;
  return [
    { query: 'checkTitle', error: /Invalid URL/ },
    { query: 'sharedResource', error: /Resource site doesn't exist/ },
  ].some((x) => {
    return query === x.query && x.error.test(msg);
  });
}

function getVariables(queryKey, pageParam) {
  let variables =
    queryKey.length > 1 && typeof queryKey[queryKey.length - 1] !== 'string' ? queryKey[queryKey.length - 1] : null;
  if (pageParam) {
    variables = {
      ...(variables ?? {}),
      cursor: pageParam,
    };
  }
  return variables;
}

export function ClientProvider({ initialClient = null, children }) {
  const [client, setClient] = useState(initialClient || null);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError: (error) => {
            if (isTokenError(error)) {
              window.dispatchEvent(new Event('gql:tokenError'));
              // handled by the auth state machine
            }
          },
        }),
        queryCache: new QueryCache({
          onError: (error, query) => {
            const isSharingSession = checkSharingSession();
            if (isInternalServerError(error)) {
              maybeHandleServerError(error);
            } else if (isMaintenanceRedirect(error)) {
              window.location.replace(MAINTENANCE_URL);
            } else if (isAdminError(error)) {
              // redirect to the admin logout
              // https://crazyegg.slack.com/archives/C01NC1M383V/p1670876657438079?thread_ts=1670847974.347419&cid=C01NC1M383V
              clearHoneybadgerCtx();
              clearCookies();
              selectivelyClearLocalStorage();
              window.sessionStorage.clear();
              window.accountChannel?.postMessage?.({
                message: 'logout',
              });

              window.location.href = `${window.LEGACY_APP_URL}/admin/logout/redirect`;
            } else if (isSessionError(error)) {
              // handled by the auth state machine
            } else if (isTokenError(error) && !isSharingSession) {
              window.dispatchEvent(new Event('gql:tokenError'));
              // handled by the auth state machine
            } else if (isNetworkError(error)) {
              // network errors are already handled by retry
              // noop
            } else if (shouldIgnoreError(error)) {
              //ignore some expected errors
              //noop
            } else if (isNormalError(error)) {
              //ignore some normal errors - generally not found errors
              //noop
            } else if (error.message.indexOf('[GraphQL]') >= 0 && import.meta.env.PROD) {
              // something else went wrong, report it
              Honeybadger.notify(error.message || 'GraphQLError', {
                context: {
                  error,
                  query: query?.meta?.query?.loc?.source?.body,
                  variables: getVariables(query.queryKey),
                },
                name: `GraphQL error in ${error.graphQLErrors?.[0]?.path}`,
              });
            }
          },
        }),
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
              if ((failureCount < 3 && isNetworkError(error)) || isTokenError(error)) return true;
              return false;
            },
            useErrorBoundary: true,
            staleTime: 60 * 1000,
            queryFn: async ({ queryKey, meta, pageParam }) => {
              const variables = getVariables(queryKey, pageParam);
              const { data, error, operation } = await client.query(meta.query, variables);
              const queryName = operation.query.definitions[0].name.value;
              const stringVars = JSON.stringify(operation.variables);
              if (error) {
                Honeybadger.addBreadcrumb(`[GQL query]: ${queryName}`, {
                  category: 'error',
                  metadata: { variables: stringVars, error: JSON.stringify(error) },
                });
                throw error;
              } else {
                Honeybadger.addBreadcrumb(`[GQL query]: ${queryName}`, {
                  category: 'query',
                  metadata: { variables: stringVars },
                });
              }
              return data;
            },
          },
          mutations: {
            retry: (failureCount, error) => {
              if ((failureCount < 3 && isNetworkError(error)) || isTokenError(error)) return true;
              return false;
            },
          },
        },
      }),
    [client],
  );

  useEffect(() => {
    // if we already have an initialClient, don't bother waiting for one
    if (client) return;
    setClient(newClient());
  }, [client]);

  useEffect(() => {
    function refreshClient() {
      setClient(newClient());
      queryClient.clear();
    }
    window.addEventListener('account:refetch', refreshClient);
    return () => window.removeEventListener('account:refetch', refreshClient);
  }, [queryClient]);

  return (
    <ClientContext.Provider value={{ client }}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ClientContext.Provider>
  );
}
