import { formatDistanceStrict, fromUnixTime } from 'date-fns';

import { formatDate } from '/src/utils/date';
import {
  abTestTypes,
  abTestVariantTypes,
  AB_TEST_STATUSES,
  VARIANT_STATUSES,
} from '/src/features/ab-testing/constants';
import { convertKeysToCamelCase } from '/src/utils/string';
import { createEmptyVariant, parseExistingVariant } from './machines/variant-editor';
import { GOAL_PURPOSES } from '/src/features/goals/constants';

import { isNumber } from '/src/utils';

export function generateVariantEditorPatches({ variants, autoReweight = true }) {
  return variants
    .map((variant) => {
      if (variant.type === 'control') {
        return {
          id: isNumber(variant.id) ? variant.id : undefined,
          type: abTestVariantTypes.CONTROL,
          editorPatch: null,
          weight: !autoReweight && variant.weight ? parseFloat(variant.weight) : undefined,
          status: undefined,
        };
      }
      return {
        id: isNumber(variant.id) ? variant.id : undefined,
        type: abTestVariantTypes.VARIANT,
        editorPatch: JSON.stringify(variant.editorPatch),
        weight: !autoReweight && variant.weight ? parseFloat(variant.weight) : undefined,
        status: undefined,
      };
    })
    .filter(Boolean);
}

export function generateSplitVariantPayload({ variants, autoReweight = true }) {
  return variants
    .map((variant) => {
      if (variant.type === 'control') {
        return {
          id: isNaN(variant.id) ? undefined : variant.id,
          type: abTestVariantTypes.CONTROL,
          redirectUrl: null,
          weight: !autoReweight && variant.weight ? parseFloat(variant.weight) : undefined,
          status: undefined,
        };
      }
      return {
        id: isNaN(variant.id) ? undefined : variant.id,
        type: abTestVariantTypes.VARIANT,
        redirectUrl: variant.redirectUrl,
        weight: !autoReweight && variant.weight ? parseFloat(variant.weight) : undefined,
        status: undefined,
      };
    })
    .filter(Boolean);
}

export function generateVariantPayload({ variants, type, autoReweight = true }) {
  const nonPristineVariants = variants.filter((v) => !['DELETED', 'PRISTINE'].includes(v.status));
  const activeVariants = variants.filter((v) => v.status !== 'DELETED');
  const processedType = type.toUpperCase();
  if (processedType === abTestTypes.SPLIT) {
    return generateSplitVariantPayload({ variants: activeVariants, autoReweight });
  } else if (processedType === abTestTypes.PATCH) {
    return generateVariantEditorPatches({ variants: nonPristineVariants, autoReweight });
  }
  return null;
}

export function abTestDuration(abTest) {
  if (!abTest) return null;

  if (abTest.status === AB_TEST_STATUSES.DRAFT) {
    return {
      value: 'Not yet launched.',
      label: 'duration',
    };
  }

  if (abTest.duration) {
    return {
      value: formatDistanceStrict(0, fromUnixTime(abTest.duration)),
      label: 'duration',
    };
  }

  if (abTest.publishedAt) {
    return {
      value: formatDistanceStrict(abTest.stoppedAt || new Date(), fromUnixTime(abTest.publishedAt)),
      label: 'duration',
    };
  }

  return {
    value: formatDistanceStrict(abTest.stoppedAt || new Date(), fromUnixTime(abTest.createdAt)),
    label: 'duration',
  };
}

export function abTestCreatedBy(abTest) {
  if (!abTest) return null;

  if (abTest.createdAt) {
    return {
      label: 'created',
      value: formatDate(abTest.createdAt),
      author: abTest.createdByUserName,
    };
  }
}

export function calcTotalWeight(variants) {
  return variants.reduce(
    (acc, variant) => acc + parseFloat(variant.weight && variant.weight !== '' ? variant.weight : 0),
    0,
  );
}

export function balanceWeights(variants, force) {
  const activeVariants = variants.filter(
    (variant) => variant.status !== VARIANT_STATUSES.DELETED && !variant.isRetired,
  );

  const sum = calcTotalWeight(activeVariants);

  const variantWeights = activeVariants.map((variant) => parseFloat(variant?.weight ?? 0));

  if (Math.abs(sum - 100) < 1 || force) {
    const diff = 100 - sum;

    const adjustment = diff / activeVariants.length;

    const adjustedWeights = variantWeights.map((weight) => parseFloat((weight + adjustment).toFixed(1)));

    const adjustedSum = adjustedWeights.reduce((acc, weight) => acc + weight, 0);

    if (adjustedSum !== 100) {
      const lastIndex = adjustedWeights.length - 1;
      adjustedWeights[lastIndex] = parseFloat((adjustedWeights[lastIndex] + (100 - adjustedSum)).toFixed(1));

      return {
        total: 100,
        weights: adjustedWeights,
        variants: activeVariants.map((v, i) => ({ ...v, weight: adjustedWeights[i] })),
      };
    }

    return {
      total: adjustedSum,
      weights: adjustedWeights,
      variants: activeVariants.map((v, i) => ({ ...v, weight: adjustedWeights[i] })),
    };
  } else {
    return { total: sum, weights: variantWeights, variants: activeVariants };
  }
}

export function convertDraftToPayload(id, draft, storedVariants) {
  const payload = convertKeysToCamelCase(JSON.parse(draft));
  const type = payload.type.toUpperCase();

  return {
    ...payload,
    id,
    devices: payload.devices.map((d) => d.toUpperCase()),
    type,
    variants: storedVariants.map((v) => parseExistingVariant(v, type)),
    goal: payload.goal
      ? {
          ...payload.goal,
          purpose: payload.goal?.purpose?.toUpperCase?.() ?? GOAL_PURPOSES.MORE_PAGE_VIEWS,
          triggers: payload.goal.triggers.map((t) => ({
            ...t,
            uiMode: t.uiMode.toUpperCase(),
          })),
        }
      : undefined,
  };
}

export function getInitialVariantStatus(variant) {
  if (variant.type === 'control') {
    return 'STATIC';
  }
  if (variant.status) {
    return variant.status;
  }
  if (isNaN(variant.id) && Object.keys(variant.editorPatch).length > 0) {
    return 'DIRTY'; // it is a draft or was before
  }
  return 'PRISTINE';
}

export function prepareVariantsForEdit(variants, { createBlank = true } = {}) {
  if (!variants) {
    return null;
  }
  const variantsOnly = variants.filter((v) => v.type === 'variant');
  const payloadVariants = variants.map((v) => ({
    ...v,
    status: getInitialVariantStatus(v),
  }));
  const newVariant = !createBlank || variantsOnly.length ? null : createEmptyVariant(variants.length);
  return [...payloadVariants, newVariant].filter(Boolean);
}

function goalToArgs(goal) {
  return {
    ...goal,
    purpose: goal.purpose.toUpperCase(),
    triggers: goal.triggers.map((t) => ({
      ...t,
      elementSelectorType: t.elementSelectorType?.toUpperCase?.(),
      trackingOption: t.trackingOption?.toUpperCase?.(),
      uiMode: t.uiMode.toUpperCase(),
    })),
  };
}

export function contextToArgs(context) {
  const { id, goal, variants, ...rest } = context.payload; // eslint-disable-line

  return {
    ...rest,
    siteId: context.siteId || context.payload.siteId,
    variants: generateVariantPayload({
      variants: context.variants || variants,
      type: context.payload.type,
      autoReweight: context.payload.autoReweight,
    }),
    goalId: isNumber(context.payload.goalId) ? context.payload.goalId : null,
    goal: isNumber(context.payload.goalId) ? undefined : context.goal ? goalToArgs(context.goal) : undefined,
  };
}

export function payloadToArgs(payload) {
  const { id, autoReweight, goalId, siteId, type, goal, variants, ...rest } = payload; // eslint-disable-line

  return {
    ...rest,
    siteId: payload.siteId,
    variants: generateVariantPayload({
      variants,
      type,
      autoReweight,
    }),
    goalId: goalId !== 'draft' ? goalId : null,
    goal: undefined,
  };
}

export async function syncVariants(
  id,
  context,
  { mutateAddVariants, mutateUpdateDraft, mutateDeleteVariant, mutateUpdateVariants },
) {
  const variants = context.variants || context.payload.variants;
  if (!variants) return null;

  const operations = {
    create: variants.filter((v) => isNaN(v.id) && v.status === 'DIRTY'),
    update: variants.filter((v) => isNumber(v.id) && v.status === 'DIRTY'),
    delete: variants.filter((v) => isNumber(v.id) && v.status === 'DELETED'),
  };

  const payload = context.payload;

  await Promise.all(
    [
      operations.create.length
        ? mutateAddVariants({
            id,
            variants: generateVariantPayload({
              variants: operations.create,
              type: payload.type,
              autoReweight: payload.autoReweight,
            }),
          })
        : null,
      operations.update.length
        ? mutateUpdateVariants({
            id,
            variants: generateVariantPayload({
              variants: operations.update,
              type: payload.type,
              autoReweight: payload.autoReweight,
            }),
          })
        : null,
      operations.delete.length
        ? operations.delete.map((v) => {
            return mutateDeleteVariant({
              id,
              variantId: v.id,
            });
          })
        : null,
      mutateUpdateDraft
        ? mutateUpdateDraft({
            id,
            args: contextToArgs(context),
          })
        : null,
    ]
      .filter(Boolean)
      .flat(),
  );
}
