import { yupToFormErrors } from 'formik';

import { AUTH_TOKEN_KEY, FEATURES } from '/src/features/_global/constants';
import { SHAREABLE_ROUTES } from '/src/features/team-and-sharing/constants';
import { decodedToken, hasLoginToken } from '/src/utils/auth';
import { CURRENCIES } from '/src/features/_global/constants';

const { GOALS, SURVEYS } = FEATURES;

export function preloadImage(url) {
  const image = new Image();
  image.src = url;
}

export function debounce(func, timeout) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export function throttle(func, timeout) {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), timeout);
    }
  };
}

export function copyToClipboard(val) {
  navigator.clipboard.writeText(val);
}

export function arraysEqual(arr1, arr2, key = null) {
  return (
    arr1.length === arr2.length &&
    arr1.every(function (item, i) {
      if (key) {
        return item[key] === arr2[i][key];
      }
      return item === arr2[i];
    })
  );
}

export function arraysEqualIgnoreOrder(a, b) {
  return a.length === b.length && a.every((element) => b.includes(element));
}

export const scrollToElement = (element, yOffset = 100, duration = 500) => {
  const elementBounds = element.getBoundingClientRect();
  const startingY = window.pageYOffset;
  const diff = elementBounds.y - yOffset - startingY;
  let start;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // elapsed time in milliseconds since starting scroll
    const time = timestamp - start;
    const percentage = Math.min(time / duration, 1);
    window.scrollTo(0, startingY + diff * percentage);

    // proceed with animation
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
};

export function checkShareSession() {
  const { host } = window.location;
  return host.indexOf('share') === 0;
}

export function getSharingToken() {
  const authToken = window.shr;
  if (authToken) return authToken;

  return null;
}

export function getShareTokenPayload() {
  const shareToken = getSharingToken();
  if (!shareToken) return null;

  const tokenPayload = decodedToken(shareToken.replace('share_', ''));
  if (!tokenPayload) return null;

  return tokenPayload;
}

export function getShareCodeFromUrl() {
  if (window.location.pathname.includes('/s/')) {
    return window.location.pathname.replace('/s/', '');
  }
  if (window.location.search.includes('code=')) {
    const search = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    return search.code;
  }
  return null;
}

export function hasSharingToken(token) {
  const tokenPayload = decodedToken(token.replace('share_', ''));
  if (!tokenPayload) return false;
  return Boolean((tokenPayload.shared_items && !tokenPayload.sub) || tokenPayload.shared_item);
}

export function checkSharingSession() {
  return (
    SHAREABLE_ROUTES.some((route) => window.location.pathname.match(route)) &&
    (window.location.host.indexOf('share.') === 0 ||
      window.ce_sharingSession === true ||
      window.location.pathname.indexOf('sharing') >= 0 ||
      window.location.search.indexOf('sharing=1') > 0)
  );
}

export const extendTypekitFontFace = function () {
  if (typeof MutationObserver === 'undefined') {
    return;
  }
  const fixFontDisplay = function () {
    // inject font-display option into typekit fonts
    const styles = document.getElementsByTagName('style');
    for (const style of styles) {
      if (
        style.innerText &&
        style.innerText.indexOf('proxima-nova') !== -1 &&
        style.innerText.indexOf('@font-face{font-display:swap;') === -1
      ) {
        style.innerText = style.innerText.split('@font-face{').join('@font-face{font-display:swap;');
      }
    }
  };
  const observer = new MutationObserver(function (mutationsList) {
    for (let i = 0; i < mutationsList.length; i++) {
      fixFontDisplay();
    }
  });
  observer.observe(document.getElementsByTagName('head')[0], { attributes: false, childList: true, subtree: false });
  window.fixFontObserver = observer;
};

export function timeoutPromise(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export function isStaging() {
  if (import.meta.env.PROD) {
    if (window.ENVIRONMENT === 'staging') {
      return true;
    }
  }
  return false;
}

export function pick(obj, ...args) {
  return {
    ...args.reduce((res, key) => ({ ...res, [key]: obj[key] }), {}),
  };
}

export function pickFields(obj, fields) {
  if (!fields || fields.length === 0) {
    return {};
  }
  return fields.reduce((filtered, field) => {
    if (field in obj) {
      filtered[field] = obj[field];
    }
    return filtered;
  }, {});
}

export function rejectFields(obj, fields) {
  const result = { ...obj };
  fields.forEach((field) => {
    delete result[field];
  });
  return result;
}

export function isProduction() {
  if (import.meta.env.PROD) {
    if (window.ENVIRONMENT !== 'staging') {
      return true;
    }
  }
  return false;
}

export function isDevelopment() {
  if (import.meta.env.DEV || window.location.hostname === 'core.crazyeggdev.com') {
    return true;
  } else {
    return false;
  }
}

export function isE2E() {
  if (window.playwright || window.isE2E || window.location.port === '4173') {
    return true;
  }
  return false;
}

export function abbreviateNumber(input) {
  const NUM_SYMBOL = ['', 'K', 'M', 'B', 'T'];
  const tier = (Math.log10(Math.abs(input)) / 3) | 0;

  if (tier === 0) return input;

  const suffix = NUM_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  const scaled = input / scale;

  // just for in case
  if (!suffix) return input;

  return scaled.toFixed(1) + suffix;
}

export function checkEnterprise(currentUser) {
  return currentUser?.subscriptions.snapshots?.paymentPlan?.isEnterprise === true;
}

export function isSharing() {
  return checkSharingSession() && !hasLoginToken();
}

export function parseDecimal(input = 0, decimalPlaces = 2) {
  return parseFloat(
    parseFloat(input || 0)
      .toFixed(decimalPlaces)
      .toString(),
  );
}

export function isPreview() {
  return window.location.port === '4173';
}

export function flashElement(el) {
  el.scrollIntoView();
  el.classList.add('transition', 'duration-200', 'ease-in-out', '!border-dodger-blue-500');
  setTimeout(() => {
    el.classList.remove('!border-dodger-blue-500');
  }, 400);
}

export function removeHash() {
  history.pushState('', document.title, window.location.pathname + window.location.search);
}

export function getDocumentCoordinates(el) {
  let box = el.getBoundingClientRect();

  return {
    top: box.top + window.pageYOffset,
    right: box.right + window.pageXOffset,
    bottom: box.bottom + window.pageYOffset,
    left: box.left + window.pageXOffset,
  };
}

export function processNumberInput(e) {
  const validKey = /\d|\./.test(e.key);
  const inputSatisfied = /^\d{1,3}\.\d$/.test(e.target.value);
  const safeKeys = ['Tab', 'Escape', 'ArrowLeft', 'ArrowRight', 'Backspace'];
  const selection = document.getSelection();
  const isSelected = selection.type === 'Range' && selection.focusNode.contains(e.target);

  if (isSelected && validKey) {
    return;
  }

  if ((!validKey || inputSatisfied) && !safeKeys.includes(e.key)) {
    e.preventDefault();
  }
}

export function formatCurrency(value, currency = CURRENCIES.USD, { trimEmptyCents = false } = {}) {
  if (typeof value === 'number' && typeof currency === 'string') {
    let formattedValue = new Intl.NumberFormat('en', {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
    }).format(value);

    if (trimEmptyCents && value % 1 === 0) {
      // check if the value has no fractional cents
      // if it has, remove the ".00" part from the formatted value
      formattedValue = formattedValue.replace(/(\.0*|,0*)$/, '');
    }

    return formattedValue;
  } else {
    return value;
  }
}

export function getValidationErrors(schema, value) {
  let errors;
  try {
    schema.validateSync(value, { abortEarly: false });
  } catch (yupErrors) {
    errors = yupToFormErrors(yupErrors);
  }
  return errors;
}

const querySelectorCheck = (input) => document.createDocumentFragment().querySelector(input);

export function isValidCssSelector(input) {
  try {
    querySelectorCheck(input);
    return true;
  } catch {
    return false;
  }
}

const conversionType = (resourceType) => {
  switch (resourceType) {
    case GOALS:
      return 'goalConversion';
    case SURVEYS:
      return 'surveyResponse';
    default:
      return null;
  }
};

export function channelsURL(resourceType, resourceID) {
  const token = window.localStorage.getItem(AUTH_TOKEN_KEY);

  return `https://channels.crazyegg.com/webhooks/new?resource_type=${conversionType(
    resourceType,
  )}&resource_id=${resourceID}&token=${token}`;
}

export function isNumber(value) {
  return typeof value === 'number';
}
