import { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { capitalize, Button } from '@crazyegginc/hatch';

import { changelogReactionMutation } from '/src/features/whats-new/mutations';
import { useMutation, useNeedsUpgradeToAccessFeature, useMetric } from '/src/hooks';
import { formatDate, externalizeLinks } from '../utils';
import { WHATS_NEW_FEATURES, WHATS_NEW_TYPES } from '../constants';
import { getFeatureName } from '/src/features/_global/utils';
import { Attachment } from './Attachment';
import { Dot } from './Dot';
import { MANAGE_SUBSCRIPTION_PATH } from '/src/features/_global/constants';

import { ReactComponent as CameraIcon } from '@crazyegginc/hatch/dist/images/icon-camera-filled.svg';
import { ReactComponent as RecordingIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as ABTestIcon } from '@crazyegginc/hatch/dist/images/icon-ab-test-outline.svg';
import { ReactComponent as EditorIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as HelpIcon } from '@crazyegginc/hatch/dist/images/icon-help-filled.svg';
import { ReactComponent as AccountIcon } from '@crazyegginc/hatch/dist/images/icon-user-filled.svg';
import { ReactComponent as OptionsIcon } from '@crazyegginc/hatch/dist/images/icon-cog-filled.svg';
import { ReactComponent as InstallationIcon } from '@crazyegginc/hatch/dist/images/icon-check-install-outline.svg';
import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';
import { ReactComponent as SurveyIcon } from '@crazyegginc/hatch/dist/images/icon-survey-filled.svg';
import { ReactComponent as TrafficAnalysisIcon } from '@crazyegginc/hatch/dist/images/icon-chart-filled.svg';
import { ReactComponent as LiveIcon } from '@crazyegginc/hatch/dist/images/icon-list-outline.svg';
import { ReactComponent as GoalsIcon } from '@crazyegginc/hatch/dist/images/icon-goal-filled.svg';
import { ReactComponent as CTAsIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';

import { ReactionPicker } from '/src/features/whats-new/components/ReactionPicker';

export function Event({ event }) {
  const changelogReaction = useMutation(changelogReactionMutation);
  const contentRef = useRef(null);
  const isBeta = !!event.featureFlag;
  const type = event.tags && event.tags[0];
  const isUnseen =
    JSON.parse(window.sessionStorage.getItem('unseenEvents') || '[]').includes(parseInt(event.id)) ||
    event.seenAt === null;

  const trackMetric = useMetric();
  const needsUpgradeToAccess = shouldSeeUpgradePrompt(event.category, useNeedsUpgradeToAccessFeature(event.category));

  useEffect(() => {
    if (contentRef.current) {
      externalizeLinks(contentRef.current);
    }
  }, [contentRef]);

  useEffect(() => {
    return () => {
      window.sessionStorage.removeItem('unseenEvents');
    };
  }, []);

  return (
    <div
      className={classNames(
        'relative mr-12 flex w-full',
        'after:absolute after:left-[11px] after:top-[50px] after:z-[2] after:h-full after:w-[3px] after:bg-mystic-500 last:after:h-0',
      )}
    >
      <Dot type={type}>
        <div className="absolute right-[calc(100%+15px)] flex flex-col whitespace-nowrap text-right">
          <span className="text-sm">{formatDate(new Date(event.publishedAt))}</span>
          <div className="w-max-[100px] mt-1.25 flex flex-wrap-reverse justify-end gap-1 self-end">
            {isBeta ? <ChangeLogTag text="Beta" /> : null}
            {event.tags.map((tag) => (
              <ChangeLogTag key={tag} text={capitalize(tag)} />
            ))}
          </div>
        </div>
      </Dot>
      <div
        className={classNames(
          'relative mb-6 ml-6 box-border max-w-[650px] flex-1 rounded border border-mystic-500 bg-white px-7 pt-7 drop-shadow',
          {
            'pb-7': !needsUpgradeToAccess,
          },
        )}
      >
        {isUnseen ? (
          <span className="absolute right-7 top-7 h-2 w-2 select-none rounded-full bg-radical-red-500">&nbsp;</span>
        ) : null}
        <div className="flex items-center">
          <FeatureIcon feature={event.category} />
          <span className="ml-4 text-lg leading-6 tracking-normal text-mako-500">
            <strong>{getFeatureName(event.category)}:</strong> {event.title}
          </span>
        </div>
        <div
          className="textWithLinks my-3.5 text-sm leading-5 tracking-normal text-mako-500"
          dangerouslySetInnerHTML={{ __html: event.contentHtml }}
          ref={contentRef}
        />

        <div className="grid grid-cols-2 gap-x-2">
          {event.attachments.map((attachment) => (
            <div
              key={`Attachment${attachment.id}`}
              className={classNames({
                'col-span-2': event.attachments.length === 1,
              })}
            >
              <Attachment attachment={attachment} />
            </div>
          ))}
        </div>

        <div className="absolute right-full top-[47px] h-0 w-0 -translate-y-1/2 border-8 border-transparent border-r-mystic-500" />
        <div className="absolute right-full top-[47px] h-0 w-0 -translate-y-1/2 border-[7px] border-transparent border-r-white" />

        {/* START: reactions */}
        <div className="mt-5 flex flex-row items-center justify-between border-t-2 border-dashed border-mystic-500 pt-2">
          <span className="mr-4 text-sm font-bold leading-tight text-lynch-500">Tell us what you think</span>
          <ReactionPicker
            reaction={event.reaction}
            onReaction={(reaction) => {
              changelogReaction.mutate({
                eventId: parseInt(event.id),
                reaction,
              });
              window.CE2.SURVEY_VARIABLES = {
                eventTitle: event.title,
              };
              const surveyId = '8d8b4321-c0bc-4f2d-b6ce-7da11d71eb67';
              window.CE2?.Survey?.stop?.(surveyId);
              window.CE2?.showSurvey?.(surveyId);
            }}
          />
        </div>
        {/* END: reactions */}
        {needsUpgradeToAccess ? (
          <div className="text-body-2 -mx-7 mt-6 flex h-16 items-center justify-center bg-solitude-500">
            <div>Upgrade to start using {getFeatureName(event.category)} today. </div>
            <Button
              component={Link}
              onMouseDown={() => trackMetric('upgrade_cta_click', 'whats_new_entry')}
              to={MANAGE_SUBSCRIPTION_PATH}
              className="ml-5"
            >
              Upgrade plan
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export function FeatureIcon({ feature }) {
  let Icon;
  switch (feature.toUpperCase()) {
    case WHATS_NEW_FEATURES.AB_TESTING:
      Icon = ABTestIcon;
      break;
    case WHATS_NEW_FEATURES.ACCOUNT:
      Icon = AccountIcon;
      break;
    case WHATS_NEW_FEATURES.CTAS:
      Icon = CTAsIcon;
      break;
    case WHATS_NEW_FEATURES.EDITOR:
      Icon = EditorIcon;
      break;
    case WHATS_NEW_FEATURES.ERRORS_TRACKING:
      Icon = ErrorIcon;
      break;
    case WHATS_NEW_FEATURES.HELP:
      Icon = HelpIcon;
      break;
    case WHATS_NEW_FEATURES.INSTALLATION:
      Icon = InstallationIcon;
      break;
    case WHATS_NEW_FEATURES.OPTIONS:
      Icon = OptionsIcon;
      break;
    case WHATS_NEW_FEATURES.RECORDINGS:
      Icon = RecordingIcon;
      break;
    case WHATS_NEW_FEATURES.SNAPSHOTS:
      Icon = CameraIcon;
      break;
    case WHATS_NEW_FEATURES.SURVEYS:
      Icon = SurveyIcon;
      break;
    case WHATS_NEW_FEATURES.TRAFFIC_ANALYSIS:
      Icon = TrafficAnalysisIcon;
      break;
    case WHATS_NEW_FEATURES.LIVE_ACTIVITY:
      Icon = LiveIcon;
      break;
    case WHATS_NEW_FEATURES.GOALS:
      Icon = GoalsIcon;
      break;
    default:
      return null;
  }

  return (
    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-solitude-500 text-dodger-blue-500">
      <Icon className="h-5 w-5 fill-current" />
    </div>
  );
}

function ChangelogPill({ text, className = null }) {
  return (
    <div
      className={classNames(
        'm-0 mb-1.25 box-border flex h-5 items-center justify-center self-end rounded-full px-2 text-2xs font-semibold leading-4 tracking-normal text-white',
        className,
      )}
    >
      {text}
    </div>
  );
}

function ChangeLogTag({ text }) {
  if (!text) {
    return null;
  }

  switch (text.toUpperCase()) {
    case WHATS_NEW_TYPES.BETA:
      return <ChangelogPill text={text} className="bg-radical-red-500" />;
    case WHATS_NEW_TYPES.FIX:
      return <ChangelogPill text={text} className="bg-dandelion-500" />;
    case WHATS_NEW_TYPES.NEW:
      return <ChangelogPill text={text} className="bg-dodger-blue-500" />;
    case WHATS_NEW_TYPES.IMPROVEMENT:
      return <ChangelogPill text={text} className="bg-lavender-500" />;
    default:
      return <ChangelogPill text={text} className="bg-cadet-blue-500" />;
  }
}

function shouldSeeUpgradePrompt(feature, needsUpgrade) {
  switch (feature) {
    case WHATS_NEW_FEATURES.ACCOUNT:
    case WHATS_NEW_FEATURES.HELP:
    case WHATS_NEW_FEATURES.EDITOR:
      return false;
    default:
      return needsUpgrade;
  }
}
