import classNames from 'classnames';

import { ActionIconButton } from '../../common/results/ActionIconButton';
import { useAuthContext } from '/src/hooks';

import { ReactComponent as DownloadIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-download.svg';
import { ReactComponent as ReloadIcon } from '@crazyegginc/hatch/dist/images/icon-redo.svg';

export function CommonActions({ survey, refresh, className }) {
  return (
    <div className={classNames('flex items-center space-x-2.5', className)}>
      <DownloadResults survey={survey} />
      <ActionIconButton tooltip="Refresh results" icon={ReloadIcon} onClick={refresh} label="Refresh results" />
    </div>
  );
}

function DownloadResults({ survey }) {
  const { token } = useAuthContext();
  const disabled = survey.responseCount === 0;

  return (
    <ActionIconButton
      tooltip="Download all responses"
      label="Download all responses"
      component="a"
      href={`${window.CORE_API_URL}/export/survey.csv?site_id=${survey.siteId}&survey_id=${survey.id}&token=${token}`}
      icon={DownloadIcon}
      download={true}
      disabled={disabled}
    />
  );
}
