import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { differenceInDays, fromUnixTime } from 'date-fns';
import { Button, Tooltip, SkeletonLine } from '@crazyegginc/hatch';
import { useAtom } from 'jotai';

import { DisplayCurrency } from '/src/features/_global/components/DisplayCurrency';
import {
  useMutation,
  useNeedsUpgradeToAccessFeature,
  useAuthContext,
  usePermissions,
  useModal,
  useNotifications,
  useSite,
  useHasFeatureFlag,
} from '/src/hooks';

import { goalSelectedDatesAtom } from '/src/features/goals/store';
import { DeleteModal } from './modals/DeleteModal';
import { WebP } from '/src/components/WebP';
import { RecordingsUpgradeModal } from '/src/features/recordings/components/dashboard/modals/RecordingsUpgradeModal';
import { ABTestingUpgradeModal } from '/src/features/ab-testing/components/modals/ABTestingUpgradeModal';

import { GoalTriggerSummary, generateRecordingsFilterUrlForGoal } from '/src/features/goals/utils';
import { formatDate } from '/src/utils/date';
import { flashElement } from '/src/utils';

import { goalDeleteMutation } from '/src/features/goals/mutations';
import { GOAL_TRIGGERS_METADATA } from '/src/features/goals/constants';
import { FEATURES } from '/src/features/_global/constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';

import { ChannelsLink } from '/src/features/_global/components/ChannelsLink';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as RecordingIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as ABTestIcon } from '@crazyegginc/hatch/dist/images/icon-ab-test-outline.svg';
import { ReactComponent as ShareIcon } from '@crazyegginc/hatch/dist/images/icon-share-outline.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as CurrencyIcon } from '@crazyegginc/hatch/dist/images/icon-currency-outline.svg';

const modalDeleteOptions = {
  overlaycolor: 'rgba(59, 66, 70, 0.65)',
};

export function GoalDetailCard({
  goal,
  abTestsCount = 0,
  abTests = [],
  counts,
  isFetchingCounts = true,
  sessionsCount,
  isFetchingSessionsCount = true,
}) {
  const { id } = useParams();
  const { isSharing } = useAuthContext();
  const { sites } = useSite({ dontSelectSite: true, sitesQueryProps: { enabled: !isSharing } });
  const modal = useModal();
  const navigate = useNavigate();
  const notifications = useNotifications();
  const permissions = usePermissions();
  const [selection] = useAtom(goalSelectedDatesAtom);

  const canShare = permissions.can('share', goal).allowed;
  const canEdit = permissions.can('edit', goal).allowed;
  const canDelete = permissions.can('delete', goal).allowed;
  const needsUpgradeToAccessRecordings = useNeedsUpgradeToAccessFeature(FEATURES.RECORDINGS);
  const needsUpgradeToAccessABTesting = useNeedsUpgradeToAccessFeature(FEATURES.AB_TESTING);

  const hasChannelsFlag = useHasFeatureFlag('channels');

  const { mutate: mutateDeleteGoal } = useMutation(goalDeleteMutation);

  const getSiteName = useCallback(
    (cellRowSiteId) => {
      return sites.find((x) => cellRowSiteId === x.id)?.name ?? null;
    },
    [sites],
  );

  const recordingsFilterUrl = useMemo(() => {
    if (!goal) return null;
    return generateRecordingsFilterUrlForGoal(goal, selection);
  }, [goal, selection]);

  const isLoading = !goal;

  const recordedSessionsDays = goal?.createdAt
    ? Math.min(30, differenceInDays(new Date(), fromUnixTime(goal.createdAt)))
    : 30;

  function getTotalABTestsConversions(abtests) {
    if (abtests.length === 0) return 0;

    return abTests.reduce((total, abtest) => {
      return total + abtest.totalConversions;
    }, 0);
  }

  const totalConversionsCount = counts?.conversionsCount ?? 0;
  const totalABTestsConversions = getTotalABTestsConversions(abTests);

  const hasValueSet = goal?.triggers?.some?.((trigger) => !!trigger.valueType) ?? false;

  return (
    <div className="w-full">
      <div
        className="flex flex-col items-center rounded border border-mystic-500 bg-white p-8"
        style={{
          boxShadow: '0 4px 6px 0 rgba(7,31,49,0.07)',
        }}
      >
        <div className="mb-5 flex w-full justify-between">
          <span className="flex w-[62.5%] min-w-0">
            <div className="mr-5 flex w-[35px]">
              {isLoading ? (
                <div className="h-[35px] w-[35px]">
                  <SkeletonLine className="!h-[35px] !rounded" width="100%" />
                </div>
              ) : (
                <WebP
                  className="w-[35px]"
                  webp={GOAL_TRIGGERS_METADATA[goal.icon.toUpperCase()]?.webp ?? GOAL_TRIGGERS_METADATA.OTHER.webp}
                  fallback={GOAL_TRIGGERS_METADATA[goal.icon.toUpperCase()]?.png ?? GOAL_TRIGGERS_METADATA.OTHER.png}
                  width="35"
                  alt={goal.purpose.replace('_', ' ')}
                />
              )}
            </div>
            <span className="flex-1 overflow-hidden">
              <h3 className="text-header-6 mb-1">
                {isLoading ? <SkeletonLine width="50%" height="10px" /> : goal.name}
              </h3>
              {isLoading ? (
                <div className="mt-2.5">
                  <SkeletonLine width="50%" height="10px" />
                </div>
              ) : (
                <GoalTriggerSummary goal={goal} />
              )}
            </span>
          </span>
          {!isLoading && (
            <>
              <ul className="flex space-x-2.5">
                {hasChannelsFlag ? (
                  <li className="w-[130px]">
                    <ChannelsLink feature={FEATURES.GOALS} featureID={goal?.id} />
                  </li>
                ) : null}
                {canShare ? (
                  <li>
                    <Button
                      onClick={() => {
                        window.SharingModal.show({
                          entity: { id: goal.id, siteId: goal.siteId, type: SHARABLE_RESOURCE_TYPES.GOAL },
                        });
                      }}
                      variant="secondary"
                    >
                      <ShareIcon className="mr-1.5 h-4 w-4 fill-current text-dodger-blue-500" />
                      Share
                    </Button>
                  </li>
                ) : null}
                {canEdit ? (
                  <li>
                    <Button
                      onClick={() => {
                        navigate(`/goals/${goal.id}/edit`);
                      }}
                      variant="secondary"
                    >
                      <EditIcon className="mr-1.5 h-4 w-4 fill-current text-dodger-blue-500" />
                      Edit
                    </Button>
                  </li>
                ) : null}
                {canDelete ? (
                  <li>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        modal.show(
                          <DeleteModal
                            goal={goal}
                            abTests={abTests}
                            onConfirm={(id) => {
                              mutateDeleteGoal(
                                {
                                  id: Number(id),
                                },
                                {
                                  onSuccess: () => {
                                    notifications.success({ content: 'Goal deleted successfully.', timeout: 3000 });
                                    navigate('/goals', { replace: true });
                                  },
                                  onError: (error) => {
                                    notifications.error({
                                      content: 'Goal delete failed.',
                                      timeout: 3000,
                                      context: { error },
                                    });
                                  },
                                },
                              );
                            }}
                          />,
                          modalDeleteOptions,
                        )
                      }
                    >
                      <DeleteIcon className="mr-1.5 h-4 w-4 fill-current text-dodger-blue-500" />
                      Delete
                    </Button>
                  </li>
                ) : null}
              </ul>
            </>
          )}
        </div>

        <div className="flex w-full flex-row justify-between">
          <div className="w-[62.5%]">
            <div className="rounded bg-white-lilac-500">
              <span className="flex h-[40px] border-b border-dashed border-mystic-500 px-2 pb-2.5 pt-3">
                <span className="mr-11 flex w-[196px] items-center justify-end pl-0.5 text-right">
                  <h3 className="text-header-6 mr-1.5 leading-none">Total conversions</h3>
                  <Tooltip
                    tooltipContent={
                      <div className="w-[335px] px-2 py-2.5">
                        <h5 className="mb-2.5 text-xs font-bold text-white">
                          Does this look different to your A/B test conversions?
                        </h5>
                        <p className="text-xs font-light leading-tight tracking-wide text-white">
                          Conversions for A/B tests are only counted if a visitor has landed on your test pages before
                          performing a goal, whereas all conversions are counted in your goals dashboard.
                        </p>
                      </div>
                    }
                  >
                    <InfoIcon
                      className="h-3 w-3 -translate-y-px transform fill-current text-dodger-blue-300"
                      aria-label="Info on conversion"
                    />
                  </Tooltip>
                </span>
                {isFetchingCounts ? (
                  <SkeletonLine width="15%" height="12px" />
                ) : (
                  <Tooltip
                    tooltipContent={
                      <div className="flex flex-col pr-2 text-left">
                        <span className="font-normal">
                          <span className="mr-1 inline-block w-[130px] text-right">All goal conversions:</span>
                          <span className="font-semibold">{totalConversionsCount}</span>
                        </span>

                        <span className="font-normal">
                          <span className="mr-1 inline-block w-[130px] text-right">A/B Test conversions:</span>
                          <span className="font-semibold">{totalABTestsConversions}</span>
                        </span>
                      </div>
                    }
                  >
                    <span className="text-body-5 mt-0.5 flex leading-none underline decoration-cadet-blue-500 decoration-dashed underline-offset-2">
                      {totalConversionsCount}
                    </span>
                  </Tooltip>
                )}
              </span>
              <span className="flex h-[40px] items-center rounded-b px-2 pb-2.5 pt-3">
                <span className="mr-11 flex w-[196px] items-center justify-end pl-0.5 text-right">
                  <h3 className="text-header-6 mr-1.5 leading-none">Total visitors converting</h3>
                  <Tooltip
                    tooltipContent={
                      <div className="w-[335px] px-2 py-2.5">
                        <p className="text-xs font-light leading-tight tracking-wide text-white">
                          Total number of <strong>visitors</strong> who have converted. Each visitor may convert
                          multiple times, but will only count once in this figure.
                        </p>
                      </div>
                    }
                  >
                    <InfoIcon
                      className="h-3 w-3 -translate-y-px transform fill-current text-dodger-blue-300"
                      aria-label="Info on conversion"
                    />
                  </Tooltip>
                </span>
                {isFetchingCounts ? (
                  <SkeletonLine width="20%" height="12px" />
                ) : (
                  <p className="text-body-5">{counts?.uniqueConversionsCount ?? 0}</p>
                )}
              </span>
              <span className="flex h-[40px] w-full items-center rounded-b px-2 pb-2.5 pt-3">
                <div className="flex items-center justify-between">
                  <span className="mr-11 flex w-[196px] items-center justify-end pl-0.5 text-right">
                    <CurrencyIcon className="mr-2.5 h-4 w-4 fill-current" />
                    <h3 className="text-header-6 mr-1.5 leading-none">Total value</h3>
                  </span>
                  <span className="whitespace-nowrap">
                    {!goal ? (
                      <SkeletonLine width="20%" height="12px" />
                    ) : hasValueSet && goal?.totalValue?.displayWorth >= 0 ? (
                      <DisplayCurrency
                        value={{
                          displayWorth: goal.totalValue.displayWorth,
                          displayCurrency: goal.totalValue.displayCurrency,
                          isConverted: false,
                        }}
                        precision={10000}
                        textOnly={true}
                        className="text-body-5"
                      />
                    ) : (
                      <p className="text-body-5">Not set</p>
                    )}
                  </span>
                </div>
                <div className="w-full text-right">
                  <Link to={`/goals/${id}/edit-value`} className="text-link">
                    {goal?.hasValue ? 'Edit value' : 'Set Goal value'}
                  </Link>
                </div>
              </span>
            </div>
          </div>
          <ul className="w-[35.5%] overflow-hidden rounded bg-white-lilac-500">
            <li className="flex h-[40px] items-center justify-between border-b border-dashed border-mystic-500 px-8 pb-2.5 pt-3">
              <span className="flex flex-1 items-center truncate">
                <span className="mr-3 flex w-[86px] items-center justify-end">
                  <h4 className="text-header-6">Goal Created</h4>
                </span>
                <div className="min-w-0 flex-1 truncate">
                  {isLoading ? (
                    <SkeletonLine width="20%" height="12px" />
                  ) : (
                    <time className="text-body-5" dateTime={goal.createdAt}>
                      {formatDate(goal.createdAt)}
                    </time>
                  )}
                </div>
              </span>
            </li>

            {!isSharing ? (
              <li
                className={classNames(
                  'flex h-[40px] items-center justify-between px-8 pb-2.5 pt-3',
                  'border-b border-dashed border-mystic-500 last:border-b-0',
                )}
              >
                {needsUpgradeToAccessRecordings ? (
                  <span className="flex flex-1 items-center truncate">
                    <span className="mr-3 flex w-[86px] items-center justify-end">
                      <h4 className="text-body-5 font-semibold">Recordings</h4>
                    </span>
                    <div className="min-w-0 flex-1 truncate">
                      <button
                        type="button"
                        className="text-link"
                        onClick={() => modal.show(<RecordingsUpgradeModal />)}
                      >
                        🚀 Start Recording now
                      </button>
                    </div>
                  </span>
                ) : (
                  <>
                    <span className="flex flex-1 items-center">
                      <span className="flex w-32 items-center justify-end">
                        <h4 className="text-header-6">Recordings</h4>
                        <RecordingIcon className="ml-3.5 mr-3 h-4 w-4 fill-current text-cadet-blue-500" />
                      </span>
                      <div className="flex flex-1 items-center space-x-2">
                        {isFetchingSessionsCount || !goal ? (
                          <SkeletonLine width="100%" height="12px" />
                        ) : (
                          <>
                            <p className="text-body-5">{sessionsCount ?? 0}</p>
                            <Tooltip
                              tooltipContent={
                                <div className="px-2 py-2.5">
                                  <p className="text-xs leading-tight tracking-wide text-white">
                                    {recordedSessionsDays === 0 ? (
                                      <span>The number of recorded sessions today</span>
                                    ) : null}
                                    {recordedSessionsDays === 1 ? (
                                      <span>The number of recorded sessions for the last day</span>
                                    ) : null}
                                    {recordedSessionsDays > 1 ? (
                                      <span>
                                        The number of recorded sessions for the last {recordedSessionsDays} days
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                              }
                            >
                              <InfoIcon
                                className="h-3 w-3 -translate-y-px transform fill-current text-dodger-blue-300"
                                aria-label="Info on recordings count"
                              />
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </span>
                    {!isFetchingSessionsCount && goal && (sessionsCount ?? 0 > 0) && !isSharing ? (
                      <Link
                        to={{
                          pathname: '/recordings',
                          search: `filters=${recordingsFilterUrl}&site=${getSiteName(goal.siteId)}`,
                        }}
                        className="text-link hover:underline"
                      >
                        View
                      </Link>
                    ) : null}
                  </>
                )}
              </li>
            ) : null}

            <li className="flex h-[40px] items-center justify-between px-8 pb-2.5 pt-3">
              {needsUpgradeToAccessABTesting ? (
                <span className="flex flex-1 items-center truncate">
                  <span className="mr-3 flex w-[86px] items-center justify-end">
                    <h4 className="text-body-5 font-semibold">A/B Tests</h4>
                  </span>
                  <div className="min-w-0 flex-1 truncate">
                    <button type="button" className="text-link" onClick={() => modal.show(<ABTestingUpgradeModal />)}>
                      🚀 Start A/B Testing now
                    </button>
                  </div>
                </span>
              ) : (
                <>
                  <span className="flex flex-1 items-center">
                    <span className="flex w-32 items-center justify-end">
                      <h4 className="text-header-6">A/B Tests</h4>
                      <ABTestIcon className="ml-3.5 mr-3 h-4 w-4 fill-current text-cadet-blue-500" />
                    </span>
                    <div className="flex-1">
                      {isLoading ? (
                        <SkeletonLine width="100%" height="12px" />
                      ) : (
                        <p className="text-body-5">{abTestsCount}</p>
                      )}
                    </div>
                  </span>
                  {!isLoading && abTestsCount > 0 && (
                    <button
                      onClick={() => {
                        const el = document.querySelector('#ab-tests > div');
                        flashElement(el);
                      }}
                      className="text-link hover:underline"
                    >
                      View
                    </button>
                  )}
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
