/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Transition } from '@headlessui/react';

export function Drawer({ children, opened = false }) {
  return (
    <Transition
      show={opened}
      enter="transition-transform duration-500"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      appear={true}
      role="complementary"
      id="ce_drawer"
      className="absolute right-1.25 top-1.25 h-[calc(100%-20px)] w-[450px] overflow-hidden rounded bg-white shadow"
      style={{ zIndex: '99999' }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {children}
    </Transition>
  );
}
