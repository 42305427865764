import classNames from 'classnames';
import { Button, Popover, StyledPopoverPanel, PopoverItem, Indicator } from '@crazyegginc/hatch';
import { useAtom } from 'jotai';

import { AB_TEST_STATUSES } from '/src/features/ab-testing/constants';

export function AbTestStatusDropdown({ atom }) {
  const [filters, setFilters] = useAtom(atom);

  const status = filters.status
    ? filters.status === AB_TEST_STATUSES.STOPPED
      ? 'ended'
      : filters.status.toLowerCase()
    : null;

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as={Button}
            variant="secondary"
            size="lg"
            className={classNames('capitalize', { '!border-dodger-blue-500': open })}
          >
            {status || 'Show All'}
            <Indicator type="dropdown" className=" ml-2.5" />
          </Popover.Button>

          <StyledPopoverPanel align="center">
            {({ close }) => (
              <>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setFilters({ ...filters, status: null });
                    close();
                  }}
                >
                  All Tests
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setFilters({ ...filters, status: AB_TEST_STATUSES.DRAFT });
                    close();
                  }}
                >
                  Drafts
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setFilters({ ...filters, status: AB_TEST_STATUSES.RUNNING });
                    close();
                  }}
                >
                  Running
                </PopoverItem>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setFilters({ ...filters, status: AB_TEST_STATUSES.STOPPED });
                    close();
                  }}
                >
                  Ended
                </PopoverItem>
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
