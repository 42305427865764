import { useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { Panel, Button, Input, Spinner, Autocomplete, Tooltip, AIBox } from '@crazyegginc/hatch';

import { useMutation, useAuthContext, useModal, useNotifications, useCrazyAI } from '/src/hooks';
import { purgeLocalStorage } from '/src/utils/storage';
import { DeleteConfirmationModal } from '/src/components/modals/DeleteConfirmationModal';
import { SadToSeeYouGoModal } from '../components/modals/SadToSeeYouGoModal';
// import { ChangeAvatarModal } from '/src/components/modals/account/change-avatar';
import { SectionHeader } from '../components/accountUI';
import { deleteAccountMutation, updateUserProfileMutation } from '/src/features/account/mutations';
import { PLAN_TYPES, SUBSCRIPTION_STATES, LANGUAGES } from '/src/features/_global/constants';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

const { CANCELING, CANCELED, EXPIRED } = SUBSCRIPTION_STATES;

const languageOptions = Object.keys(LANGUAGES)
  .map((lang) => ({ value: lang, label: LANGUAGES[lang].name }))
  .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Please provide a name.')
    .max(40, 'Please provide a shorter name, maximum ${max} characters.'),
  email: yup.string().required('Please provide an email.').email('Please provide a valid email.'),
});

// eslint-disable-next-line no-restricted-syntax
export default function AccountProfile() {
  const { currentUser, currentAccount, subscription } = useAuthContext();

  const modal = useModal();
  const notifications = useNotifications();
  const updateUserProfile = useMutation(updateUserProfileMutation);
  const deleteAccount = useMutation(deleteAccountMutation);
  const isClosed = [CANCELED, CANCELING, EXPIRED].includes(subscription.state);

  return (
    <div className="px-10">
      <Panel className="!flex-row space-x-10">
        <div className="flex w-[100px] flex-shrink-0 flex-col">
          <img
            src={currentUser.avatarUrl}
            width="100px"
            height="100px"
            className="h-[100px] w-[100px] rounded-full"
            alt="avatar"
          />
          {/*<button
            className="!mt-3.5 text-link"
            type="button"
            onClick={() => modal.show(<ChangeAvatarModal />)}
          >
            Change image
          </button>*/}
          <div className="text-body-5 mt-2 text-center leading-none">
            Use{' '}
            <a href="http://gravatar.com" className="text-link" target="_blank" rel="noopener noreferrer">
              gravatar.com
            </a>{' '}
            to change your profile image
          </div>
        </div>
        <div>
          <Formik
            initialValues={{
              name: currentUser.name ?? '',
              email: currentUser.email,
              language: currentUser.language,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              const variables = {
                name: values.name,
                login: values.email,
                language: values.language,
              };
              updateUserProfile.mutate(variables, {
                onError: (error) => {
                  if (error?.graphQLErrors?.find((e) => e.message === 'login: has invalid format')) {
                    notifications.error({
                      title: 'Failed to save changes!',
                      content: 'Please provide a valid email address.',
                      skipHoneybadger: true,
                    });
                  } else if (error?.graphQLErrors?.find((e) => e.message === 'login: This email already exists.')) {
                    notifications.error({
                      title: 'Failed to save changes!',
                      content: 'The email address you provided is already in use.',
                      skipHoneybadger: true,
                    });
                  } else {
                    notifications.error({
                      title: 'Failed to save changes!',
                      content: 'There was an error while saving your changes, please try again or contact support.',
                      context: { error },
                    });
                  }
                  actions.resetForm();
                },
                onSuccess: (data) => {
                  actions.resetForm({
                    values: {
                      name: data.updateUserProfile.name,
                      email: data.updateUserProfile.email,
                      language: data.updateUserProfile.language,
                    },
                  });
                  notifications.success({
                    title: 'All done!',
                    content: 'Changes saved successfully.',
                    timeout: 3000,
                  });
                },
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting, dirty }) => {
              return (
                <Form>
                  <div className="w-100 space-y-3.75">
                    <Input
                      name="name"
                      id="name"
                      label="Your name"
                      size="lg"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && errors.name ? errors.name : null}
                    />
                    <Input name="email" id="email" label="Your email" size="lg" value={values.email} disabled={true} />

                    <Autocomplete
                      label={
                        <div className="flex items-center">
                          <span className="mr-2">Preferred language</span>
                          <Tooltip
                            tooltipContent={
                              <div className="max-w-[250px]">
                                We will try to use your preferred language for AI generated summaries, but will use
                                English when that is not possible.
                              </div>
                            }
                          >
                            <InfoIcon
                              aria-label="information on language"
                              className="h-3 w-3 fill-current text-dodger-blue-300"
                            />
                          </Tooltip>
                        </div>
                      }
                      options={languageOptions}
                      value={values.language}
                      onChange={(value) => setFieldValue(`language`, value)}
                    />
                    <LanguageTest languageCode={values.language} />
                  </div>
                  <div className="text-body-2 mt-5" data-test-id="accountNumberText">
                    Your account number: <span className="text-body-1">{currentAccount.paddedId}</span>
                  </div>
                  <a href="https://auth.app.crazyegg.com/v2/settings" className="text-link mt-2.5 block">
                    Account and security settings
                  </a>
                  <p className="text-caption">Change password, email and two-factor authentication</p>

                  <Button
                    id="update_profile_button"
                    className="!mt-[35px]"
                    type="submit"
                    disabled={isSubmitting || !dirty}
                  >
                    {isSubmitting ? (
                      <div className="flex items-center">
                        <Spinner className="mr-2.5 h-4 w-4 text-lynch-500" />
                        Updating...
                      </div>
                    ) : (
                      'Update profile'
                    )}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Panel>
      {(isClosed || [PLAN_TYPES.FREEMIUM, PLAN_TYPES.ZARAZ].includes(subscription.plan.type)) &&
        currentAccount.isAccountHolder && (
          <>
            <SectionHeader className="mt-[50px]">Delete account</SectionHeader>
            <Panel>
              <div className="text-body-2">
                By deleting your Crazy Egg account, all subscriptions and data will be deleted permanently. This cannot
                be undone.
              </div>
              <Button
                variant="warning"
                className="mt-[25px]"
                onClick={() =>
                  modal.show(
                    <DeleteConfirmationModal
                      text="You are about to delete your Crazy Egg account. Deleting your account will delete all your data
              permanently."
                      entity="account"
                      onDelete={(deleteString) => {
                        return deleteAccount.mutate(
                          { delete: deleteString, userId: currentUser.userId },
                          {
                            onError: (error) => {
                              modal.close();
                              notifications.error({
                                content: 'Failed to delete your account. Please contact support for assistance.',
                                timeout: 30000,
                                context: { error },
                              });
                            },
                            onSuccess: (data) => {
                              if (data.deleteAccount === true) {
                                modal.close();
                                modal.show(<SadToSeeYouGoModal />);
                                setTimeout(() => {
                                  modal.close();
                                  purgeLocalStorage();
                                  window.location.replace(`${window.LEGACY_APP_URL}/login`);
                                }, 5000);
                              }
                            },
                          },
                        );
                      }}
                    />,
                  )
                }
              >
                Delete account
              </Button>
            </Panel>
          </>
        )}
    </div>
  );
}

function LanguageTest({ languageCode }) {
  const location = useLocation();
  const { currentUser } = useAuthContext();
  const language = LANGUAGES[languageCode].name;

  const params = useMemo(
    () => ({
      language,
    }),
    [language],
  );

  const context = useMemo(
    () => ({
      url: `${location.pathname}${location.search}`,
      userId: currentUser?.userId,
    }),
    [location.pathname, location.search, currentUser?.userId],
  );

  const { data, error, positiveFeedback, negativeFeedback, refetch } = useCrazyAI({
    type: 'language-test',
    params,
    context,
  });

  return (
    <div className="!mb-10 ml-5">
      <AIBox
        id="language-test"
        data={data}
        error={error}
        refetch={refetch}
        onPositive={positiveFeedback}
        onNegative={negativeFeedback}
        showChangeLanguage={false}
      />
    </div>
  );
}
