import { DEVICE_TYPES } from '/src/features/_global/constants';

export const ABTEST = 'ABTEST';

export const abTestTypes = {
  SPLIT: 'SPLIT',
  PATCH: 'PATCH',
};

export const abTestVariantTypes = {
  CONTROL: 'CONTROL',
  VARIANT: 'VARIANT',
};

export const DEVICE_WIDTHS = {
  [DEVICE_TYPES.DESKTOP]: `${Math.max(window.innerWidth, 1366)}px`,
  [DEVICE_TYPES.TABLET]: '768px',
  [DEVICE_TYPES.PHONE]: '375px',
};

export const VARIANT_STATUSES = {
  STATIC: 'STATIC',
  DELETED: 'DELETED',
  PRISTINE: 'PRISTINE',
  DIRTY: 'DIRTY',
};

export const AB_TEST_STATUSES = {
  DELETED: 'DELETED',
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
};

export const AB_TESTS_CREATION_LINK = '/ab-tests/new';
