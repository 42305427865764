import { useEffect } from 'react';

import { CodeBox } from '/src/components/CodeBox';
import { useWizard } from '/src/hooks';
import { useScriptUrl } from '/src/features/installation/utils';
import { WizardHeader, WizardSubHeader } from '/src/components/wizard/legacy/wizard-ui';

import { SupportLinks } from '/src/support';

export function ManualInstructions() {
  const [code] = useScriptUrl();
  const { set: wizardSet } = useWizard();

  useEffect(() => {
    wizardSet({
      back: '/install/choose-method',
      backEnabled: true,
      next: '/install/manually/check-script',
      nextEnabled: true,
      title: 'Manual installation',
      currentStep: 2,
      totalSteps: 3,
    });
  }, [wizardSet]);

  return (
    <div className="text-body-2 flex flex-col">
      <WizardHeader className="!mb-5">Alright, do-it-yourselfer!</WizardHeader>

      <WizardSubHeader>
        To install Crazy Egg, place this script at the top of the &lt;head&gt; section just after any &lt;meta&gt; tags.
      </WizardSubHeader>

      <CodeBox loading={false} code={code} />

      <a className="text-link mt2.5" target="_blank" rel="noopener noreferrer" href={SupportLinks.install.manual}>
        How do I manually install this tracking script onto my site?
      </a>

      <div className="mt-8">
        Click <strong className="text-body-1">Next</strong> once you have installed the tracking script.
      </div>
    </div>
  );
}
