import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { usePermissions } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';
import { Paywall } from '/src/components/Paywall';
import { Button, Tooltip } from '@crazyegginc/hatch';

import GoalTargetPNG from '@crazyegginc/hatch/dist/images/illustration-goal-target.png';
import GoalTargetWebP from '@crazyegginc/hatch/dist/images/illustration-goal-target.webp';

export function NoGoalsWall({ mini }) {
  const permissions = usePermissions();
  const canCreateGoals = permissions.can('create', FEATURES.GOALS).allowed;

  return (
    <Paywall>
      <Paywall.Image
        webp={GoalTargetWebP}
        png={GoalTargetPNG}
        width={mini ? '80px' : '175px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />

      <Paywall.Title mini={mini}>Set up your first goal!</Paywall.Title>
      <Paywall.Body>
        What do you want to achieve for your site? Creating a goal will help guide you through the tools you&apos;ll
        need to optimize your
        <br />
        site, as well as track the success of your pages and changes.
      </Paywall.Body>

      <Tooltip show={!canCreateGoals} tooltipContent="You don't have permission to create an A/B Test.">
        <Button
          component={Link}
          to="/goals/new"
          disabled={!canCreateGoals}
          className={classNames('mb-5', { 'pointer-events-none': !canCreateGoals })}
        >
          Create a Goal
        </Button>
      </Tooltip>
    </Paywall>
  );
}
