import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, Navigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useSite, usePermissions } from '/src/hooks';
import { SurveyEditor } from '/src/features/addons/surveys/editor/SurveyEditor';
import { CTAEditor } from '/src/features/addons/ctas/editor/CTAEditor';
import { AddonLoading } from '/src/features/addons/common/AddonLoading';
import { generateSurveyEditorFormat } from '/src/features/addons/surveys/editor/editor-functions';
import { generateCTAEditorFormat } from '/src/features/addons/ctas/editor/editor-functions';
import { addonDetailQuery, SITES_FOR_ADDONS } from '/src/features/addons/queries';
import { FEATURES } from '/src/features/_global/constants';
import { ADDON_TYPES } from '/src/features/addons/constants';

// eslint-disable-next-line no-restricted-syntax
export default function CopyAddon() {
  const { id } = useParams();
  const permissions = usePermissions();

  const canCreateSurvey = permissions.can('create', FEATURES.SURVEYS).allowed;
  const canCreateCTA = permissions.can('create', FEATURES.CTAS).allowed;

  const { data, isFetching } = useQuery({
    ...addonDetailQuery({ id }),
    enabled: Boolean(id),
  });

  const { sites, loadingSites } = useSite({
    sitesQuery: SITES_FOR_ADDONS,
    dontSelectSite: true,
  });

  const addon = data?.addonDetail;
  const canCreate =
    (addon?.type === ADDON_TYPES.SURVEY && canCreateSurvey) || (addon?.type === ADDON_TYPES.CTA && canCreateCTA);

  useEffect(() => {
    if (window.Metrics && addon && canCreate) {
      if (addon.type === ADDON_TYPES.CTA) {
        window.Metrics.used('CTA: Editor - Copy');
      } else if (addon.type === ADDON_TYPES.SURVEY) {
        window.Metrics.used('Survey: Editor - Copy');
      }
    }
  }, [addon, canCreate]);

  if ((!data && isFetching) || loadingSites) {
    return <AddonLoading type={FEATURES.ADDONS} />;
  }

  if (!id || (addon && !canCreate) || (!isFetching && !addon)) {
    return <Navigate replace to="/addons" />;
  }

  const { type, name, content, siteId, goal } = addon;
  const site = sites.find((s) => s.id === siteId);

  switch (type) {
    case ADDON_TYPES.SURVEY: {
      const surveyData = generateSurveyEditorFormat({ name, content, site });

      surveyData.name = `${surveyData.name} Copy`;

      // change questions ids and update them in logic references too
      const mapping = {};
      surveyData.questions = surveyData.questions.map((q) => {
        const newId = uuid();
        mapping[q.id] = newId;
        return { ...q, id: newId };
      });
      surveyData.questions = surveyData.questions.map((q) => {
        return {
          ...q,
          logic: {
            ...q.logic,
            statements: q.logic.statements.map((s) => {
              return {
                ...s,
                actions: s.actions.map((a) => {
                  return {
                    ...a,
                    editorAction: a.editorAction?.startsWith('id-')
                      ? `id-${mapping[a.editorAction.replace('id-', '')]}`
                      : a.editorAction,
                  };
                }),
              };
            }),
          },
        };
      });

      return <SurveyEditor initData={surveyData} />;
    }
    case ADDON_TYPES.CTA: {
      const ctaData = generateCTAEditorFormat({ name, content, site, goalId: goal?.id });
      ctaData.name = `${ctaData.name} Copy`;

      return <CTAEditor initData={ctaData} />;
    }
    default:
      return <Navigate replace to="/addons" />;
  }
}
