import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';

import { Button, RadioGroup, Input } from '@crazyegginc/hatch';
import { useMutation, useNotifications, useModal } from '/src/hooks';
import { setRecordingSettings } from '/src/features/options/mutations';
// import { ElementSelector } from '/src/components/ElementSelector';
import { isValidCssSelector } from '/src/utils';
import { SupportLinks } from '/src/support';
import { Modal } from '/src/contexts/modal';
import { superLenientUrlRegex } from '/src/utils/regex';

import { SiteSettingsModalListofInputs } from '/src/features/options/components/modals/SiteSettingsModalListofInputs';
import { ReactComponent as SearchIcon } from '@crazyegginc/hatch/dist/images/icon-search-filled.svg';

/*
  TO REFACTOR:
  - https://github.com/CrazyEggInc/shell/pull/2019/files/d142da65cf6e94ef0bfd4bfcb5fe77e9d8a70915..0997d0d9ffa2743fd948fae26d5b66c63d883655#r1718073489
*/

export function SiteSettingsModal({ site }) {
  const { mutate: mutateRecordingSettings, isLoading } = useMutation(setRecordingSettings);
  const notifications = useNotifications();
  const modal = useModal();

  const testDuplicate = (val, type) => {
    const valueArr = val.map((v) => v[type]).filter(Boolean);
    return new Set(valueArr).size !== valueArr.length;
  };

  const validationSchema = yup.object().shape({
    urlMatchingRules: yup
      .array()
      .of(
        yup.object().shape({
          u: yup
            .string()
            .matches(superLenientUrlRegex, 'This URL is not valid. Please correct it and try again.')
            .matches(
              new RegExp(`${site.name.replace(/\./g, '\\.')}`, 'i'),
              `The provided URL must be on ${site.name}.`,
            ),
        }),
      )
      .test({
        name: 'is-duplicate',
        test: function (value) {
          const isDuplicate = testDuplicate(value, 'u');

          return !isDuplicate
            ? true
            : this.createError({
                message: 'Please provide a unique URL.',
              });
        },
      }),
    blockedUrlMatchingRules: yup
      .array()
      .of(
        yup.object().shape({
          u: yup
            .string()
            .matches(superLenientUrlRegex, 'This URL is not valid. Please correct it and try again.')
            .matches(
              new RegExp(`${site.name.replace(/\./g, '\\.')}`, 'i'),
              `The provided URL must be on ${site.name}.`,
            ),
        }),
      )
      .test({
        name: 'is-url-duplicate',
        test: function (value) {
          const isDuplicate = testDuplicate(value, 'u');

          return !isDuplicate
            ? true
            : this.createError({
                message: 'Please provide a unique URL.',
              });
        },
      }),
    maskElements: yup
      .array()
      .of(
        yup.object().shape({
          selector: yup
            .string()
            .max(10000, 'Your CSS selector exceeds the maximum length of 10,000 characters.')
            .test('css-selector', 'Please enter a valid CSS selector.', (value) => isValidCssSelector(value)),
        }),
      )
      .test({
        name: 'is-css-duplicate',
        test: function (value) {
          const isDuplicate = testDuplicate(value, 'selector');

          return !isDuplicate
            ? true
            : this.createError({
                message: 'Please provide a unique CSS selector.',
              });
        },
      }),
  });

  const getArrayofUrls = (urls) => {
    return urls.rules;
  };

  const initialValues = {
    pageTargetingEnabled: site?.recordingSettings?.pageTargetingEnabled ?? false,
    endSessionOnBlockedUrl: site?.recordingSettings?.endSessionOnBlockedUrl ?? false,
    urlMatchingRules: site?.recordingSettings?.urlMatchingRules
      ? getArrayofUrls(JSON.parse(site.recordingSettings.urlMatchingRules))
      : [],
    blockedUrlMatchingRules: site?.recordingSettings?.blockedUrlMatchingRules
      ? getArrayofUrls(JSON.parse(site.recordingSettings.blockedUrlMatchingRules))
      : [],
    maskElements: site?.recordingSettings?.maskElements ? JSON.parse(site.recordingSettings.maskElements) : [],
  };

  return (
    <Modal dialogClassName="!p-0 w-[640px]" disableOutsideClick={true}>
      <div className="border-b border-mystic-500 px-[30px] pb-6 pt-[30px]">
        <h4 className="text-header-3">Recordings Settings</h4>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          mutateRecordingSettings(
            {
              siteId: site.id,
              pageTargetingEnabled: values.urlMatchingRules[0]?.u ? values.pageTargetingEnabled : false,
              endSessionOnBlockedUrl: values.endSessionOnBlockedUrl,
              urlMatchingRules:
                values.urlMatchingRules.length > 0 && values.urlMatchingRules[0].u && values.pageTargetingEnabled
                  ? `{"rules": ${JSON.stringify(values.urlMatchingRules)}}`
                  : null,
              blockedUrlMatchingRules:
                values.blockedUrlMatchingRules.length > 0 && values.blockedUrlMatchingRules[0].u
                  ? `{"rules": ${JSON.stringify(values.blockedUrlMatchingRules)}}`
                  : null,
              maskElements: values.maskElements[0]?.selector ? JSON.stringify(values.maskElements) : null,
            },
            {
              onError: (error) => {
                notifications.error({
                  content: `Setting update failed for ${site.name}.`,
                  timeout: 3000,
                  context: { error },
                });
              },
              onSuccess: () => {
                notifications.success({ content: `Setting updated successfully for ${site.name}.`, timeout: 3000 });
                modal.close();
              },
            },
          );
        }}
      >
        {({ dirty }) => {
          return (
            <Form>
              <div className="h-[50vh] max-h-[440px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-cadet-blue-500 scrollbar-thumb-rounded">
                <SiteSettingsSection title="What should we record?">
                  <SiteSettingSectionRecordPage siteName={site.name} />
                </SiteSettingsSection>
                <SiteSettingsSection title="What should we ignore?">
                  <SiteSettingsSectionIgnorePage />
                  <SiteSettingsSectionCode />
                  <SiteSettingsSectionIgnoreElements />
                  <SiteSettingsSectionCSSElements />
                </SiteSettingsSection>
              </div>
              <div className="flex items-center space-x-2.5 border-t border-mystic-500 px-[30px] py-6">
                <Button variant="primary" type="submit" disabled={!dirty || isLoading}>
                  {isLoading ? `Saving...` : `Save`}
                </Button>
                <Modal.Cancel />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

function SiteSettingsSection({ title, children }) {
  return (
    <div className="px-[30px] py-5 first:border-b first:border-dashed first:border-mystic-500">
      <h4 className="text-header-5 mb-5">{title}</h4>
      <div>{children}</div>
    </div>
  );
}

function SiteSettingSectionRecordPage({ siteName }) {
  const { values, setFieldValue } = useFormikContext();
  const { pageTargetingEnabled, urlMatchingRules } = values;

  return (
    <div>
      <RadioGroup
        size="sm"
        options={[
          {
            value: false,
            label: `Record all visitors to ${siteName}`,
          },
          { value: true, label: 'Only start recording sessions once visitors land on a specific page' },
        ]}
        value={pageTargetingEnabled}
        onChange={(value) => {
          urlMatchingRules.length < 1 ? setFieldValue('urlMatchingRules', [{ u: '' }]) : null;
          setFieldValue('pageTargetingEnabled', value);
        }}
      />
      {pageTargetingEnabled && (
        <div className="pt-4">
          <SiteSettingsModalListofInputs listData="urlMatchingRules" type="record" />
        </div>
      )}
    </div>
  );
}

function SiteSettingsSectionIgnorePage() {
  const { values, setFieldValue } = useFormikContext();
  const { blockedUrlMatchingRules, endSessionOnBlockedUrl } = values;

  return (
    <div className="mb-5">
      <p className="text-body-1"> What pages should we ignore?</p>
      <p className="text-body-4 mb-[15px] leading-tight">Choose which pages Crazy Egg shouldn’t record.</p>
      {blockedUrlMatchingRules.length > 0 && (
        <div className="mb-[15px]">
          <RadioGroup
            size="sm"
            options={[
              {
                value: false,
                label: `Skip these pages from recording`,
              },
              { value: true, label: 'End recording session when visitor reaches these pages' },
            ]}
            value={endSessionOnBlockedUrl}
            onChange={(value) => {
              setFieldValue('endSessionOnBlockedUrl', value);
            }}
          />
        </div>
      )}
      <SiteSettingsModalListofInputs listData="blockedUrlMatchingRules" type="ignore" showSingleDelete={true} />
    </div>
  );
}

function SiteSettingsSectionCode() {
  return (
    <div className="pb-[30px]">
      <p className="text-body-4 mb-2.5">
        You can also stop a recording by calling{' '}
        <code className="rounded-[3px] border border-mystic-500 bg-white-lilac-500 px-1.25 text-center font-mono text-2xs">
          CE2.stopRecording()
        </code>{' '}
        on your website like so:
      </p>
      <code className="mb-2.5 block whitespace-pre rounded bg-white-lilac-500 p-[15px] font-mono text-2xs text-black-pearl-500">
        {`(window.CE_API || (window.CE_API=[])).push(function(){\n${' '}${' '}CE2.stopRecording();\n});`}
      </code>
      <a
        href={SupportLinks.siteSettings.sessionsTriggeredByJS}
        target="_blank"
        rel="noopener noreferrer"
        className="text-link text-xs font-normal"
      >
        Learn more about recording sessions triggered by JavaScript
      </a>
    </div>
  );
}

function SiteSettingsSectionIgnoreElements() {
  return (
    <div className="mb-5">
      <p className="text-body-1 mb-2.5">Which elements should we ignore?</p>
      <p className="text-body-2">For privacy reasons, Crazy Egg automatically masks the following elements:</p>
      <ul className="text-body-2 my-2.5 ml-5 list-disc">
        <li>Email, password, numeric, and text input fields</li>
        <li>All slider and dial controls</li>
      </ul>
      <p className="text-body-2">
        If you want to mask additional elements, you can add them below by clicking “Add element”, or enter the URL of a
        page with the element you&apos;d like to mask, then find and click on the element to add it to the mask list.{' '}
        <a
          href={SupportLinks.siteSettings.maskingElement}
          target="_blank"
          rel="noopener noreferrer"
          className="text-link font-normal"
        >
          Learn more about masking elements
        </a>
        .
      </p>
    </div>
  );
}

function SiteSettingsSectionCSSElements() {
  return (
    <div className="pb-5">
      <p className="text-body-1 mb-2.5">Mask these elements:</p>
      <div className="flex flex-col">
        <SiteSettingsModalListofInputs
          listData="maskElements"
          type="css"
          buttonName="element"
          placeholder="e.g. input[type=hidden]"
          showSingleDelete={true}
        >
          <div className="flex flex-1 pl-2.5">
            <div className="flex-1">
              <Input
                placeholder="e.g. example.com"
                name={'blocking.url'}
                value={''}
                className="rounded-br-none rounded-tr-none"
                onChange={() => ''}
                disabled={true}
              />
            </div>
            <Button
              variant="secondary"
              className="w-[180px] rounded-bl-none rounded-tl-none pl-2.5 text-[13px]"
              disabled={true}
            >
              <SearchIcon className="mr-1.25 h-4 w-4 fill-current" />
              Find element on page
            </Button>
          </div>
        </SiteSettingsModalListofInputs>
      </div>
      {/* <ElementSelector
      url={}
      editorPath="inspect"
      event-name="page-interaction"
      onSelect={}
      className="h-full w-full"
    /> */}
    </div>
  );
}
