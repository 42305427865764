import { startOfDay, endOfDay, subYears } from 'date-fns';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import { Tooltip, DateRangePicker } from '@crazyegginc/hatch';

import { useHasFeatureFlag } from '/src/hooks';
import { useErrorsFilter } from '../../errors-filter-context';
import { SearchInput } from '/src/components/search-input';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';

export function ErrorsFilterBar() {
  const isHighVolume = useHasFeatureFlag('high-volume-errors-dashboard');
  const { queryParams, setDateRange, setSearch } = useErrorsFilter();

  return (
    <div className="mb-10 flex w-full items-end">
      <Tab.List className="flex border-b border-mystic-500">
        {[
          { title: 'Unresolved errors', tooltip: 'Errors that are still active on your site' },
          { title: 'Resolved errors', tooltip: 'Errors that have been fixed' },
          { title: 'Muted errors', tooltip: 'Active or fixed errors that you want to ignore' },
        ].map((header) => (
          <Tab
            key={header.title}
            className={({ selected }) =>
              classNames(
                'text-header-4 border-b-2 px-5 pb-3 text-dodger-blue-500 focus:outline-none focus-visible:outline-black',
                'flex items-center',
                {
                  'border-dodger-blue-500': selected,
                  'border-transparent': !selected,
                },
              )
            }
            data-testid={header.id}
          >
            {header.title}
            <Tooltip tooltipContent={<div className="w-28">{header.tooltip}</div>}>
              <InfoIcon
                className="ml-1.25 h-3.5 w-3.5 fill-current text-dodger-blue-300"
                aria-label={`Info on ${header.title.toLowerCase()}`}
              />
            </Tooltip>
          </Tab>
        ))}
      </Tab.List>

      <div className="flex-grow"></div>
      {!isHighVolume ? (
        <>
          <div className="mr-2.5">
            <SearchInput
              search={setSearch}
              placeholder="Search for URL or error"
              initialValue={queryParams.search}
              className="!border-solitude-500 !bg-solitude-500 !font-semibold !placeholder-dodger-blue-500/50"
            />
          </div>
          <DateRangePicker
            lowerBoundary={startOfDay(subYears(new Date(), 2))}
            upperBoundary={endOfDay(new Date())}
            startDate={queryParams.dateRange?.start_date}
            endDate={queryParams.dateRange?.end_date}
            special={queryParams.dateRange?.special}
            setDateRange={setDateRange}
            setCommonDateRange={setDateRange}
            showCommonRanges={true}
            maxRange={31}
            size="lg"
          />
        </>
      ) : null}
    </div>
  );
}
