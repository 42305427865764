import { INTERVAL_TYPES } from '/src/features/_global/constants';

export function inflect(input, count) {
  if (count === 1) {
    return input;
  } else {
    return `${input}s`;
  }
}

export function snakeToCamel(input, lowerCase = true) {
  const processedInput = lowerCase ? input.toLowerCase() : input;
  return processedInput.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
}

export function camelToSnake(input) {
  return input
    .split(/(?=[A-Z])/)
    .map((x) => x.toLowerCase())
    .join('_');
}

export function convertKeysToCamelCase(input) {
  if (typeof input !== 'object' || input === null) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map(convertKeysToCamelCase);
  }

  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [snakeToCamel(key), convertKeysToCamelCase(value)]),
  );
}

export function capitalizeFirstLetter(input) {
  return `${input.charAt(0).toUpperCase()}${input.slice(1)}`;
}

export function toTitleCase(input) {
  return input.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function toConstant(input) {
  return input.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toUpperCase();
}

export function convertErrorTypeToConstant(input) {
  const errorType = input.match(/(^[^:|^-]*)|(^Unknown Resource Error)/gi);

  const mainType = camelToSnake(errorType[0].replace(/\s/g, '')).toUpperCase();
  const subType = errorType[1] ? camelToSnake(errorType[1]).toUpperCase() : null;

  return [mainType, subType];
}

export function htmlDecode(input) {
  var e = document.createElement('textarea');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

export function abbreviatedIntervalType(intervalType) {
  switch (intervalType) {
    case INTERVAL_TYPES.DAYS:
      return 'd';
    case INTERVAL_TYPES.MONTHS:
      return 'mo';
    case INTERVAL_TYPES.YEARS:
      return 'yr';
    default:
      return '-';
  }
}

export function humanizeList(items, process = (item) => item) {
  const last = items.pop();
  return `${items.map((item) => process(item)).join(', ')} and ${process(last)}`;
}

export function stringGen(len) {
  let text = '';
  const charset = 'abcdefghijklmnopqrstuvwxyz';

  for (var i = 0; i < len; i++) text += charset.charAt(Math.floor(Math.random() * charset.length));

  return text;
}

export const foobars = [
  'foo',
  'bar',
  'baz',
  'qux',
  'quux',
  'corge',
  'grault',
  'garply',
  'waldo',
  'fred',
  'plugh',
  'xyzzy',
  'thud',
  'foobar',
  ...Array.from({ length: 100 }).map(() => stringGen(3)),
];

export const foobarGen = (i) => () => foobars[i++];
