/* eslint-disable no-restricted-syntax */
import { useState, useRef, useEffect, forwardRef, useLayoutEffect, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useScrollYPosition } from 'react-use-scroll-position';
import { Tooltip, Divider } from '@crazyegginc/hatch';

import { ReactComponent as SnapshotIcon } from '@crazyegginc/hatch/dist/images/icon-camera-filled.svg';
import { ReactComponent as RecordingsIcon } from '@crazyegginc/hatch/dist/images/icon-recording-filled.svg';
import { ReactComponent as AbTestingIcon } from '@crazyegginc/hatch/dist/images/icon-ab-test-outline.svg';
import { ReactComponent as EditorIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as HelpIcon } from '@crazyegginc/hatch/dist/images/icon-help-filled.svg';
import { ReactComponent as OptionsIcon } from '@crazyegginc/hatch/dist/images/icon-cog-filled.svg';
import { ReactComponent as CommentIcon } from '@crazyegginc/hatch/dist/images/icon-comment-outline.svg';
import { ReactComponent as WhatsNewIcon } from '@crazyegginc/hatch/dist/images/icon-megaphone-filled.svg';
import { ReactComponent as InstallIcon } from '@crazyegginc/hatch/dist/images/icon-check-install-outline.svg';
// import { ReactComponent as ExpandIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-download.svg';
import { ReactComponent as ChartIcon } from '@crazyegginc/hatch/dist/images/icon-chart-filled.svg';
import { ReactComponent as BalloonIcon } from '@crazyegginc/hatch/dist/images/icon-logo-ce-balloon.svg';
import { ReactComponent as ErrorIcon } from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg';
import { ReactComponent as CeLogoIcon } from '@crazyegginc/hatch/dist/images/logo-crazy-egg-solid.svg';
import { ReactComponent as SurveyIcon } from '@crazyegginc/hatch/dist/images/icon-survey-filled.svg';
import { ReactComponent as CTAsIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';
import { ReactComponent as LiveIcon } from '@crazyegginc/hatch/dist/images/icon-list-outline.svg';
import { ReactComponent as GoalsIcon } from '@crazyegginc/hatch/dist/images/icon-goal-filled.svg';
import { ReactComponent as FunnelIcon } from '@crazyegginc/hatch/dist/images/icon-filter-filled.svg';
import { ReactComponent as HomeIcon } from '@crazyegginc/hatch/dist/images/icon-home-filled.svg';

import { FEATURES, CAPABILITY_REASONS } from '/src/features/_global/constants';
import { SupportLinks } from '/src/support';

import { changeLogQuery } from '/src/features/whats-new/queries';
import { FeatureUsage } from '/src/components/usage';
import { getSessionQueryParameters } from '/src/utils/url';
import { useMockyNavSidebar } from '/src/features/commenting/mockyNavSidebar';
import { usePermissions } from '/src/hooks';

import { AccountSelector } from '/src/components/layout/navbar/AccountSelector';
import { GettingStarted } from '/src/features/getting-started/components/GettingStarted';

function FeatureLink(
  {
    secondary = false,
    to,
    onClick = null,
    title,
    icon: Icon,
    expanded = true,
    showBadge = false,
    badgeCount = null,
    tooltipText = null,
    customActive = null,
  },
  ref,
) {
  const location = useLocation();
  const isCustomActive = customActive?.(location);

  return (
    <Tooltip placement="right" tooltipContent={tooltipText || title} show={!expanded}>
      <div
        ref={ref}
        className={classNames('relative h-10 w-full', {
          'w-full': expanded,
          'w-10': !expanded,
        })}
      >
        <NavLink
          to={
            typeof to === 'object'
              ? {
                  pathname: to.pathname,
                  search: `${to.search}${getSessionQueryParameters() ? `&${getSessionQueryParameters()}` : ''}`,
                }
              : { pathname: to, search: `?${getSessionQueryParameters()}` }
          }
          className={({ isActive }) =>
            classNames('pointer group box-border flex h-10 w-full items-center p-2.5 no-underline hover:bg-white', {
              'bg-white bg-opacity-[0.25] hover:bg-opacity-[0.25]': isCustomActive != null ? isCustomActive : isActive,
              'hover:bg-opacity-[0.15]': isCustomActive != null ? !isCustomActive : !isActive,
              'rounded-l': expanded,
              'justify-center rounded': !expanded,
            })
          }
          onClick={onClick}
          aria-label={title}
        >
          <div className="relative">
            <Icon className="h-4 w-4 fill-current text-white" />
            {showBadge ? <Badge size={badgeCount === null ? '12px' : '16px'}>{badgeCount}</Badge> : null}
          </div>
          {expanded ? (
            <span
              className={classNames('ml-2.5', {
                'text-nav-primary': !secondary,
                'text-nav-secondary': secondary,
              })}
            >
              {title}
            </span>
          ) : null}
        </NavLink>
      </div>
    </Tooltip>
  );
}
// eslint-disable-next-line
FeatureLink = forwardRef(FeatureLink);

export function NavBar() {
  const navFixedContent = useRef();
  const navStickyContent = useRef();
  // https://app.shortcut.com/crazyegg/story/25277
  // const [expanded, setExpanded] = useState(() => window.localStorage.getItem('shell:expanded') === '1');
  const expanded = true;
  const permissions = usePermissions();
  const [minHeight, setMinHeight] = useState(0);
  const commentsRef = useRef();
  const { isCommentsOpen, newNotificationExist } = useMockyNavSidebar({ commentsRef });

  useEffect(() => {
    window.localStorage.setItem('shell:expanded', expanded ? '1' : '0');
  }, [expanded]);

  const scrollY = useScrollYPosition();
  const shouldBeFixed = useMemo(() => window.innerHeight + scrollY > minHeight, [minHeight, scrollY]);

  const canVisitOptions = permissions.can('navigate', FEATURES.OPTIONS).allowed;
  const canViewComments = permissions.can('navigate', FEATURES.COMMENTING).allowed;
  const canVisitHelp = permissions.can('navigate', 'Help').allowed;
  const canVisitWhatsNew = permissions.can('navigate', FEATURES.WHATS_NEW).allowed;
  const canViewInstall = permissions.can('view', FEATURES.INSTALLATION).allowed;
  const usagePermission = permissions.can('navStatus', FEATURES.BILLING);

  const canVisitLive = permissions.can('navigate', FEATURES.LIVE_ACTIVITY).allowed;
  const canVisitSnapshots = permissions.can('navigate', FEATURES.SNAPSHOTS).allowed;
  const canVisitRecordings = permissions.can('navigate', FEATURES.RECORDINGS).allowed;
  const canVisitGoals = permissions.can('navigate', FEATURES.GOALS).allowed;
  const canVisitAbTesting = permissions.can('navigate', FEATURES.AB_TESTING).allowed;
  const canVisitTraffic = permissions.can('navigate', FEATURES.TRAFFIC_ANALYSIS).allowed;
  const canVisitErrors = permissions.can('navigate', FEATURES.ERRORS_TRACKING).allowed;
  const canVisitSurveys = permissions.can('navigate', FEATURES.SURVEYS).allowed;
  const canVisitCTAs = permissions.can('navigate', FEATURES.CTAS).allowed;

  const canVisitEditor = permissions.can('navigate', 'EditorDashboard');

  const canVisitFunnel = permissions.can('navigate', FEATURES.FUNNEL).allowed;
  const canVisitHome = permissions.can('navigate', 'HOME').allowed;

  const { data: changelogData } = useQuery({
    ...changeLogQuery({ newOnly: true }),
    enabled: canVisitWhatsNew,
  });

  const unseenEvents = (changelogData?.changelog ?? []).filter((event) => event.seenAt === null);
  const unseenEventsCount = unseenEvents.length;
  const hasUnseenEvents = unseenEventsCount > 0;

  useLayoutEffect(() => {
    setTimeout(() => {
      const fixedBox = navFixedContent.current?.getBoundingClientRect();
      const stickyBox = navStickyContent.current?.getBoundingClientRect();

      if (!fixedBox || !stickyBox) return;

      setMinHeight(fixedBox.height + (fixedBox.top + scrollY) + stickyBox.height);
    }, 100);
  }, [expanded]); // eslint-disable-line

  return (
    <nav
      id="navbar"
      style={{ minHeight: `${minHeight}px` }}
      className={classNames('box-border flex flex-shrink-0 flex-col bg-[#00509D]', {
        'w-[180px]': expanded,
        'w-[60px]': !expanded,
      })}
      data-testid="left-nav"
    >
      <div
        className={classNames({
          'flex-1': shouldBeFixed,
        })}
      >
        <div ref={navFixedContent}>
          {expanded ? (
            <CeLogoIcon
              className="mx-auto mb-[33px] mt-[28px] h-[59px] w-[112px] fill-current text-white"
              aria-label="Crazy Egg logo"
            />
          ) : (
            <BalloonIcon
              className="mx-auto mb-[33px] mt-[28px] h-[48px] w-[25px] fill-current text-white"
              aria-label="Crazy Egg logo"
            />
          )}

          <div className="px-3">
            <GettingStarted />
          </div>

          <div
            className={classNames('flex flex-col p-0', {
              'w-[calc(100% - 15px)] ml-[15px]': expanded,
              'mx-auto w-10': !expanded,
            })}
          >
            <div className="flex flex-col gap-y-[3px]">
              {canVisitHome ? <FeatureLink title="Home" to="/home" icon={HomeIcon} expanded={expanded} /> : null}
              {canVisitLive ? (
                <FeatureLink title="Live Activity" to="/live" icon={LiveIcon} expanded={expanded} />
              ) : null}
              {canVisitGoals ? <FeatureLink title="Goals" to="/goals" icon={GoalsIcon} expanded={expanded} /> : null}
              {canVisitSnapshots ? (
                <FeatureLink title="Snapshots" to="/snapshots" icon={SnapshotIcon} expanded={expanded} />
              ) : null}
              {canVisitRecordings ? (
                <FeatureLink title="Recordings" to="/recordings" icon={RecordingsIcon} expanded={expanded} />
              ) : null}
              {canVisitErrors ? <FeatureLink title="Errors" to="/errors" icon={ErrorIcon} expanded={expanded} /> : null}
              {canVisitTraffic ? (
                <FeatureLink title="Traffic" to="/traffic" icon={ChartIcon} expanded={expanded} />
              ) : null}
              {canVisitFunnel ? (
                <FeatureLink title="Funnel" to="/funnel" icon={FunnelIcon} expanded={expanded} />
              ) : null}
              {canVisitAbTesting ? (
                <FeatureLink title="A/B Testing" to="/ab-tests" icon={AbTestingIcon} expanded={expanded} />
              ) : null}
              {canVisitEditor ? (
                <FeatureLink title="Editor" to="/page-edits" icon={EditorIcon} expanded={expanded} />
              ) : null}

              {canVisitSurveys ? (
                <FeatureLink
                  title="Surveys"
                  customActive={(location) => {
                    if (location.pathname !== '/addons') return false;
                    const search = new URLSearchParams(location.search);
                    return search.get('type') === FEATURES.SURVEYS;
                  }}
                  to={{ pathname: '/addons', search: `type=${FEATURES.SURVEYS}` }}
                  icon={SurveyIcon}
                  expanded={expanded}
                />
              ) : null}
              {canVisitCTAs && (
                <FeatureLink
                  title="CTAs"
                  customActive={(location) => {
                    if (location.pathname !== '/addons') return false;
                    const search = new URLSearchParams(location.search);
                    return search.get('type') === FEATURES.CTAS;
                  }}
                  to={{ pathname: '/addons', search: `type=${FEATURES.CTAS}` }}
                  icon={CTAsIcon}
                  expanded={expanded}
                />
              )}
            </div>

            <Divider
              className={classNames('mb-[23px] mt-[21px] !border-[#3386bf]', {
                'mr-[25px] !w-auto': expanded,
              })}
            />

            <div className="flex flex-col gap-y-[3px]">
              {canVisitHelp ? (
                <FeatureLink
                  secondary={true}
                  title="Help"
                  to={SupportLinks.general.helpCenter}
                  icon={HelpIcon}
                  expanded={expanded}
                  tooltipText="Help center"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(SupportLinks.general.helpCenter, '_blank');
                  }}
                />
              ) : null}

              {canVisitOptions ? (
                <FeatureLink secondary={true} title="Options" icon={OptionsIcon} to="/options" expanded={expanded} />
              ) : null}

              {canViewComments ? (
                <FeatureLink
                  secondary={true}
                  title="Comments"
                  icon={CommentIcon}
                  ref={commentsRef}
                  onClick={() => window.Mocky?.toggleNotifications()}
                  showBadge={newNotificationExist}
                  expanded={expanded}
                  customActive={() => isCommentsOpen}
                />
              ) : null}

              {canVisitWhatsNew ? (
                <FeatureLink
                  secondary={true}
                  title="What's new"
                  to="/new"
                  icon={WhatsNewIcon}
                  showBadge={hasUnseenEvents}
                  badgeCount={unseenEventsCount}
                  expanded={expanded}
                />
              ) : null}

              {canViewInstall ? (
                <FeatureLink
                  secondary={true}
                  title="Install Crazy Egg"
                  to="/install"
                  icon={InstallIcon}
                  expanded={expanded}
                />
              ) : null}
            </div>
          </div>

          {(usagePermission.allowed || usagePermission.reason !== CAPABILITY_REASONS.HIDDEN) && (
            <>
              <Divider
                className={classNames('mb-[30px] mt-5 !border-[#3386bf]', {
                  'mx-[25px] !w-auto': expanded,
                  'mx-auto !w-10': !expanded,
                })}
              />

              <div className="pb-5">
                <FeatureUsage
                  cta_id="navbar"
                  locked={usagePermission.reason === CAPABILITY_REASONS.SUBSCRIPTION_ISSUE}
                  expanded={expanded}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div
        ref={navStickyContent}
        className={classNames('sticky bottom-0 z-50 flex flex-col items-center justify-center bg-[#00509D]', {
          'w-[180px]': expanded,
          'w-[60px]': !expanded,
        })}
      >
        {/*
        <button
          onClick={() => setExpanded((e) => !e)}
          className={classNames(
            'pointer box-border flex h-10 items-center justify-center p-2.5 no-underline hover:bg-white hover:bg-opacity-[0.15] focus-visible:outline-white',
            {
              'w-[180px]': expanded,
              'w-[60px]': !expanded,
            },
          )}
        >
          <ExpandIcon
            aria-label="expand"
            className={classNames('h-4 w-4 fill-current text-white opacity-50', {
              'rotate-90': expanded,
              '-rotate-90': !expanded,
            })}
          />
          {expanded ? <span className="ml-2 text-sm font-semibold text-white opacity-50">Minimize bar</span> : null}
        </button>
        */}

        <Divider className="!border-[#3386bf]" />

        <AccountSelector expanded={expanded} />
      </div>
    </nav>
  );
}

function Badge({ size, children }) {
  return (
    <span
      className="badge absolute -right-1.25 -top-1.5 flex items-center justify-center rounded-full border-2 border-[#00509d] bg-radical-red-500 text-[9px] font-bold leading-[11px] tracking-normal text-white opacity-100 group-active:border-[#3f6ba7] group-hover:border-[#3f6ba7]"
      style={{ height: size, width: size }}
    >
      {children}
    </span>
  );
}
