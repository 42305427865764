import { FEATURES, PERMISSION_NOT_ALLOWED } from '/src/features/_global/constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';

export const AB_TESTING_PERMISSIONS = {
  [FEATURES.AB_TESTING]: ({ capabilities, isSharing, sharedResource }) => {
    const sharedResourceIsABTest = sharedResource?.resource?.resourceType === SHARABLE_RESOURCE_TYPES.ABTEST ?? false;
    return isSharing
      ? {
          navigate: PERMISSION_NOT_ALLOWED,
          view: sharedResourceIsABTest ? { allowed: true } : PERMISSION_NOT_ALLOWED,
          edit: PERMISSION_NOT_ALLOWED,
          create: PERMISSION_NOT_ALLOWED,
        }
      : {
          navigate: capabilities.abTesting.permissions.nav,
          view: capabilities.abTesting.permissions.view,
          edit: capabilities.abTesting.permissions.edit,
          create: capabilities.abTesting.permissions.create,
        };
  },
};
