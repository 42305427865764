import { NewAddonButton } from '/src/features/addons/common/NewAddonButton';
import { Paywall } from '/src/components/Paywall';

import AddonPNG from '@crazyegginc/hatch/dist/images/illustration-addons.png';
import AddonWebP from '@crazyegginc/hatch/dist/images/illustration-addons.webp';

function NoAddonsWall() {
  return (
    <Paywall>
      <Paywall.Image webp={AddonWebP} fallback={AddonPNG} width="175px" />
      <Paywall.Title>Create your first Add-On!</Paywall.Title>

      <Paywall.Body>
        Learn from your visitors — ask how they feel,
        <br /> what they wish was different, and more!
      </Paywall.Body>
      <NewAddonButton size="base" disableIfNoPermission={true} />
    </Paywall>
  );
}

NoAddonsWall.displayName = 'NoAddonsWall';

export { NoAddonsWall };
