// Global singleton used to store general app configuration used to interact with the
// our external systems. Called if Core because without it the app can't know who to
// interact with.

class AppConfig {
  #skipV2Auth = false;

  legacyCoreBaseURL() {
    if (process.env.NODE_ENV === 'test') {
      return 'http://localhost';
    }
    return window.LEGACY_APP_URL;
  }

  authBaseURL() {
    if (process.env.NODE_ENV === 'test') {
      return 'http://localhost';
    }
    return window.AUTH_API_URL;
  }

  isV2AuthEnabled() {
    return !!this.authBaseURL() && !this.skipV2Auth;
  }

  setSkipV2Auth(skipV2Auth) {
    this.skipV2Auth = skipV2Auth;
  }
}

const singleton = new AppConfig();
export { singleton as AppConfig };
