import { ReactComponent as CloseIcon } from '@crazyegginc/hatch/dist/images/icon-cross.svg';

export function ConsoleErrors({ onClose }) {
  const { errors } = window.console;

  return (
    <div className="fixed bottom-0 left-0 right-0 mx-auto flex max-h-[50vh] w-[98%] flex-col rounded-t-lg bg-black-pearl-500 px-2 pb-1 pt-2 text-white shadow">
      {window.ceSessionId ? <span className="text-body-3 mb-1">Session ID: {window.ceSessionId}</span> : null}
      <ul>
        {errors.map((err, idx) => (
          <li key={`console-error-${idx}`} className="mb-1 rounded bg-mako-500 bg-opacity-90 px-3 py-2 last:mb-0">
            <RenderError error={err} />
          </li>
        ))}
      </ul>

      <button
        className="absolute -top-11 right-1 flex h-9 w-9 items-center justify-center rounded-full border-2 border-black-pearl-500 bg-black-pearl-500 p-2 text-white shadow-lg transition ease-linear hover:bg-white hover:text-black-pearl-500"
        onClick={() => onClose()}
      >
        <CloseIcon className="h-3 w-3 fill-current" aria-label="close error dialog" />
      </button>
    </div>
  );
}

function RenderError({ error }) {
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (Array.isArray(error) && error.length === 1) {
    const [err] = error;
    if (err instanceof Error) {
      return err.message;
    }
    return String(err);
  }

  if (Array.isArray(error) && error.length > 1) {
    const [heading, actualError] = error;
    if (actualError instanceof Error) {
      return `${heading}: ${actualError.message}`;
    }
    return `${heading}: ${String(actualError)}`;
  }
}
